import update from 'immutability-helper'

export const TOGGLED_CONFIRM_DELETE_ISSUE = 'TOGGLED_CONFIRM_DELETE_ISSUE'

export const handleToggledConfirmDeleteIssue = (state, action) => update(state, {
    confirmDeleteIssue: {
        isOpen: {$set: action.state},
        issueId: {$set: action.issueId}
    }
})

export function toggledConfirmDeleteIssue (
    state = undefined,
    issueId = undefined
) {
    return {
        type: TOGGLED_CONFIRM_DELETE_ISSUE,
        state,
        issueId
    }
}

export const openConfirmDeleteIssue = (
    issueId = undefined
) => {
    return (dispatch, getState) => {
        dispatch(toggledConfirmDeleteIssue(true, issueId))
    }
}

export const closeConfirmDeleteIssue = () => {
    return (dispatch, getState) => {
        dispatch(toggledConfirmDeleteIssue(false, undefined))
    }
}

export const confirmDeleteIssue = { isOpen: false, issueId: undefined }
