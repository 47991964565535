import update from 'immutability-helper'

export const TOGGLED_PICK_ORDER = 'TOGGLED_PICK_ORDER'

export const handleToggledPickOrder = (state, action) => update(state, {
    pickOrder: {
        isOpen: {$set: action.state},
        orderId: {$set: action.orderId}
    }
})

export function toggledPickOrder (
    state = undefined,
    orderId = undefined
) {
    return {
        type: TOGGLED_PICK_ORDER,
        state,
        orderId
    }
}

export const openPickOrder = (
    orderId = undefined
) => {
    return (dispatch, getState) => {
        dispatch(toggledPickOrder(true, orderId))
    }
}

export const closePickOrder = () => {
    return (dispatch, getState) => {
        dispatch(toggledPickOrder(false, undefined))
    }
}

export const pickOrder = { isOpen: false, orderId: undefined }
