import React from 'react'
import ImagePreview from '../UploadImageForm/ImagePreview'
import Placeholder from '../UploadImageForm/placeholder'
import { Form, Col, InputGroup } from 'react-bootstrap'
import Dropzone from 'react-dropzone'

export const renderFormDropzone = ({
    handleOnDrop,
    input,
    imagefile,
    label,
    showLeftLabel = false,
    text,
    meta: { error, touched }
}) => {
    return (
        <Form.Group as={Col}>
            <InputGroup>
                {showLeftLabel && <Form.Label column sm={6}>{label}</Form.Label>}
                <Dropzone
                    accept='image/jpeg, image/png, image/gif, image/bmp'
                    className='upload-container'
                    // onDrop={handleOnDrop}
                    onDropAccepted={(file) => {
                        // input.onChange(file)
                        handleOnDrop(file)
                    }}>
                        {({getRootProps, getInputProps}) => (
                            <section style={{width: '100%'}}>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {imagefile && <ImagePreview imagefile={imagefile} />}
                                    {!imagefile && <Placeholder error={error} touched={touched} />}
                                </div>
                                {text && <p>{text}</p>}
                            </section>
                        )}
                </Dropzone>
            </InputGroup>
        </Form.Group>
    );
}

/*
<DropzoneComponent handleDrop={handleOnDrop} />
*/
