import update from 'immutability-helper'

import {
  loadCountries,
  LOADED_COUNTRIES,
  handleLoadedCountries
} from './LoadCountries'

export { loadCountries }

// ------------------------------------
// Constants
// ------------------------------------
export const COUNTRIES_UPDATING = 'COUNTRIES_UPDATING'
export const COUNTRIES_LOADED = 'COUNTRIES_LOADED'

// ------------------------------------
// Actions
// ------------------------------------
export const countriesIsUpdating = (
    value = true
) => ({
    type: COUNTRIES_UPDATING,
    payload: value
})

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [COUNTRIES_UPDATING]: (state, action) => (
        update(state, {
            isUpdating: {$set: action.payload}
        })
    ),

    [LOADED_COUNTRIES]: (state, action) => handleLoadedCountries(state, action)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    isUpdating: false,
    items: []
}

export default function countriesReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
