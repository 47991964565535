import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'

/**
 * Inititator
 */
export const requestPassword = (
    email = undefined
) => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/auth/forgot/`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: {
                    email
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    resolve()
                },
                success (resp) {
                    resolve()
                }
            })
        })
    }
}
