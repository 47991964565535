import React from 'react';
import { connect } from 'react-redux';
import { Pagination } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import queryString from 'query-string';

const generatePages = (location, links, meta) => {
    const r = 4;
    const start = 1;
    const { last_page: end } = meta;
    const { current_page: current } = meta;

    // // [1 2 3 ... 8 9 10 ... 21 22 23]
    // const pages = Array(end - start + 1).fill().map((_, idx) => start + idx)
    //     .filter(i => (i < r || (i < current + r && i > current - r) || i > end - r))
    //     .map(p => (
    //         <LinkContainer to={`?page=${p}`} key={`page.${p}`}>
    //             <Pagination.Item active={current === p}>{p}</Pagination.Item>
    //         </LinkContainer>
    //     ))

    const { search } = location;
    const args = queryString.parse(search);

    // [... 2 3 4 5 6 7 ...]
    const pages = Array(end - start + 1).fill().map((_, idx) => start + idx)
        .filter(i => (i < current + r && i > current - r))
        .map(p => (
            <LinkContainer to={`?${queryString.stringify({...args, ...{page: p}})}`} key={`page.${p}`}>
                <Pagination.Item active={current === p}>{p}</Pagination.Item>
            </LinkContainer>
        ))

    return (
        <Pagination>
            {pages}
        </Pagination>
    );
}

function PaginationView({ location, data }) {

    const { links, meta } = data;

    const linksExists = links && Object.keys(links).length > 0;
    const metaExists = meta && Object.keys(meta).length > 0;

    if (!linksExists || !metaExists) {
        return (
            <>
                <div />
            </>
        );
    }

    return (
        <>
            {generatePages(location, links, meta)}
        </>
    );
}

export default connect((state, ownProps) => ({
}), {
})(PaginationView);
