/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// App components
import CartTable from '../CartTable'
import CartTotalsTable from '../CartTotalsTable'
import renderLoading from '../../renderLoading'
import { removeIssue, setQuantity } from '../../reducers/modules/carts'

// State
// ...

// Styles
// import * as classes from './CheckoutStageOne.module.scss'


class CheckoutStageOne extends React.Component {
    static propTypes = {
        cartData: PropTypes.string.isRequired,
        settings: PropTypes.object.isRequired,
        address: PropTypes.object.isRequired,
        postages: PropTypes.object.isRequired,

        removeIssue: PropTypes.func.isRequired,
        setQuantity: PropTypes.func.isRequired,
        isStore: PropTypes.bool.isRequired
    }
  
    constructor (props) {
        super(props)

        this.removeIssue = this.removeIssue.bind(this)
        this.updateQuantity = this.updateQuantity.bind(this)
    }

    removeIssue (issueId, cartId) {
        this.props.removeIssue(issueId, cartId)
    }

    updateQuantity (issueId, cartId, quantity) {
        this.props.setQuantity(issueId, cartId, quantity)
    }

    render () {
        const { cartData, settings, address, postages, isStore } = this.props
        
        const displayTable = (cartData && address && settings.data && postages.domestic.length > 0 && postages.international.length > 0)

        return (<>
            <h4>Varukorg</h4>
            {!displayTable && renderLoading()}
            {displayTable && <CartTable
                editable={true}
                deleteable={true}
                cartData={cartData}
                onRemoveIssue={this.removeIssue}
                onUpdateQuantity={this.updateQuantity} />}
            <h4>Totalt</h4>
            {!displayTable && renderLoading()}
            {displayTable && <CartTotalsTable displayVat={true} cartData={cartData} address={address} postages={postages} settings={settings} isStore={isStore} />}
        </>)
    }
}

const mapStateToProps = (state) => ({
})
export default connect((mapStateToProps), {
    removeIssue,
    setQuantity,
})(CheckoutStageOne)

