import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form } from 'react-bootstrap';
import { renderFormField } from '../../components/FormHelper';
// import * as classes from './SelectYearForm.module.scss';

const validate = values => {
    const errors = {}
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

function FilterOrdersYear(props) {

    const { handleSubmit, defaultValue } = props;

    return (
        <Form onSubmit={handleSubmit}>
            <p><b>Visa ordrar för år...</b></p>
            <Form.Row>
                <Field
                    name='year'
                    placeholder='År'
                    submitIcon={'search'}
                    component={renderFormField}
                    defaultValue={defaultValue} />
            </Form.Row>
        </Form>
    );
}

export default connect(
    (state, ownProps) => ({
        initialValues: ownProps.defaultValue
    })
)(
    reduxForm({
        form: 'filterOrdersYear',
        validate,
        warn,
        enableReinitialize : true
    })(FilterOrdersYear)
);
