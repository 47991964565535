import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { renderForm } from '../../components/FormHelper';
// import * as classes from './LoginForm.module.scss';

const validate = values => {
    const errors = {};
    if (!values.email) {
        errors.email = 'Obligatorisk';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Ogiltig E-Post adress';
    }
    if (!values.password) {
        errors.password = 'Obligatorisk';
    } else if (values.password.length < 1) {
        errors.password = 'Lite för kort lösenord...';
    }
    return errors;
}

const warn = values => {
  const warnings = {};
  return warnings;
}

function LoginForm({ handleSubmit, handleRegister, handleResetPassword, pristine, submitting }) {

    const [showPassword, setShowPassword] = useState(false);
    const [resetEmail, setResetEmail] = useState('');

    const onResetEmailChanged = (email) => {
        setResetEmail(email);
    }
    
    const showForgotPassword = () => {
        setShowPassword(!showPassword);
    }

    const forgotPasswordText = (showPassword ? 'Dölj' : 'Jag har glömt mitt lösenord')

    let fields = [
        { name: 'email', label: 'E-Post', icon: 'envelope', fieldType: 'field' },
        { name: 'password', label: 'Lösenord', icon: 'key', fieldType: 'field', type: 'password' },
        [
            { name: 'submit', variant: 'dark', label: 'Logga in', fieldType: 'submit' },
            { name: 'register', variant: 'outline-dark', label: 'Registrera', fieldType: 'button', callback: handleRegister }
        ],
        { name: 'forgot-password', label: forgotPasswordText, fieldType: 'button', variant: 'link', callback: showForgotPassword },
    ];

    if (showPassword) {
        fields.push([
            { name: 'forgot-password-email', label: 'Skriv in din e-post address', icon: 'envelope', fieldType: 'field', onChange: (e) => onResetEmailChanged(e.target.value), appendButton: {
                name: 'forgot-password-button', variant: 'dark', label: 'Skicka', fieldType: 'button', callback: () => { handleResetPassword(resetEmail) }}
            },
        ])
    }

    return (
        <Form onSubmit={handleSubmit}>
            {renderForm(fields)}
        </Form>
    );
}

export default connect(
    (state, ownProps) => ({
    })
)(
    reduxForm({
        form: 'login',
        validate,
        warn,
        enableReinitialize : true
    })(LoginForm)
);
