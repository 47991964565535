import React from 'react'
import PropTypes from 'prop-types'

import LoginModal from '../LoginModal'
import PickOrderModal from '../PickOrderModal'
import DivideOrderModal from '../DivideOrderModal'
import BuybackOrderModal from '../BuybackOrderModal'
import EditMagazineModal from '../EditMagazineModal'
import DeleteCategoryModal from '../DeleteCategoryModal'
import DeleteMagazineModal from '../DeleteMagazineModal'
import NotifyUsersModal from '../NotifyUsersModal'
import SetTransactionIdModal from '../SetTransactionIdModal'
import ConfirmDeleteIssueModal from '../ConfirmDeleteIssueModal'
import PrintReceiptModal from '../PrintReceiptModal/PrintReceiptModal'

function ModalHolder ({ children, ...props }) {
  return (
    <div>
      <LoginModal {...props} />
      <PickOrderModal />
      <DivideOrderModal />
      <BuybackOrderModal />
      <EditMagazineModal />
      <DeleteCategoryModal />
      <DeleteMagazineModal />
      <NotifyUsersModal />
      <SetTransactionIdModal />
      <ConfirmDeleteIssueModal />
      <PrintReceiptModal />
    </div>
  )
}

ModalHolder.propTypes = {
  children: PropTypes.element
}

export default ModalHolder
