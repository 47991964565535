/* @flow */
import update from 'immutability-helper'

// Load domestic
import {
  loadDomestic,
  LOADED_DOM_POSTAGES,
  handleLoadedDomestic
} from './LoadDomestic'

// Create domestic
import {
  createDomestic,
  CREATED_DOM_POSTAGE,
  handleCreatedDomestic
} from './CreateDomestic'

// Remove domestic
import {
  removeDomestic,
  REMOVED_DOM_POSTAGE,
  handleRemovedDomestic
} from './RemoveDomestic'

// Load international
import {
  loadInternational,
  LOADED_INT_POSTAGES,
  handleLoadedInternational
} from './LoadInternational'

// Create international
import {
  createInternational,
  CREATED_INT_POSTAGE,
  handleCreatedInternational
} from './CreateInternational'

// Remove international
import {
  removeInternational,
  REMOVED_INT_POSTAGE,
  handleRemovedInternational
} from './RemoveInternational'

export { loadDomestic, createDomestic, removeDomestic }
export { loadInternational, createInternational, removeInternational }

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATING_POSTAGES = 'UPDATING_POSTAGES'
export const CLEAR_ALL_POSTAGES = 'CLEAR_ALL_POSTAGES'

const INCREMENT = 1;
const DECREMENT = 2;

// ------------------------------------
// Actions
// ------------------------------------
export function pushUpdating () {
  return {
    type: UPDATING_POSTAGES,
    payload: INCREMENT
  }
}

export function popUpdating () {
  return {
    type: UPDATING_POSTAGES,
    payload: DECREMENT
  }
}

/**
 * Public function
 * Attempt to fetch the list of all postages
 */
export const loadAllPostages = () => {
  return (dispatch, getState) => {
    dispatch(loadDomestic())
    dispatch(loadInternational())
  }
}

/**
 * Public function
 * Tell the store to clear out all the postages
 */
export function clearPostages () {
  return {
    type: CLEAR_ALL_POSTAGES
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [UPDATING_POSTAGES]: (state, action) => {
    if (INCREMENT === action.payload) {
      return update(state, { isUpdating: {$set: state.isUpdating + 1}})
    } else if (DECREMENT === action.payload) {
      return update(state, { isUpdating: {$set: state.isUpdating - 1}})
    } else {
      return state
    }
  },

  [LOADED_INT_POSTAGES]: (state, action) => handleLoadedInternational(state, action),

  [CREATED_INT_POSTAGE]: (state, action) => handleCreatedInternational(state, action),

  [REMOVED_INT_POSTAGE]: (state, action) => handleRemovedInternational(state, action),

  [LOADED_DOM_POSTAGES]: (state, action) => handleLoadedDomestic(state, action),

  [CREATED_DOM_POSTAGE]: (state, action) => handleCreatedDomestic(state, action),

  [REMOVED_DOM_POSTAGE]: (state, action) => handleRemovedDomestic(state, action),
  
  [CLEAR_ALL_POSTAGES]: (state, action) => (
    update(state, {$set: initialState})
  )
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isUpdating: 0,
  domestic: [],
  international: []
}

export default function postagesReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
