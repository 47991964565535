import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating} from './index'

/**
 * Actions
 */
export const DELETED_ORDER = 'DELETED_ORDER'


/**
 * Dispatcher
 * 
 * @param {*} index 
 */
export function deletedOrder (
    index = undefined
) {
    return {
        type: DELETED_ORDER,
        index: index
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleDeletedOrder = (state, action) => update(state, {
    data: {$splice: [[action.index, 1]]}
})


/**
 * Inititator
 * 
 * @param {*} userId 
 */
export const deleteOrder = (
    orderId = undefined
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/orders/${orderId}`,
                method: 'DELETE',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    const index = getState().orders.data.map(o => o.id).indexOf(parseInt(resp.data.id))
                    if (index > -1) {
                        dispatch(deletedOrder(index))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
