import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const LOADED_USER_CARTS = 'LOADED_USER_CARTS'

/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export const loadedCarts = (
    carts = []
) => ({
    type: LOADED_USER_CARTS,
    payload: carts
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadedCarts = (state, action) => update(state, {
    carts: {$set: action.payload}
})


/**
 * Initiator
 */
export const loadCarts = (
    userId = undefined
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/users/${userId}/carts`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: {},
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    if (resp.data) {
                        dispatch(loadedCarts(resp.data))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
