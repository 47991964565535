import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm, initialize } from 'redux-form';
import { Form } from 'react-bootstrap';
import { renderForm } from '../../components/FormHelper';
// import * as classes from './PermissionsForm.module.scss';

const validate = values => {
    const errors = {}
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

const generatePermissions = (permissions) => (
    { permissions: permissions }
)

function PermissionsForm(props) {

    const { permissions, handleSubmit, initialize } = props;
    
    useEffect(() => {
        if (permissions) {
            // initialize(generatePermissions(permissions))
        }
    }, [permissions, initialize]);

    const fields = [
        { name: 'permissions', label: 'Rättigheter', fieldType: 'field' },
        { name: 'submit', variant: 'dark', label: 'Spara', fieldType: 'submit' }
    ];

    return (
        <>
            <Form onSubmit={handleSubmit}>
                {renderForm(fields)}
            </Form>
        </>
    );
}

export default connect(
    (state, ownProps) => ({
        initialize,
        initialValues: generatePermissions(ownProps.permissions)
    })
)(
    reduxForm({
        form: 'permissions',
        validate,
        warn,
        enableReinitialize : true
    })(PermissionsForm)
);
