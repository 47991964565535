/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Row, Col, Card } from 'react-bootstrap'

// App components
import AccountPages from '../../components/AccountPages'
import AccountForm from '../../components/AccountForm'
import AddressForm from '../../components/AddressForm'
import PasswordForm from '../../components/PasswordForm'
import CompanyForm from '../../components/CompanyForm'
import renderLoading from '../../renderLoading'

// State
import { updateData, updateAddress } from '../../reducers/modules/user'

// Styles
// import * as classes from './AccountView.module.scss'

// Constants
//...

export class AccountView extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,

    updateData: PropTypes.func.isRequired,
    updateAddress: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)

    this.onAccountValidated = this.onAccountValidated.bind(this)
    this.onAddressValidated = this.onAddressValidated.bind(this)
    this.onPasswordValidated = this.onPasswordValidated.bind(this)
    this.onCompanyValidated = this.onCompanyValidated.bind(this)
  }

  onPasswordValidated (form) {
    // this.props.updatePassword(form.new_password1, form.new_password2, form.password)
  }

  onAccountValidated (form) {
    const { id: userId } = this.props.user.data
    const { first_name, last_name, email } = form

    this.props.updateUserData(userId, { first_name, last_name, email })
  }

  onAddressValidated (form) {
    const { user } = this.props
    const { id: userId } = user.data
    const { id: addressId } = user.address
    const { street, zip, city, country_id } = form

    this.props.updateUserAddress(userId, addressId, street, zip, city, country_id)
  }

  onCompanyValidated (form) {
    console.log('TODO company data saved')
  }

  render () {
    const { user } = this.props
    const { address, data } = user
    const { company } = data || {}

    return (
      <Container>
        <Row>
          <Col xs={3}>
            <AccountPages />
          </Col>
          <Col xs={9}>
            <Row>
              <Col xs={6}>
                <Card body>
                  <h6>Kontouppgifter</h6>
                  {data && <AccountForm user={data} onSubmit={this.onAccountValidated} />}
                  {!data && renderLoading()}
                </Card>
                {company && <Card body>
                  <h6>Företagsinformation</h6>
                  <CompanyForm company={company} onSubmit={this.onCompanyValidated} />
                </Card>}
              </Col>
              <Col xs={6}>
                <Card body>
                  <h6>Adress</h6>
                  {address && <AddressForm address={address} onSubmit={this.onAddressValidated} />}
                  {!address && renderLoading()}
                </Card>
                <Card body>
                  <h6>Ändra lösenord</h6>
                  <PasswordForm onSubmit={this.onPasswordValidated} />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user
})
export default connect((mapStateToProps), {
  updateAddress,
  updateData
})(AccountView)
