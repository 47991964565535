/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap'

// App components
import { isAdmin, isStore, isUser } from '../../PermissionsHelper'

// State
import { loadCarts } from '../../reducers/modules/user'
import { loadCategories, ACTIVE as CAT_ACTIVE } from '../../reducers/modules/categories'
import { loadPages, ACTIVE as PAG_ACTIVE  } from '../../reducers/modules/pages'
import { openLogin } from '../../reducers/modules/modals'
import { logout } from '../../reducers/modules/user'
import { loadCountries } from '../../reducers/modules/countries'
import { getCartIssues } from '../../cartHelper'

// Styles
import * as classes from './TopbarView.module.scss'

export class TopbarView extends React.Component {
    static propTypes = {
        categories: PropTypes.object.isRequired,
        pages: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,

        loadCarts: PropTypes.func.isRequired,
        loadCategories: PropTypes.func.isRequired,
        loadPages: PropTypes.func.isRequired,
        openLogin: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired,
        loadCountries: PropTypes.func.isRequired,
    }

    constructor (props) {
        super(props)
        this.handleLogin = this.handleLogin.bind(this)
    }

    componentDidMount () {
        if (this.props.categories.isUpdating === 0 && this.props.categories.data.length === 0) {
            this.props.loadCategories()
        }
        if (this.props.pages.isUpdating === 0 && this.props.pages.data.length === 0) {
            this.props.loadPages()
        }

        this.props.loadCountries()
    }

    handleLogin (evt) {
        evt.preventDefault()
        if (this.props.user.token) {
            this.props.logout()
        } else {
            this.props.openLogin()
        }
    }

    render () {
        try {
            const { user } = this.props

            const authActionText = user.token ? 'Logga ut' : 'Logga in'
            
            const { data: categories } = this.props.categories
            const { data: pages } = this.props.pages
            const filteredCategories = [...categories].filter(c => parseInt(c.active) === CAT_ACTIVE)
            const filteredPages = [...pages].filter(p => parseInt(p.active) === PAG_ACTIVE)
            
            const cart = user.carts ? user.carts[0] : undefined
            const cartData = cart ? cart.data : undefined
            const issues = cartData ? getCartIssues(cartData) : undefined

            return (
                <Navbar className={classes.navbar}>
                    <Container>
                        <Nav.Item>
                            <NavDropdown title='Kategorier'>
                                {filteredCategories.map((category) => (
                                <Nav.Link as={Link} className={classes.navlink} key={category.id} to={`/categories/${category.id}`}>{category.name}</Nav.Link>
                                ))}
                            </NavDropdown>
                        </Nav.Item>
                        {!isAdmin(user) && <Nav.Item className='mr-auto'>
                            <NavDropdown title='Information'>
                                {filteredPages.map((page) => (
                                    <Nav.Link as={Link} key={page.id} to={`/pages/${page.id}`}>{page.name}</Nav.Link>
                                ))}
                            </NavDropdown>
                        </Nav.Item>}
                        {isAdmin(user) && <Nav.Item className='mr-auto'>
                            <Nav.Link as={Link} to='/admin'>Till administration</Nav.Link>
                        </Nav.Item>}
                        {isStore(user) && <Nav.Item className='mr-auto'>
                            <Navbar.Text style={{ color: 'white', fontWeight: 700 }}>Inloggad som butiksanvändare</Navbar.Text>
                        </Nav.Item>}
                        <Nav.Item>
                            <Nav.Link href='#' onClick={this.handleLogin}>{authActionText}</Nav.Link>
                        </Nav.Item>
                        {!user.token && <Nav.Item>
                            <Nav.Link as={Link} to='/register'>Registrera</Nav.Link>
                        </Nav.Item>}
                        {isUser(user) && <Nav.Item>
                            <Nav.Link as={Link} to={'/account'}>Konto</Nav.Link>
                        </Nav.Item>}
                        {isUser(user) && <Nav.Item>
                            <Nav.Link as={Link} to={'/account/cart'}>Varukorg</Nav.Link>
                        </Nav.Item>}
                        {isUser(user) && issues && issues.length > 0 && <Nav.Item>
                            <Nav.Link as={Link} to={'/account/checkout'}>Till Kassan</Nav.Link>
                        </Nav.Item>}
                    </Container>
                </Navbar>
            )
        } catch (error) {
            return (
                <Navbar className={classes.navbar}>
                    <Container>
                        <Nav.Item>
                            <NavDropdown title='Kategorier'></NavDropdown>
                        </Nav.Item>
                        <Nav.Item className='mr-auto'>
                            <NavDropdown title='Information'></NavDropdown>
                        </Nav.Item>
                    </Container>
                </Navbar>
            )
        }
    }
}

const mapStateToProps = (state) => ({
  categories: state.categories,
  pages: state.pages,
  user: state.user
})
export default connect((mapStateToProps), {
  loadCarts,
  loadCategories,
  loadPages,
  openLogin,
  logout,
  loadCountries
})(TopbarView)
