import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'
import { issueCreated } from './CreateIssue'
import { issueUpdated } from './UpdateIssue'

/**
 * Initiator
 */
export const loadIssue = (
    issueId = 0
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/issues/${issueId}`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    const { items: issues } = getState().issues
                    const index = issues.map(i => i.id).indexOf(parseInt(resp.data.id))
                    if (index > -1) {
                      dispatch(issueUpdated(resp.data, index))
                    } else {
                      dispatch(issueCreated(resp.data))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
