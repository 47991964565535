import update from 'immutability-helper'

/**
 * Actions
 */
export const DID_SET_ADDRESS = 'DID_SET_ADDRESS'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function didSetAddress (
    address = undefined
) {
    return {
        type: DID_SET_ADDRESS,
        payload: address
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleDidSetAddress = (state, action) => update(state, {
    address: {$set: action.payload}
})


/**
 * Inititator
 */
export const setAddress = (
    address = undefined
) => {
    return (dispatch, getState) => {
        dispatch(didSetAddress(address))
    }
}
