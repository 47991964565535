
export const CARD_PAYMENT = 0
export const INVOICE_PAYMENT = 1
export const PRE_PAYMENT = 2

export const getCheckoutMethod = (checkoutMethod) => {
    switch (checkoutMethod) {
        case CARD_PAYMENT:
            return 'Kort'
        case INVOICE_PAYMENT:
            return 'Faktura'
        case PRE_PAYMENT:
            return 'Förskott'
        default:
            return `Unknown checkout method (${checkoutMethod})`
    }
}
