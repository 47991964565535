import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminPages from '../../components/AdminPages';
import PagesTable from '../../components/PagesTable';
import renderLoading from '../../renderLoading';
import PaginationView from '../../components/PaginationView';
import PageForm from '../../components/PageForm';
import { clearPages, loadPages, createPage, removePage, lockPage, unlockPage } from '../../reducers/modules/pages';
import { NEW } from '../../components/FormHelper/renderFormEditor';

function AdminPagesView(props) {

    const [loading, setLoading] = useState(false);

    const { pages, clearPages, loadPages, createPage, removePage, lockPage, unlockPage } = props;

    useEffect(() => {
        if (!loading) {
            setLoading(true);
            clearPages();
            loadPages();
        }
    }, [loading, clearPages, loadPages]);

    const handleLockpage = (pageId, shouldLock) => {
        if (shouldLock) {
            lockPage(pageId)
        } else {
            unlockPage(pageId)
        }
    }
  
    const handleDeletePage = (pageId) => {
        removePage(pageId)
    }
  
    const handleAddPage = (form) => {
        createPage(form)
    }

    const { data, isUpdating } = pages;

    return (
        <Container>
            <Row>
                <Col xs={2}>
                    <AdminPages />
                </Col>
                <Col xs={10}>
                    <Card body>
                        <h4>Information</h4>
                        <p>För att ändra uppgifter om en existerande sida behöver du endast ändra texten i fälten; den kommer sedan automatiskt att uppdatera sig själv på webplatsen efter c:a 1 sekunds uppehåll.</p>
                        <p>För att lägga till en sida fyll i dess titel och innehåll i fälten längst ner.</p>
                        <p>För att låsa, eller låsa upp, en sida så behöver du endast klicka på hänglåset i tabellen. <FontAwesomeIcon icon='lock' /> betyder att sidan inte är synlig, och <FontAwesomeIcon icon='unlock' /> betyder att sidan är synlig. En synlig sida återfinner du längst ner på varje sida. För kunder återfinns den även längst upp i det svarta fältet.</p>
                        <p>För att ändra i en existerande sida klickar du på namnet, du går då in i en ny sida där du har möjlighet att ändra innehållet samt namnet.</p>
                    </Card>
        
                    <Card body>
                        <h4>Lagrade sidor</h4>
                        {isUpdating > 0 && renderLoading()}
                        {data && <PagesTable pages={data} handleLock={handleLockpage} handleDelete={handleDeletePage} />}
                        {data && <PaginationView {...props} data={pages} />}
                    </Card>
        
                    <Card body>
                        <h4>Skapa en ny sida</h4>
                        <PageForm editorType={NEW} onSubmit={handleAddPage} />
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default connect((state) => ({
    pages: state.pages
}), {
    clearPages,
    loadPages,
    createPage,
    removePage,
    lockPage,
    unlockPage
})(AdminPagesView);
