import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isAdmin } from '../../PermissionsHelper';
import { loadMagazine } from '../../reducers/modules/magazines';
import { EditIssueView, UserContent } from './';

function ContentSelector({ user, issue, magazine, category, cover, issues, loadMagazine }) {

    const [loadedMagazine, setLoadedMagazine] = useState(false);

    useEffect(() => {
        if (!loadedMagazine && isAdmin(user) && magazine) {
            setLoadedMagazine(true);
            loadMagazine(magazine.id);
        }
    }, [user, magazine, loadedMagazine, loadMagazine]);

    if (!issue || !magazine || !category) {
        return (
            <>
                <UserContent />
            </>
        );
    }

    if (isAdmin(user)) {
        return (
            <>
                <EditIssueView {...{user, issue, magazine, category, cover, issues}} />
            </>
        );
    } else {
        return (
            <>
                <UserContent {...{user, issue, magazine, category, cover, issues}} />
            </>
        );
    }
}

export default connect((state, ownProps) => ({
    user: state.user
}), {
    loadMagazine
})(ContentSelector)
