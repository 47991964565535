import React from 'react';
import FriendRow from './FriendRow';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';

export const friendColumns = [
    'id',
    'Namn',
    'URL',
    '3'
];

function FriendsTable({ friends, skip = [], handleDelete }) {

    const filtered = [...friendColumns].filter((col, index) => !skip.includes(col) && !skip.includes(index));

    return (
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    {filtered.map((col, index) => (<th key={index}>{isNaN(col) ? col : ''}</th>))}
                </tr>
            </thead>
            <tbody>
                {friends.map((row) => (
                    <FriendRow key={row.id} row={row} skip={skip} handleDelete={handleDelete} />
                ))}
            </tbody>
        </Table>
    );
}

export default connect((state, ownProps) => ({
}), {
})(FriendsTable);
