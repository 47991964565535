import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import queryString from 'query-string';
import CartHandle from '../../components/CartHandle';
import SearchForm from '../../components/SearchForm';
import Logo from './logo.png';
import { isUser } from '../../PermissionsHelper';
import { loadData } from '../../reducers/modules/user';
import { isStore } from '../../PermissionsHelper';
import { addIssue } from '../../reducers/modules/user';
import { performSearch } from '../../reducers/modules/search';
import * as classes from './MainbarView.module.scss';

function MainbarView(props) {

    const { user, loadData, history, addIssue, search, performSearch } = props;
    const [loadedMe, setLoadedMe] = useState(false);
    const [searchString, setSearchString] = useState(undefined);

    const handleSearch = (form) => {
        const urlArgs = queryString.stringify(form);

        if (urlArgs) {
            if (user && isStore(user)) {
                setSearchString(`?${urlArgs}`);
                performSearch(`?${urlArgs}`);
            } else {
                history.push(`/search?${urlArgs}`);
            }
        }
    }

    useEffect(() => {
        const automagicCartEntry = (issueId) => {
            const cartId = user.carts?.[0]?.id;
            setSearchString(undefined);
            if (cartId) {
                addIssue(issueId, cartId, 1);
            }
        }

        if (isStore(user)) {
            const foundIssue = search.data?.[0];
            const { barcode: foundBarcode } = foundIssue || {};
            const expectedBarcode = searchString;
            
            if (foundBarcode && expectedBarcode && expectedBarcode.includes(foundBarcode)) {
                automagicCartEntry(foundIssue.id);
            }
        }
    }, [search, user, searchString, addIssue]);

    useEffect(() => {
        const { token } = user;

        if (token && !loadedMe) {
            setLoadedMe(true);
            loadData("me");
        }
    }, [user, loadData, loadedMe]);

    const displayCart = user?.token && isUser(user);

    return (
        <div className={classes.mainbar}>
            <Container>
                <Row>
                    <Col xs={4}>
                        <Link to={'/'}><Image className="float-left" src={Logo} /></Link>
                    </Col>
                    <Col xs={5} className="d-flex" style={{ alignItems: 'center' }}>
                        <SearchForm onSubmit={handleSearch} />
                    </Col>
                    <Col xs={3}>
                        {displayCart && <CartHandle />}
                    </Col>
                </Row>
            </Container>
        </div>

    );
}

export default connect((state) => ({
    user: state.user,
    search: state.search,
}), {
    loadData,
    addIssue,
    performSearch
})(MainbarView);
