import React from 'react'
import { Field } from 'redux-form'
import { Form } from 'react-bootstrap'

import { renderFormField, renderFormRadio, renderFormSwitch, 
         renderFormCheck, renderFormEditor, renderFormDropzone,
         renderFormSubmit, renderFormSearch, renderFormText,
         renderFormButton, renderFormTexteditor } from './index'
         
import { randomId } from '../../random'

const decideType = (field) => (
    field.fieldType === 'field' ? renderFormField :
    field.fieldType === 'radio' ? renderFormRadio :
    field.fieldType === 'switch' ? renderFormSwitch :
    field.fieldType === 'check' ? renderFormCheck :
    field.fieldType === 'editor' ? renderFormEditor :
    field.fieldType === 'dropzone' ? renderFormDropzone :
    field.fieldType === 'submit' ? renderFormSubmit :
    field.fieldType === 'search' ? renderFormSearch :
    field.fieldType === 'text' ? renderFormText :
    field.fieldType === 'button' ? renderFormButton :
    field.fieldType === 'texteditor' ? renderFormTexteditor :
    undefined // Fall back to undefined if no proper field is selected
)

export const renderField = (field) => {
    const cmp = decideType(field)

    if (cmp !== undefined) {
        return (<Field
            key={field.name}
            size='sm'
            {...field}
            component={cmp} />)
    }

    const error = `Incorrect field type (${JSON.stringify(field, null, 2)}), ignoring.`;
    console.error(error, new Error(error).stack);
}

export const renderSingle = (field) => {
    if (field === '-') {
        return (<hr key={randomId()} />)
    } else {
        return (<Form.Row key={field.name}>
            {renderField(field)}
        </Form.Row>)
    }
}

export const renderMultiple = (fields) => (
    <Form.Row key={fields.map(f => f.name).join('.')}>
        {fields.map(field => (
            renderField(field)
        ))}
    </Form.Row>
)

export const renderForm = (fields) => (
    fields.map(field => (
        Array.isArray(field) ? renderMultiple(field) : renderSingle(field)
    ))
)
