import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'
import { pushAlert } from '../alerts'

/**
 * Inititator
 * 
 * @param {*} userId 
 */
export const shortlist = (
    issueId = 0
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            const { id: userId } = getState().user.data
            
            request({
                url: `${PROTOCOL}://${URI}/users/${userId}/shortlists`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: {
                    issueId
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    let { response } = err
                    let error = JSON.parse(response)
                    dispatch(pushAlert('Önskelistan', error.messages[0]))
                    resolve()
                },
                success (resp) {
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
