import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card , Button} from 'react-bootstrap';
import chunk from '../../chunk';
import CreateItem from '../../components/CreateItem';
import MagazineIssue from '../../components/MagazineIssue';
import { FinancialForm, IssueDetailsForm, ContentsForm } from './AdminForms';
import * as classes from './IssueView.module.scss';
import NEW_ISSUE from './new_issue.jpg';
import { updateIssue } from '../../reducers/modules/issues';
import { updateMagazine } from '../../reducers/modules/magazines';
import { openConfirmDeleteIssue } from '../../reducers/modules/modals';

const MAX_ISSUES_ROW = 6;

function EditIssueView({
    user,
    issue,
    magazine,
    category,
    cover,
    issues,
    addIssue,
    shortlist,
    editorState,
    onEditorChanged,
    updateIssue,
    updateMagazine,
    openConfirmDeleteIssue
}) {

    // Copy the object, we will use this to update one single object from all forms.
    const [issueCopy, setIssueCopy] = useState(JSON.parse(JSON.stringify(issue)));

    const handleDelete = (evt) => {
        evt.preventDefault();

        const { id: issueId } = issue;

        console.log(`TODO: EditIssueView:handleDelete(${issueId})`);

        openConfirmDeleteIssue(issueId);
    }

    const handleExport = (evt) => {
        evt.preventDefault();

        const { id: issueId } = issue;

        console.log(`TODO: EditIssueView:handleExport(${issueId})`);
    }

    const handleChangedContents = (form) => {
        const { magazine, cover, ...issue } = form || {};

        const { theme, inprice, outprice, description: issueDesc } = issue || {};
        const { title, homepage, description: magazineDesc } = magazine || {};

        const magazineCopy = JSON.parse(JSON.stringify(issueCopy.magazine));
        
        setIssueCopy({
            ...issueCopy, 
            ...{ theme, inprice, outprice, description: issueDesc }, 
            ...{ magazine: {...magazineCopy, ...{ title, homepage, description: magazineDesc }} }
        });
    }

    const handleChangedDetails = (form) => {
        const { magazine, cover, ...issue } = form || {};

        const { year, yearly_issue, serialnum, pages, weight, editions, stock, sold, destroyed, paid, barcode } = issue || {};
        const { category_id, issues_anually, issn, language, dimensions } = magazine || {};

        const magazineCopy = JSON.parse(JSON.stringify(issueCopy.magazine));

        if (cover && cover.name) {
            setIssueCopy({
                ...issueCopy,
                ...{ new_cover_name: cover.name },
                ...{ year, yearly_issue, serialnum, pages, weight, editions, stock, sold, destroyed, paid, barcode },
                ...{ magazine: {...magazineCopy, ...{ category_id, issues_anually, issn, language, dimensions }} }
            });
        } else {
            setIssueCopy({
                ...issueCopy,
                ...{ year, yearly_issue, serialnum, pages, weight, editions, stock, sold, destroyed, paid, barcode },
                ...{ magazine: {...magazineCopy, ...{ category_id, issues_anually, issn, language, dimensions }} }
            });
        }
    }

    const handleChangedFinancial = (form) => {
        console.log('handleChangedFinancial')
        const { magazine } = form || {};

        const { PG, BG, VAT, paymentmethod_id, ...rest } = magazine || {};

        const magazineCopy = JSON.parse(JSON.stringify(issueCopy.magazine));

        setIssueCopy({
            ...issueCopy,  
            ...{ magazine: {...magazineCopy, ...{ PG, BG, VAT, paymentmethod_id, return: rest.return }} }
        });
    }

    const handleSave = () => {
        // console.log(issueCopy);
        updateIssue(issueCopy);
    }

    // This will make a placeholder for the "add magazine" action - only for admin users
    const prepended = [{
        id: -1
    }].concat(issues);
    
    const rows = chunk(prepended, MAX_ISSUES_ROW);

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <Card body>
                            <p>OBS! Fält markerade med * måste fyllas i.</p>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <Card body>
                            <IssueDetailsForm issue={issue} onChange={handleChangedDetails} />
                        </Card>
                        <Card body>
                            <Button variant='danger' onClick={handleDelete} block>Ta bort utgåva</Button>
                        </Card>
                    </Col>

                    <Col xs={6}>
                        <ContentsForm issue={issue} onChange={handleChangedContents} />

                        <Button variant='dark' onClick={handleSave} block>Spara</Button>
                    </Col>

                    <Col xs={3}>
                        {/* <Card body>
                            <h4>Leverantörs info</h4>
                            <DistributorForm issue={issue} onChange={handleChangedDistributor} />
                        </Card> */}
                        <Card body>
                            <FinancialForm issue={issue} onChange={handleChangedFinancial} />
                        </Card>
                        <Card body>
                            <Button variant='dark' onClick={handleExport} block>Exportera till excel</Button>
                        </Card>
                    </Col>
                </Row>

                {rows.map((row, index) => (
                    <Row key={index} className={[classes.row, 'd-flex', 'justify-content-start']}>
                        {row.map(i => (
                            <Col key={i.id} xs={2} className={['d-flex', 'align-self-end', 'justify-content-center', 'align-items-start']}>
                                {i.id === -1 ? (<CreateItem image={NEW_ISSUE} issue={issue} />) : (<MagazineIssue magazine={i.magazine} issue={i} />)}
                            </Col>
                        ))}
                    </Row>
                ))}
            </Container>
        </>
    );
}


export default connect((state, ownProps) => ({
}), {
    updateIssue,
    updateMagazine,
    openConfirmDeleteIssue
})(EditIssueView);
