import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';
import AdminPages from '../../components/AdminPages';
import MagazinesTable from '../../components/MagazinesTable';
import PaginationView from '../../components/PaginationView';
import renderLoading from '../../renderLoading';
import { clearMagazines, loadMagazines } from '../../reducers/modules/magazines';
import { openEditMagazine, openDeleteMagazine } from '../../reducers/modules/modals';
// import * as classes from './AdminMagazinesView.module.scss';

function AdminMagazinesView(props) {

    // eslint-disable-next-line
    const { magazineId, magazines, clearMagazines, loadMagazines, openEditMagazine, openDeleteMagazine, location } = props;

    const [magazinePage, setMagazinePage] = useState({ page: 1 });

    useEffect(() => {
        loadMagazines(magazinePage)
    }, [magazinePage, loadMagazines, clearMagazines]);

    useEffect(() => {
        const { search: page } = location;
        const args = queryString.parse(page);
        
        setMagazinePage({
            page: (args.page ? args.page : 1)
        });
    }, [location, setMagazinePage]);

    const handleEditMagazine = (magazineId) => {
        openEditMagazine(magazineId);
    }

    const handleDeleteMagazine = (magazineId) => {
        openDeleteMagazine(magazineId);
    }

    const { data, isUpdating } = magazines;

    return (
        <Container>
            <Row>
                <Col xs={2}>
                    <AdminPages />
                </Col>
                <Col xs={10}>
                    <Card body>
                        <h4>Information</h4>
                        <p>För att ändra en redan existerande tidskrift så klickar du på <FontAwesomeIcon icon='edit' />, en ny dialog öppnas då upp där du kan ändra i tidskriftens detaljer.</p>
                        <p>För att ta bort en existerande tidskrift klickar du på <FontAwesomeIcon icon='times' /> i listan, du får då en dialog där du måste bekräfta genom att skriva in tidskriftens namn.</p>
                        <p>För att lägga till en ny tidskrift använd formuläret längst ner på sidan.</p>
                        <Badge variant="danger">OBS, om du tar bort en tidskrift försvinner även de utgåvor som är kopplade till den.</Badge>
                    </Card>

                    <Card body>
                        <h4>Tidskrifter</h4>
                        {isUpdating > 0 && renderLoading()}
                        {data && <MagazinesTable
                            magazines={data}
                            handleEdit={handleEditMagazine}
                            handleDelete={handleDeleteMagazine} />}
                        {data && <PaginationView {...props} data={magazines} />}
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default connect(((state) => ({
    magazines: state.magazines
})), {
    clearMagazines,
    loadMagazines,
    openEditMagazine,
    openDeleteMagazine
})(AdminMagazinesView);
