import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card , Button} from 'react-bootstrap';
import { FinancialForm, IssueDetailsForm, ContentsForm } from './AdminForms';
import { createIssue } from '../../reducers/modules/issues';
import { loadMagazine } from '../../reducers/modules/magazines';

const defaultData = (magazineId) => (
    {
        magazine: {
            id: magazineId
        },
        cover: {}
    }
);

function NewIssueView(props) {

    const { match, loadMagazine, magazines, createIssue } = props;

    const { magazineId } = match.params;

    // Copy the object, we will use this to update one single object from all forms.
    const [newIssue, setNewIssue] = useState(JSON.parse(JSON.stringify(defaultData(magazineId))));

    const [loadedMagazine, setLoadedMagazine] = useState(false);
    const [copiedMagazine, setCopiedMagazine] = useState(false);

    // This should only be written once...
    const [magazine, setMagazine] = useState({});

    useEffect(() => {
        if (!loadedMagazine) {
            setLoadedMagazine(true);

            loadMagazine(magazineId);
        }
    }, [loadedMagazine, setLoadedMagazine, loadMagazine, magazineId]);

    useEffect(() => {
        if (magazines) {
            setMagazine(magazines.data.find((m) => parseInt(m.id) === parseInt(magazineId)));
        }
    }, [magazines, magazineId]);

    useEffect(() => {
        if (!copiedMagazine && magazine?.id) {
            setCopiedMagazine(true);
            setNewIssue({
                magazine: magazine
            })
        }
    }, [magazine, copiedMagazine, setCopiedMagazine, setNewIssue]);

    useEffect(() => {
        console.log(newIssue)
    }, [newIssue]);

    const handleChangedContents = (form) => {
        const { magazine, cover, ...issue } = form || {};

        const { theme, inprice, outprice, description: issueDesc } = issue || {};
        const { title, homepage, description: magazineDesc } = magazine || {};

        const magazineCopy = JSON.parse(JSON.stringify(newIssue.magazine));
        
        setNewIssue({
            ...newIssue, 
            ...{ theme, inprice, outprice, description: issueDesc }, 
            ...{ magazine: {...magazineCopy, ...{ title, homepage, description: magazineDesc }} }
        });
    }

    const handleChangedDetails = (form) => {
        const { magazine, cover, ...issue } = form || {};

        const { year, yearly_issue, serialnum, pages, weight, editions, stock, sold, destroyed, paid, barcode } = issue || {};
        const { category_id, issues_anually, issn, language, dimensions } = magazine || {};

        // TODO: cover is not updated... it needs to be uploaded and then it can be added to this object.

        const magazineCopy = JSON.parse(JSON.stringify(newIssue.magazine));

        if (cover && cover.name) {
            setNewIssue({
                ...newIssue,
                ...{ new_cover_name: cover.name },
                ...{ year, yearly_issue, serialnum, pages, weight, editions, stock, sold, destroyed, paid, barcode },
                ...{ magazine: {...magazineCopy, ...{ category_id, issues_anually, issn, language, dimensions }} }
            });
        } else {
            setNewIssue({
                ...newIssue,
                ...{ year, yearly_issue, serialnum, pages, weight, editions, stock, sold, destroyed, paid, barcode },
                ...{ magazine: {...magazineCopy, ...{ category_id, issues_anually, issn, language, dimensions }} }
            });
        }
    }

    const handleChangedFinancial = (form) => {
        console.log('handleChangedFinancial')
        const { magazine } = form || {};

        const { PG, BG, VAT, paymentmethod_id, ...rest } = magazine || {};

        const magazineCopy = JSON.parse(JSON.stringify(newIssue.magazine));

        setNewIssue({
            ...newIssue,  
            ...{ magazine: {...magazineCopy, ...{ PG, BG, VAT, paymentmethod_id, return: rest.return }} }
        });
    }

    const handleSave = () => {
        createIssue(newIssue);
    }
    
    return (
        <>
            <Container>
                <Row>
                    <Col xs={3}>
                        <Card body>
                            <IssueDetailsForm issue={newIssue} onChange={handleChangedDetails} />
                        </Card>
                    </Col>

                    <Col xs={6}>
                        <ContentsForm issue={newIssue} onChange={handleChangedContents} />

                        <Button variant='dark' onClick={handleSave} block>Spara</Button>
                    </Col>

                    <Col xs={3}>
                        <Card body>
                            <FinancialForm issue={newIssue} onChange={handleChangedFinancial} />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}


export default connect((state, ownProps) => ({
    magazines: state.magazines
}), {
    loadMagazine,
    createIssue
})(NewIssueView);
