import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'react-bootstrap'
import UserRow from './UserRow'

export const userColumns = [
    'Förnamn',
    'Efternamn',
    'E-Post',
    '2',
    'Aktiverad'
];

function UsersTable({ users, skip = [], activate }) {

    const filtered = [...userColumns].filter((col, index) => !skip.includes(col) && !skip.includes(index));

    return (
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    {filtered.map((col, index) => (<th key={index}>{isNaN(col) ? col : ''}</th>))}
                </tr>
            </thead>
            <tbody>
                {users.map((row) => (
                    <UserRow key={row.id} row={row} skip={skip} activate={activate} />
                ))}
            </tbody>
        </Table>
    );
}

export default connect((state, ownProps) => ({
}), {
})(UsersTable)
