import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col, Card } from 'react-bootstrap'
import AdminPages from '../../components/AdminPages'
import renderLoading from '../../renderLoading'
import CartTable from '../../components/CartTable'
import OrderTotalsTable from '../../components/OrderTotalsTable'
import OrderStatusTable from '../../components/OrderStatusTable'
import { getAddress } from '../../orderHelper'
import { CARD_PAYMENT, INVOICE_PAYMENT, PRE_PAYMENT } from '../../components/ReceiptTable'
import { loadOrder } from '../../reducers/modules/orders'
// import * as classes from './AdminOrderView.module.scss'

const renderStatus = (order) => (
    <Card body>
        <h4>Status</h4>
        <OrderStatusTable order={order} />
        <OrderTotalsTable order={order} />
    </Card>
)

const renderContents = (cartData) => (
    <Card body>
        <h4>Innehåll</h4>
        <CartTable editable={false} deleteable={false} cartData={cartData} />
    </Card>
)

const renderAddress = (heading, address) => (
    <Card body>
        {heading && <h5>{heading}</h5>}
        <p>
            {address.map((row, index) => {
                if (row === '1') {
                    // This is because of a bug... the api seems to somehow add a '1' for extraaddress payload. Maybe it's the "checkbox"?
                    return undefined
                }
                if (/^([\d]{1,})$/i.test(row)) {
                    return (<React.Fragment key={`address.row.${index}`}>{row}{'\u00A0'}</React.Fragment>)
                } else {
                    return (<React.Fragment key={`address.row.${index}`}>{row}<br/></React.Fragment>)
                }
            })}
        </p>
    </Card>
)

const getInvoiceTitle = (checkoutmethod, txnid) => {
    if (typeof checkoutmethod === 'undefined')
        return undefined;

    switch (checkoutmethod) {
        case CARD_PAYMENT:
            return (txnid ? "Betalad av" : "Betalas av");
        case INVOICE_PAYMENT:
            return "Faktureras till";
        case PRE_PAYMENT:
            return "Beställare";
        default:
            return undefined;
    }
}

const renderOrder = (order) => {
    const { checkoutmethod, txnid } = order

    const delivery = getAddress(order)

    const sendToTitle = "Skickas till"

    const invoiceToTitle = getInvoiceTitle(checkoutmethod, txnid)

    return (
        <>
            {renderStatus(order)}
            {renderContents(order.cart)}
            {renderAddress(sendToTitle, delivery)}
            {renderAddress(invoiceToTitle, delivery)}
        </>
    );
}

function AdminOrderView({ orders, match, loadOrder }) {

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const { orderId } = match.params;
        if (!loaded) {
            loadOrder(orderId);
            setLoaded(true);
        }
    }, [loaded, setLoaded, loadOrder, match]);

    if (!orders) {
        return (
            <>
                <Container>
                    <Row>
                        <Col>
                            {renderLoading()}
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

    const { orderId } = match.params;
    const { data } = orders;
    const index = data.map(o => o.id).indexOf(parseInt(orderId));

    return (
        <>
            <Container>
                <Row>
                    <Col xs={2}>
                        <AdminPages />
                    </Col>
                    <Col xs={10}>              
                        {(index === -1) && renderLoading()}
                        {(index !== -1) && renderOrder(data[index])}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default connect(((state, ownProps) => ({
    orders: state.orders,
})), {
    loadOrder
})(AdminOrderView);
