import update from 'immutability-helper'

/**
 * Actions
 */
export const TOGGLE_ADVANCED = 'TOGGLE_ADVANCED'

/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export const toggledAdvanced = (
    show = false
) => ({
    type: TOGGLE_ADVANCED,
    payload: show
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleToggleAdvanced = (state, action) => update(state, {
    showAdvanced: {$set: action.payload}
})


/**
 * Initiator
 */
export const toggleAdvanced = (
    show = false
) => {
    return (dispatch, getState) => {
        dispatch(toggledAdvanced(show))
    }
}
