/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { Form } from 'react-bootstrap'

// App componetns
import { renderForm } from '../../components/FormHelper'

// State
// ...

// Styles
// import * as classes from './CreateCategoryForm.module.scss'

// Constants
// ...

const validate = values => {
  const errors = {}
  if (!values.name) {
    errors.name = 'Obligatoriskt'
  } else if (parseInt(values.name) < 1) {
    errors.name = 'Namnet måste vara minst ett tecken långt.'
  }
  return errors
}

const warn = values => {
  const warnings = {}
  return warnings
}

class CreateCategoryForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired
  }

  render () {
    const {
      handleSubmit
    } = this.props

    const fields = [
        {
            fieldType: 'field',
            name: 'name',
            label: 'Namn',
            submitStyle: { paddingLeft: '30px', paddingRight: '30px' },
            appendSubmit: {
                name: 'submit', variant: 'dark', label: 'Lägg till', fieldType: 'submit'
            }
        }
    ]

    try {
      return (
        <Form onSubmit={handleSubmit}>
          {renderForm(fields)}
        </Form>)
    } catch (error) {
      return (<p>error</p>)
    }
  }
}

export default reduxForm({
  form: 'createCategory',
  validate,
  warn
})(CreateCategoryForm)
