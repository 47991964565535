import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import renderLoading from '../../renderLoading';
import AdminPages from '../../components/AdminPages';
import FriendsTable from '../../components/FriendsTable';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PaginationView from '../../components/PaginationView';
import CreateFriendForm from '../../components/CreateFriendForm';
import { loadFriends, clearFriends, createFriend, deleteFriend } from '../../reducers/modules/friends';
// import * as classes from './AdminFriendsView.module.scss'

function AdminFriendsView(props) {

    const { friends, loadFriends, clearFriends, createFriend, deleteFriend } = props;

    useEffect(() => {
        clearFriends();
        loadFriends();
    }, [clearFriends, loadFriends]);

    const handleAddFriend = (form) => {
        const { name, url } = form;
        createFriend(name, url);
    }

    const handleDelFriend = (friendId) => {
        deleteFriend(friendId);
    }

    const { data, isUpdating } = friends;

    return (
        <>
            <Container>
                <Row>
                    <Col xs={2}>
                        <AdminPages />
                    </Col>
                    <Col xs={10}>
                        <Card body>
                            <h4>Information</h4>
                            <p>För att ändra uppgifter om en existerande vän behöver du endast ändra texten i fälten; den kommer sedan automatiskt att uppdatera sig själv på webplatsen efter c:a 1 sekunds uppehåll.</p>
                            <p>För att lägga till en vän skriv in dess namn och web-adress i fälten längst ner. Kom ihåg att formatera web-adressen korrekt <kbd>http://www. ...</kbd></p>
                        </Card>

                        <Card body>
                            {isUpdating > 0 && renderLoading()}
                            {data && <FriendsTable friends={data} handleDelete={handleDelFriend} />}
                            {data && <PaginationView {...props} data={friends} />}
                        </Card>

                        <Card body>
                            <h4>Lägg till ny vän här</h4>
                            <CreateFriendForm onSubmit={handleAddFriend} />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default connect((state) => ({
    friends: state.friends
}), {
    loadFriends,
    clearFriends,
    createFriend,
    deleteFriend
})(AdminFriendsView);
