import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import AccountPages from '../../components/AccountPages';
import ShortlistTable from '../../components/ShortlistTable';
import PaginationView from '../../components/PaginationView';
import renderLoading from '../../renderLoading';
import { loadShortlist, removeFromShortlist } from '../../reducers/modules/user';
// import * as classes from './AccountShortlistView.module.scss';

function AccountShortlistView(props) {
    const { user, loadShortlist, removeFromShortlist } = props;
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const { data } = user;
        if (!loaded && data) {
            const { id: userId } = data;
            setLoaded(true);
            loadShortlist(userId);
        }
    }, [loaded, user, setLoaded, loadShortlist]);

    const handleRemoveFromShortlist = (issueId) => {
        removeFromShortlist(issueId);
    }

    const { shortlists, isUpdating } = user;
    const { data } = shortlists;

    return (
        <Container>
            <Row>
                <Col xs={3}>
                    <AccountPages />
                </Col>
                <Col xs={9}>
                    <Card body>
                        <h4>Mina bevakningar</h4>
                        {isUpdating > 0 && renderLoading()}
                        {data && <ShortlistTable shortlists={data} path='account' skip={[0, 3, 4]} handleRemove={handleRemoveFromShortlist} />}
                        {data && <PaginationView {...props} data={shortlists} />}
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default connect((state, ownProps) => ({
    user: state.user
}), {
    loadShortlist,
    removeFromShortlist
})(AccountShortlistView);
