import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card , Button} from 'react-bootstrap';
import { FinancialForm, IssueDetailsForm, ContentsForm } from './AdminForms';
import { updateIssue, createIssueMagazine } from '../../reducers/modules/issues';
import { updateMagazine } from '../../reducers/modules/magazines';
import { openConfirmDeleteIssue } from '../../reducers/modules/modals';

const defaultData = (categoryId) => (
    {
        magazine: {
            category_id: categoryId
        },
        cover: {}
    }
);


function NewMagazineView(props) {

    // eslint-disable-next-line
    const { issues, magazines, createIssueMagazine, match, location, history } = props;

    const { categoryId } = match.params;

    const [newIssue, setNewIssue] = useState(JSON.parse(JSON.stringify(defaultData(categoryId))));

    useEffect(() => {
        // console.log(newIssue)
    }, [newIssue]);

    const handleChangedContents = (form) => {
        const { magazine, cover, ...issue } = form;

        const { theme, inprice, outprice, description: issueDesc } = issue;
        const { title, homepage, description: magazineDesc } = magazine;

        const { magazine: copy } = newIssue;

        const magazineCopy = JSON.parse(JSON.stringify(copy));
        
        setNewIssue({
            ...newIssue,
            ...{ cover },
            ...{ theme, inprice, outprice, description: issueDesc }, 
            ...{ magazine: {...magazineCopy, ...{ title, homepage, description: magazineDesc }} }
        });
    }

    const handleChangedDetails = (form) => {
        const { magazine, cover, ...issue } = form;

        const { year, serialnum, pages, weight, editions, stock, sold, destroyed, paid, barcode } = issue;
        const { category_id, issues_anually, issn, language, dimensions } = magazine;

        const magazineCopy = JSON.parse(JSON.stringify(newIssue.magazine));


        if (cover && cover.name) {
            setNewIssue({
                ...newIssue,
                ...{ new_cover_name: cover.name },
                ...{ year, serialnum, pages, weight, editions, stock, sold, destroyed, paid, barcode },
                ...{ magazine: {...magazineCopy, ...{ category_id, issues_anually, issn, language, dimensions }} }
            });
        } else {
            setNewIssue({
                ...newIssue,
                ...{ year, serialnum, pages, weight, editions, stock, sold, destroyed, paid, barcode },
                ...{ magazine: {...magazineCopy, ...{ category_id, issues_anually, issn, language, dimensions }} }
            });
        }
    }

    const handleChangedFinancial = (form) => {
        const { magazine } = form;

        const { PG, BG, VAT, paymentmethod_id, ...rest } = magazine;

        const magazineCopy = JSON.parse(JSON.stringify(newIssue.magazine));

        setNewIssue({
            ...newIssue,  
            ...{ magazine: {...magazineCopy, ...{ PG, BG, VAT, paymentmethod_id, return: rest.return }} }
        });
    }

    const handleSave = async () => {
        try {
            const payload = await createIssueMagazine(newIssue);
            if (payload) {
                const { id } = payload;
                if (id) {
                    setTimeout(() => {
                        history.push(`/issue/${id}`);
                    }, 50);
                }
            }
        } catch (err) {
            console.error(err)
        }
    }
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <Card body>
                            <p>OBS! Fält markerade med * måste fyllas i.</p>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <Card body>
                            <IssueDetailsForm issue={newIssue} onChange={handleChangedDetails} />
                        </Card>
                    </Col>

                    <Col xs={6}>
                        <ContentsForm issue={newIssue} onChange={handleChangedContents} />

                        <Button variant='dark' onClick={handleSave} block>Spara</Button>
                    </Col>

                    <Col xs={3}>
                        {/* <Card body>
                            <h4>Leverantörs info</h4>
                            <DistributorForm issue={newIssue} onChange={handleChangedDistributor} />
                        </Card> */}
                        <Card body>
                            <FinancialForm issue={newIssue} onChange={handleChangedFinancial} />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}


export default connect((state, ownProps) => ({
    issues: state.issues
}), {
    updateIssue,
    updateMagazine,
    openConfirmDeleteIssue,
    createIssueMagazine
})(NewMagazineView);
