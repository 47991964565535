import update from 'immutability-helper'

export const TOGGLED_DIVIDE_ORDER = 'TOGGLED_DIVIDE_ORDER'

export const handleToggledDivideOrder = (state, action) => update(state, {
    divideOrder: {
        isOpen: {$set: action.state},
        orderId: {$set: action.orderId}
    }
})

export function toggledDivideOrder (
    state = undefined,
    orderId = undefined
) {
    return {
        type: TOGGLED_DIVIDE_ORDER,
        state,
        orderId
    }
}

export const openDivideOrder = (
    orderId = undefined
) => {
    return (dispatch, getState) => {
        dispatch(toggledDivideOrder(true, orderId))
    }
}

export const closeDivideOrder = () => {
    return (dispatch, getState) => {
        dispatch(toggledDivideOrder(false, undefined))
    }
}

export const divideOrder = { isOpen: false, orderId: undefined }
