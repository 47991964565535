/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// App components
import renderLoading from '../../renderLoading'
import PaymentSelectionForm from '../PaymentSelectionForm'
import { PRE_PAYMENT } from '../ReceiptTable'

// State
import { setPaymentMethod } from '../../reducers/modules/checkout'

// Styles
// import * as classes from './CheckoutStageThree.module.scss'

// Constants
// ...

class CheckoutStageThree extends React.Component {
    static propTypes = {
        checkout: PropTypes.object.isRequired,

        setPaymentMethod: PropTypes.func.isRequired,

        user: PropTypes.object.isRequired
    }

    constructor (props) {
        super(props)

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleNewVal = this.handleNewVal.bind(this)

        this.state = {
            displayPrepaymentText: false
        }
    }

    handleSubmit (form) {
        const { user } = this.props;

        if (user?.permissions?.includes("store")) {
            // Do nothing for store?...
        } else {
            const { paymentMethod } = form
            this.props.setPaymentMethod(paymentMethod, true)    
        }
    }

    handleNewVal (newVal) {
        this.setState({
            displayPrepaymentText: (parseInt(newVal) === PRE_PAYMENT)
        })
    }

    render () {
        try {
            const { displayPrepaymentText } = this.state

            return (<>
                <PaymentSelectionForm onSubmit={this.handleSubmit} onNewVal={this.handleNewVal} />
                {displayPrepaymentText && <p>Vid förskottsbetalning skickas ett meddelande till din epostadress med instruktioner för hur du ska gå tillväga med betalningen. När betalningen är registrerad på vårt konto behandlar vi ordern och skickar tidskrifterna till dig.</p>}
            </>)
        } catch (err) {
            console.error(err)
            return renderLoading()
        }
    }
}

const mapStateToProps = (state) => ({
    checkout: state.checkout,
    user: state.user
})
export default connect((mapStateToProps), {
    setPaymentMethod,
})(CheckoutStageThree)

