import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Initiator
 * 
 * @param {*} userId
 * @param {*} permissions
 */
export const updatePermissions = (
    userId = 0,
    permissions = ''
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/users/${userId}/permissions`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: {
                    permissions
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    if (resp.data) {
                        dispatch(popUpdating())
                    }
                    resolve()
                }
            })
        })
    }
}
    