import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL, minutesPassed } from '../api'
import { pushUpdating, popUpdating } from './index'

export const MINIMUM_UPDATE_TIME = 15 //minutes

/**
 * Actions
 */
export const LOADED_PAGES = 'LOADED_PAGES'
export const LOADING_PAGES = 'LOADING_PAGES'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function loadingPages () {
    return {
        type: LOADING_PAGES,
        payload: Date()
    }
}

/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function loadedPages (
    pages = undefined
) {
    return {
        type: LOADED_PAGES,
        payload: pages
    }
}

/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadedPages = (state, action) => update(state, {
    data: {$set: action.payload.data},
    links: {$set: action.payload.links},
    meta: {$set: action.payload.meta}
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadingPages = (state, action) => update(state, {
    loaded: {$set: action.payload}
})


/**
 * Initiator
 */
export const loadPages = (
    filter = undefined
) => {
    return (dispatch, getState) => {

        const { loaded } = getState().pages || undefined
        if (!minutesPassed(MINIMUM_UPDATE_TIME, loaded)) {
            return
        }
        dispatch(loadingPages())

        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/pages`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: filter,
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(loadedPages(resp))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
