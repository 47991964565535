import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const CREATED_INT_POSTAGE = 'CREATED_INT_POSTAGE'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function internationalCreated (
    postage = {}
) {
    return {
        type: CREATED_INT_POSTAGE,
        payload: postage
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleCreatedInternational = (state, action) => update(state, {
    international: {$push: [action.payload]}
})


/**
 * Inititator
 */
export const createInternational = (
    price = 0,
    lbound = 0
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/intpostages`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: {
                    price, lbound
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(internationalCreated(resp.data))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
