/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// App components
import renderLoading from '../../renderLoading'

// State
// ...

// Styles
// import * as classes from './OrderStatusTable.module.scss'

// Constants
const UNSET_DATE = '0000-00-00 00:00:00'

export class OrderStatusTable extends React.Component {
    static propTypes = {
        order: PropTypes.object.isRequired
    }

    render = () => {
        try {
            const { order } = this.props

            const paymentIcon = (order.txnid !== '0' ? 'check' : 'times')
            const paymentColor = (order.txnid !== '0' ? 'green' : 'red')

            const handledIcon = (order.processed_at !== UNSET_DATE ? 'check' : 'times')
            const handledColor = (order.processed_at !== UNSET_DATE ? 'green' : 'red')

            return (
                <Table striped bordered hover size="sm">
                    <tbody>
                        <tr>
                            <td>Betald</td>
                            <td><FontAwesomeIcon icon={paymentIcon} color={paymentColor} /></td>
                        </tr>
                        <tr>
                            <td>Behandlad</td>
                            <td><FontAwesomeIcon icon={handledIcon} color={handledColor} /></td>
                        </tr>
                    </tbody>
                </Table>
            )
        } catch (err) {
            console.error(err)
            return renderLoading()
        }
    }
}

const mapStateToProps = (state, ownProps) => ({
})
export default connect((mapStateToProps), {
})(OrderStatusTable)
