import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'
import { createdOrder } from './CreateOrder'

/**
 * Actions
 */
export const UPDATED_USER_ORDER = 'UPDATEUPDATED_USER_ORDERD_ORDER'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export const updatedOrder = (
    order = {},
    index = 0
) => ({
    type: UPDATED_USER_ORDER,
    payload: order,
    index: index
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleUpdatedOrder = (state, action) => update(state, {
    data: {
        [action.index]: {$set: action.payload}
    }
})


/**
 * Inititator
 */
export const updateOrder = (
    userId = undefined,
    orderId = undefined,
    data = undefined
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/user/${userId}/orders/${orderId}`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: data,
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    const { items: orders } = getState().orders
                    const index = orders.map(o => o.id).indexOf(parseInt(resp.data.id))
                    if (index > -1) {
                        dispatch(updatedOrder(resp.data, index))
                    } else {
                        dispatch(createdOrder(resp.data))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
