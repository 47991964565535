import React from 'react';
import { connect } from 'react-redux';
import { Modal, Badge } from 'react-bootstrap';
import DeleteCategoryForm from '../DeleteCategoryForm';
import { removeCategory } from '../../reducers/modules/categories';
import { closeDeleteCategory } from '../../reducers/modules/modals';
// import * as classes from './DeleteCategoryModal.module.scss';

function DeleteCategoryModal(props) {

    const { modal, categories, closeDeleteCategory, removeCategory } = props;

    const handleCancel = () => {
        closeDeleteCategory();
    }

    const formValidated = (form) => {
        if (form.confirm === form.name) {
            removeCategory(form.id);
            closeDeleteCategory();
        } else {
            console.warn(`Incorrect category name, aborting.`);
            closeDeleteCategory();
        }
    }

    const { isOpen } = modal;

    if (!isOpen) {
        return (
            <div />
        );
    }

    const { categoryId } = modal;
    const { data } = categories;
    const category = data.find(c => parseInt(c.id) === parseInt(categoryId));

    return (
        <Modal size='lg' show={isOpen} onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Bekräfta borttagning av kategori "{category.name}"</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><Badge variant="danger">OBS</Badge> Om du tar bort en kategori försvinner även de tidskrifter som är kopplade till den.</p>
                <DeleteCategoryForm onSubmit={formValidated} category={category} />
            </Modal.Body>
        </Modal>
    );
}

export default connect(((state) => ({
    modal: state.modals.deleteCategory,
    categories: state.categories
})), {
    closeDeleteCategory,
    removeCategory
})(DeleteCategoryModal);
