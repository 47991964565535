import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, } from 'react-bootstrap';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminPages from '../../components/AdminPages';
import ShortlistTable from '../../components/ShortlistTable';
import PaginationView from '../../components/PaginationView';
import renderLoading from '../../renderLoading';
import { clearShortlists, loadShortlists } from '../../reducers/modules/shortlists';
import { openNotifyInterestedUsers } from '../../reducers/modules/modals';
// import * as classes from './AdminShortlistView.module.scss';

function AdminShortlistView(props) {

    const { shortlists, location, loadShortlists, openNotifyInterestedUsers } = props;

    const [shortlistPage, setShortlistPage] = useState({ page: 1 });

    useEffect(() => {
        loadShortlists(shortlistPage)
    }, [shortlistPage, loadShortlists]);

    useEffect(() => {
        const { search: page } = location;
        const args = queryString.parse(page);
        
        setShortlistPage({
            page: (args.page ? args.page : 1)
        });
    }, [location, setShortlistPage]);

    const handleSendEmail = (issueId) => {
        openNotifyInterestedUsers(issueId);
    }

    const handleRemove = (issueId) => {
        // TODO: Remove all short lists for issue?
    }

    const { data, isUpdating } = shortlists;

    return (
        <Container>
            <Row>
                <Col xs={2}>
                    <AdminPages />
                </Col>
                <Col xs={10}>
                    <Card body>
                        <h4>Önskade tidskrifter</h4>
                        <p>Här listas de tidskrifter, som inte finns i lager, med flest intresserade kunder.</p>
                        <p>För att skicka notis till de intresserade kunderna kan du klicka på <FontAwesomeIcon icon={['far', 'envelope']} /> symbolen.</p>
                    </Card>

                    <Card body>
                        <h4>Intresseanmälningar</h4>
                        {isUpdating > 0 && renderLoading()}
                        {data.length > 0 && <ShortlistTable shortlists={data} openSendEmail={handleSendEmail} handleRemove={handleRemove} />}
                        {data.length > 0 && <PaginationView {...props} data={shortlists} />}
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default connect(((state) => ({
    shortlists: state.shortlists
})), {
    loadShortlists,
    clearShortlists,
    openNotifyInterestedUsers
})(AdminShortlistView);
