import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'
import { loadedMagazines } from './LoadMagazines'

/**
 * Inititator
 * 
 * @param {*} categoryId 
 */
export const loadCategoryMagazines = (
    categoryId = 0,
    pageString = '',
    incDiscontinued = false
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            // TODO: Clean this up... there must be a helper to use...
            let vars = []
            if (incDiscontinued === true) {
                vars.push('incDiscontinued=true')
            }
            let queryString = vars.join('&')
            if (queryString.length > 0) {
                if (pageString === '') {
                    queryString = `?${queryString}`
                } else {
                    queryString = `&${queryString}`
                }
            }
            request({
                url: `${PROTOCOL}://${URI}/categories/${categoryId}/magazines${pageString}${queryString}`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    try {
                        dispatch(loadedMagazines(resp))
                    } catch (error) {
                        console.error(error)
                    } finally {
                        dispatch(popUpdating())
                        resolve()
                    }
                }
            })
        })
    }
}
