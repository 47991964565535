/* @flow */

// Framework
import React from 'react'
import { Form, Col, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { renderField } from './renderForm'

export const renderFormTexteditor = (props) => {
  const {
    input,
    
    namePrefix,
    type,
    parts,
    children,
    size = 'sm',

    showLabel = false,
    label = '',
    
    prependText = '',
    prependIcon = '',

    appendLabel = '',

    appendSubmit,
    appendButton,
    
    placeholder,
    meta: { touched, error },

    rows
  } = props

  return (
    <Form.Group as={Col} xs={parts} controlId={`${(namePrefix ? namePrefix : '')}${input.name}`}>
        {showLabel && <Form.Label size={size}>{label}</Form.Label>}
        <InputGroup size={size}>
            {(prependText || prependIcon) && <InputGroup.Prepend>
                    <InputGroup.Text id={`${input.name}Prepend`}>
                    {prependText}{prependIcon && <FontAwesomeIcon icon={prependIcon} />}
                    </InputGroup.Text>
            </InputGroup.Prepend>}      
            <Form.Control
                {...input}
                name={`${(namePrefix ? namePrefix : '')}${input.name}`}
                placeholder={(placeholder ? placeholder : label)}
                type={type}
                value={input.value}
                autoComplete="off"
                rows={rows ? rows : 10}
                as="textarea">
                {children}
            </Form.Control>
            <InputGroup.Append>
                {appendLabel && <InputGroup.Text id="basic-addon2">@example.com</InputGroup.Text>}
                {appendSubmit && renderField({...appendSubmit, group: false})}
                {appendButton && renderField({...appendButton, group: false})}
            </InputGroup.Append>
                {(touched && error) &&
                    <Form.Control.Feedback type="invalid" className="d-block">{error}</Form.Control.Feedback>
                }
        </InputGroup>
    </Form.Group>
  )
}
