import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Form } from 'react-bootstrap';
import { renderForm } from '../../components/FormHelper';
// import * as classes from './RegisterUserForm.module.scss';
  
const validate = (values) => {
    const errors = {}

    if (!values.firstname) {
        errors.firstname = 'Obligatoriskt'
    } else if (values.firstname.length < 1) {
        errors.firstname = 'Ditt förnamn måste vara ifyllt'
    }
    if (!values.lastname) {
        errors.lastname = 'Obligatoriskt'
    } else if (values.lastname.length < 1) {
        errors.lastname = 'Ditt efternamn måste vara ifyllt'
    }
    if (!values.email) {
        errors.email = 'Obligatorisk'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Ogiltig E-Post adress'
    }
    if (!values.street) {
        errors.street = 'Obligatoriskt'
    } else if (values.street.length < 1) {
        errors.street = 'Din gatuadress måste vara ifylld'
    }
    if (!values.zip) {
        errors.zip = 'Obligatoriskt'
    } else if (values.zip.length < 1) {
        errors.zip = 'Ditt postnummer måste vara ifyllt'
    }
    if (!values.password) {
        errors.password = 'Obligatoriskt'
    } else if (values.password.length < 1) {
        errors.password = 'Lite för kort lösenord...'
    }
    if (!values.password_confirmation) {
        errors.password = 'Obligatoriskt'
    } else if (values.password_confirmation !== values.password) {
        errors.password = 'Lösenorden måste matcha'
    }

    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

const SWEDEN = 215;

function RegisterUserForm({ countries, handleSubmit, pristine, submitting }) {

    const options = countries.items.map((country) => (
        <option key={country.id} value={country.id}>{country.short_name}</option>
    ));

    const fields = [
        [
            { name: 'firstname', label: 'Förnamn', icon: 'user', fieldType: 'field' },
            { name: 'lastname', label: 'Efternamn', icon: 'user', fieldType: 'field' }
        ],
        { name: 'email', label: 'E-Post', icon: 'envelope', fieldType: 'field' },
        '-',
        { name: 'street', label: 'Gatuadress', fieldType: 'field' },
        [
            { name: 'zip', label: 'Postnummer', parts: 4, fieldType: 'field' },
            { name: 'postal', label: 'Ort', fieldType: 'field' }
        ],
        { name: 'country', label: 'Land', as: 'select', defaultValue: 'Sverige', children: options, fieldType: 'field', parse: (value) => Number(value) },
        '-',
        [
            { name: 'password', label: 'Lösenord', icon: 'key', type: 'password', fieldType: 'field' },
            { name: 'password_confirmation', label: 'Lösenord, igen', icon: 'key', type: 'password', fieldType: 'field'}
        ],
        { name: 'user-form-submit', variant: 'dark', label: 'Registrera privat', fieldType: 'submit', pristine, submitting },
    ];

    return (
        <>
            <Form onSubmit={handleSubmit}>
                {renderForm(fields)}
            </Form>
        </>
    );
}

export default connect(
    (state, ownProps) => ({
        countries: state.countries,
        initialValues: {
            country: SWEDEN
        }
    })
)(
    reduxForm({
        form: 'registerUser',
        validate,
        warn,
        enableReinitialize : true
    })(RegisterUserForm)
);
