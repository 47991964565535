/* @flow */

// Framework
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { Form } from 'react-bootstrap'

// App components
import { renderForm } from '../FormHelper'
import renderLoading from '../../renderLoading'

// State
// ...

// Styles
// import * as classes from './AlternativeAddressForm.module.scss'

// Constants
// ...

const validate = values => {
    const errors = {}
    if (!values.street) {
        errors.street = 'Obligatoriskt'
    } else if (values.street.length < 1) {
        errors.street = 'Din gatuadress måste vara ifylld'
    }
    if (!values.zip) {
        errors.zip = 'Obligatoriskt'
    } else if (values.zip.length < 1) {
        errors.zip = 'Ditt postnummer måste vara ifyllt'
    }
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

class AlternativeAddressForm extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        countries: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired
    }

    componentDidMount () {
        this.props.initialize({items: 1})
    }

    render () {
        try {
            const {
                handleSubmit, //submitting, pristine
            } = this.props
    
            const { items: countries } = this.props.countries
        
            const fields = [
                {name: 'recipient', label: 'Mottagare', fieldType: 'field'},
                {name: 'street', label: 'Gatuadress', fieldType: 'field'},
                [
                    {name: 'zip', label: 'Postnummer', parts: '4', fieldType: 'field'},
                    {name: 'city', label: 'Ort', fieldType: 'field'}
                ]
            ]
            const { country_id: countryId } = this.props.user.address 
            const countryIndex = countries.map(c => c.id).indexOf(countryId)
            const country = countries[countryIndex].short_name

            return (
                <Form onSubmit={handleSubmit}>
                    {renderForm(fields)}
                    <p>{country}</p>
                </Form>
            )
        } catch (err) {
            return renderLoading()
        }
    }
}

AlternativeAddressForm = reduxForm({
  form: 'alternativeAddress',
  validate,
  warn
})(AlternativeAddressForm)

AlternativeAddressForm = connect(
  state => ({
    countries: state.countries,
    user: state.user
  })
)(AlternativeAddressForm)

export default AlternativeAddressForm
