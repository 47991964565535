import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { URI_UPLOAD } from '../../reducers/modules/api'
import { loadMagazine } from '../../reducers/modules/magazines'
import { loadCategory } from '../../reducers/modules/categories'
import { loadIssue, loadMagazineIssues } from '../../reducers/modules/issues'
import { ContentSelector } from '.'
import MISSING from './missingimage.jpg'

function IssueView({ match, issues, magazines, categories, loadIssue, loadMagazine, loadMagazineIssues, loadCategory }) {
    
    const [loadedIssue, setLoadedIssue] = useState(false);
    const [loadedIssues, setLoadedIssues] = useState(false);
    const [selectedIssue, setSelectedIssue] = useState();

    useEffect(() => {
        const { params: { issueId } } = match;
        const { items } = issues;
        const issueIndex = items.map((i) => (i.id)).indexOf(parseInt(issueId));

        if (issueIndex === -1 && !loadedIssue) {
            setLoadedIssue(true);
            loadIssue(issueId);
        }

        if (selectedIssue && selectedIssue.id !== parseInt(issueId)) {
            setSelectedIssue(undefined);
            setLoadedIssues(false);
            loadIssue(issueId);
        }

    }, [match, loadedIssue, issues, loadIssue]);

    useEffect(() => {
        const { params: { issueId } } = match;

        const { isUpdating, items } = issues;

        if (!isUpdating && !loadedIssues) {
            const issueIndex = items.map((i) => (i.id)).indexOf(parseInt(issueId));
            const issue = items[issueIndex]
            if (issue) {
                setSelectedIssue(issue);
                setLoadedIssues(true);
                loadMagazineIssues(issue.magazine_id)
            }
        }
    }, [match, issues, loadedIssues, loadMagazineIssues]);

    const { params: { issueId } } = match;

    const { items } = issues || [];

    const { data: categoryData } = categories;

    // const issueIndex = items.map((i) => (parseInt(i.id))).indexOf(parseInt(issueId));

    // const issue = (issueIndex > -1 ? items[issueIndex] : undefined);
    
    const { magazine } = (items && items.length > 0 ? items[0] : {});

    const categoryId = (magazine ? magazine.category_id : undefined);

    const categoryIndex = (categoryData && categoryId ? categoryData.map(c => c.id).indexOf(parseInt(categoryId)) : -1);

    const category = (categoryIndex > -1 ? categoryData[categoryIndex] : undefined);

    const cover = (selectedIssue && selectedIssue.cover ? `http://${URI_UPLOAD}/covers/${selectedIssue.cover.name}` : MISSING);

    return (
        <>
            <ContentSelector issue={selectedIssue} magazine={magazine} category={category} cover={cover} issues={items} />
        </>
    );
}

export default connect((state, ownProps) => ({
    issues: state.issues,
    magazines: state.magazines,
    categories: state.categories,
    user: state.user
}), {
    loadIssue,
    loadMagazine,
    loadMagazineIssues,
    loadCategory
})(IssueView);
