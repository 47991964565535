import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const CREATED_ORDER = 'CREATED_ORDER'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function createdOrder (
    order = {}
) {
    return {
        type: CREATED_ORDER,
        payload: order
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleCreatedOrder = (state, action) => {
    return update(state, {
        data: {$push: [action.payload]}
    })
}


/**
 * Inititator
 */
export const createOrder = () => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/orders`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: {},
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(createdOrder(resp.data))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
