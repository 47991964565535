import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'
import { issueCreated } from './CreateIssue'
import { pushToast } from '../toasts'

/**
 * Actions
 */
export const UPDATED_ISSUE = 'UPDATED_ISSUE'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export const issueUpdated = (
    issue = {},
    index = 0
) => ({
    type: UPDATED_ISSUE,
    payload: issue,
    index: index
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleUpdatedIssue = (state, action) => update(state, {
    items: {
      [action.index]: {$set: action.payload}
    }
})


/**
 * Inititator
 */
export const updateIssue = (
    data = {}
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/issues/${data.id}`,
                method: 'PUT',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data,
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    const { items: issues } = getState().issues
                    const index = issues.map(i => i.id).indexOf(parseInt(resp.data.id))
                    if (index > -1) {
                        dispatch(issueUpdated(resp.data, index))
                        dispatch(pushToast('Tidskriftens nummer har uppdaterats.'))
                    } else {
                        dispatch(issueCreated(resp.data))
                        dispatch(pushToast('Ett nytt nummer har skapats.'))
                    }
                    dispatch(popUpdating())

                    resolve()
                }
            })
        })
    }
}
