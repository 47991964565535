import React from "react";
import PropTypes from "prop-types";

const Placeholder = ({ error, touched }) => (
  <div style={{border: 'dashed', borderWidth: '1px', height: '220px', width: '100%', backgroundColor: 'white'}} />
);

Placeholder.propTypes = {
  error: PropTypes.string,
  touched: PropTypes.bool
};

export default Placeholder;
