/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Table } from 'react-bootstrap'

// App components
import PostageRow from './PostageRow'

// State
// ...

// Styles
// ...

// Constants
// ...

export const postageColumns = [
    'id',
    'pris (kr)',
    'min (gram)',
    '3'
]

export class PostagesTable extends React.Component {
    static propTypes = {
        postages: PropTypes.array.isRequired,
        skip: PropTypes.array,
        handleDelete: PropTypes.func.isRequired
    }

    render = () => {
        const { postages, handleDelete, skip = [] } = this.props
        const filtered = [...postageColumns].filter((col, index) => !skip.includes(col) && !skip.includes(index))

        return (
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        {filtered.map((col, index) => (<th key={index}>{isNaN(col) ? col : ''}</th>))}
                    </tr>
                </thead>
                <tbody>
                    {postages.map((row) => (
                        <PostageRow key={row.id} row={row} skip={this.props.skip} handleDelete={handleDelete} />
                    ))}
                </tbody>
            </Table>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
})
export default connect((mapStateToProps), {
})(PostagesTable)
