import React from 'react'
import { Spinner } from 'react-bootstrap'


export default (args = {
    variant: 'info',
    tint: true
}) => (
    <div className={['card-overlay', 'w-100', 'd-flex', 'justify-content-center', 'align-items-center', (args.tint ? 'card-overlay-tint' : '')].join(' ')} style={{zIndex: 10000}}>
        <Spinner animation="border" variant={args.variant} />
    </div>
)
