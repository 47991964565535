import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Alert } from 'react-bootstrap'
import { popAlert } from '../../reducers/modules/alerts'

// An alert will show for maximum 10 seconds
const TIMEOUT_MESSAGE = 10000

const getMessages = (messages) => {
    if (Array.isArray(messages)) {
        return messages.map((message) => <p>{message}</p>)
    } else {
        return <p>{messages}</p>
    }
}

const AlertMessage = (props) => {

    const { alert, popAlert } = props
    const { heading, alert: message, uid } = alert

    useEffect(() => {
        const timer = setTimeout(() => {
            popAlert(uid)
        }, TIMEOUT_MESSAGE)

        return () => clearTimeout(timer)
    }, [popAlert, uid])

    return (
        <Alert variant="danger" onClose={() => popAlert(uid)} dismissible>
            {heading && <Alert.Heading>{ heading }</Alert.Heading>}
            {message && getMessages(message)}
        </Alert>
    )
}

AlertMessage.propTypes = {
    alert: PropTypes.object.isRequired,
    popAlert: PropTypes.func.isRequired,
}

AlertMessage.defaultProps = {
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, {
    popAlert
})(AlertMessage)
