/* @flow */
import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'

/**
 * Actions
 */
export const CANCELLED_ORDER = 'CANCELLED_ORDER'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function cancelledOrder (
    index = undefined
) {
    return {
        type: CANCELLED_ORDER,
        index: index
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleCancelledOrder = (state, action) => update(state, {
    isUpdating: {$set: false},
    items: {$splice: [[action.index, 1]]}
})


/**
 * Inititator
 */
export const cancelPayment = (
    data = undefined
) => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/checkout/cancel`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: data,
                error (err) {
                    console.error(JSON.stringify(err))
                    resolve()
                },
                success (resp) {
                    const { orders } = getState().user
                    if (orders && orders.data) {
                        const index = orders.data.map(o => o.id).indexOf(resp.data.id)
                        if (index !== -1) {
                            dispatch(cancelledOrder(index))
                        }    
                    }
                    resolve()
                }
            })
        })
    }
}
