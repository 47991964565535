import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import CategoryList from '../../components/CategoryList/CategoryList';
import OrbitView from '../../components/OrbitView/OrbitView';
import MagazineIssue from '../../components/MagazineIssue/MagazineIssue';
import chunk from '../../chunk';
import { clearMagazines, loadLatestMagazines } from '../../reducers/modules/magazines';
import * as classes from './FrontView.module.scss';

const MAX_ISSUES_ROW = 5

function FrontView({ magazines, clearMagazines, loadLatestMagazines }) {

    const { data } = magazines;

    useEffect(() => {
        clearMagazines();
        loadLatestMagazines();
    }, [clearMagazines, loadLatestMagazines]);

    if (!data || data.length === 0) {
        return (
            <Container>
                <Row>
                    <Col xs={3}>
                        <CategoryList />
                    </Col>
                    <Col xs={9}>
                        <Row className={classes.banners} >
                            <OrbitView />
                        </Row>

                        <Row>
                            <h2 className={classes.header_newissues}>Nyinkommet i butiken</h2>
                        </Row>
                    
                        {[...Array(6).keys()].map((row) => (
                            <Row key={row} className={[classes.row, 'd-flex', 'justify-content-start']}>
                                {[...Array(5).keys()].map((col) => (
                                    <Col key={col} className={['d-flex', 'align-self-end', 'justify-content-center']}>
                                        <MagazineIssue magazine={undefined} issue={undefined} />
                                    </Col>
                                ))}
                            </Row>
                        ))}
                    </Col>
                </Row>
            </Container>
        );
    }

    let rows = chunk(data, MAX_ISSUES_ROW)
    
    return (
        <Container>
            <Row>
                <Col xs={3}>
                    <CategoryList />
                </Col>
                <Col xs={9}>
                    <Row className={classes.banners} >
                        <OrbitView />
                    </Row>

                    <Row>
                        <h2 className={classes.header_newissues}>Nyinkommet i butiken</h2>
                    </Row>

                    {rows.map((row, index) => (
                        <Row key={index} className={[classes.row, 'd-flex', 'justify-content-start']}>
                            {row.map(magazine => (
                                <Col key={magazine.id} className={['d-flex', 'align-self-end', 'justify-content-center']}>
                                    <MagazineIssue magazine={magazine} issue={magazine.latest_issue} />
                                </Col>
                            ))}
                        </Row>
                    ))}
                </Col>
            </Row>
        </Container>
    );
}

export default connect((state) => ({
    magazines: state.magazines
}), {
    loadLatestMagazines,
    clearMagazines
})(FrontView);
