import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const LOADED_INT_POSTAGES = 'LOADED_INT_POSTAGES'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function internationalLoaded (
    postages = undefined
) {
    return {
        type: LOADED_INT_POSTAGES,
        payload: postages
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadedInternational = (state, action) => update(state, {
    international: {$set: action.payload}
})


/**
 * Inititator
 */
export const loadInternational = () => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/intpostages`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(internationalLoaded(resp.data))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
