/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Row, Col, Card, Table } from 'react-bootstrap'

// App components
import AdminPages from '../../components/AdminPages'
import FilterAccountingForm from '../../components/FilterAccountingForm'
import AccountingRow from '../../components/AccountingRow'

// State
import { filterAccounting } from '../../reducers/modules/accounting'

// Styles
// import * as classes from './AdminAccountingView.module.scss'

// Constants
// ...

const renderTable = (accounting) => {
  return (
    accounting.map((row) => (
      <AccountingRow key={row.id} row={row} />
    ))
  )
}

export class AdminAccountingView extends React.Component {
  static propTypes = {
    accounting: PropTypes.object.isRequired,

    filterAccounting: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)

    this.handleFilterAccounting = this.handleFilterAccounting.bind(this)
  }

  handleFilterAccounting (form) {
    const { start_year, start_month, start_day, stop_year, stop_month, stop_day, magazine_id } = form
    this.props.filterAccounting(start_year, start_month, start_day, stop_year, stop_month, stop_day, magazine_id)
  }

  componentDidMount () {
  }

  render () {
    try {
      const { data: accounting } = this.props.accounting

      const colWidth = 100
      return (
        <Container>
          <Row>
            <Col xs={2}>
              <AdminPages />
            </Col>
            <Col xs={10}>
              <Card body>
                <h4>Information</h4>
                <p>Här kan du printa ut en sammanställning av all försäljning.</p>
                <p>Filtrera vilka datum du vill söka resultat för här.</p>

                <FilterAccountingForm onSubmit={this.handleFilterAccounting} />
              </Card>

              <Card body>
                <h4>Sammanställning</h4>
                <h5>Från: 2019-01-01 0:00:00 Till: 2019-12-31 23:59:59</h5>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th />
                      <th width={colWidth}>Antal ordrar</th>
                      <th width={colWidth}>Antal enheter</th>
                      <th width={colWidth}>Varuvärde, exkl. moms</th>
                      <th width={colWidth}>Deb. frakt exkl. moms</th>
                      <th width={colWidth}>Moms</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accounting.length > 0 && renderTable(accounting)}
                  </tbody>
                </Table>
              </Card>

              <Card body>
                <h4>Detaljerad</h4>
                <h5>Från: 2019-01-01 0:00:00 Till: 2019-12-31 23:59:59</h5>
              </Card>
            </Col>
          </Row>
        </Container>
      )
    } catch (error) {
      return (
        <Container>
          <p>laddar...</p>
        </Container>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  accounting: state.accounting
})
export default connect((mapStateToProps), {
  filterAccounting
})(AdminAccountingView)
