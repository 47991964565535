import update from 'immutability-helper'

import {
    performSearch,
    FINISHED_SEARCH,
    handleFinishedSearch
} from './SearchItems'

import {
    toggleAdvanced,
    TOGGLE_ADVANCED,
    handleToggleAdvanced
} from './ControlAdvanced'

export { performSearch, toggleAdvanced }

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATING_SEARCH = 'UPDATING_SEARCH'
export const CLEAR_ALL_SEARCH = 'CLEAR_ALL_SEARCH'

const INCREMENT = 1;
const DECREMENT = 2;


// ------------------------------------
// Actions
// ------------------------------------
export function pushUpdating () {
    return {
        type: UPDATING_SEARCH,
        payload: INCREMENT
    }
}

export function popUpdating () {
    return {
        type: UPDATING_SEARCH,
        payload: DECREMENT
    }
}

/**
 * Public function
 * Tell the store to clear out all the issues
 */
export function clearSearch () {
    return {
        type: CLEAR_ALL_SEARCH
    }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [UPDATING_SEARCH]: (state, action) => {
        if (INCREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating + 1}})
        } else if (DECREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating - 1}})
        } else {
            return state
        }
    },
    
    [FINISHED_SEARCH]: (state, action) => handleFinishedSearch(state, action),

    [TOGGLE_ADVANCED]: (state, action) => handleToggleAdvanced(state, action),
    
    [CLEAR_ALL_SEARCH]: (state, action) => (
        update(state, {
            isUpdating: {$set: false},
            items: {$set: []}
        })
    )
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    isUpdating: 0,
    data: [],
    links: {},
    meta: {},
    showAdvanced: false
}

export default function searchReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
