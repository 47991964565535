import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating} from './index'

/**
 * Actions
 */
export const UPDATED_ORBIT = 'UPDATED_ORBIT'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function updatedOrbit (
    orbit = undefined,
    index = undefined
) {
    return {
        type: UPDATED_ORBIT,
        payload: orbit,
        index: index
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleUpdatedOrbit = (state, action) => update(state, {
    images: { data: {
        [action.index]: {$set: action.payload}
    }}
})


/**
 * Initiator
 * 
 * @param {*} orbitId 
 * @param {*} name
 */
export const updateOrbit = (
    orbitId = undefined,
    data = undefined
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/orbits/${orbitId}`,
                method: 'PUT',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: data,
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    const { data: orbits } = getState().orbits.images 
                    const index = orbits.map(o => o.id).indexOf(parseInt(resp.data.id))
                    if (index > -1) {
                        dispatch(updatedOrbit(resp.data, index))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
    