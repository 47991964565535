/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap'
import { reduxForm } from 'redux-form'

// App componetns
import { renderForm } from '../../components/FormHelper'

// State
import { loadMagazines } from '../../reducers/modules/magazines'

// Styles
// import * as classes from './FilterAccountingForm.module.scss'

// Constants
// ...

const validate = values => {
    const errors = {}
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

class FilterAccountingForm extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        loadMagazines: PropTypes.func.isRequired
    }

    componentDidMount () {
        this.props.loadMagazines(undefined, false)
    }

    render () {
        const {
            handleSubmit, //submitting, pristine
        } = this.props

        try {
            const { data: magazines } = this.props.magazines

            const chooseMagazine = {id: -2, title: '-- välj tidskrift --'}
            const allMagazines = {id: -1, title: 'Alla tidskrifter'}

            const joinedMagazines = [chooseMagazine, allMagazines].concat(magazines)
            
            const magazineOptions = joinedMagazines.map((magazine) => (
                <option key={magazine.id} value={magazine.id}>{magazine.title}</option>
            ))

            const fields = [
                [{fieldType: 'field', name: 'start_year', label: 'år'}, {fieldType: 'field', name: 'start_month', label: 'månad'}, {fieldType: 'field', name: 'start_day', label: 'dag'}],
                [{fieldType: 'field', name: 'stop_year', label: 'år'}, {fieldType: 'field', name: 'stop_month', label: 'månad'}, {fieldType: 'field', name: 'stop_day', label: 'dag'}],
                {fieldType: 'field', name: 'magazine_id', label: 'Tidskrift', as: 'select', defaultValue: 'Alla tidskrifter', children: magazineOptions},
                {variant: 'dark', label: 'Sök', fieldType: 'submit'},
            ]

            return (
                <Form onSubmit={handleSubmit}>
                    {renderForm(fields)}
                </Form>)
        } catch (error) {
            console.error(error)
            return (<p>error</p>)
        }
    }
}

FilterAccountingForm = reduxForm({
  form: 'filterAccounting',
  validate,
  warn
})(FilterAccountingForm)

FilterAccountingForm = connect(
  state => ({
    magazines: state.magazines
  }),
  {
    loadMagazines
  }
)(FilterAccountingForm)

export default FilterAccountingForm
