/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { Form } from 'react-bootstrap'

// App componetns
import { renderForm } from '../../components/FormHelper'

// State
// ...

// Styles
// import * as classes from './PasswordForm.module.scss'

// Constants
// ...

const validate = values => {
  const errors = {}
  if (!values.password) {
    errors.password = 'Obligatoriskt'
  } else if (values.password.length < 1) {
    errors.password = 'Lite för kort lösenord...'
  }
  if (!values.password_confirmation) {
    errors.password = 'Obligatoriskt'
  } else if (values.password_confirmation !== values.password) {
    errors.password = 'Lösenorden måste matcha'
  }
  return errors
}

const warn = values => {
  const warnings = {}
  return warnings
}

export const WITH_TOKEN = 1
export const WITH_PASSWORD = 2

class PasswordForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetType: PropTypes.number,
    initialValues: PropTypes.object
  }

  render () {
    const {
      handleSubmit, resetType, //pristine, submitting
    } = this.props

    let fields = [
      {name: 'password', label: 'Nytt lösenord', prependIcon: 'key', type: 'password', fieldType: 'field'},
      {name: 'password_confirmation', label: 'Nytt lösenord, igen', prependIcon: 'key', type: 'password' , fieldType: 'field'}
    ]

    switch (resetType) {
      case WITH_TOKEN:
        fields = [...fields, ...[
          '-',
          {name: 'resettoken', label: 'Återställningskod', prependIcon: 'key', fieldType: 'field', type: 'text'},
        ]]
        break
      
      case WITH_PASSWORD:
      default:
        fields = [...fields, ...[
          '-',
          {name: 'current_password', label: 'Nuvarande lösenord', prependIcon: 'key', type: 'password', fieldType: 'field'},
        ]]
    }

    fields = [...fields, {name: 'submit', variant: 'dark', label: 'Spara', fieldType: 'submit'}]

    return (
      <Form onSubmit={handleSubmit}>
        {renderForm(fields)}
      </Form>
    )
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
PasswordForm = reduxForm({
    form: 'password',
    validate,
    warn,
    enableReinitialize : true
})(PasswordForm)

// You have to connect() to any reducers that you wish to connect to yourself
PasswordForm = connect(
    (state, ownProps) => ({
        initialValues: ownProps.initialValues
    })
)(PasswordForm)

export default PasswordForm
