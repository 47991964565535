/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// App components
import { formatPrice } from '../../PriceHelper'
import { getPaymentMethod } from './payment_methods'
import { CARD_PAYMENT, INVOICE_PAYMENT, PRE_PAYMENT } from '../ReceiptTable'

// State
// ...

// Styles
// ...

// Constants
// ...

const getCheckoutType = (checkoutType) => {
    switch (parseInt(checkoutType)) {
        case CARD_PAYMENT:
            return (<><FontAwesomeIcon icon={['far', 'credit-card']} color='green' /></>);
        case INVOICE_PAYMENT:
        case PRE_PAYMENT:
            return (<><FontAwesomeIcon icon={['far', 'envelope']} color='green' /></>);
        default:
            return checkoutType;
    }
}

export class ReceiptTable extends React.Component {
    static propTypes = {
        order: PropTypes.object.isRequired,
        title: PropTypes.string
    }

    render = () => {
        try {
            const { settings, title } = this.props
            const { order } = this.props || {}
        
            const invoiceTitle = order.invoice_number !== 0 ? 'Fakturanummer:' : 'Ordernummer:'
            const invoiceData = order.invoice_number !== 0 ? order.invoice_number : order.id
            
            const totalVat = order.amount_total - (order.amount_total / (1 + settings.data.VAT / 100));

            return (
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th colSpan={2}><b>{title ? title : 'Kvitto'}</b></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td>Genomfört</td><td>{order.created_at}</td></tr>
                        {parseInt(order.txnid) !== 0 && <tr><td>Transaktionsnummer</td><td>{order.txnid}</td></tr>}
                        {(parseInt(order.checkoutmethod) === CARD_PAYMENT) && <tr><td>Betalningsmetod</td><td>{getCheckoutType(order.checkoutmethod)}</td></tr>}
                        {order.checkoutmethod === 0 && <tr><td>Betalningslösning</td><td>{getPaymentMethod(order.paymenttype)}</td></tr>}
                        {order.checkoutmethod === 1 && <tr><td>{invoiceTitle}</td><td>{invoiceData}</td></tr>}
                        <tr><td>Summa</td><td>{formatPrice(order.amount)} kr.</td></tr>
                        <tr><td>Frakt</td><td>{formatPrice(order.shipping + order.extraaddressshipping)} kr.</td></tr>
                        <tr><td>Totalt belopp</td><td>{formatPrice(order.amount_total)} kr.</td></tr>
                        <tr><td>varav moms (6%)</td><td>{formatPrice(totalVat)} kr.</td></tr>
                    </tbody>
                </Table>
            )
        } catch (err) {
            return <div />
        }
    }
}

const mapStateToProps = (state, ownProps) => ({
    settings: state.settings
})
export default connect((mapStateToProps), {
})(ReceiptTable)
