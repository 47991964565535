/**
 * Helper to divide an array of items into array of arrays
 * with specific sizes.
 * 
 * @param {*} array 
 * @param {*} size 
 */
const Chunk = (array, size) => {
    const chunked_arr = []
    let index = 0
    while (index < array.length) {
      chunked_arr.push(array.slice(index, size + index))
      index += size
    }
    return chunked_arr
}

export default Chunk
