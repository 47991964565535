import update from 'immutability-helper'

// Load shortlists
import {
    loadShortlists,
    LOADED_SHORTLISTS,
    handleLoadedShortlists
} from './LoadShortlists'

// Create shortlist
import {
    createShortlist,
    CREATED_SHORTLIST,
    handleCreatedShortlist
} from './CreateShortlist'

// Remove shortlist
import {
    deletedShortlist,
    DELETED_SHORTLIST,
    handleDeletedShortlist
} from './DeleteShortlist'

export { loadShortlists, createShortlist, deletedShortlist }

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATING_SHORTLISTS = 'UPDATING_SHORTLISTS'
export const CLEAR_ALL_SHORTLISTS = 'CLEAR_ALL_SHORTLISTS'

const INCREMENT = 1;
const DECREMENT = 2;

// ------------------------------------
// Actions
// ------------------------------------
export function pushUpdating () {
    return {
        type: UPDATING_SHORTLISTS,
        payload: INCREMENT
    }
}

export function popUpdating () {
    return {
        type: UPDATING_SHORTLISTS,
        payload: DECREMENT
    }
}

/**
 * Public function
 * Tell the store to clear out all the SHORTLISTS
 */
export function clearShortlists () {
    return {
        type: CLEAR_ALL_SHORTLISTS
    }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [UPDATING_SHORTLISTS]: (state, action) => {
        if (INCREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating + 1}})
        } else if (DECREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating - 1}})
        } else {
            return state
        }
    },

    [LOADED_SHORTLISTS]: (state, action) => handleLoadedShortlists(state, action),

    [CREATED_SHORTLIST]: (state, action) => handleCreatedShortlist(state, action),

    [DELETED_SHORTLIST]: (state, action) => handleDeletedShortlist(state, action),

    [CLEAR_ALL_SHORTLISTS]: (state, action) => (
        update(state, {$set: initialState})
    )
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    isUpdating: 0,
    data: [],
    links: undefined,
    meta: undefined
}

export default function shortlistsReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
