import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm, initialize } from 'redux-form';
import { Form } from 'react-bootstrap';
import { renderForm } from '../FormHelper';
// import * as classes from './PageForm.module.scss';

const validate = values => {
    const errors = {}
    // if (!values.name) {
    //     errors.name = 'Obligatoriskt'
    // } else if (values.name.length < 1) {
    //     errors.name = 'Din gatuadress måste vara ifylld'
    // }
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

function PageForm(props) {

    const { page, handleSubmit, editorType, initialize } = props;

    useEffect(() => {
        if (page) {
            initialize(page)
        }
    }, [page, initialize]);

    const fields = [
        { name: 'name', label: 'Sidans titel', fieldType: 'field'},
        { name: 'text', label: '', fieldType: 'editor', editorType },
        { variant: 'dark', name: 'submit', label: 'Spara', fieldType: 'submit' }
    ];

    return (
        <Form onSubmit={handleSubmit}>
            {renderForm(fields)}
        </Form>
    );
}

export default connect(
    (state, ownProps) => ({
        initialize,
        initialValues: ownProps.page
    })
)(
    reduxForm({
        form: 'page',
        validate,
        warn,
        enableReinitialize : true
    })(PageForm)
);
