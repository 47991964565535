/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// App components
import renderLoading from '../../renderLoading'
import AlternativeAddressForm from '../AlternativeAddressForm'

// State
import { toggleAlternativeCheckoutAddress } from '../../reducers/modules/checkout'
import { nextStage, setAddress } from '../../reducers/modules/checkout'

// Styles
// import * as classes from './CheckoutStageTwo.module.scss'

// Constants
// ...

const renderRealUserAddress = (title, user, country) => {
    return (<>
        <h4>{title}</h4>
        <Card body>
            <h5>{user.data.first_name} {user.data.last_name}</h5>
            <p>{user.address.street}</p>
            <p>{user.address.zip} {user.address.city}</p>
            <p>{country}</p>
        </Card>
    </>)
}

const renderRealCompanyAddress = (title, user, country) => {
    return (<>
        <h4>{title}</h4>
        <Card body>
            <h5>{user.data.company.name}</h5>
            {user.data.company.reference && <p>att: {user.data.company.reference}</p>}
            <p>{user.address.street}</p>
            <p>{user.address.zip} {user.address.city}</p>
            <p>{country}</p>
        </Card>
    </>)
}

const renderRealAddress = (user, countries) => {
    const countryIndex = countries.items.map(c => c.id).indexOf(user.address.country_id)
    const country = countries.items[countryIndex].short_name

    const title = user.data.company ? 'Faktureringsadress' : 'Leveransadress'

    if (user.data.company) {
        return renderRealCompanyAddress(title, user, country)
    }

    return renderRealUserAddress(title, user, country)
}

const renderAlternative = (props, handleSubmit) => {
    return (<>
        <h4>Leveransadress</h4>
        <Card body>
            <h6>Välj annan leveransadress:</h6>
            <p>En extra kostnad om 10 kr. tillkommer för separat fakturering</p>
            <AlternativeAddressForm onSubmit={handleSubmit} />
            <p><i>Du kan inte välja ett annat land när du väljer alternativ leveransadress</i></p>
        </Card>
    </>)
}

class CheckoutStageTwo extends React.Component {
    static propTypes = {
        checkout: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        countries: PropTypes.object.isRequired,
        toggleAlternativeCheckoutAddress: PropTypes.func.isRequired,
        nextStage: PropTypes.func.isRequired,
        setAddress: PropTypes.func.isRequired
    }

    constructor (props) {
        super(props)

        this.toggleAlterantive = this.toggleAlterantive.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    
    handleSubmit (form) {
        const { user } = this.props
        const { address } = user
        const { items: countries } = this.props.countries
        const countryIndex = countries.map(c => c.id).indexOf(address.country_id)
        const countryName = countries[countryIndex].short_name
        const altAddress = Object.assign({}, form, {country: countryName})
        this.props.setAddress(altAddress)
        this.props.nextStage()
    }

    toggleAlterantive (evt) {
        evt.preventDefault()

        this.props.toggleAlternativeCheckoutAddress()
    }

    render () {
        try {
            const { user, countries, checkout } = this.props
            const { useAlternativeAddress } = checkout
            const toggleText = (useAlternativeAddress ? 'Använd samma adress' : 'Välj annan leveransadress')
            const toggleIcon = (useAlternativeAddress ? 'minus' : 'plus')
            return (
                <Row>
                    <Col xs={6}>
                        {renderRealAddress(user, countries)}
                        <Button variant='link' onClick={this.toggleAlterantive}><FontAwesomeIcon icon={toggleIcon} /> {toggleText}</Button>
                    </Col>
                    {useAlternativeAddress && <Col xs={6}>
                        {renderAlternative(this.props, this.handleSubmit)}
                    </Col>}
                </Row>
            )
        } catch (err) {
            console.error(err)
            return renderLoading()
        }
    }
}

const mapStateToProps = (state) => ({
    checkout: state.checkout,
    user: state.user,
    countries: state.countries
})
export default connect((mapStateToProps), {
    toggleAlternativeCheckoutAddress,
    nextStage,
    setAddress
})(CheckoutStageTwo)

