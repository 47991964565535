import update from 'immutability-helper'

import {
    loadCarts,
    LOADED_CARTS,
    handleLoadedCarts
} from './LoadCarts'

import {
    createCart,
    CREATED_CART,
    handleCreatedCart
} from './CreateCart'

import {
    updateCart,
    UPDATED_CART,
    handleUpdatedCart
} from './UpdateCart'

import {
    addIssue
} from './AddIssue'

import {
    setQuantity
} from './SetQuantity'

import {
    removeIssue
} from './RemoveIssue'

export { loadCarts, createCart, updateCart }
export { addIssue, setQuantity, removeIssue}

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATING_CARTS = 'UPDATING_CARTS'
export const CLEAR_ALL_CARTS = 'CLEAR_ALL_CARTS'

// ------------------------------------
// Actions
// ------------------------------------
/**
 * Private function
 * Used to toggle the isUpdating flag in the all carts state
 *
 * @param state     The isUpdating state
 */
export function cartsIsUpdating (
    state = true
) {
    return {
        type: UPDATING_CARTS,
        payload: state
    }
}

/**
 * Public function
 * Tell the store to clear out all the carts
 */
export function clearCarts () {
    return {
        type: CLEAR_ALL_CARTS
    }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [UPDATING_CARTS]: (state, action) => (
        update(state, {
            isUpdating: {$set: action.payload}
        })
    ),
    
    [LOADED_CARTS]: (state, action) => handleLoadedCarts(state, action),

    [CREATED_CART]: (state, action) => handleCreatedCart(state, action),

    [UPDATED_CART]: (state, action) => handleUpdatedCart(state, action),

    [CLEAR_ALL_CARTS]: (state, action) => (
        update(state, {
            isUpdating: {$set: false},
            items: {$set: []}
        })
    )
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    isUpdating: false,
    items: []
}

export default function cartsReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
