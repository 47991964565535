/* @flow */

// Framework
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { Form, Col } from 'react-bootstrap'

// App components
import { renderFormRadio } from '../FormHelper'
import renderLoading from '../../renderLoading'
import { CARD_PAYMENT, INVOICE_PAYMENT, PRE_PAYMENT } from '../ReceiptTable'

// State
// ...

// Styles
// import * as classes from './PaymentSelectionForm.module.scss'

// Constants
// ...

const validate = values => {
    const errors = {}
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

class PaymentSelectionForm extends React.Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        onNewVal: PropTypes.func.isRequired,
    }

    render () {
        try {
            const {
                handleSubmit, //submitting, pristine
                onNewVal,
                user: { data: { company } }
            } = this.props

            const { permissions } = this.props.user

            if (!permissions) {
                return <div />
            }

            const isAdmin = permissions.includes('admin')
            const isStore = permissions.includes('store')

            return (
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="paymentMethod">
                            {(!isAdmin && !isStore) && <Field
                                name='paymentMethod'
                                label='Visa/Mastercard'
                                theValue={`${CARD_PAYMENT}`}
                                onChange={(evt, newVal, oldVal) => onNewVal(newVal)}
                                component={renderFormRadio} />}
                            {((!isAdmin && !isStore) && company) && <Field
                                name='paymentMethod'
                                label='Faktura'
                                theValue={`${INVOICE_PAYMENT}`}
                                onChange={(evt, newVal, oldVal) => onNewVal(newVal)}
                                component={renderFormRadio} />}
                            {!company && <Field
                                name='paymentMethod'
                                label='Förskott'
                                theValue={`${PRE_PAYMENT}`}
                                defaultChecked={true}
                                onChange={(evt, newVal, oldVal) => onNewVal(newVal)}
                                component={renderFormRadio} />}
                        </Form.Group>
                    </Form.Row>
                </Form>
            )
        } catch (err) {
            console.error(err)
            return renderLoading()
        }
    }
}

PaymentSelectionForm = reduxForm({
    form: 'paymentSelection',
    validate,
    warn
})(PaymentSelectionForm)

PaymentSelectionForm = connect(
    state => ({
        countries: state.countries,
        user: state.user,
        initialValues: {
            paymentMethod: '0'
        }
    })
)(PaymentSelectionForm)

export default PaymentSelectionForm
