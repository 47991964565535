import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'
import { pushToast } from '../toasts'

/**
 * Actions
 */
export const CREATED_ISSUE_MAGAZINE = 'CREATED_ISSUE_MAGAZINE'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function createdIssueMagazine (
    issuemagazine = {}
) {
    return {
        type: CREATED_ISSUE_MAGAZINE,
        payload: issuemagazine
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleCreatedIssueMagazine = (state, action) => update(state, {
    items: {$push: [action.payload]}
})


/**
 * Inititator
 * 
 * @param {*} userId 
 */
export const createIssueMagazine = (
    data = {}
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())

        const { magazine, inprice, outprice, pages, year, theme, description: contents, weight, editions, stock, sold, destroyed, paid } = data;
        const { title, description } = magazine;
        if (!title) {
            dispatch(pushToast("Måste ange en titel för en tidskrift!"));
            return;
        }
        if (!inprice || !outprice) {
            dispatch(pushToast("Måste ange ett pris för en tidskrift!"));
            return;
        }
        if (!pages) {
            dispatch(pushToast("Måste skriva in antal sidor för att skapa tidskrift"));
            return;
        }
        if (!year) {
            dispatch(pushToast("Tidskriften måste ha ett årtal"));
            return;
        }
        if (!theme) {
            dispatch(pushToast("Tidskriften måste ha ett tema"));
            return;
        }
        if (!description) {
            dispatch(pushToast("Tidskriften måste ha en beskrivning"));
            return;
        }
        if (!contents) {
            dispatch(pushToast("Tidskriften måste ha en innehållsförteckning"));
            return;
        }
        if (!weight) {
            dispatch(pushToast("Tidskriften måste ha en vikt"));
            return;
        }
        if (!editions) {
            dispatch(pushToast("Tidskriften måste ha multipelnummer"));
            return;
        }
        if (!stock || !sold || !destroyed || !paid) {
            dispatch(pushToast("Måste ange lagerhållningen"));
            return;
        }

        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/issuemagazine/`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: data,
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(createdIssueMagazine(resp.data))
                    dispatch(popUpdating())
                    resolve(resp.data)
                }
            })
        })
    }
}
