import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Form, Col, Button } from 'react-bootstrap';
import { renderForm } from '../FormHelper';
// import * as classes from './AdvancedSearchForm.module.scss';

const validate = values => {
    const errors = {}
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

function AdvancedSearchForm(props) {

    const { handleSubmit, clearFields } = props;

    const showLabel = true;

    const fields = [
        [
            { name: 'search_title', fieldType: 'field', label: 'Titel', placeholder: 't.ex. Arbetaren', showLabel },
            { name: 'search_year', fieldType: 'field', label: 'År', placeholder: 't.ex. 2007', showLabel },
            { name: 'search_number', fieldType: 'field', label: 'Nummer', placeholder: 't.ex. 19', showLabel }
        ],
        [
            { name: 'search_total_issue', fieldType: 'field', label: 'Löpnummer', placeholder: 't.ex. 1265', showLabel },
            { name: 'search_barcode', fieldType: 'field', label: 'Streckkod', placeholder: 't.ex. 2007:19', showLabel },
            { name: 'search_theme', fieldType: 'field', label: 'Tema', showLabel }
        ],
        [
            { name: 'search_description', fieldType: 'field', label: 'Beskrivning', showLabel },
            { name: 'search_contents', fieldType: 'field', label: 'Innehåll', showLabel }
        ]
    ];

    return (
        <Form onSubmit={handleSubmit}>
            {renderForm(fields)}

            <Form.Row>
                <Form.Group as={Col} xs={{span: 3, offset: 6}}>
                    <Button variant='dark' block onClick={(evt) => {evt.preventDefault(); clearFields()}}>
                        Rensa
                    </Button>
                </Form.Group>

                <Form.Group as={Col} xs={{span: 3}}>
                    <Button variant='dark' type='submit' block>
                        Sök
                    </Button>
                </Form.Group>
            </Form.Row>
        </Form>
    );
}

export default connect(
    (state, ownProps) => ({
    })
)(
    reduxForm({
        form: 'advancedSearchForm',
        validate,
        warn,
        enableReinitialize : true
    })(AdvancedSearchForm)
);
