import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Form } from 'react-bootstrap';
import { renderForm } from '../FormHelper';
// import * as classes from './OrbitsSettingsForm.module.scss';

const validate = values => {
    const errors = {}
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

function OrbitsSettingsForm(props) {

    const { handleSubmit } = props;
        
    const showLabel = true;

    const fields = [[
        { name: 'timer_speed', label: 'Timer hastighet', showLabel, fieldType: 'field' },
        { name: 'animation_speed', label: 'Animation hastighet', showLabel, fieldType: 'field' },
        { name: 'bullets', label: 'Bullets', fieldType: 'switch', showLabel }
    ]];

    return (
        <Form onSubmit={handleSubmit}>
            {renderForm(fields)}
        </Form>
    );
}

export default connect(
    (state, ownProps) => ({
        initialValues: ownProps.setting
    })
)(
    reduxForm({
        form: 'orbitSettingsForm',
        validate,
        warn,
        enableReinitialize : true
    })(OrbitsSettingsForm)
);
