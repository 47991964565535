import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'
import { createdUser } from './CreateUser'
import { updatedUser } from './UpdateUser'


/**
 * Inititator
 * 
 * @param {*} userId 
 */
export const loadUser = (
    userId = undefined
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/users/${userId}`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    const { data: users } = getState().users
                    const index = users.map(o => o.id).indexOf(parseInt(parseInt(resp.data.id)))
                    if (index > -1) {
                        dispatch(updatedUser(resp.data, index))
                    } else {
                        dispatch(createdUser(resp.data))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
