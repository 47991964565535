import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import CategoryList from '../../components/CategoryList';
import MagazineIssue from '../../components/MagazineIssue';
import CreateItem from '../../components/CreateItem';
import chunk from '../../chunk';
import renderLoading from '../../renderLoading';
import PaginationView from '../../components/PaginationView';
import IncludeOldIssuesForm from '../../components/IncludeOldIssuesForm';
import { clearMagazines, loadCategoryMagazines } from '../../reducers/modules/magazines';
import { isAdmin } from '../../PermissionsHelper';
import * as classes from './CategoryView.module.scss';
import NEW_MAGAZINE from './new_magazine.jpg';

const MAX_MAGAZINES_ROW = 4;

const renderShelves = (rows, categoryId) => (
    rows.map((row, index) => (
        <Row key={index} className={[classes.row, 'd-flex', 'justify-content-start']}>
            {row.map((magazine) => (
                <Col key={magazine.id} xs={3} className={['d-flex', 'align-self-end', 'justify-content-center', 'align-items-start']}>
                    {magazine.id === -1 ? (<CreateItem image={NEW_MAGAZINE} {...{categoryId}} />) : (<MagazineIssue magazine={magazine} issue={magazine.latest_issue} />)}
                </Col>
            ))}
        </Row>
    ))
)

function CategoryView(props) {

    const { match, location, categories, magazines, user, loadCategoryMagazines, clearMagazines } = props;

    const [category, setCategory] = useState(undefined);
    const [loaded, setLoaded] = useState(false);
    const [includeOld, setIncludeOld] = useState(false);

    useEffect(() => {
        const { categoryId } = match.params;

        if (parseInt(categoryId) !== category) {
            setCategory(categoryId);
            setLoaded(false);
        }

    }, [match, location, category]);

    useEffect(() => {

        if (!loaded && category) {
            const { search: pageString } = location;
            clearMagazines();
            loadCategoryMagazines(category, pageString, includeOld);
            setLoaded(true);
        }
    }, [clearMagazines, loadCategoryMagazines, category, loaded, location, includeOld]);

    const toggledExlude = (form) => {
        const { includeOldIssues } = form;
        setIncludeOld(includeOldIssues);
        setLoaded(false);
    }

    const { categoryId } = match.params;
    const { data: actual } = magazines;

    // This will make a placeholder for the "add magazine" action - only for admin users
    const magazinesData = (user && isAdmin(user)) ? [{
        id: -1,
        latest_issue: {}
    }].concat(actual) : actual;
    
    const filteredMagazines = magazinesData.filter(m => m.latest_issue)
    const rows = chunk([...filteredMagazines], MAX_MAGAZINES_ROW)
    
    const categoryIndex = categories.data.map(c => c.id).indexOf(parseInt(categoryId));

    const { name: categoryTitle } = categoryIndex > -1 ? categories.data[categoryIndex] : "laddar...";

    const content = magazines.isLoading ? renderLoading() : renderShelves(rows, categoryId);

    return (
        <>
            <Container>
                <Row>
                    <Col xs={3}>
                        <CategoryList />
                    </Col>
                    <Col xs={9}>
                        <Row>
                            <h1>{categoryTitle}</h1>
                        </Row>
                            {content}
                        <Row>
                            <Col>
                                <div className={['d-flex', 'justify-content-center', 'align-items-center'].join(' ')}>
                                    <IncludeOldIssuesForm onSubmit={toggledExlude} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {magazinesData.length > 0 && <PaginationView {...props} data={magazines} />}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const mapStateToProps = (state, ownProps) => ({
    categories: state.categories,
    magazines: state.magazines,
    form: state.form,
    user: state.user
})

export default connect((mapStateToProps), {
    clearMagazines,
    loadCategoryMagazines
})(CategoryView)
