import update from 'immutability-helper'

// Load orbits
import {
    loadOrbitsState,
    loadOrbits,
    LOADED_ORBITS,
    handleLoadedOrbits
} from './LoadOrbits'

// Update orbit
import {
    updateOrbit,
    UPDATED_ORBIT,
    handleUpdatedOrbit
} from './UpdateOrbit'

// Remove orbit
import {
    removeOrbit,
    REMOVED_ORBIT,
    handleRemovedOrbit
} from './RemoveOrbit'

// Load orbit settings
import {
    loadOrbitSettingsState,
    loadOrbitSettings,
    LOADED_ORBIT_SETTINGS,
    handleLoadedOrbitSettings
} from './LoadOrbitSettings'

// Change active state
import {
    activateOrbit, deactivateOrbit
} from './ActivateImage'

export { loadOrbits, updateOrbit, removeOrbit, activateOrbit, deactivateOrbit }
export { loadOrbitSettings }

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATING_ORBITS = 'UPDATING_ORBITS'
export const CLEAR_ALL_ORBITS = 'CLEAR_ALL_ORBITS'

const INCREMENT = 1;
const DECREMENT = 2;

export const ACTIVE = 1
export const INACTIVE = 0

// ------------------------------------
// Actions
// ------------------------------------
export function pushUpdating () {
    return {
        type: UPDATING_ORBITS,
        payload: INCREMENT
    }
}

export function popUpdating () {
    return {
        type: UPDATING_ORBITS,
        payload: DECREMENT
    }
}

/**
 * Public function
 * Tell the store to clear out all the orbits
 */
export function clearOrbits () {
    return {
        type: CLEAR_ALL_ORBITS
    }
}


// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [UPDATING_ORBITS]: (state, action) => {
        if (INCREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating + 1}})
        } else if (DECREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating - 1}})
        } else {
            return state
        }
    },

    [LOADED_ORBITS]: (state, action) => handleLoadedOrbits(state, action),

    [UPDATED_ORBIT]: (state, action) => handleUpdatedOrbit(state, action),

    [REMOVED_ORBIT]: (state, action) => handleRemovedOrbit(state, action),

    [LOADED_ORBIT_SETTINGS]: (state, action) => handleLoadedOrbitSettings(state, action),

    [CLEAR_ALL_ORBITS]: (state, action) => (
        update(state, {$set: initialState})
    )
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    isUpdating: 0,
    images: loadOrbitsState,
    settings: loadOrbitSettingsState
}

export default function orbitsReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
