import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import CartTable from '../../components/CartTable';
import CartTotalsTable from '../../components/CartTotalsTable';
import { removeIssue, setQuantity } from '../../reducers/modules/carts';
import { loadCarts } from '../../reducers/modules/user';
import { getCartIssues } from '../../cartHelper';
import renderLoading from '../../renderLoading';
// import * as classes from './CartView.module.scss';

const renderMessage = () => {
    return (<h4>Din varukorg är tom</h4>)
}

const renderLoadingPage = () => (
    <Container>
        <Row>
            <Col>
            <h1>Varukorg</h1>
            </Col>
        </Row>
        <Row>
            <Col>
                {renderLoading()}
            </Col>
        </Row>
    </Container>
)

function CartView(props) {

    const { user, postages, settings, loadCarts, removeIssue, setQuantity } = props;
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const { data } = user;
        if (!loaded && data) {
            const { id: userId } = data;
            setLoaded(true);
            loadCarts(userId);
        }
    }, [loaded, user, loadCarts]);

    const handleRemoveIssue = (issueId, cartId) => {
        removeIssue(issueId, cartId);
    }

    const handleUpdateQuantity = (issueId, cartId, quantity) => {
        setQuantity(issueId, cartId, quantity);
    }

    if (!user) {
        return renderLoadingPage();
    }

    const { address, permissions } = user;

    if (!user.carts || !permissions || !address ) {
        return renderLoadingPage();
    }

    const cart = user.carts[0];
    const cartData = cart.data;
    const issues = getCartIssues(cartData)

    const displayTable = (cartData && issues.length > 0 && address && settings.data && postages.domestic.length > 0 && postages.international.length > 0)

    const isStore = permissions.includes('store')

    return (
        <Container>
            <Row>
                <Col>
                <h1>Varukorg</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                {!displayTable && renderMessage()}
                {displayTable && <CartTable 
                    editable={true}
                    deleteable={true}
                    cartData={cartData}
                    onRemoveIssue={handleRemoveIssue}
                    onUpdateQuantity={handleUpdateQuantity} />}
                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 4, offset: 8 }}>
                    <h4>Totalt</h4>
                    {displayTable && <CartTotalsTable displayVat={true} cartData={cartData} address={address} postages={postages} settings={settings} isStore={isStore} />} 
                    <Row>
                        <Col>
                            <Link to={'/'}>
                                <Button variant='dark' block>Fortsätt handla</Button>
                            </Link>
                        </Col>
                        <Col>
                            {(displayTable) && <Link to={'/account/checkout'}>
                                <Button variant='dark' block>Till kassan</Button>
                            </Link>}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

/*
export class asd extends React.Component {

    componentWillMount () {
        if (this.props.user.data) {
            const { id: userId } = this.props.user.data
            this.props.loadCarts(userId)
        }
    }

    componentWillReceiveProps (nextProps) {
        if (!this.props.user.data && nextProps.user.data) {
            const { id: userId } = nextProps.user.data
            this.props.loadCarts(userId)
        }
    }

    render () {
        try {
            
            return (
            )
        } catch (err) {
            return renderLoading()
        }
    }
}
*/

export default connect((state, ownProps) => ({
    user: state.user,
    postages: state.postages,
    settings: state.settings
}), {
    loadCarts,
    removeIssue,
    setQuantity,
})(CartView);
