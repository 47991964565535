import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { userColumns } from '.';
// import * as classes from './UserRow.module.scss'

const ACTIVE = 1;

const renderRow = (row, columns, activateFunc) => {
    return (<tr>
        {(columns.indexOf(userColumns[0]) !== -1) && (<td>{row.first_name}</td>)}
        {(columns.indexOf(userColumns[1]) !== -1) && (<td>{row.last_name}</td>)}
        {(columns.indexOf(userColumns[2]) !== -1) && (<td>{row.email}</td>)}
        {(columns.indexOf(userColumns[3]) !== -1) && (<td align='center'><Link to={`/admin/users/${row.id}`}><FontAwesomeIcon icon='user' color='black' /></Link></td>)}
        {(columns.indexOf(userColumns[4]) !== -1) && (<td align='center'><FontAwesomeIcon icon={parseInt(row.activated) === parseInt(ACTIVE) ? 'unlock' : 'lock'} onClick={() => activateFunc(row.id, parseInt(row.activated) !== ACTIVE)} /></td>)}
    </tr>)
}

function UserRow({ row, skip = [], activate }) {
    const columns = [...userColumns].filter((col, index) => !skip.includes(col) && !skip.includes(index));

    return (
        <>
            {renderRow(row, columns, activate)}
        </>
    );
}

export default connect((state) => ({
}), {
})(UserRow);
