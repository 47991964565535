/* @flow */
import update from 'immutability-helper'

// Load friends
import {
  loadFriends,
  LOADING_FRIENDS, LOADED_FRIENDS,
  handleLoadingFriends, handleLoadedFriends
} from './LoadFriends'

// Create friend
import {
  createFriend,
  CREATED_FRIEND,
  handleCreatedFriend
} from './CreateFriend'

// Remove friend
import {
  deleteFriend,
  DELETED_FRIEND,
  handleDeletedFriend
} from './DeleteFriend'

// Update friend
import {
  updateFriend,
  UPDATED_FRIEND,
  handleUpdatedFriend
} from './UpdateFriend'

export { loadFriends, createFriend, deleteFriend, updateFriend }

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATING_FRIENDS = 'UPDATING_FRIENDS'
export const CLEAR_ALL_FRIENDS = 'CLEAR_ALL_FRIENDS'

const INCREMENT = 1;
const DECREMENT = 2;

// ------------------------------------
// Actions
// ------------------------------------
export function pushUpdating () {
  return {
    type: UPDATING_FRIENDS,
    payload: INCREMENT
  }
}

export function popUpdating () {
  return {
    type: UPDATING_FRIENDS,
    payload: DECREMENT
  }
}

/**
 * Public function
 * Tell the store to clear out all the friends
 */
export function clearFriends () {
  return {
    type: CLEAR_ALL_FRIENDS
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [UPDATING_FRIENDS]: (state, action) => {
    if (INCREMENT === action.payload) {
      return update(state, { isUpdating: {$set: state.isUpdating + 1}})
    } else if (DECREMENT === action.payload) {
      return update(state, { isUpdating: {$set: state.isUpdating - 1}})
    } else {
      return state
    }
  },

  [LOADED_FRIENDS]: (state, action) => handleLoadedFriends(state, action),

  [LOADING_FRIENDS]: (state, action) => handleLoadingFriends(state, action),

  [CREATED_FRIEND]: (state, action) => handleCreatedFriend(state, action),

  [DELETED_FRIEND]: (state, action) => handleDeletedFriend(state, action),

  [UPDATED_FRIEND]: (state, action) => handleUpdatedFriend(state, action),

  [CLEAR_ALL_FRIENDS]: (state, action) => (
    update(state, {$set: initialState})
  )
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isUpdating: 0,
  data: [],
  links: {},
  meta: {}
}

export default function friendsReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
