import update from 'immutability-helper'
import reqwest from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { popUpdating, pushUpdating } from './index'
import { pushToast } from '../toasts'

/**
 * Actions
 */
export const CREATED_ISSUE = 'CREATED_ISSUE'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function issueCreated (
    issue = {}
) {
    return {
        type: CREATED_ISSUE,
        payload: issue
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleCreatedIssue = (state, action) => update(state, {
    items: {$push: [action.payload]}
})


/**
 * Inititator
 */
export const createIssue = (
    data = {}
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            reqwest({
                url: `${PROTOCOL}://${URI}/issues`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data,
                error: (err) => {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success: (resp) => {
                    dispatch(issueCreated(resp.data))
                    dispatch(popUpdating())
                    dispatch(pushToast('Ett nytt nummer har skapats.'))
                    resolve()
                }
            })
        })
    }
}
