/* @flow */

// Framework
import React from 'react'
import { Col } from 'react-bootstrap'

export const renderFormText = ({
    label,
    type,
}) => (
    <Col>
        {type === 'p' && <p>{label}</p>}
        {type === 'h1' && <h1>{label}</h1>}
        {type === 'h2' && <h2>{label}</h2>}
        {type === 'h3' && <h3>{label}</h3>}
        {type === 'h4' && <h4>{label}</h4>}
        {type === 'h5' && <h5>{label}</h5>}
        {type === 'h6' && <h6>{label}</h6>}
    </Col>
)
