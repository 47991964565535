import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import store from 'store'
import { formatPrice } from '../../PriceHelper'
import { orderColumns } from '../OrdersTable'
import { getOrderDocument } from '../../orderHelper'
import { updateOrder } from '../../reducers/modules/orders'
import * as classes from './OrdersTable.module.scss'
// import ReactToPrint from 'react-to-print'
// import { PrintReceipt } from '../PrintReceipt/PrintReceipt'

// Constants
const UNSET_DATE = null;
const CHECKOUT_CARD = 0;
const CHECKOUT_INVOICE = 1;
const CHECKOUT_ADVANCE = 2;

const getProcessed = (order) => {
    const props = {
        icon: (order.processed_at !== UNSET_DATE ? 'check' : 'times'),
        color: (order.processed_at !== UNSET_DATE ? 'green' : 'red')
    };

    return (<FontAwesomeIcon {...props} />);
}

const getPaymentMethod = (order, handleTransactionId) => {
    const { checkoutmethod: paymentMethod, txnid: paid } = order;
    const color = paid ? 'green' : 'red';
    switch (paymentMethod) {
        case CHECKOUT_CARD:
            return (<><FontAwesomeIcon icon={['far', 'credit-card']} color={color} /></>);
        case CHECKOUT_INVOICE:
            return renderTooltipIcon("Ändra transaktions id", ['far', 'envelope'], handleTransactionId, order.id, color);
        case CHECKOUT_ADVANCE:
            return renderTooltipIcon("Ändra transaktions id", ['far', 'envelope'], handleTransactionId, order.id, color);
        default:
            return '';
    }
}

const getInvoiceCol = (order, setInvoiceNbr, setTxnid, editable = false) => {
    if (UNSET_DATE === order.processed_at) {
        return '';
    } else if (CHECKOUT_INVOICE === order.checkoutmethod) {
        if (editable) {
            return (<Form.Control disabled={order.deleted_at ? true : false} name='invoice_number' type='text' defaultValue={order.invoice_number} onChange={evt => setInvoiceNbr(evt.target.value)} />);
        } else {
            return order.invoice_number;
        }
    } else if (CHECKOUT_ADVANCE === order.checkoutmethod) {
        if (editable) {
            return (<Form.Control disabled={order.deleted_at ? true : false} name='txnid' type='text' defaultValue={order.txnid} onChange={evt => setTxnid(evt.target.value)} />);
        } else {
            return order.txnid;
        }
    } else {
        return `Kvitto ${order.id}`;
    }
}

const renderTooltipIcon = (text, icon, func, orderId, color) => (
    <OverlayTrigger
        placement='bottom'
        overlay={
            <Tooltip>{text}</Tooltip>
        }>
        <FontAwesomeIcon icon={icon} color={color} onClick={() => {
            if (func) func(orderId)
        }} />
    </OverlayTrigger>
)

const renderRow = (props, xTbToken, showOrderId, giveDocument, componentRef) => {
    const { row, path, columns } = props
    const { handleDelete, handleUndelete, handlePickOrder, handleDivideOrder, handleBuyback, handleTransactionId, handlePrint, setInvoiceNbr, setTxnid } = props

    let idColumn = undefined;

    if (giveDocument) {
        idColumn = getOrderDocument(row, xTbToken, showOrderId)

        if (typeof idColumn === 'undefined') {
            idColumn = row.id;
        }
    } else {
        idColumn = row.id
    }
    
    return (<tr>
        {(columns.indexOf(orderColumns[0]) !== -1) && (<td className={row.deleted_at && classes.deleted}>{idColumn}</td>)}
        {(columns.indexOf(orderColumns[1]) !== -1) && (<td align='center' className={row.deleted_at && classes.deleted}><Link to={`/${path}/users/${row.user_id}`}><FontAwesomeIcon icon='user' color='black' /></Link></td>)}
        {(columns.indexOf(orderColumns[2]) !== -1) && (<td align='right'>{getInvoiceCol(row, setInvoiceNbr, setTxnid, false)}</td>)}
        {(columns.indexOf(orderColumns[3]) !== -1) && (<td align='right' className={row.deleted_at && classes.deleted}>{formatPrice(row.amount)} kr.</td>)}
        {(columns.indexOf(orderColumns[4]) !== -1) && (<td align='right' className={row.deleted_at && classes.deleted}>{formatPrice(row.shipping)} kr.</td>)}
        {(columns.indexOf(orderColumns[5]) !== -1) && (<td align='right' className={row.deleted_at && classes.deleted}>{formatPrice(row.amount_total)} kr.</td>)}
        {(columns.indexOf(orderColumns[6]) !== -1) && (<td align='center'>{getPaymentMethod(row, handleTransactionId)}</td>)}
        {(columns.indexOf(orderColumns[7]) !== -1) && (<td align='center'>{getProcessed(row)}</td>)}
        {(columns.indexOf(orderColumns[8]) !== -1) && (<td align='center'><Link to={`/${path}/orders/${row.id}`}><FontAwesomeIcon icon='shopping-basket' color='black' /></Link></td>)}
        {(columns.indexOf(orderColumns[9]) !== -1) && (<td align='center'>{row.processed_at === UNSET_DATE && renderTooltipIcon('Hantera order', 'tasks', handlePickOrder, row.id)}</td>)}
        {(columns.indexOf(orderColumns[10]) !== -1) && (<td align='center'>{row.deleted_at ? renderTooltipIcon('Återta order', 'plus-circle', handleUndelete, row.id) : renderTooltipIcon('Makulera order', 'minus-circle', handleDelete, row.id)}</td>)}
        {(columns.indexOf(orderColumns[11]) !== -1) && (<td align='center'>{row.processed_at === UNSET_DATE && renderTooltipIcon('Dela upp order', 'divide', handleDivideOrder, row.id)}</td>)}
        {(columns.indexOf(orderColumns[12]) !== -1) && (<td align='center'>{row.processed_at !== UNSET_DATE && renderTooltipIcon('Återköp', 'undo', handleBuyback, row.id)}</td>)}
        {(columns.indexOf(orderColumns[13]) !== -1) && (<td align='center'>{row.processed_at !== UNSET_DATE && renderTooltipIcon('Skriv ut', 'print', handlePrint, row.id)}</td>)}
    </tr>)
}

// const renderPrintIcon = (componentRef) => (
//     <div>
//         <ReactToPrint
//             trigger={() => <FontAwesomeIcon icon={['fas', 'print']} color='black' />}
//             content={() => componentRef.current}
//         />
//         <PrintReceipt ref={componentRef} />
//     </div>
// )

function OrderRow({
    row,
    path,
    skip,
    updateOrder,
    showOrderId,
    giveDocument,
    handleDelete,
    handleUndelete,
    handlePickOrder,
    handleDivideOrder,
    handleBuyback,
    handleTransactionId,
    handlePrint,
}) {

    const componentRef = useRef();

    const [invoiceNbr, setInvoiceNbr] = useState(undefined);
    const [txnId, setTxnId] = useState(undefined);

    useEffect(() => {
        if (invoiceNbr) {
            const { id: orderId } = row;
            updateOrder(orderId, {invoice_number: invoiceNbr});
        }
    }, [invoiceNbr, updateOrder, row]);


    useEffect(() => {
        if (txnId) {
            const { id: orderId } = row;
            updateOrder(orderId, {txnid: txnId});
        }
    }, [txnId, updateOrder, row]);

    const columns = [...orderColumns].filter((col, index) => !skip.includes(col) && !skip.includes(index));
    
    const xTbToken = store.get('tbwt');

    return (
        <>
            {renderRow({row, path, columns, handleDelete, handleUndelete, handlePickOrder, handleDivideOrder, handleBuyback, handleTransactionId, handlePrint, setInvoiceNbr, setTxnId}, xTbToken, showOrderId, giveDocument, componentRef)}
        </>
    );
}

export default connect((state) => ({
}), {
    updateOrder
})(OrderRow);
