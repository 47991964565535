import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import CategoryRow from './CategoryRow';

export const categoryColumns = [
    'id',
    'Namn',
    '2',
    '3'
];

function CategoryTable({ categories, handleLock, handleDelete, skip = [] }) {

    const filtered = [...categoryColumns].filter((col, index) => !skip.includes(col) && !skip.includes(index))

    return (
        <>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        {filtered.map((col, index) => (<th key={index}>{isNaN(col) ? col : ''}</th>))}
                    </tr>
                </thead>
                <tbody>
                    {categories.map((row) => (
                        <CategoryRow
                            key={row.id} 
                            row={row}
                            skip={skip}
                            handleLock={handleLock}
                            handleDelete={handleDelete} />
                    ))}
                </tbody>
            </Table>
        </>
    );
}

export default connect((state, ownProps) => ({
}), {
})(CategoryTable)
