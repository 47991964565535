/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// App componetns
import { magazineColumns } from './MagazinesTable'

// State
// ...

// Styles
// ...

// Constants
// ...

class MagazineRow extends React.Component {
    static propTypes = {
        row: PropTypes.object.isRequired,
        skip: PropTypes.array,
        handleEdit: PropTypes.func.isRequired,
        handleDelete: PropTypes.func.isRequired
    }

    constructor (props) {
        super(props)

        this.titleRef = React.createRef()
    }

    render () {
        const { row, handleEdit, handleDelete, skip = [] } = this.props
        const columns = [...magazineColumns].filter((col, index) => !skip.includes(col) && !skip.includes(index))
        
        return (<tr>
            {(columns.indexOf(magazineColumns[0]) !== -1) && (<td>{row.id}</td>)}
            {(columns.indexOf(magazineColumns[1]) !== -1) && (<td><Form.Control defaultValue={row.title} ref={this.titleRef} /></td>)}
            {(columns.indexOf(magazineColumns[2]) !== -1) && (<td align='center'><FontAwesomeIcon icon='edit' onClick={() => handleEdit(row.id)} /></td>)}
            {(columns.indexOf(magazineColumns[3]) !== -1) && (<td align='center'><FontAwesomeIcon icon='times' onClick={() => handleDelete(row.id)} /></td>)}
        </tr>)
    }
}

const mapStateToProps = (state) => ({
})
export default connect((mapStateToProps), {
})(MagazineRow)
