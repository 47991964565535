/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { unserialize } from 'php-serialize'

// App components
import { formatPrice } from '../../PriceHelper'

// State
import { loadCarts } from '../../reducers/modules/user'
import { loadAllPostages } from '../../reducers/modules/postages'

// Styles
import * as classes from './CartHandle.module.scss'

// Constants
// ...

const urldecode = (str) => {
  return decodeURIComponent((str + '').replace(/\+/g, '%20'));
}

export class CartHandle extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,

    loadCarts: PropTypes.func.isRequired,
    loadAllPostages: PropTypes.func.isRequired
  }

  componentDidMount () {
    this.props.loadAllPostages()
  }

  render () {
    try {
      const cart = this.props.user.carts[0]
      const decoded = urldecode(cart.data)
      const unserialized = unserialize(decoded)

      var issues = Object.keys(unserialized).map((key) => unserialized[key]);

      const items = issues.reduce((t, {quantity}) => {
        return parseInt(t) + parseInt(quantity)
      }, 0)

      const total = issues.reduce((t, {quantity, price}) => {
        return parseInt(t) + (parseInt(quantity) * parseInt(price))
      }, 0)

      return (
        <div className={classes.container}>
          <Link to={'/account/cart'}>
            <FontAwesomeIcon icon='shopping-basket'className={classes.basket} />
            <span className={classes.bold}>{items}</span> varor <span className={classes.bold}>{formatPrice(total)} kr.</span>
          </Link>
        </div>
      )
    } catch (error) {
      return (
        <div className={classes.container}>
          <FontAwesomeIcon icon='shopping-basket'className={classes.basket} />
          <span className={classes.bold}>0</span> varor <span className={classes.bold}>0 kr.</span>
        </div>
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user,
  postages: state.postages
})
export default connect((mapStateToProps), {
  loadCarts,
  loadAllPostages
})(CartHandle)
