import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { countriesIsUpdating } from './index'

/**
 * Actions
 */
export const LOADED_COUNTRIES = 'LOADED_COUNTRIES'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function loadedCountries (
    countries = []
) {
    return {
        type: LOADED_COUNTRIES,
        payload: countries
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadedCountries = (state, action) => update(state, {
    isUpdating: {$set: false},
    items: {$set: action.payload}
})


/**
 * Inititator
 */
export const loadCountries = () => {
    return (dispatch, getState) => {
        dispatch(countriesIsUpdating(true))
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/countries`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(countriesIsUpdating(false))
                    resolve()
                },
                success (resp) {
                    dispatch(loadedCountries(resp.data))
                    resolve()
                }
            })
        })
    }
}
