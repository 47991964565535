import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Image, ListGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { loadPages, ACTIVE } from '../../reducers/modules/pages'
import { loadFriends } from '../../reducers/modules/friends'
import { loadSettings } from '../../reducers/modules/settings'
import * as classes from './FooterView.module.scss'
import EPAY from './payoff-blue.png'
import VISA from './visa.png'
import ELECTRON from './electron.png'
import MASTER from './mastercard.png'
import { randomInt } from '../../random'

const renderPlaceHolderColumn = (title, length) => (
    <>
        <h5 className={classes.header}>{title}</h5>
        <ListGroup as="ul" className={classes.list}>
            {[...Array(length).keys()].map((index) => (
                <ListGroup.Item key={index} as="li">
                <Link to={`/`} className={classes.placeholder} style={{width: `${randomInt(60, 70)}%`}}>&nbsp;</Link>
                </ListGroup.Item>
            ))}
        </ListGroup>
    </>
)

const renderPagesColumn = (pages) => (
    <>
        <h5 className={classes.header}>Tidskriftsbutiken.nu</h5>
        <ListGroup as="ul" className={classes.list}>
            {pages.map((page) => (
                <ListGroup.Item key={page.id} as="li">
                    <Link to={`/pages/${page.id}`}>{page.name}</Link>
                </ListGroup.Item>
            ))}
        </ListGroup>
    </>
);

const renderFriendsColumn = (friends) => (
    <>
        <h5 className={classes.header}>Våra vänner</h5>
        <ListGroup as="ul" className={classes.list}>
            {friends.map((friend) => (
                <ListGroup.Item key={friend.id} as="li">
                    <a href={friend.url} target="_blank" rel="noopener noreferrer">{friend.name}</a>
                </ListGroup.Item>
            ))}
        </ListGroup>
    </>
);

const renderSocialColumn = (settings) => (
    <>
        <h5 className={classes.header}>Följ oss på:</h5>
        {settings.twitter && <a href={settings.twitter} className={classes.social}>
            <FontAwesomeIcon icon={['fab', 'twitter']} />Twitter
        </a>}
        {settings.facebook && <a href={settings.facebook} className={classes.social}>
            <FontAwesomeIcon icon={['fab', 'facebook']} />Facebook
        </a>}
        {settings.googleplus && <a href={settings.googleplus} className={classes.social}>
            <FontAwesomeIcon icon={['fab', 'google']} />Google Plus
        </a>}
        {settings.linkedin && <a href={settings.linkedin} className={classes.social}>
            <FontAwesomeIcon icon={['fab', 'linkedin']} />Linkedin
        </a>}
    </>
);

const renderLinksColumn = (settings) => (
    <>
        <h5 className={classes.header}>Du hittar oss på:</h5>
        <p>{settings.street}</p>
        <p>{settings.zip} {settings.city}</p>
        <p style={{marginBottom: '15px'}}>{settings.phone}</p>
        <Image src={EPAY} thumbnail className={classes.epay}/>
        <h5 className={classes.header}>Vi accepterar kortbetalning</h5>
        <Image src={VISA} thumbnail className={classes.creditcard} />
        <Image src={ELECTRON} thumbnail className={classes.creditcard} />
        <Image src={MASTER} thumbnail className={classes.creditcard} />
    </>
)

function FooterView({ pages, friends, settings, loadPages, loadFriends, loadSettings }) {

    useEffect(() => {
        loadPages();
        loadFriends();
        loadSettings();
    }, [loadPages, loadFriends, loadSettings]);

    const { data: pagesData } = pages;
    const { data: friendsData } = friends;
    const { data: settingsData } = settings;

    if (!pagesData || !friendsData || !settingsData) {
        return (
            <div className={classes.container}>
                <Container>
                    <Row>
                        <Col xs={3}>
                            {renderPlaceHolderColumn("Tidskriftsbutiken.nu", 2)}
                        </Col>
                        <Col xs={3}>
                            {renderPlaceHolderColumn("Våra vänner", 7)}
                        </Col>
                        <Col xs={3}>
                            {renderPlaceHolderColumn("Följ oss på:", 2)}
                        </Col>
                        <Col xs={3} className={classes.address}>
                            {renderPlaceHolderColumn("Du hittar oss på:", 3)}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    const filteredPages = [...pagesData].filter(p => parseInt(p.active) === ACTIVE)

    return (
        <div className={classes.container}>
            <Container>
                <Row>
                    <Col xs={3}>
                        {renderPagesColumn(filteredPages)}
                    </Col>
                    <Col xs={3}>
                        {renderFriendsColumn(friendsData)}
                    </Col>
                    <Col xs={3}>
                        {renderSocialColumn(settingsData)}
                    </Col>
                    <Col xs={3} className={classes.address}>
                        {renderLinksColumn(settingsData)}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default connect((state) => ({
    pages: state.pages,
    friends: state.friends,
    settings: state.settings
}), {
    loadPages,
    loadFriends,
    loadSettings
})(FooterView);
