/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// App componetns
import { postageColumns } from './PostagesTable'

// State
// ...

// Styles
// import * as classes from './PostageRow.module.scss'

// Constants
// ...

class PostageRow extends React.Component {
    static propTypes = {
        row: PropTypes.object.isRequired,
        skip: PropTypes.array,
        handleDelete: PropTypes.func.isRequired
    }

    constructor (props) {
        super(props)

        this.priceRef = React.createRef()
        this.lboundRef = React.createRef()
    }

    render () {
        const { row, handleDelete, skip = [] } = this.props
        const columns = [...postageColumns].filter((col, index) => !skip.includes(col) && !skip.includes(index))

        return (<tr>
            {(columns.indexOf(postageColumns[0]) !== -1) && (<td>{row.id}</td>)}
            {(columns.indexOf(postageColumns[1]) !== -1) && (<td><Form.Control defaultValue={row.price} ref={this.priceRef} /></td>)}
            {(columns.indexOf(postageColumns[2]) !== -1) && (<td><Form.Control defaultValue={row.lbound} ref={this.lboundRef} /></td>)}
            {(columns.indexOf(postageColumns[3]) !== -1) && (<td align='center'><FontAwesomeIcon icon='times' onClick={() => handleDelete(row.id)} /></td>)}
        </tr>)
    }
}

const mapStateToProps = (state) => ({
})
export default connect((mapStateToProps), {
})(PostageRow)
