import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Carousel, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { loadOrbits } from '../../reducers/modules/orbits'
import { PROTOCOL, URI_UPLOAD } from '../../reducers/modules/api'
import LOADING from './loading.jpeg'
import Img from 'react-image'
import * as classes from './OrbitView.module.scss'


const loading = () => (
    <Img
        src={LOADING}
        className={[classes.image, classes.grayscale].join(' ')}
    />
)

function OrbitView({ orbits, loadOrbits }) {

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const { images } = orbits;
        if (images.data.length === 0 && !loaded) {
            setLoaded(true);
            loadOrbits();
        }
    }, [orbits, loaded, loadOrbits]);

    const { images: { data } } = orbits;

    if (data.length === 0) {
        return (
            <Carousel className={classes.widthfix}>
                {[...Array(1).keys()].map((orbit) => (
                    <Carousel.Item key={`orbit-${orbit}`} as={Link} to={`#`} className={classes.widthfix}>
                        <Image
                            src={LOADING}
                            className={classes.widthfix}
                            thumbnail
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
        );
    }

    return (
        <Carousel className={classes.widthfix}>
            {data.map(orbit => (
                <Carousel.Item key={orbit.id} as={Link} to={orbit.url} className={classes.widthfix}>
                    <Image
                        src={`${PROTOCOL}://${URI_UPLOAD}/banners/${orbit.name}`}
                        className={classes.widthfix}
                        loader={loading()}
                        thumbnail
                    />
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default connect((state, ownProps) => ({
    orbits: state.orbits
}), {
    loadOrbits
})(OrbitView)
