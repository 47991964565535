/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'

// App components
// ...

// State
import { closeNotifyInterestedUsers } from '../../reducers/modules/modals'

// Styles
// import * as classes from './NotifyUsersModal.module.scss'

// Constants
// ...

export class NotifyUsersModal extends React.Component {
    static propTypes = {
        modal: PropTypes.object.isRequired,
        shortlists: PropTypes.object.isRequired,
        
        closeNotifyInterestedUsers: PropTypes.func.isRequired
    }

    constructor (props) {
        super(props)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.formValidated = this.formValidated.bind(this)
    }

    handleCancel () {
        this.props.closeNotifyInterestedUsers()
    }

    handleSubmit () {
        // this.refs.form.submit()
    }

    formValidated (form) {
        // this.props.login(form.email, form.password)
    }

    render () {
        const { isOpen } = this.props.modal

        if (!isOpen) {
            return <div />
        }

        return (
            <Modal size='lg' show={isOpen} onHide={this.handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Skicka meddelande till intresserade kunder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>TODO</p>
                <Button variant='dark' type='submit' onClick={() => {}}>Skicka</Button>
            </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    modal: state.modals.notifyInterestedUsers,
    shortlists: state.shortlists
})
export default connect((mapStateToProps), {
    closeNotifyInterestedUsers
})(NotifyUsersModal)
