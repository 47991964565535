/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container } from 'react-bootstrap'

// App components
import ToastMessage from './ToastMessage'

// State
// ...

// Styles
// import * as classes from './ToastsView.module.scss'

export class ToastsView extends React.Component {
  static propTypes = {
    toasts: PropTypes.object.isRequired,
  }

  render () {
    try {
      const { toasts } = this.props.toasts

      return (
        <Container>
          {toasts && toasts.map((toast) => <ToastMessage toast={toast} key={toast.uid} />)}
        </Container>
      )
    } catch (error) {
      return (
        <Container>
          <p>laddar...</p>
        </Container>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  toasts: state.toasts
})
export default connect((mapStateToProps), {
})(ToastsView)
