import { submit } from 'redux-form'

/**
 * This is a hack to remotely submit a bunch of forms from AccountCheckoutView
 */
export const dispatchRemoteSubmitHack = (
    form = undefined
) => {
    return (dispatch, getState) => {
        dispatch(submit(form))
    }
}
