import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Form } from 'react-bootstrap';
import { formatPrice } from '../../PriceHelper';
import { renderForm } from '../FormHelper';

const validate = values => {
    const errors = {}
    if (!values.items || parseInt(values.items) < 1) {
        errors.items = 'Du kan inte välja mindre än 1 kopia'
    }
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

function AddToCartForm({ issue, handleSubmit, submitting, pristine, initialize }) {

    const priceText = `pris: ${formatPrice(issue.outprice)} kr.`;

    const fields = [
        {
            name: 'items',
            prependText: priceText,
            placeholder: 'antal',
            fieldType: 'field',
            parse: (value) => Number(value),
            appendSubmit: {
                name: 'submit', variant: 'dark', label: 'Lägg i varukorg', fieldType: 'submit', submitting, pristine
            }
        },
    ];
    
    return (
        <>
            <Form onSubmit={handleSubmit} style={{maxWidth: '400px'}}>
                {renderForm(fields)}
            </Form>
        </>
    );
}

export default connect(
    (state, ownProps) => ({
        initialValues: {items: 1}
    })
)(
    reduxForm({
        form: 'addToCart',
        validate,
        warn,
        enableReinitialize : true
    })(AddToCartForm)
);
