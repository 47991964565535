import React from 'react';
import DraftEditor from './DraftEditor';

export default function LoadedTextEditor(props) {
    const { onChange, value } = props;

    return (
        <DraftEditor value={value} onChange={onChange} />
    );
}
