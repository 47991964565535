import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating} from './index'

/**
 * Actions
 */
export const REMOVED_ORBIT = 'REMOVED_ORBIT'


/**
 * Dispatcher
 * 
 * @param {*} index 
 */
export function removedOrbit (
    index = undefined
) {
    return {
        type: REMOVED_ORBIT,
        index: index
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleRemovedOrbit = (state, action) => update(state, {
    images: {
        data: {$splice: [[action.index, 1]]}
    }
})


/**
 * Inititator
 * 
 * @param {*} userId 
 */
export const removeOrbit = (
    orbitId = undefined
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/orbits/${orbitId}`,
                method: 'DELETE',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    const { data: orbits } = getState().orbits.images
                    const index = orbits.map(o => o.id).indexOf(parseInt(resp.data.id))
                    if (index > -1) {
                        dispatch(removedOrbit(index))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
