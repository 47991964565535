import update from 'immutability-helper'

export const TOGGLED_DELETE_CATEGORY = 'TOGGLED_DELETE_CATEGORY'

export const handleToggledDeleteCategory = (state, action) => update(state, {
    deleteCategory: {
        isOpen: {$set: action.state},
        categoryId: {$set: action.categoryId}
    }
})

export function toggledDeleteCategory (
    state = undefined,
    categoryId = undefined
) {
    return {
        type: TOGGLED_DELETE_CATEGORY,
        state,
        categoryId
    }
}

export const openDeleteCategory = (
    categoryId = undefined
) => {
    return (dispatch, getState) => {
        dispatch(toggledDeleteCategory(true, categoryId))
    }
}

export const closeDeleteCategory = () => {
    return (dispatch, getState) => {
        dispatch(toggledDeleteCategory(false, undefined))
    }
}

export const deleteCategory = { isOpen: false, categoryId: undefined }
