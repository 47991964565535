import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { cartsIsUpdating } from './index'

/**
 * Actions
 */
export const LOADED_CARTS = 'LOADED_CARTS'

/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export const loadedCarts = (
    carts = undefined
) => ({
    type: LOADED_CARTS,
    payload: carts
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadedCarts = (state, action) => update(state, {
    isUpdating: {$set: false},
    items: {$set: action.payload}
})


/**
 * Initiator
 */
export const loadCarts = () => {
    return (dispatch, getState) => {
        dispatch(cartsIsUpdating(true))
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/carts`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(cartsIsUpdating(false))
                    resolve()
                },
                success (resp) {
                    dispatch(loadedCarts(resp.data))
                    resolve()
                }
            })
        })
    }
}
