import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const LOADED_ISSUES = 'LOADED_ISSUES'

/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export const loadedIssues = (
    issues = []
) => ({
    type: LOADED_ISSUES,
    payload: issues
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadedIssues = (state, action) => update(state, {
    items: {$set: action.payload}
})


/**
 * Initiator
 */
export const loadIssues = () => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/issues`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(loadedIssues(resp.data))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
