/* @flow */

// Framework
import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap'

// App components
// import renderLoading from '../../renderLoading'

// State
// ...

// Styles
// import * as classes from './AccountPaymentView.module.scss'

// Constants
//...

export class AccountPaymentView extends React.Component {
  static propTypes = {
  }

  render () {
    return (
      <Container>
        <Row>
          <Col>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
})
export default connect((mapStateToProps), {
})(AccountPaymentView)
