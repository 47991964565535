/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap'
import queryString from 'query-string'

// App components
import CategoryList from '../../components/CategoryList/CategoryList'
import PasswordForm, { WITH_TOKEN } from '../../components/PasswordForm'

// State
import { resetPassword } from '../../reducers/modules/user'

// Styles
import * as classes from './ActivateView.module.scss'

// Constants
//...

export class ActivateView extends React.Component {
  static propTypes = {
    resetPassword: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)

    this.onPasswordValidated = this.onPasswordValidated.bind(this)
  }

  onPasswordValidated (form) {
    const { resettoken, password, password_confirmation } = form
    this.props.resetPassword(resettoken, password, password_confirmation)
  }

  render () {
    const { search } = this.props.location
    const { token: resettoken } = queryString.parse(search)

    const values = {
      resettoken
    }

    return (
      <Container>
        <Row>
          <Col xs={3}>
            <CategoryList />
          </Col>
          <Col xs={9}>
            <Row><h2 className={classes.header_newissues}>Återställ lösenord</h2></Row>
            <PasswordForm resetType={WITH_TOKEN} initialValues={values} onSubmit={this.onPasswordValidated} />
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
})
export default connect((mapStateToProps), {
  resetPassword
})(ActivateView)
