/* @flow */

// Framework
import React from 'react'
import { Button } from 'react-bootstrap'
import { wrapFormCol } from './wrapFormCol'

export const renderFormButton = ({
  input,
  variant,
  label,
  submitting,
  pristine,
  block = 'btn-block',
  group = true,
  callback = () => {},
  meta: { touched, error, warning }
}) => {
  if (group) {
    return wrapFormCol(<Button {...input} variant={variant} disabled={submitting|pristine} onClick={callback} className={block}>
      {label}
    </Button>)
  }
  return (<Button {...input} variant={variant} disabled={submitting|pristine} onClick={callback} className={block}>
    {label}
  </Button>)
}
