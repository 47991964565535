/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { formatPrice } from '../../PriceHelper'

// App componetns
// ...

// State
// ...

// Styles
//import * as classes from './AccountingRow.module.scss'

// Constants
// ...

class AccountingRow extends React.Component {
  static propTypes = {
    row: PropTypes.object.isRequired
  }

  render () {
    try {
      const { row } = this.props
      
      return (
        <tr>
          <td>{row.name}</td>
          <td>{row.count_orders}</td>
          <td>{row.count_units}</td>
          <td>{formatPrice(row.value)} kr.</td>
          <td>{formatPrice(row.shipping)} kr.</td>
          <td>{formatPrice(row.vat)} kr.</td>
        </tr>
      )
    } catch (error) {
      return (
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      )
    }
  }
}

const mapStateToProps = (state) => ({
})
export default connect((mapStateToProps), {
})(AccountingRow)
