import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'react-bootstrap';
import yabbcode from 'ya-bbcode';
import renderLoading from '../../renderLoading';
import { loadPage } from '../../reducers/modules/pages';
// import * as classes from './PageView.module.scss';

const parser = new yabbcode();
parser.registerTag('*', {
	type: 'replace',
	open: (attr) => {
		return `<li>`;
	},
	close: '</li>'
}).registerTag('br', {
	type: 'replace',
	open: (attr) => {
		return `<br>`;
	},
	close: null
});

function PageView({ pages, match, loadPage }) {

    const { pageId } = match.params;
    const { data } = pages || [];
    const pageIndex = data.map(p => p.id).indexOf(parseInt(pageId));

    useEffect(() => {
        const { isUpdating } = pages;

        if (pageIndex === -1 && !isUpdating) {
            loadPage(pageId)
        }
    }, [pageIndex, pageId, loadPage, pages]);

    if (pageIndex === -1) {
        return (
            <>
                <Container>
                    {renderLoading()}
                </Container>
            </>
        );
    }
    
    const page = pageIndex > -1 ? data[pageIndex] : undefined;

    const html = page && {__html: (page.text ? parser.parse(page.text.replace(/\[br\]/g, '<br/>')) : '')};

    return (
        <>
            <Container>
                <Row>
                    <h1>{page.name}</h1>
                    {html && <p dangerouslySetInnerHTML={html} />}
                </Row>
            </Container>
        </>
    );
}

export default connect(((state, ownProps) => ({
    pages: state.pages
})), {
    loadPage
})(PageView);
