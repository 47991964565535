import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { exportsIsUpdating } from './index'

/**
 * Actions
 */
export const CREATED_EXPORT = 'CREATED_EXPORT'

/**
 * Dispatcher
 */
export const createdExport = (
    excel = {}
) => ({
    type: CREATED_EXPORT,
    payload: excel
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleCreatedExport = (state, action) => update(state, {
    isUpdating: {$set: false},
    url: {$set: action.payload}
})


/**
 * Initiator
 */
export const createExport = (
    start_year = 0,
    start_month = 0,
    start_day = 0,

    stop_year = 0,
    stop_month = 0,
    stop_day = 0,

    replace_all = true,
    magazine_ids = [],
    overwrite = true
) => {
    return (dispatch, getState) => {
        dispatch(exportsIsUpdating(true))
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/export`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: {
                    start_year, start_month, start_day,
                    stop_year, stop_month, stop_day,
                    replace_all, overwrite, magazine_ids
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(exportsIsUpdating(false))
                    resolve()
                },
                success (resp) {
                    dispatch(createdExport(resp.data))
                    resolve()
                }
            })
        })
    }
}
