import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import AccountPages from '../../components/AccountPages';
import renderLoading from '../../renderLoading';
import CartTable from '../../components/CartTable';
import CartTotalsTable from '../../components/CartTotalsTable';
import { loadOrders } from '../../reducers/modules/user';
// import * as classes from './AccountOrderView.module.scss';

function AccountOrderView(props) {

    const { user, postages, settings, loadOrders, match } = props;
    const { orderId } = match.params;
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const { data } = user;

        if (data && !loaded) {
            const { id: userId } = data;
            setLoaded(true);
            loadOrders(userId);
        }
    }, [user, loadOrders, loaded]);

    if (!user || !user.orders) {
        return (
            <Container>
                <Row>
                    <Col xs={3}>
                        <AccountPages />
                    </Col>
                    <Col xs={9}>
                        <h4>Order</h4>
                        {renderLoading()}
                    </Col>
                </Row>
            </Container>
        );
    }
    
    const { address, permissions } = user;
    const { data } = user.orders;
    const index = data.map(o => o.id).indexOf(parseInt(orderId));
    const cartData = data[index].cart;

    const isStore = permissions.includes('store');

    return (
        <Container>
            <Row>
                <Col xs={3}>
                    <AccountPages />
                </Col>
                <Col xs={9}>
                    <h4>Order {orderId}</h4>
                    
                    {(index === -1) && renderLoading()}
                    
                    {(index !== -1) && <>
                        <Row>
                            <Col>
                                <CartTable editable={false} deleteable={false} cartData={cartData} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 4, offset: 8 }}>
                                <h4>Totalt</h4>
                                {!(cartData && address) && renderLoading()}
                                {(cartData && address) && <CartTotalsTable displayVat={true} cartData={cartData} address={address} postages={postages} settings={settings} isStore={isStore} />}
                            </Col>
                        </Row>
                    </>}

                </Col>
            </Row>
        </Container>
    );
}
   
export default connect(((state, ownProps) => ({
    user: state.user,
    postages: state.postages,
    settings: state.settings
})), {
    loadOrders
})(AccountOrderView);
