import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const DELETED_FRIEND = 'DELETED_FRIEND'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function deletedFriend (
    index = -1
) {
    return {
        type: DELETED_FRIEND,
        index: index
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleDeletedFriend = (state, action) => update(state, {
    data: {$splice: [[action.index, 1]]}
})


/**
 * Inititator
 */
export const deleteFriend = (
    friendId = 0
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/friends/${friendId}`,
                method: 'DELETE',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    const friendId = resp.data.id
                    const { data: friends } = getState().friends
                    const index = friends.map(f => f.id).indexOf(parseInt(friendId))
                    if (index > -1) {
                        dispatch(deletedFriend(index))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
