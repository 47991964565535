import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Badge } from 'react-bootstrap';
import { closeConfirmDeleteIssue } from '../../reducers/modules/modals';
import { deleteIssue } from '../../reducers/modules/issues';
// import { deleteIssue } from '../../reducers/modules/issues';
// import * as classes from './ConfirmDeleteIssueModal.module.scss';

function ConfirmDeleteIssueModal(props) {

    const { modal, deleteIssue, closeConfirmDeleteIssue } = props;

    const handleCancel = () => {
        closeConfirmDeleteIssue()
    }

    const handleDeleteIssue = () => {
        const { issueId } = modal;
        deleteIssue(issueId);
        closeConfirmDeleteIssue();
    }

    const { isOpen, issueId } = modal;

    if (!isOpen) {
        return (<div />);
    }

    return (
        <Modal size='lg' show={isOpen} onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Ta bort utgåva nr. {issueId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Bekräfta att du vill ta bort utgåva {issueId}</p>
                <p><Badge variant="danger">OBS, detta går inte att återta, utgåvan försvinner för gott.</Badge></p>
                
                <Button variant='danger' type='submit' onClick={handleDeleteIssue}>Bekräfta ta bort utgåva {issueId}</Button>
            </Modal.Body>
        </Modal>
    );
}

export default connect((state) => ({
    modal: state.modals.confirmDeleteIssue
}), {
    closeConfirmDeleteIssue,
    deleteIssue
})(ConfirmDeleteIssueModal);
