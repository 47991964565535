import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import queryString from 'query-string';
import store from 'store';
import ReceiptTable from '../../components/ReceiptTable';
import CartTable from '../../components/CartTable';
import renderLoading from '../../renderLoading';
import { CARD_PAYMENT, INVOICE_PAYMENT, PRE_PAYMENT } from '../../components/ReceiptTable';
import { getOrderDocument, getStorePaymentInformation } from '../../orderHelper';
import { loadOrder } from '../../reducers/modules/user';
import { clearCheckoutState } from '../../reducers/modules/checkout';
import { isStore } from '../../PermissionsHelper';
// import * as classes from './AccountAcceptUrl.module.scss';

const getOrderTitle = (checkoutmethod) => {
    if (typeof checkoutmethod === 'undefined')
        return undefined

    switch (checkoutmethod) {
        case CARD_PAYMENT:
            return "Kvitto"
        case INVOICE_PAYMENT:
            return "Tack för din order!"
        case PRE_PAYMENT:
            return "Tack för din order!"
        default:
            return undefined
    }
}

const getOrderText = (checkoutmethod) => {
    if (typeof checkoutmethod === 'undefined')
        return undefined

    switch (checkoutmethod) {
        case CARD_PAYMENT:
            return "Tack för din order, den granskas och kommer att expedieras inom 0-4 arbetsdagar."
        case INVOICE_PAYMENT:
            return "Din beställning granskas och kommer att expedieras inom 0-4 arbetsdagar. Beställningen skickas tillsammans med en faktura."
        case PRE_PAYMENT:
            return "Din beställning granskas och kommer att expedieras inom 0-4 arbetsdagar efter att vi mottagit din betalning."
        default:
            return undefined
    }
}

function AccountAcceptUrl(props) {

    const { user, location, match, loadOrder } = props;

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (user.data && !loaded) {
            const { id: userId } = user.data
            const { search } = location

            // First try to parse from url-params
            const { orderid } = queryString.parse(search)

            setLoaded(true);

            if (!orderid) {
                // If it doesn't exist, parse it from the url
                const { orderId } = match.params
                loadOrder(userId, orderId)
            } else {
                loadOrder(userId, orderid)
            }
        }

        // dispatch(clearCheckoutState())
    }, [user, loadOrder, loaded, location, match]);

    const { data: orders } = user.orders

    const { match: { params: { orderId } } } = props

    const { search } = location
    const { txnid/*, amount, currency, date, time, txnfee, paymenttype, cardno, hash*/ } = queryString.parse(search)

    const index = orders.map(o => o.id).indexOf(parseInt(orderId))
    const order = orders[index] || {}
    
    const xTbToken = store.get('tbwt');

    const title = getOrderTitle(parseInt(order.checkoutmethod))

    const orderText = getOrderText(parseInt(order.checkoutmethod))

    let orderDocument = getOrderDocument(order, xTbToken);

    if (isStore(user)) {
        orderDocument = getStorePaymentInformation(order.id, xTbToken, true);
    }

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <Card body>
                            {(!order || !title) && renderLoading()}
                            {(order && title) && <h4>{title}</h4>}
                            {(order && orderText) && <p>{orderText}</p>}
                        </Card>
                        <Card body>
                            {(!order || !title) && renderLoading()}
                            {(order && title) && <ReceiptTable order={{txnid, ...order, ...queryString.parse(search)}} title={title} />}

                            {/* This should probably point to API URL instead. */}
                            {orderDocument && orderDocument}
                        </Card>
                        <Card body>
                            <h4>Innehåll</h4>

                            {!(order && order.cart) && renderLoading()}
                            {order && order.cart && <CartTable editable={false} deleteable={true} cartData={order.cart} />}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default connect((state, ownProps) => ({
    user: state.user
}), {
    loadOrder,
    clearCheckoutState
})(AccountAcceptUrl);
