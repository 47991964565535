import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form } from 'react-bootstrap';
import * as classes from './FilterOrdersState.module.scss';

const validate = values => {
  const errors = {}
  return errors
}

const warn = values => {
  const warnings = {}
  return warnings
}

const renderRadio = ({
  input,
  label,
  defaultChecked
}) => {
  if (defaultChecked) {
    return (<Form.Check
      {...input}
      inline
      label={label}
      value={input.value}
      type='radio'
      defaultChecked />)
  } else {
    return (<Form.Check
      {...input}
      inline
      label={label}
      value={input.value}
      type='radio' />)
  }
}

function FilterOrdersState(props) {

    const { handleChange, defaultValue } = props;

    const defaultId = parseInt(defaultValue.processed);

    return (
        <Form.Group controlId="state" className={classes.container}>
            <Field
                name='state'
                label='Ej hanterade'
                id='state0'
                component={renderRadio}
                defaultChecked={defaultId === 0}
                onChange={() => { handleChange(0) }} />

            <Field
                name='state'
                label='Hanterade'
                id='state1'
                component={renderRadio}
                defaultChecked={defaultId === 1}
                onChange={() => { handleChange(1) }} />

            <Field
                name='state'
                label='Alla'
                id='state2'
                component={renderRadio}
                defaultChecked={defaultId === 2}
                onChange={() => { handleChange(2) }} />
        </Form.Group>
    );
}

export default connect(
    (state, ownProps) => ({
        initialValues: ownProps.defaultValue
    })
)(
    reduxForm({
        form: 'FilterOrdersState',
        validate,
        warn,
        enableReinitialize : true
    })(FilterOrdersState)
);
