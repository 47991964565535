/* @flow */

// Framework
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { reduxForm, initialize } from 'redux-form'
import { Form } from 'react-bootstrap'

// App componetns
import { renderForm } from '../FormHelper'

// State
// ...

// Styles
// import * as classes from './CompanyForm.module.scss'

// Constants
// ...

const validate = values => {
  const errors = {}
  if (!values.street) {
    errors.street = 'Obligatoriskt'
  } else if (values.street.length < 1) {
    errors.street = 'Din gatuadress måste vara ifylld'
  }
  if (!values.zip) {
    errors.zip = 'Obligatoriskt'
  } else if (values.zip.length < 1) {
    errors.zip = 'Ditt postnummer måste vara ifyllt'
  }
  return errors
}

const warn = values => {
  const warnings = {}
  return warnings
}

class CompanyForm extends React.Component {
  static propTypes = {
    company: PropTypes.object.isRequired,

    handleSubmit: PropTypes.func.isRequired
  }

  componentDidMount () {
    this.props.initialize(this.props.company)
  }

  render () {
    const {
      handleSubmit, //submitting, pristine
    } = this.props
    
    const fields = [
      {name: 'name', label: 'Företagsnamn', icon: 'company', fieldType: 'field'},
      [
        {name: 'orgid', label: 'Organisationsnummer', icon: 'uscompanyer', fieldType: 'field'},
        {name: 'reference', label: 'Referens/kostnadsställe', icon: 'company', fieldType: 'field'}
      ],
      {name: 'submit', variant: 'dark', label: 'Spara', fieldType: 'submit'}
    ]

    return (
      <Form onSubmit={handleSubmit}>
        {renderForm(fields)}
      </Form>
    )
  }
}

CompanyForm = reduxForm({
  form: 'company',
  validate,
  warn,
  enableReinitialize : true
})(CompanyForm)

CompanyForm = connect(
  state => ({
    initialize
  })
)(CompanyForm)

export default CompanyForm
