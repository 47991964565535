import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminPages from '../../components/AdminPages';
import PageForm from '../../components/PageForm';
import renderLoading from '../../renderLoading';
import { loadPage, updatePage } from '../../reducers/modules/pages';
import { LOAD, NEW } from '../../components/FormHelper/renderFormEditor';

function AdminPageView(props) {

    const { pages, loadPage, updatePage, match } = props;

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            const { pageId } = match.params;
            const { data } = pages;
            const pageIndex = data.map(p => p.id).indexOf(parseInt(pageId));
    
            if (!loading && pageIndex === -1) {
                setLoading(true);
                loadPage(pageId)
            }
        }
    }, [loading, match, loadPage, pages]);
    

    const handleSavePage = (form) => {
        const { pageId } = match.params;
        const { name, text } = form;
        updatePage(pageId, {name, text});
    }

    const { pageId } = match.params;
    const { data, isUpdating } = pages;
    const pageIndex = data.map(p => p.id).indexOf(parseInt(pageId));
    const page = data[pageIndex];

    return (
        <Container>
            <Row>
                <Col xs={2}>
                    <AdminPages />
                </Col>
                <Col xs={10}>
                    <Card body>
                        <h4>Information</h4>
                        <p>För att ändra uppgifter om en existerande sida behöver du endast ändra texten i fälten; den kommer sedan automatiskt att uppdatera sig själv på webplatsen efter c:a 1 sekunds uppehåll.</p>
                        <p>För att lägga till en sida fyll i dess titel och innehåll i fälten längst ner.</p>
                        <p>För att låsa, eller låsa upp, en sida så behöver du endast klicka på hänglåset i tabellen. <FontAwesomeIcon icon='lock' /> betyder att sidan inte är synlig, och <FontAwesomeIcon icon='unlock' /> betyder att sidan är synlig. En synlig sida återfinner du längst ner på varje sida. För kunder återfinns den även längst upp i det svarta fältet.</p>
                        <p>För att ändra i en existerande sida klickar du på <FontAwesomeIcon icon='edit' />, en dialog dyker då upp där du har möjlighet att ändra innehållet samt namnet.</p>
                    </Card>

                    <Card body>
                        <h4>Ändra "{page && page.name}"</h4>
                        {isUpdating > 0 && renderLoading()}
                        {page && <PageForm page={page} editorType={LOAD} onSubmit={handleSavePage} />}
                        {!page && <PageForm editorType={NEW} onSubmit={handleSavePage} />}
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default connect((state) => ({
    pages: state.pages
}), {
    loadPage,
    updatePage
})(AdminPageView);
