/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { Form } from 'react-bootstrap'

// App componetns
import { renderForm } from '../FormHelper'

// State
// ...

// Styles
// import * as classes from './EditMagazineForm.module.scss'

// Constants
// ...

const validate = values => {
    const errors = {}
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

class EditMagazineForm extends React.Component {
    static propTypes = {
        magazine: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired
    }
  
    render () {
        const {
            handleSubmit
        } = this.props

        const showLabel = true

        const fields = [
        [
            {name: 'title', label: 'Namn', showLabel},
            {name: 'issn', label: 'ISSN', showLabel},
            {name: 'PG', label: 'PG', showLabel},
            {name: 'BG', label: 'BG', showLabel},
            {name: 'issues_anually', label: 'Utgåvor/år', showLabel},
            {name: 'dimensions', label: 'Dimensioner', showLabel}]
        ]

        return (
            <Form onSubmit={handleSubmit}>
                {renderForm(fields)}
            </Form>
        )
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
EditMagazineForm = reduxForm({
    form: 'editMagazine',
    validate,
    warn,
    enableReinitialize : true
})(EditMagazineForm)

// You have to connect() to any reducers that you wish to connect to yourself
EditMagazineForm = connect(
    (state, ownProps) => ({
        initialValues: ownProps.magazine
    })
)(EditMagazineForm)

export default EditMagazineForm
