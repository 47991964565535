/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Badge } from 'react-bootstrap'

// App components
import DeleteMagazineForm from '../DeleteMagazineForm'

// State
import { removeMagazine } from '../../reducers/modules/magazines'
import { closeDeleteMagazine } from '../../reducers/modules/modals'

// Styles
// import * as classes from './DeleteMagazineModal.module.scss'

// Constants
// ...

export class DeleteMagazineModal extends React.Component {
    static propTypes = {
        modal: PropTypes.object.isRequired,
        magazines: PropTypes.object.isRequired,
        
        closeDeleteMagazine: PropTypes.func.isRequired,
        removeMagazine: PropTypes.func.isRequired
    }

    constructor (props) {
        super(props)
        this.handleCancel = this.handleCancel.bind(this)
        this.formValidated = this.formValidated.bind(this)
    }

    handleCancel () {
        this.props.closeDeleteMagazine()
    }

    formValidated (form) {
        if (form.confirm === form.name) {
            this.props.removeMagazine(form.id)
            this.props.closeDeleteMagazine()
        } else {
            console.warn(`Incorrect magazine name, aborting.`)
            this.props.closeDeleteMagazine()
        }
    }

    render () {
        const { isOpen } = this.props.modal

        if (!isOpen) {
            return <div />
        }

        const { magazineId } = this.props.modal
        const { data: magazines } = this.props.magazines
        const magazine = magazines.find(m => parseInt(m.id) === parseInt(magazineId))

        return (
            <Modal size='lg' show={isOpen} onHide={this.handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Bekräfta borttagning av tidskrift "{magazine.name}"</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><Badge variant="danger">OBS</Badge> Om du tar bort en tidskrift försvinner även de utgåvor som är kopplade till den.</p>
                <DeleteMagazineForm onSubmit={this.formValidated} magazine={magazine} />
            </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    modal: state.modals.deleteMagazine,
    magazines: state.magazines
})
export default connect((mapStateToProps), {
    closeDeleteMagazine,
    removeMagazine
})(DeleteMagazineModal)
