import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const SUCCESS_LOGOUT = 'SUCCESS_LOGOUT'

/**
 * Dispatcher, success
 * 
 * @param {*} token 
 */
export const successLogout = () => ({
    type: SUCCESS_LOGOUT
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleSuccessLogout = (state, action) => update(state, {
    token: {$set: null},
    data: {$set: null},
    permissions: {$set: null}
})


/**
 * Initiator
 */
export const logout = () => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/auth/logout`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                },
                success (resp) {
                    dispatch(popUpdating())
                }
            })

            // Always clear the token, regardless of server response
            store.remove('tbwt')
            dispatch(successLogout())
            resolve()
        })
    }
}
    