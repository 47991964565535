/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { Form } from 'react-bootstrap'

// App componetns
import { renderForm } from '../FormHelper'

// State
// ...

// Styles
// import * as classes from './DeleteCategoryForm.module.scss'

// Constants
// ...

const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = 'Obligatoriskt'
    } else if (parseInt(values.name) < 1) {
        errors.name = 'Namnet måste vara minst ett tecken långt.'
    }
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

class DeleteCategoryForm extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        category: PropTypes.object.isRequired
    }

    render () {
        const {
            handleSubmit, category, submitting, pristine
        } = this.props

        const fields = [
            {fieldType: 'field', name: 'confirm', label: `Skriv in kategorins namn (${category.name})`, placeholder: 'Namn', showLabel: true},
            {name: 'delete-category-submit', variant: 'danger', label: 'Bekräfta borttagning', fieldType: 'submit', submitting, pristine, block: false}
        ]

        try {
            return (
                <Form onSubmit={handleSubmit}>
                    {renderForm(fields)}
                </Form>)
        } catch (err) {
            return (<p>error</p>)
        }
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
DeleteCategoryForm = reduxForm({
  form: 'deleteCategory',
  validate,
  warn
})(DeleteCategoryForm)

// You have to connect() to any reducers that you wish to connect to yourself
DeleteCategoryForm = connect(
  (state, ownProps) => ({
      initialValues: ownProps.category
  })
)(DeleteCategoryForm)

export default DeleteCategoryForm
