/* @flow */
import update from 'immutability-helper'

import {
    pushAlert,
    PUSHED_ALERT,
    handlePushedAlert
} from './Push'

import {
    popAlert,
    POPPED_ALERT,
    handlePoppedAlert
} from './Pop'

export { pushAlert, popAlert }

// ------------------------------------
// Constants
// ------------------------------------
export const ALERTS_UPDATING = 'ALERTS_UPDATING'

// ------------------------------------
// Actions
// ------------------------------------
export const alertsIsUpdating = (
    value = true
) => ({
    type: ALERTS_UPDATING,
    payload: value
})

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [ALERTS_UPDATING]: (state, action) => (
        update(state, {
            isUpdating: {$set: action.payload}
        })
    ),

    [PUSHED_ALERT]: (state, action) => handlePushedAlert(state, action),
    [POPPED_ALERT]: (state, action) => handlePoppedAlert(state, action),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    isUpdating: false,
    alerts: []
}

export default function alertsReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
