import moment from 'moment'

const development = false //(process.env.NODE_ENV !== 'production')

export const PROTOCOL = (development ? 'http' : 'https')
export const URI = (development ? '192.168.27.131/api/v1' : 'api.tidskriftsbutiken.se/api/v1')
export const LIMIT = (development ? '20' : '20')

export const URI_UPLOAD = (development ? '192.168.27.131/storage/uploads' : 'api.tidskriftsbutiken.se/storage/uploads')

export const config = {
    URI,
    LIMIT,
    URI_UPLOAD
}

export const minutesPassed = (minutes, lastLoad) => {
    if (!lastLoad) return true

    return moment().isAfter(moment(lastLoad).add(minutes, 'minutes'))
}
