import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, initialize } from 'redux-form';
import { Form } from 'react-bootstrap';
import { renderForm } from '../FormHelper';
// import * as classes from './AddressForm.module.scss';

const validate = values => {
    const errors = {}
    // if (!values.street) {
    //     errors.street = 'Obligatoriskt'
    // } else if (values.street.length < 1) {
    //     errors.street = 'Din gatuadress måste vara ifylld'
    // }
    // if (!values.zip) {
    //     errors.zip = 'Obligatoriskt'
    // } else if (values.zip.length < 1) {
    //     errors.zip = 'Ditt postnummer måste vara ifyllt'
    // }
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

function SocialNetworksForm(props) {

    const { handleSubmit } = props;

    const fields = [
        { name: 'twitter', label: 'Twitter', fieldType: 'field', prependIcon: ['fab', 'twitter'] },
        { name: 'facebook', label: 'Facebook', fieldType: 'field', prependIcon: ['fab', 'facebook'] },
        { name: 'googleplus', label: 'Google Plus', fieldType: 'field', prependIcon: ['fab', 'google'] },
        { name: 'linkedin', label: 'Linkedin', fieldType: 'field', prependIcon: ['fab', 'linkedin'] },
        { name: 'submit', variant: 'dark', label: 'Spara', fieldType: 'submit' }
    ];

    return (
        <>
            <Form onSubmit={handleSubmit}>
                {renderForm(fields)}
            </Form>
        </>
    );
}

export default connect(
    (state, ownProps) => ({
        initialize,
        initialValues: ownProps.settings
    })
)(
    reduxForm({
        form: 'social',
        validate,
        warn,
        enableReinitialize : true
    })(SocialNetworksForm)
);
