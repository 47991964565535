import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'
import { loadedAddress } from './LoadAddress'

/**
 * Initiator
 * 
 * @param {*} userId 
 * @param {*} addressId 
 * @param {*} street 
 * @param {*} zip 
 * @param {*} city 
 * @param {*} country_id 
 */
export const updateAddress = (
    userId = 0,
    addressId = 0,
    street,
    zip,
    city,
    country_id
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/users/${userId}/addresses/${addressId}`,
                method: 'PUT',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: {
                    street,
                    zip,
                    city,
                    country_id
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    if (resp.data) {
                    dispatch(loadedAddress(resp.data))
                    dispatch(popUpdating())
                    }
                    resolve()
                }
            })
        })
    }
}
    