import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import * as classes from './FilterOrdersMonth.module.scss';

const validate = values => {
  const errors = {}
  return errors
}

const warn = values => {
  const warnings = {}
  return warnings
}

function FilterOrdersMonth(props) {

    const { defaultValue, onSubmit } = props;

    const handleChange = (month) => {
        onSubmit({month})
    }

    return (
        <>
            <p><b>Visa ordrar för månad...</b></p>
            <ToggleButtonGroup type="radio" name="month" size="sm" onChange={handleChange} defaultValue={defaultValue.month.toString()} className={classes.container}>
                <ToggleButton variant='dark' value='1'>Jan</ToggleButton>
                <ToggleButton variant='dark' value='2'>Feb</ToggleButton>
                <ToggleButton variant='dark' value='3'>Mar</ToggleButton>
                <ToggleButton variant='dark' value='4'>Apr</ToggleButton>
                <ToggleButton variant='dark' value='5'>Maj</ToggleButton>
                <ToggleButton variant='dark' value='6'>Jun</ToggleButton>
                <ToggleButton variant='dark' value='7'>Jul</ToggleButton>
                <ToggleButton variant='dark' value='8'>Aug</ToggleButton>
                <ToggleButton variant='dark' value='9'>Sep</ToggleButton>
                <ToggleButton variant='dark' value='10'>Okt</ToggleButton>
                <ToggleButton variant='dark' value='11'>Nov</ToggleButton>
                <ToggleButton variant='dark' value='12'>Dec</ToggleButton>
            </ToggleButtonGroup>
        </>
    );
}

export default connect(
    (state, ownProps) => ({
        initialValues: ownProps.defaultValue
    })
)(
    reduxForm({
        form: 'FilterOrdersMonth',
        validate,
        warn,
        enableReinitialize : true
    })(FilterOrdersMonth)
);
