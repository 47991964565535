import { changedStage } from './ChangeStage' 

/**
 * Inititator
 */
export const nextStage = () => {
    return (dispatch, getState) => {
        const nextStage = getState().checkout.stage + 1
        dispatch(changedStage(nextStage))
    }
}
