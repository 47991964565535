import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import PageRow from './PageRow';

export const pageColumns = [
    'id',
    'Namn',
    '2',
    '3'
]

function PagesTable(props) {

    const { pages, skip = [], handleLock, handleDelete } = props;

    const filtered = [...pageColumns].filter((col, index) => !skip.includes(col) && !skip.includes(index));

    return (
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    {filtered.map((col, index) => (<th key={index}>{isNaN(col) ? col : ''}</th>))}
                </tr>
            </thead>
            <tbody>
                {pages.map((row) => (
                    <PageRow key={row.id} row={row} skip={skip} handleLock={handleLock} handleDelete={handleDelete} />
                ))}
            </tbody>
        </Table>
    );
}

export default connect(((state, ownProps) => ({
})), {
})(PagesTable);
