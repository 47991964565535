/* @flow */

// Framework
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { Form } from 'react-bootstrap'

// App componetns
import { renderForm } from '../../components/FormHelper'

// State
// ...

// Styles
// import * as classes from './SearchUsersForm.module.scss'

// Constants
// ...

const validate = values => {
    const errors = {}
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

class SearchUsersForm extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    }

    render () {
        const {
            handleSubmit
        } = this.props

        const fields = [
            {
                fieldType: 'field',
                name: 'search',
                label: 'Sök på e-post, förnamn, eller efternamn',
                submitStyle: { paddingLeft: '30px', paddingRight: '30px' },
                appendSubmit: {
                    name: 'submit', variant: 'dark', label: 'Sök', fieldType: 'submit'
                }
            }
        ]
        
        return (
            <Form onSubmit={handleSubmit}>
                {renderForm(fields)}
            </Form>
        )
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
SearchUsersForm = reduxForm({
    form: 'searchUsers',
    validate,
    warn
})(SearchUsersForm)

// You have to connect() to any reducers that you wish to connect to yourself
SearchUsersForm = connect(
    state => ({
    })
)(SearchUsersForm)

export default SearchUsersForm
