import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Form } from 'react-bootstrap';
import { renderForm } from '../../../components/FormHelper';
import { LOAD, NEW } from '../../../components/FormHelper/renderFormEditor';

const validate = values => {
  const errors = {}
  return errors
}

const warn = values => {
  const warnings = {}
  return warnings
}

function ContentsForm({ issue, handleSubmit, submitting, pristine, handleChange }) {

	const descriptionLabel = issue?.magazine?.title + " *";

	const fields = [
		{ name: 'magazine.title', label: 'Tidskriftens titel *', fieldType: 'field', onChange: handleChange },
		{ name: 'magazine.homepage', label: 'Tidskriftens websida', fieldType: 'field', onChange: handleChange },
		{ name: 'theme', label: 'Numrets tema', fieldType: 'field', onChange: handleChange },
		[
			{ name: 'inprice', label: 'Pris (in) *:', showLabel: true, fieldType: 'field', onChange: handleChange },
			{ name: 'outprice', label: 'Pris (ut) *:', showLabel: true, fieldType: 'field', onChange: handleChange }
		],
		{ name: 'description', label: 'Innehåll', showLabel: true, fieldType: 'editor', editorType: LOAD, onChange: handleChange },
		{ name: 'magazine.description', label: descriptionLabel, showLabel: true, fieldType: 'editor', editorType: LOAD, onChange: handleChange }
	];

	return (
		<>
			<Form onSubmit={handleSubmit}>
				{renderForm(fields)}
			</Form>
		</>
	);
}

export default connect(
	(state, ownProps) => ({
		initialValues: ownProps.issue
	})
)(
    reduxForm({
		form: 'issueContents',
		enableReinitialize: true,
		validate,
		warn
    })(ContentsForm)
);
