import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from '.'
import { updatedOrder } from './UpdateOrder'
import { createdOrder } from './CreateOrder'

/**
 * Inititator
 * 
 * @param {*} userId 
 */
export const buybackOrder = (
    orderId = undefined,
    updatedCart = undefined,
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            // const date = new Date().toISOString()

            request({
                url: `${PROTOCOL}://${URI}/orders/${orderId}/buyback`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: {
                    updatedCart
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    const { data: orders } = getState().orders

                    // Update old order
                    const index = orders.map(o => o.id).indexOf(parseInt(resp.data.order.id))
                    if (index > -1) {
                        dispatch(updatedOrder(resp.data.order, index))
                    } else {
                        dispatch(createdOrder(resp.data.order))
                    }
                    
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
