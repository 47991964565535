import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import TransactionNumberForm from '../TransactionNumberForm';
import { closeSetTransactionId } from '../../reducers/modules/modals';
import { updateOrder } from '../../reducers/modules/orders';
// import * as classes from './SetTransactionIdModal.module.scss';

function SetTransactionIdModal(props) {

    const { modal, updateOrder, closeSetTransactionId } = props;
    
    const handleCancel = () => {
        closeSetTransactionId();
    }
    
    const formValidated = (form) => {
        const { orderId } = modal;
    
        updateOrder(orderId, form);
        closeSetTransactionId();
    }

    const { isOpen } = modal;

    if (!isOpen) {
        return (<div />);
    }

    // const { orderId } = modal;
    // const { data } = orders;
    // const index = data.map(o => o.id).indexOf(parseInt(orderId));
    // const order = data[index];

    return (
        <Modal show={isOpen} onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Hantera transaktionsnummer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Ordern anses betald om ett transaktionsnummer finns.</p>
                <TransactionNumberForm onSubmit={formValidated} />
            </Modal.Body>
        </Modal>
    );
}

export default connect((state) => ({
    modal: state.modals.setTransactionId,
    orders: state.orders
}), {
    closeSetTransactionId,
    updateOrder
})(SetTransactionIdModal);
