import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Popover, Row, Col, Button } from 'react-bootstrap';
import PopoverStickOnHover from '../PopoverStickOnHover/PopoverStickOnHover';
import Img from 'react-image';
import { formatPrice } from '../../PriceHelper';
import { URI_UPLOAD } from '../../reducers/modules/api';
import { addIssue } from '../../reducers/modules/user';
import * as classes from './SearchIssue.module.scss';
import MISSING from './missingimage.jpg';
import LOADING from './loading.jpg'

function SearchIssue(props) {

    const { issue, user, addIssue } = props;

    const addtoCart = (evt) => {
        evt.preventDefault()
        
        const { id: issueId } = issue;
        const cartId = user.carts[0].id;
    
        addIssue(issueId, cartId, 1)
    }

    const cover = (issue.cover ? `http://${URI_UPLOAD}/covers/${issue.cover}` : MISSING)

    return (
        <PopoverStickOnHover
            component={
                <>
                    <Popover id={issue.id} title={issue.title} style={{minWidth: 200}} content={true}>
                        <h5>{issue.title}</h5>
                        <h6>{issue.year}:{issue.yearly_issue} {issue.total_issue ? `(${issue.total_issue})` : ''}</h6>
                        <p>I lager: {issue.stock} st</p>
                        <Row>
                        <Col>
                            <h5>{formatPrice(issue.outprice)} kr.</h5>
                        </Col>
                        {(user.token && issue.stock > 0) && <Col>
                            <Button onClick={addtoCart} variant="dark">Köp</Button>
                        </Col>}
                        </Row>
                    </Popover>
                </>
            }
            placement='bottom-start'
            delay={200}>
            <Link to={`/issue/${issue.id}`}>
                <div className={classes.captionWrapper}>
                    <Img
                        src={[cover, LOADING, MISSING]}
                        className={[classes.image, (issue.stock === 0 ? classes.grayscale : '')].join(' ')}
                        loader={LOADING}
                    />
                    {issue.stock === 0 && <div className={classes.caption}>
                        <p className={classes.captionText}>Slutsåld</p>
                    </div>}
                </div>
            </Link>
        </PopoverStickOnHover>
    );
}

export default connect((state, ownProps) => ({
    user: state.user
}), {
    addIssue
})(SearchIssue);
