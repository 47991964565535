export const DANKORT_VISA_DANKORT = 1
export const VISA_VISA_ELECTRON = 3
export const MASTERCARD = 4
export const JCB = 6
export const MAESTRO = 7
export const DINERS_CLUB = 8
export const AMERICAN_EXPRESS = 9
export const FORBRUGSFORENINGEN = 11
export const DANSKE_NETBETALINGER = 13
export const PAYPAL = 14
export const KLARNA = 17
export const SVEA_WEB_PAY = 18
export const SEB = 19
export const NORDEA = 20
export const HANDELSBANKEN = 21
export const SWEDBANK = 22
export const VIA_BILL = 23
export const BEEPTIFY = 24
export const IDEAL = 25
export const PAII = 27
export const BRANDTS_GAVEKORT = 28
export const MOBILEPAY_ONLINE = 29
export const EKSPRESS_BANK = 31
export const MASTERPASS = 34

export const getPaymentMethod = (paymentMethod) => {
    var parsed = 0
    try {
        parsed = parseInt(paymentMethod)
    } catch (error) {
        parsed = 0
    }

    switch (parsed) {
        case DANKORT_VISA_DANKORT:
            return 'Dankort/Visa Dankort'
        case VISA_VISA_ELECTRON:
            return 'Visa / Visa Electron'
        case MASTERCARD:
            return 'Mastercard'
        case JCB:
            return 'JCB'
        case MAESTRO:
            return 'Maestro'
        case DINERS_CLUB:
            return 'Diners Club'
        case AMERICAN_EXPRESS:
            return 'American Express'
        case FORBRUGSFORENINGEN:
            return 'Forbrugsforeningen'
        case DANSKE_NETBETALINGER:
            return 'Danske Netbetalinger'
        case PAYPAL:
            return 'PayPal'
        case KLARNA:
            return 'Klarna'
        case SVEA_WEB_PAY:
            return 'SveaWebPay'
        case SEB:
            return 'SEB (SE)'
        case NORDEA:
            return 'Nordea (SE)'
        case HANDELSBANKEN:
            return 'Handelsbanken (SE)'
        case SWEDBANK:
            return 'Swedbank (SE)'
        case VIA_BILL:
            return 'ViaBill'
        case BEEPTIFY:
            return 'Beeptify'
        case IDEAL:
            return 'iDEAL'
        case PAII:
            return 'Paii'
        case BRANDTS_GAVEKORT:
            return 'Brandts Gavekort'
        case MOBILEPAY_ONLINE:
            return 'MobilePay Online'
        case EKSPRESS_BANK:
            return 'Ekspress Bank'
        case MASTERPASS:
            return 'Masterpass'
        default:
            return `Unknown payment method (${paymentMethod})`
    }
}
