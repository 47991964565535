import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { randomInt } from '../../random';
import { loadCategories, ACTIVE } from '../../reducers/modules/categories';
import * as classes from './CategoryList.module.scss';

const NBR_OF_PLACEHOLDERS = 15;

function CategoryList({categories, loadCategories}) {

    const { data, isUpdating } = categories;

    useEffect(() => {
        if (!isUpdating && data.length === 0) {
            loadCategories();
        }
    }, [data, isUpdating, categories, loadCategories]);

    if (data.length === 0) {
        return (
            <>
                <h3 className={classes.header}>Kategorier</h3>
                {[...Array(NBR_OF_PLACEHOLDERS).keys()].map((number) => (
                    <h6 className={classes.placeholder} key={number} style={{width: `${randomInt(60, 70)}%`}}>&nbsp;</h6>
                ))}
            </>
        )
    }

    const filtered = [...data].filter(c => parseInt(c.active) === ACTIVE);

    return (
        <>
            <h3 className={classes.header}>Kategorier</h3>
            {filtered.map((category) => (
                <h6 className={classes.categoryLink} key={category.id}>
                <Link to={`/categories/${category.id}`}>
                    {category.name}
                </Link>
                </h6>
            ))}
        </>
    );
}

export default connect((state, ownProps) => ({
    categories: state.categories
}), {
    loadCategories
})(CategoryList);
