import update from 'immutability-helper'

// Load exports
import {
    createExport,
    CREATED_EXPORT,
    handleCreatedExport
} from './ExportMagazines'

export { createExport }

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATING_EXPORTS = 'UPDATING_EXPORTS'
export const CLEAR_ALL_EXPORTS = 'CLEAR_ALL_EXPORTS'

// ------------------------------------
// Actions
// ------------------------------------
/**
 * Private function
 * Used to toggle the isUpdating flag in the all exports state
 *
 * @param state     The isUpdating state
 */
export function exportsIsUpdating (
    state = true
) {
    return {
        type: UPDATING_EXPORTS,
        payload: state
    }
}

/**
 * Public function
 * Tell the store to clear out all the exports
 */
export function clearExports () {
    return {
        type: CLEAR_ALL_EXPORTS
    }
}


// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [UPDATING_EXPORTS]: (state, action) => (
        update(state, {
            isUpdating: {$set: action.payload}
        })
    ),

    [CREATED_EXPORT]: (state, action) => handleCreatedExport(state, action),

    [CLEAR_ALL_EXPORTS]: (state, action) => (
        update(state, {
            isUpdating: {$set: false},
            url: {$set: null}
        })
    )
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    isUpdating: false,
    url: null
}

export default function exportsReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
