import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const LOADED_PERMISSIONS = 'LOADED_PERMISSIONS'

/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export const loadedPermissions = (
    permissions = []
) => ({
    type: LOADED_PERMISSIONS,
    payload: permissions
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadedPermissions = (state, action) => update(state, {
    permissions: {$set: action.payload}
})


/**
 * Initiator
 */
export const loadPermissions = () => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/auth/permissions`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: {},
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    if (resp.data) {
                        dispatch(loadedPermissions(resp.data))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}