import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form } from 'react-bootstrap';
// import * as classes from './FilterOrdersPayment.module.scss';

const validate = values => {
	const errors = {}
	return errors
}

const warn = values => {
	const warnings = {}
	return warnings
}

const renderRadio = ({
	input,
	label,
	defaultChecked
}) => {
	if (defaultChecked) {
		return (<Form.Check
			{...input}
			inline
			label={label}
			value={input.value}
			type='radio'
			defaultChecked />)
	} else {
		return (<Form.Check
			{...input}
			inline
			label={label}
			value={input.value}
			type='radio' />)
	}
}

function FilterOrdersPayment(props) {

    const { handleChange, defaultValue } = props;
    const defaultId = parseInt(defaultValue.payment);

    return (
        <Form.Group controlId="payment">
            <Field
                name='payment'
                label='Kort'
                id='card'
                component={renderRadio}
                defaultChecked={defaultId === 0}
                onChange={() => { handleChange(0) }} />

            <Field
                name='payment'
                label='Faktura'
                id='invoice'
                component={renderRadio}
                defaultChecked={defaultId === 1}
                onChange={() => { handleChange(1) }} />

            <Field
                name='payment'
                label='Förskott'
                id='prepaid'
                component={renderRadio}
                defaultChecked={defaultId === 2}
                onChange={() => { handleChange(2) }} />

            <Field
                name='payment'
                label='Alla'
                id='all'
                component={renderRadio}
                defaultChecked={defaultId === 3}
                onChange={() => { handleChange(3) }} />
        </Form.Group>
    );
}

export default connect(
    (state, ownProps) => ({
        initialValues: ownProps.defaultValue
    })
)(
    reduxForm({
        form: 'FilterOrdersPayment',
        validate,
        warn,
        enableReinitialize : true
    })(FilterOrdersPayment)
);
