import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'
import { updatedCart } from './UpdateCart'
import { pushAlert } from '../alerts'
import { pushToast } from '../toasts'
import { getCartIssues } from '../../../cartHelper'

/**
 * Inititator
 * 
 * @param {*} userId 
 */
export const addIssue = (
    issueId = 0,
    cartId = 0,
    quantity = 1
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/carts/${cartId}/issues`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: {
                    issue: issueId,
                    quantity: parseInt(quantity)
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    let { response } = err
                    let error = JSON.parse(response)
                    dispatch(pushAlert('Varukorgen', error.messages))
                    resolve()
                },
                success (resp) {
                    const { carts } = getState().user
                    const index = carts.map(c => c.id).indexOf(parseInt(resp.data.id))

                    const issues = getCartIssues(resp.data.data)
                    const addedIssue = issues.find((issue) => (issue.id === issueId))

                    if (index > -1) {
                        dispatch(updatedCart(resp.data, index))

                        if (addedIssue) {
                            dispatch(pushToast(undefined, `Lade till ${addedIssue.name} i varukorgen.`))
                        }
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
