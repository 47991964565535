import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL, minutesPassed } from '../api'
import { pushUpdating, popUpdating } from './index'

export const MINIMUM_UPDATE_TIME = 15 //minutes

/**
 * Actions
 */
export const LOADED_FRIENDS = 'LOADED_FRIENDS'
export const LOADING_FRIENDS = 'LOADING_FRIENDS'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function loadingFriends () {
    return {
        type: LOADING_FRIENDS,
        payload: Date()
    }
}


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function loadedFriends (
    friends = []
) {
    return {
        type: LOADED_FRIENDS,
        payload: friends
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadingFriends = (state, action) => update(state, {
    loaded: {$set: action.payload}
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadedFriends = (state, action) => update(state, {
    data: {$set: action.payload.data},
    links: {$set: action.payload.links},
    meta: {$set: action.payload.meta}
})


/**
 * Inititator
 */
export const loadFriends = () => {
    return (dispatch, getState) => {
        
        const { loaded } = getState().friends || undefined
        if (!minutesPassed(MINIMUM_UPDATE_TIME, loaded)) {
            return
        }
        dispatch(loadingFriends())

        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/friends`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(loadedFriends(resp))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
