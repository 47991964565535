import update from 'immutability-helper'

// Load pages
import {
    loadPages,
    LOADING_PAGES, LOADED_PAGES,
    handleLoadingPages, handleLoadedPages
} from './LoadPages'

// Create page
import {
    createPage,
    CREATED_PAGE,
    handleCreatedPage
} from './CreatePage'

// Update page
import {
    updatePage,
    UPDATED_PAGE,
    handleUpdatedPage
} from './UpdatePage'

// Load page
import {
    loadPage
} from './LoadPage'

// Remove page
import {
    removePage,
    REMOVED_PAGE,
    handleRemovedPage
} from './RemovePage'

// Change active state
import {
    lockPage, unlockPage
} from './ActivatePage'

export { loadPage, loadPages, createPage, updatePage, removePage }
export { lockPage, unlockPage }

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATING_PAGES = 'UPDATING_PAGES'
export const CLEAR_ALL_PAGES = 'CLEAR_ALL_PAGES'

const INCREMENT = 1;
const DECREMENT = 2;

export const ACTIVE = 1
export const INACTIVE = 0

// ------------------------------------
// Actions
// ------------------------------------
export function pushUpdating () {
    return {
        type: UPDATING_PAGES,
        payload: INCREMENT
    }
}

export function popUpdating () {
    return {
        type: UPDATING_PAGES,
        payload: DECREMENT
    }
}

/**
 * Public function
 * Tell the store to clear out all the pages
 */
export function clearPages () {
    return {
        type: CLEAR_ALL_PAGES
    }
}


// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [UPDATING_PAGES]: (state, action) => {
        if (INCREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating + 1}})
        } else if (DECREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating - 1}})
        } else {
            return state
        }
    },

    [LOADED_PAGES]: (state, action) => handleLoadedPages(state, action),

    [LOADING_PAGES]: (state, action) => handleLoadingPages(state, action),

    [CREATED_PAGE]: (state, action) => handleCreatedPage(state, action),

    [UPDATED_PAGE]: (state, action) => handleUpdatedPage(state, action),

    [REMOVED_PAGE]: (state, action) => handleRemovedPage(state, action),

    [CLEAR_ALL_PAGES]: (state, action) => (
        update(state, {$set: initialState})
    )
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    isUpdating: 0,
    data: [],
    links: undefined,
    meta: undefined,
    loaded: undefined
}

export default function pagesReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
