import React from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom";
import { Popover, Row, Col, Button } from 'react-bootstrap'
import PopoverStickOnHover from '../PopoverStickOnHover/PopoverStickOnHover'
import Img from 'react-image'
import { formatPrice } from '../../PriceHelper'
import { URI_UPLOAD } from '../../reducers/modules/api'
import { addIssue, shortlist } from '../../reducers/modules/user'
import MISSING from './missing.jpg'
import LOADING from './loading.jpg'
import * as classes from './MagazineIssue.module.scss'

const loading = () => (
    <Img
        src={LOADING}
        className={[classes.image, classes.grayscale].join(' ')}
    />
)

function MagazineIssue({ issue, magazine, user, addIssue }) {
    const addToCart = (evt) => {
        evt.preventDefault()
        
        const issueId = issue.id
        const cartId = user.carts[0].id
    
        addIssue(issueId, cartId, 1)
    }
    
    const addToShortlist = (evt) => {
        evt.preventDefault()
        
        const issueId = issue.id
    
        shortlist(issueId)
    }

    if (!issue || !magazine) {
        return (
            <div className={classes.captionWrapper}>
                <Img
                    src={LOADING}
                    className={[classes.image, classes.grayscale].join(' ')}
                />
            </div>
        );
    }

    const cover = (issue.cover ? `http://${URI_UPLOAD}/covers/${issue.cover.name}` : MISSING)

    return (
        <PopoverStickOnHover
            component={
                <>
                    <Popover id={issue.id} title={issue.title} style={{minWidth: 200}} content={true}>
                        <h5>{magazine.title}</h5>
                        <h6>{issue.year}:{issue.yearly_issue} {issue.total_issue ? `(${issue.total_issue})` : ''}</h6>
                        <p>I lager: {issue.stock} st</p>
                        <Row>
                            <Col>
                                <h5>{formatPrice(issue.outprice)} kr.</h5>
                            </Col>

                            {(user.token && issue.stock > 0) && <Col>
                                <Button onClick={addToCart} variant="dark">Köp</Button>
                            </Col>}

                            {(user.token && issue.stock === 0) && <Col>
                                <Button onClick={addToShortlist} variant="dark">Önskelista</Button>
                            </Col>}
                        </Row>
                    </Popover>
                </>
            }
            placement='bottom-start'
            delay={200}>
            <Link to={`/issue/${issue.id}`}>
                <div className={classes.captionWrapper}>
                    <Img
                        src={[cover, MISSING]}
                        className={[classes.image, (issue.stock === 0 ? classes.grayscale : '')].join(' ')}
                        loader={loading()}
                    />
                    {issue.stock === 0 && <div className={classes.caption}>
                        <p className={classes.captionText}>Slutsåld</p>
                    </div>}
                </div>
            </Link>
        </PopoverStickOnHover>
    );
}

export default connect((state, ownProps) => ({
    user: state.user
}), {
    addIssue,
    shortlist
})(MagazineIssue);
