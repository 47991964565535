/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { Form } from 'react-bootstrap'

// App componetns
import { renderForm } from '../FormHelper'

// State
// ...

// Styles
// import * as classes from './AddToShortlistForm.module.scss'

// Constants
// ...

class AddToShortlistForm extends React.Component {
    static propTypes = {
        issue: PropTypes.object.isRequired,

        handleSubmit: PropTypes.func.isRequired
    }

    render () {
        const {
            handleSubmit, //pristine, submitting
        } = this.props

        const fields = [
            {name: 'submit', variant: 'dark', label: 'Lägg till i önskelista', fieldType: 'submit'}
        ]

        return (
            <Form onSubmit={handleSubmit}>
                {renderForm(fields)}
            </Form>)
    }
}

AddToShortlistForm = reduxForm({
    form: 'addToShortlist',
})(AddToShortlistForm)

AddToShortlistForm = connect(
    state => ({
    })
)(AddToShortlistForm)

export default AddToShortlistForm
