import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const CREATED_MAGAZINE = 'CREATED_MAGAZINE'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function createdMagazine (
    magazine = {}
) {
    return {
        type: CREATED_MAGAZINE,
        payload: magazine
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleCreatedMagazine = (state, action) => update(state, {
    data: {$push: [action.payload]}
})


/**
 * Inititator
 * 
 * @param {*} userId 
 */
export const createMagazine = (
    data = {}
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/magazines/`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: data,
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(createdMagazine(resp.data))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
