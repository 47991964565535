import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const DELETED_ISSUE = 'DELETED_ISSUE'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export const issueDeleted = (
    index = 0
) => ({
    type: DELETED_ISSUE,
    index: index
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleDeletedIssue = (state, action) => update(state, {
    items: {$splice: [[action.index, 1]]}
})


/**
 * Inititator
 */
export const deleteIssue = (
    issueId = 0
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/issues/${issueId}`,
                method: 'DELETE',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    const { items: issues } = getState().issues
                    const index = issues.map(i => i.id).indexOf(issueId)
                    if (index > -1) {
                        dispatch(issueDeleted(index))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
