import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating} from './index'
import { createdPage } from './CreatePage'
import { updatedPage } from './UpdatePage'


/**
 * Inititator
 * 
 * @param {*} pageId 
 */
export const loadPage = (
    pageId = undefined
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/pages/${pageId}`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    try {
                        const index = getState().pages.data.map(c => c.id).indexOf(parseInt(resp.data.id))
                        if (index > -1) {
                            dispatch(updatedPage(resp.data, index))
                        } else {
                            dispatch(createdPage(resp.data))
                        }
                    } catch (err) {
                        console.error(err)
                    } finally {
                        dispatch(popUpdating())
                        resolve()
                    }
                }
            })
        })
    }
}
