/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'

// App components
import AdminPages from '../../components/AdminPages'
import ExportDatabaseForm from '../../components/ExportDatabaseForm'

// State
import { createExport } from '../../reducers/modules/exports'

// Styles
// import * as classes from './AdminExportView.module.scss'

// Constants
// ...

export class AdminExportView extends React.Component {
  static propTypes = {
    exports: PropTypes.object.isRequired,

    createExport: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)

    this.handleFilterAccounting = this.handleFilterAccounting.bind(this)
  }

  handleFilterAccounting (form) {
    const { start_year, start_month, start_day, stop_year, stop_month, stop_day, replace_all, magazines, overwrite } = form
    
    const magazine_ids = magazines ? magazines.map(i => i.id) : null

    this.props.createExport(
      start_year, start_month, start_day,
      stop_year, stop_month, stop_day,
      replace_all, magazine_ids, overwrite
    )
  }

  render () {
    try {
      const { url } = this.props.exports

      return (
        <Container>
          <Row>
            <Col xs={2}>
              <AdminPages />
            </Col>
            <Col xs={10}>
              <Card body>
                <h4>Information</h4>
                <p>Här kan du exportera en excel-fil med tidskrifter i <b>lager</b>.</p>
                <p>Filtrera vilka datum du vill söka resultat för här.</p>
                <p>Begränsa mellan vilka datum tidskrifter är inlagda i databasen här.</p>
                <p>TIPS: Välj inte för många år, filen kan bli för stor för servern att hantera.</p>

                <ExportDatabaseForm onSubmit={this.handleFilterAccounting} />
              </Card>

              {url && <Card body>
                <h4>Ladda ner exporten här:</h4>
                <p>Den exporterade filen kommer automatiskt tas bort om 5 minuter!</p>
                <a href={url} download>{url}</a>
              </Card>}
            </Col>
          </Row>
        </Container>
      )
    } catch (error) {
      return (
        <Container>
          <p>laddar...</p>
        </Container>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  exports: state.exports
})
export default connect((mapStateToProps), {
  createExport
})(AdminExportView)
