/* @flow */

// Framework
import React from 'react'
import { Button } from 'react-bootstrap'
import { wrapFormCol } from './wrapFormCol'

export const renderFormSubmit = ({
  input,
  variant,
  label,
  submitting,
  pristine,
  block = 'btn-block',
  group = true,
  meta: { touched, error, warning }
}) => {
  if (group) {
    return wrapFormCol(<Button {...input} variant={variant} type='submit' disabled={submitting|pristine} className={block}>
      {label}
    </Button>)
  }
  return (<Button {...input} variant={variant} type='submit' disabled={submitting|pristine} className={block}>
    {label}
  </Button>)
}
