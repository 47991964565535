/* @flow */

// Framework
import React from 'react'
import { connect } from 'react-redux'
import { Card, Button, ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'

// App componetns
// ...

// State
// ...

// Styles
import * as classes from './AccountPages.module.scss'

// Constants
// ...

const accountPages = [
  {title: 'Konto', to: '/account'},
  {title: 'Ordrar', to: '/account/orders'},
  {title: 'Önskelista', to: '/account/shortlist'}
]

const renderPage = (page) => {
  return (
    <ListGroup.Item key={page.title} as="li">
      <Button as={Link} to={page.to} variant="link">{page.title}</Button>
    </ListGroup.Item>
  )
}

const renderPages = (pages) => {
  return (
    <ListGroup as="ul" className={classes.list}>
      {pages.map((page) => renderPage(page))}
    </ListGroup>
  )
}

class AccountPages extends React.Component {
  static propTypes = {
  }
  
  render () {
    return (
      <Card body>
        <h6>Mitt konto</h6>
        {renderPages(accountPages)}
      </Card>
    )
  }
}

const mapStateToProps = (state) => ({
})
export default connect((mapStateToProps), {
})(AccountPages)

