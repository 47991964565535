/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Table } from 'react-bootstrap'

// App components
// ...

// State
import { formatPrice } from '../../PriceHelper'
import { getCartIssues, getCartItems, getTotalWeight, getCartPrice, getCartPostage } from '../../cartHelper'

// Styles
// import * as classes from './CartTotalsTable.module.scss'

// Constants
// ...

export class CartTotalsTable extends React.Component {
    static propTypes = {
        postages: PropTypes.object.isRequired,
        settings: PropTypes.object.isRequired,
        displayVat: PropTypes.bool.isRequired,
        address: PropTypes.object.isRequired,
        cartData: PropTypes.string.isRequired,
        isStore: PropTypes.bool
    }

    render = () => {
        const { cartData, isStore } = this.props
        
        const { postages, settings, address } = this.props
        const { country_id: storeCountry } = settings.data
        const { country_id: cartCountry } = address

        try {
            const issues = getCartIssues(cartData)
            const items = getCartItems(issues)
            const totalWeight = getTotalWeight(issues)
            const price = getCartPrice(issues)

            const postage = getCartPostage(postages, storeCountry, cartCountry, issues, isStore)
            
            const total = postage + price

            const { displayVat } = this.props

            const totalVat = total - (total / (1 + settings.data.VAT / 100));

            return (
                <Table striped bordered hover size="sm">
                    <tbody>
                        <tr>
                            <td>Produkter: (st)</td>
                            <td>{items}</td>
                        </tr>
                        <tr>
                            <td>Vikt: (gram)</td>
                            <td>{totalWeight} g</td>
                        </tr>
                        <tr>
                            <td>Pris: (SEK)</td>
                            <td>{formatPrice(price)} kr.</td>
                        </tr>
                        <tr>
                            <td>Porto: (SEK)</td>
                            <td>{formatPrice(postage)} kr.</td>
                        </tr>
                        <tr>
                            <td><b>Pris med frakt: (SEK)</b></td>
                            <td>{formatPrice(total)} kr.</td>
                        </tr>
                        {displayVat && <tr>
                            <td>Varav moms (6%)</td>
                            <td>{formatPrice(totalVat)} kr.</td>
                        </tr>}
                    </tbody>
                </Table>
            )
        } catch (err) {
            return <div />
        }
    }
}

const mapStateToProps = (state, ownProps) => ({
    settings: state.settings
})
export default connect((mapStateToProps), {
})(CartTotalsTable)
