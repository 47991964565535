import React from 'react';
import { EditorState } from 'draft-js'
import DraftEditor from './DraftEditor';

export default function NewTextEditor(props) {
    const { onChange } = props;
    return (
        <DraftEditor state={EditorState.createEmpty()} onChange={onChange} />
    );
}
