/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { Form } from 'react-bootstrap'

// App componetns
import { renderForm } from '../FormHelper'

// State
// ...

// Styles
// import * as classes from './DeleteMagazineForm.module.scss'

// Constants
// ...

const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = 'Obligatoriskt'
    } else if (parseInt(values.name) < 1) {
        errors.name = 'Namnet måste vara minst ett tecken långt.'
    }
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

class DeleteMagazineForm extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        magazine: PropTypes.object.isRequired
    }

    render () {
        const {
            handleSubmit, magazine, submitting, pristine
        } = this.props

        const fields = [
            {name: 'confirm', label: `Skriv in tidskriftens namn (${magazine.name})`, placeholder: 'Namn', showLabel: true},
            {variant: 'danger', label: 'Bekräfta borttagning', fieldType: 'submit', submitting, pristine, block: false}
        ]

        try {
            return (
                <Form onSubmit={handleSubmit}>
                    {renderForm(fields)}
                </Form>)
        } catch (err) {
            return (<p>error</p>)
        }
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
DeleteMagazineForm = reduxForm({
  form: 'deleteMagazine',
  validate,
  warn
})(DeleteMagazineForm)

// You have to connect() to any reducers that you wish to connect to yourself
DeleteMagazineForm = connect(
  (state, ownProps) => ({
      initialValues: ownProps.magazine
  })
)(DeleteMagazineForm)

export default DeleteMagazineForm
