import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const LOADED_ORBIT_SETTINGS = 'LOADED_ORBIT_SETTINGS'

/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function loadedOrbitSettings (
    settings = undefined
) {
    return {
        type: LOADED_ORBIT_SETTINGS,
        payload: settings
    }
}

/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadedOrbitSettings = (state, action) => update(state, {
    settings: {
        data: {$set: action.payload.data},
        links: {$set: action.payload.links},
        meta: {$set: action.payload.meta}
    }
})


/**
 * Initiator
 */
export const loadOrbitSettings = (
    filter = undefined
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/orbitsettings`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: filter,
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(loadedOrbitSettings(resp))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}

export const loadOrbitSettingsState = {
    data: [],
    links: undefined,
    meta: undefined
}
