import update from 'immutability-helper'

export const TOGGLED_PRINT_RECEIPT = 'TOGGLED_PRINT_RECEIPT'

export const handleToggledPrintReceipt = (state, action) => update(state, {
    printReceipt: {
        isOpen: {$set: action.state},
        order: {$set: action.order}
    }
})

export function toggledPrintReceipt (
    state = undefined,
    order = undefined
) {
    return {
        type: TOGGLED_PRINT_RECEIPT,
        state,
        order
    }
}

export const openPrintReceipt = (
    order = undefined
) => {
    return (dispatch, getState) => {
        dispatch(toggledPrintReceipt(true, order))
    }
}

export const closePrintReceipt = () => {
    return (dispatch, getState) => {
        dispatch(toggledPrintReceipt(false, undefined))
    }
}

export const printReceipt = { isOpen: false, order: undefined }
