import React from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col, Card } from 'react-bootstrap'
import yabbcode from 'ya-bbcode'
import Img from 'react-image'
import AddToCartForm from '../../components/AddToCartForm'
import AddToShortlistForm from '../../components/AddToShortlistForm'
import chunk from '../../chunk'
import MagazineIssue from '../../components/MagazineIssue'
import { addIssue, shortlist } from '../../reducers/modules/user'
import { isUser, isStore } from '../../PermissionsHelper'
import Barcode from 'react-barcode'
import LOADING from './loading.jpg';
import MISSING from './missingimage.jpg';
import * as classes from './IssueView.module.scss'
import { randomInt } from '../../random'

// Constants
const parser = new yabbcode()
parser.registerTag('*', {
	type: 'replace',
	open: (attr) => {
		return `<li>`;
	},
	close: '</li>'
}).registerTag('br', {
	type: 'replace',
	open: (attr) => {
		return `<br>`;
	},
	close: null
})

const MAX_ISSUES_ROW = 6;

// const loading = () => (
//     <Img
//         src={LOADING}
//         className={[classes.image, classes.grayscale].join(' ')}
//     />
// )

const renderDataPlaceholder = (row) => (
    <div key={`placeholder-${row}`} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <dt className={classes.placeholder} style={{width: `${randomInt(30, 50)}%`}}>&nbsp;</dt><dd className={classes.placeholder} style={{width: `${randomInt(30, 50)}%`}}>&nbsp;</dd>
    </div>
)

const renderContentPlaceholder = (length) => (
    <ul>
        {[...Array(length).keys()].map((row) => (
            <li key={`content-row${row}`}>
                {[...Array(randomInt(1, 5)).keys()].map((col) => (
                    <span key={`content-row${row}col${col}`} className={classes.placeholder} style={{width: `${randomInt(70, 90)}%`, display: 'block'}}>&nbsp;</span>
                ))}
            </li>
        ))}
    </ul>
)

function UserContent({ user, issue, magazine, category, cover, issues, addIssue, shortlist }) {
    
    const addToCart = (form) => {
        const { id: issueId } = issue
        const { id: cartId } = user.carts[0]
        const { items: quantity } = form

        if (parseInt(quantity) > 0) {
            addIssue(issueId, cartId, quantity)
        }
    }

    const shortlistIssue= (form) => {
        const { id: issueId } = issue
        shortlist(issueId)
    }

    if (!issue || !magazine || !category) {
        return (
            <>
                <Container>
                    <Row>
                        <Col xs={3}>
                            <div className={classes.image}>
                                <Img
                                    className={classes.grayscale}
                                    src={LOADING} />
                            </div>
                            <h6 className={classes.placeholder} style={{width: `${randomInt(60, 70)}%`}}>&nbsp;</h6>
                            <Card body>
                                <dl style={{display: 'flex', flexDirection: 'column'}}>
                                    {[...Array(6).keys()].map((index) => (
                                        renderDataPlaceholder(index)
                                    ))}
                                </dl>
                            </Card>
                        </Col>
                        <Col xs={9}>
                            <h3 className={[classes.h3, classes.placeholder].join(' ')} style={{width: `${randomInt(30, 50)}%`}}>&nbsp;</h3>
                            <p className={classes.placeholder} style={{width: `${randomInt(30, 50)}%`}}>&nbsp;</p>
                            <h5 className={classes.placeholder} style={{width: `${randomInt(10, 30)}%`}}>&nbsp;</h5>
                            <h4 className={classes.h4}>Innehåll</h4>
                            {renderContentPlaceholder(randomInt(3, 6))}
                            <h4 className={[classes.h4, classes.placeholder].join(' ')} style={{width: `${randomInt(10, 30)}%`}}>&nbsp;</h4>
                            {renderContentPlaceholder(randomInt(2, 4))}
                        </Col>
                    </Row>
                    {[...Array(randomInt(1, 4)).keys()].map((row) => (
                        <Row key={`row${row}`} className={[classes.row, 'd-flex', 'justify-content-start']}>
                            {[...Array(5).keys()].map((col) => (
                                <Col key={`row${row}col${col}`} className={['d-flex', 'align-self-end', 'justify-content-center']}>
                                    <MagazineIssue magazine={undefined} issue={undefined} />
                                </Col>
                            ))}
                        </Row>
                    ))}
                </Container>
            </>
        );
    }

    const { description: issueDescription } = issue || "";
    const { description: magazineDescription } = magazine || "";

    let contents = '';
    if (issueDescription) {
        contents = parser.parse(issueDescription.replace(/\[br\]/g, '<br/>'));
    }

    let description = '';
    if (magazineDescription) {
        description = parser.parse(magazineDescription.replace(/\[br\]/g, '<br/>'));
    }

    const { stock, year, yearly_issue, total_issue, pages, dimensions, weight, barcode, theme } = issue || undefined;

    const { token } = user;

    const displayAddToCartForm = token && isUser(user) && stock > 0;
    const displayAddToShortForm = token && isUser(user) && stock <= 0;

    let rows = issues ? chunk(issues, MAX_ISSUES_ROW) : [];
    
    return (
        <>
            <Container>
                <Row>
                    <Col xs={3}>
                        <div className={classes.image}>
                        <Img
                            className={(stock === 0 ? classes.grayscale : '')}
                            src={[cover, MISSING]}
                            loading={LOADING} />
                        {stock === 0 && <div className={classes.caption}>
                            <p className={classes.captionText}>Slutsåld</p>
                        </div>}
                        </div>
                        <h6>Kategori: {category.name}</h6>
                        <Card body>
                        <dl>
                            <dt>Nummer:</dt><dd>{year}:{yearly_issue} {total_issue ? `(${total_issue})` : ''}</dd>
                            <dt>Issn:</dt><dd>{magazine.issn}</dd>
                            <dt>Språk:</dt><dd>{magazine.language ? magazine.language : 'Svenska'}</dd>
                            <dt>Antal sidor:</dt><dd>{pages}</dd>
                            <dt>Dimensioner:</dt><dd>{dimensions ? dimensions : (magazine.dimensions ? magazine.dimensions : '-')} mm</dd>
                            <dt>Vikt:</dt><dd>{weight} g</dd>
                            <dt>I lager:</dt><dd>{stock} st</dd>
                        </dl>
                        </Card>
                        {isStore(user) && <Card style={{display: 'flex', alignItems: 'center'}}>
                            {barcode && <Barcode value={barcode} fontSize={0} />}
                        </Card>}
                    </Col>
                    <Col xs={9}>
                        <h3 className={classes.h3}>{magazine.title} {year}:{yearly_issue} {total_issue ? `(${total_issue})` : ''}</h3>
                        <p><a href={magazine.homepage}>{magazine.homepage}</a></p>
                        {theme && (<h5>{theme}</h5>)}
                        {displayAddToCartForm && <AddToCartForm issue={issue} onSubmit={addToCart} />}
                        {!displayAddToCartForm && <p>Logga in för att beställa</p>}
                        {displayAddToShortForm && <AddToShortlistForm issue={issue} onSubmit={shortlistIssue} />}
                        <h4 className={classes.h4}>Innehåll</h4>
                        <div dangerouslySetInnerHTML={{__html: contents}}></div>
                        <h4 className={classes.h4}>{magazine.title}</h4>
                        <div dangerouslySetInnerHTML={{__html: description}}></div>
                    </Col>
                </Row>
                {rows.map((row, index) => (
                    <Row key={index} className={[classes.row, 'd-flex', 'justify-content-start']}>
                        {row.map(i => (
                        <Col key={i.id} xs={2} className={['d-flex', 'align-self-end', 'justify-content-center']}>
                            <MagazineIssue magazine={i.magazine} issue={i} />
                        </Col>
                        ))}
                    </Row>
                ))}
            </Container>
        </>
    );
}

export default connect((state, ownProps) => ({
}), {
    addIssue,
    shortlist
})(UserContent);
