/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// App components
import AdminPages from '../../components/AdminPages'
import PostagesTable from '../../components/PostagesTable'
import { DomesticForm, InternationalForm } from '../../components/CreatePostageForm'
import renderLoading from '../../renderLoading'

// State
import { createDomestic, createInternational, removeDomestic, removeInternational } from '../../reducers/modules/postages'

// Styles
// import * as classes from './AdminPostagesView.module.scss'

// Constants
// ...

export class AdminPostagesView extends React.Component {
  static propTypes = {
    postages: PropTypes.object.isRequired,

    createDomestic: PropTypes.func.isRequired,
    removeDomestic: PropTypes.func.isRequired,
    createInternational: PropTypes.func.isRequired,
    removeInternational: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)

    this.handleAddDomestic = this.handleAddDomestic.bind(this)
    this.handleDelDomestic = this.handleDelDomestic.bind(this)
    this.handleAddInternational = this.handleAddInternational.bind(this)
    this.handleDelInternational = this.handleDelInternational.bind(this)
  }

  handleAddDomestic (form) {
    const { price, lbound } = form
    this.props.createDomestic(price, lbound)
  }

  handleDelDomestic (postageId) {
    this.props.removeDomestic(postageId)
  }

  handleAddInternational (form) {
    const { price, lbound } = form
    this.props.createInternational(price, lbound)
  }

  handleDelInternational (postageId) {
    this.props.removeInternational(postageId)
  }

  render () {
    try {
      const { domestic, international, isUpdating } = this.props.postages

      return (
        <Container>
          <Row>
            <Col xs={2}>
              <AdminPages />
            </Col>
            <Col xs={10}>
              <Card body>
                <h4>Portohantering</h4>
                <p>För att ändra ett redan existerande porto så behöver du endast skriva in det nya värdet i listan, efter c:a 1 sekunds uppehåll så uppdateras listan automatiskt.</p>
                <p>För att lägga till en ny porto-nivå använd formuläret längst ner på sidan.</p>
                <p>För att ta bort ett existerande porto klickar du på <FontAwesomeIcon icon='times' /> i listan, du får då en dialog där du måste bekräfta genom att skriva in priset för det valda portot.</p>
                <p>Observera: Alla priser skall anges enligt E-Pay's standard där decimaler representeras av två nollor i ett heltal. <b>25kr anges som 2500 i E-Pay mått.</b></p>
              </Card>

              <Card body>
                <h4>Porton</h4>
                {isUpdating > 0 && renderLoading()}
                {domestic.length > 0 && <PostagesTable postages={domestic} handleDelete={this.handleDelDomestic} />}
              </Card>

              <Card body>
                <h5>Lägg till nytt porto här</h5>
                <DomesticForm onSubmit={this.handleAddDomestic} />
              </Card>

              <Card body>
                <h4>Internationella porton</h4>
                {isUpdating > 0 && renderLoading()}
                {domestic.length > 0 && <PostagesTable postages={international} handleDelete={this.handleDelInternational} />}
              </Card>

              <Card body>
                <h5>Lägg till nytt porto här</h5>
                <InternationalForm onSubmit={this.handleAddInternational} />
              </Card>
            </Col>
          </Row>
        </Container>
      )
    } catch (error) {
      console.error(error)
      return (
        <Container>
          <p>laddar...</p>
        </Container>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  postages: state.postages
})
export default connect((mapStateToProps), {
  createDomestic,
  removeDomestic,
  createInternational,
  removeInternational
})(AdminPostagesView)
