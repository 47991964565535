/* @flow */

// Framework
import React from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Row, Col, Card } from 'react-bootstrap'

// App components
import AdminPages from '../../components/AdminPages'

// State
// ...

// Styles
// import * as classes from './AdminCleaningView.module.scss'

// Constants
// ...

export class AdminCleaningView extends React.Component {
  static propTypes = {
  }

  constructor (props) {
    super(props)

    this.handleFilterAccounting = this.handleFilterAccounting.bind(this)
  }

  handleFilterAccounting (form) {
    const { start_year, start_month, start_day, stop_year, stop_month, stop_day, replace_all, magazines, overwrite } = form
    const magazine_ids = magazines.map(i => i.id)

    this.props.createExport(
      start_year, start_month, start_day,
      stop_year, stop_month, stop_day,
      replace_all, magazine_ids, overwrite
    )
  }

  render () {
    return (
      <Container>
        <Row>
          <Col xs={2}>
            <AdminPages />
          </Col>
          <Col xs={10}>
            <Card body>
              <h4>Information</h4>
              <p>För att gallra i databasen så kan du använda denna sida.</p>
              <p>Skriv in, eller scanna in, ett nummer i textrutan och klicka sedan på "lägg till" för att lägga till tidskriften i gallringslistan.</p>
              <p>Du kan också skriva in en tidskrifts namn, årgång och nummer för att lägga till tidskrifter utan EAN-kod.</p>

              {/* <ExportDatabaseForm onSubmit={this.handleFilterAccounting} /> */}
            </Card>

            <Card body>
              <p><b>Lägg till en tidskrift till gallringen här</b></p>
              <p>todo...</p>
            </Card>

            <Card body>
              <p><b>Genomför gallring</b></p>
              <p>Obs, detta kan ej återställas.</p>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
})
export default connect((mapStateToProps), {
})(AdminCleaningView)
