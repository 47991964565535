/* @flow */

// Framework
import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'

export const renderFormRadio = ({
  input,
  id,
  theValue,
  label,
  meta: { touched, error, warning }
}) => (
  <InputGroup>
    <Form.Check
      {...input}
      id={id}
      value={theValue}
      onChange={val => input.onChange(val)} 
      onBlur={() => input.onBlur(input.value)}
      type="radio"
      label={label} />
  </InputGroup>)
