import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { accountingIsUpdating } from './index'

/**
 * Actions
 */
export const FILTERED_ACCOUNTING = 'FILTERED_ACCOUNTING'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function filteredAccounting (
    accounting = {}
) {
    return {
        type: FILTERED_ACCOUNTING,
        payload: accounting
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleFilteredAccounting = (state, action) => update(state, {
    isUpdating: {$set: false},
    data: {$set: action.payload}
})


/**
 * Inititator
 * 
 * @param {*} userId 
 */
export const filterAccounting = (
    start_year = 0,
    start_month = 0,
    start_day = 0,
    stop_year = 0,
    stop_month = 0,
    stop_day = 0,
    magazine_id = 0
) => {
    return (dispatch, getState) => {
        dispatch(accountingIsUpdating(true))
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/accounting`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: {
                    start_year, start_month, start_day,
                    stop_year, stop_month, stop_day,
                    magazine_id
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(accountingIsUpdating(false))
                    resolve()
                },
                success (resp) {
                    dispatch(filteredAccounting(resp.data))
                    resolve()
                }
            })
        })
    }
}
