import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'
/**
 * Actions
 */
export const FINISHED_SEARCH = 'FINISHED_SEARCH'

/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export const finishedSearch = (
    items = []
) => ({
    type: FINISHED_SEARCH,
    payload: items
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleFinishedSearch = (state, action) => update(state, {
    data: {$set: action.payload.data},
    links: {$set: action.payload.links},
    meta: {$set: action.payload.meta}
})


/**
 * Initiator
 */
export const performSearch = (
    query = ''
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/search${query.startsWith('?') ? '' : '?'}${query}`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(finishedSearch(resp))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
