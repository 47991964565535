import update from 'immutability-helper'

export const TOGGLED_DELETE_MAGAZINE = 'TOGGLED_DELETE_MAGAZINE'

export const handleToggledDeleteMagazine = (state, action) => update(state, {
    deleteMagazine: {
        isOpen: {$set: action.state},
        magazineId: {$set: action.magazineId}
    }
})

export function toggledDeleteMagazine (
    state = undefined,
    magazineId = undefined
) {
    return {
        type: TOGGLED_DELETE_MAGAZINE,
        state,
        magazineId
    }
}

export const openDeleteMagazine = (
    magazineId = undefined
) => {
    return (dispatch, getState) => {
        dispatch(toggledDeleteMagazine(true, magazineId))
    }
}

export const closeDeleteMagazine = () => {
    return (dispatch, getState) => {
        dispatch(toggledDeleteMagazine(false, undefined))
    }
}

export const deleteMagazine = { isOpen: false, magazineId: undefined }
