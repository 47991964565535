import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form } from 'react-bootstrap';
import { renderFormField } from '../FormHelper';
import * as classes from './CustomInvoiceNbrForm.module.scss';

const validate = values => {
    const errors = {}
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

function CustomInvoiceNbrForm({ handleSubmit, handleChange, defaultNumber, orderType }) {

    // const defaultValue = parseInt(defaultNumber)

    return (
        <>
            <Form.Group controlId="customNumber" className={classes.container}>
                <p>Här kan ange ett annat {orderType}nummer efter egen önskan. Om du <u>inte</u> anger ett {orderType}nummer här så kommer ordernummer användas istället.</p>
                <Field
                    name='customNumber'
                    label={`Ange valfritt ${orderType}nummer här`}
                    id='customNumber'
                    component={renderFormField}
                    onChange={(evt) => { handleChange(evt.target.value) }} />
            </Form.Group>
        </>
    );
}

export default connect(
    state => ({
    })
)(
    reduxForm({
        form: 'customInvoiceNbr',
        validate,
        warn
    })(CustomInvoiceNbrForm)
);