import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { categoryColumns } from './CategoryTable';
import { ACTIVE, updateCategory } from '../../reducers/modules/categories';
import { useDebounce } from 'use-debounce';

function CategoryRow({ row, handleLock, handleDelete, skip = [], updateCategory }) {
    const columns = [...categoryColumns].filter((col, index) => !skip.includes(col) && !skip.includes(index));
    const activeIcon = parseInt(row.active) === ACTIVE ? 'unlock' : 'lock';
    const [name, setName] = useState(undefined);
    const [debouncedName] = useDebounce(name, 1000);
    const [update, setUpdate] = useState(false);

    const handleNameChange = (event) => {
        setName(event.target.value);
        setUpdate(true);
    }

    useEffect(() => {
        if (debouncedName && update) {
            setUpdate(false);
            updateCategory(row.id, { name: debouncedName });
        }
    }, [debouncedName, updateCategory, row, update])

    return (
        <tr>
            {(columns.indexOf(categoryColumns[0]) !== -1) && (<td>{row.id}</td>)}
            {(columns.indexOf(categoryColumns[1]) !== -1) && (<td><Form.Control defaultValue={row.name} onChange={handleNameChange} /></td>)}
            {(columns.indexOf(categoryColumns[2]) !== -1) && (<td align='center'><FontAwesomeIcon icon={activeIcon} onClick={() => handleLock(row.id, parseInt(row.active) === ACTIVE)} /></td>)}
            {(columns.indexOf(categoryColumns[3]) !== -1) && (<td align='center'><FontAwesomeIcon icon='times' onClick={() => handleDelete(row.id)} /></td>)}
        </tr>
    );
}

export default connect((state) => ({
}), {
    updateCategory
})(CategoryRow)
