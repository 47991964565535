import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import queryString from 'query-string';
import store from 'store';
import ReceiptTable from '../../components/ReceiptTable';
import CartTable from '../../components/CartTable';
import renderLoading from '../../renderLoading';
import { getOrderDocument, getStorePaymentInformation } from '../../orderHelper';
import { loadOrder } from '../../reducers/modules/user';
import { clearCheckoutState } from '../../reducers/modules/checkout';
import { isStore } from '../../PermissionsHelper';
import { openPrintReceipt } from '../../reducers/modules/modals';
// import * as classes from './AccountReceiptView.module.scss';

function AccountReceiptView(props) {

    const { user, loadOrder, location, match, openPrintReceipt } = props;

    const [loadedOrder, setLoadedOrder] = useState(false);

    const [printed, setPrinted] = useState(false);

    useEffect(() => {
        if (user.data && !loadedOrder) {
            const { id: userId } = user.data;
            const { search } = location;

            // First try to parse from url-params
            const { orderid } = queryString.parse(search);

            if (!orderid) {
                // If it doesn't exist, parse it from the url
                const { orderId } = match.params;
                loadOrder(userId, orderId);
            } else {
                loadOrder(userId, orderid);
            }

            setLoadedOrder(true)
        }

        // dispatch(clearCheckoutState())
    }, [loadOrder, location, match, user, loadedOrder]);

    const { data: orders } = user.orders;

    const { match: { params: { orderId } } } = props;

    const { search } = location;
    const { txnid, /*amount, currency, date, time, txnfee, paymenttype, cardno, hash*/ } = queryString.parse(search);

    const index = orders.map(o => o.id).indexOf(parseInt(orderId));
    const order = orders[index] || {};

    const xTbToken = store.get('tbwt');

    let orderDocument = getOrderDocument(order, xTbToken);

    if (isStore(user)) {
        orderDocument = getStorePaymentInformation(orderId, xTbToken, true);
    }

    useEffect(() => {
        if (loadedOrder && order && order.id && !printed && isStore(user)) {
            openPrintReceipt(order);

            setPrinted(true);
        }
    }, [loadedOrder, printed, setPrinted, user, order, openPrintReceipt]);

    return (
        <Container>
            <Row>
                <Col>
                    <Card body>
                        {(!order) && renderLoading()}
                        {(order) && <h4>Kvitto</h4>}
                    </Card>
                    <Card body>
                        {(!order) && renderLoading()}
                        {(order) && <ReceiptTable order={{txnid, ...order, ...queryString.parse(search)}} />}

                        {/* This should probably point to API URL instead. */}
                        {orderDocument && orderDocument}
                    </Card>
                    <Card body>
                        <h4>Innehåll</h4>

                        {!(order && order.cart) && renderLoading()}
                        {order && order.cart && <CartTable editable={false} deleteable={false} cartData={order.cart} />}
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

const mapStateToProps = (state, ownProps) => ({
    user: state.user
})
export default connect((mapStateToProps), {
    loadOrder,
    openPrintReceipt,
    clearCheckoutState
})(AccountReceiptView)
