import { updateUser } from './UpdateUser';

export const deactivateUser = (userId = undefined) => setUserActive(userId, false);

export const activateUser = (userId = undefined) => setUserActive(userId, true);

export const setUserActive = (
    userId = undefined,
    locked = undefined
) => updateUser(userId, {activate: (locked ? 1 : 0)});
