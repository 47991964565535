import request from 'reqwest';
import store from 'store';
import { URI, PROTOCOL } from '../api';
import { pushUpdating, popUpdating } from './index';
import { createdMagazine } from './CreateMagazine';
import { updatedMagazine } from './UpdateMagazine';

/**
 * Inititator
 * 
 * @param {*} userId 
 */
export const loadMagazine = (
    magazineId = 0
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/magazines/${magazineId}`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    const { data } = getState().magazines
                    const index = data.map(m => m.id).indexOf(parseInt(resp.data.id))
                    if (index > -1) {
                        dispatch(updatedMagazine(resp.data, index))
                    } else {
                        dispatch(createdMagazine(resp.data))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
