import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Card, Accordion, Button } from 'react-bootstrap';
import CheckoutStageOne from '../../components/CheckoutStageOne';
import CheckoutStageTwo from '../../components/CheckoutStageTwo';
import CheckoutStageThree from '../../components/CheckoutStageThree';
import renderLoading from '../../renderLoading';
import { prevStage, nextStage, changeCheckoutStage, setCartId, setAddress, clearCheckoutState, dispatchRemoteSubmitHack } from '../../reducers/modules/checkout';
import { createOrder } from '../../reducers/modules/user';
import * as classes from './AccountCheckoutView.module.scss';
import { setPaymentMethod } from '../../reducers/modules/checkout'
import { PRE_PAYMENT } from '../../components/ReceiptTable'

const buttons = (handlePrev, handleNext) => (
    <>
        {handlePrev && <Button variant="danger" onClick={handlePrev} className={classes.stageButton}>Gå tillbaka</Button>}
        <Button variant="success" onClick={handleNext} className={classes.stageButton}>Fortsätt</Button>
    </>
)

const renderAccordionCard = (card, key, callbacks, isUpdating) => {
    const { handlePrev, handleNext } = callbacks;

    return (
        <Card key={key}>
            {isUpdating > 0 && renderLoading()}
            <Accordion.Toggle as={Card.Header} eventKey={key}>
                <h5>{card.heading}</h5>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={key}>
                <Card.Body>
                    {card.contents}
                    {buttons((key === 0 ? null : handlePrev), handleNext)}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

const renderAccordionCards = (cards, callbacks, isUpdating) => (
    cards.map((card, key) => renderAccordionCard(card, key, callbacks, isUpdating))
)

function AccountCheckoutView(props) {

    const {
        user,
        checkout,
        countries,
        settings,
        postages,
        prevStage,
        nextStage,
        setCartId,
        setAddress,
        clearCheckoutState,
        dispatchRemoteSubmitHack,
        setPaymentMethod
    } = props;

    const handlePrev = () => {
        const { stage } = checkout;
    
        if (stage > 0) {
            prevStage();
    
            if (stage === 1) {
                clearCheckoutState();
            }
        }
    }
  
    const handleNext = () => {
        if (user?.permissions?.includes("store")) {
            // Immediately go to final stage for store users!
            setCartId(user.carts?.[0].id);

            const { address } = user;
            const { items } = countries;
            const countryIndex = items.map(c => c.id).indexOf(address.country_id);
            const countryName = items[countryIndex].short_name;
            setAddress({
                street: address.street,
                zip: address.zip,
                city: address.city,
                country: countryName
            });

            setPaymentMethod(PRE_PAYMENT, true);

            return
        }

        const { stage, useAlternativeAddress } = checkout;
        
        switch (stage) {
            case 0:
                const cart = user.carts[0];
                const { id: cartId } = cart;
                setCartId(cartId);
                nextStage();
                break;
    
            case 1:
                if (useAlternativeAddress) {
                    // This will dispatch the submit for the form, and the form will then set the value in the state
                    dispatchRemoteSubmitHack('alternativeAddress')
                } else {
                    const { address } = user;
                    const { items } = countries;
                    const countryIndex = items.map(c => c.id).indexOf(address.country_id);
                    const countryName = items[countryIndex].short_name;
                    setAddress({
                        street: address.street,
                        zip: address.zip,
                        city: address.city,
                        country: countryName
                    });
                    nextStage();
                }
                break;
    
            case 2:
                // This will dispatch the submit for the form, and the form will then set the value in the state
                dispatchRemoteSubmitHack('paymentSelection');
                break;
            
            default:
                console.warn('Unknown stage, ignoring');
                return;
        }
    }

    const { address, isUpdating, permissions } = user;
    const { stage } = checkout;

    if (!user || !user.carts || !user.permissions) {
        return (
            <Container>
                <Row>
                    <Col>
                        {renderLoading()}
                        <Accordion activeKey={stage}>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        );
    }

    const cart = user.carts[0];
    const isStore = permissions.includes('store');

    const cards = [
        { heading: 'Kontrollera din beställning', contents: (<CheckoutStageOne cartData={cart.data} settings={settings} address={address} postages={postages} isStore={isStore} />) },
        { heading: 'Kontrollera din leveransadress', contents: (<CheckoutStageTwo />) },
        { heading: 'Välj betalningsmetod', contents: (<CheckoutStageThree {...props} />) }
    ];

    const callbacks = {
        handlePrev,
        handleNext
    };
    

    return (
        <Container>
            <Row>
                <Col>
                    <Accordion activeKey={stage}>
                        {renderAccordionCards(cards, callbacks, isUpdating)}
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
}

export default withRouter(connect((state, ownProps) => ({
    user: state.user,
    settings: state.settings,
    postages: state.postages,
    checkout: state.checkout,
    countries: state.countries
}), {
    prevStage,
    nextStage,
    changeCheckoutStage,
    createOrder,
    setCartId,
    setAddress,
    clearCheckoutState,
    dispatchRemoteSubmitHack,
    setPaymentMethod
})(AccountCheckoutView));
