import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm, initialize } from 'redux-form';
import { Form } from 'react-bootstrap';
import { renderForm } from '../../components/FormHelper';
// import * as classes from './AddressForm.module.scss';

const validate = values => {
    const errors = {}
    if (!values.street) {
        errors.street = 'Obligatoriskt'
    } else if (values.street.length < 1) {
        errors.street = 'Din gatuadress måste vara ifylld'
    }
    if (!values.zip) {
        errors.zip = 'Obligatoriskt'
    } else if (values.zip.length < 1) {
        errors.zip = 'Ditt postnummer måste vara ifyllt'
    }
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

function AddressForm(props) {

    const { address, countries, handleSubmit, initialize } = props;
    
    useEffect(() => {
        if (address) {
            initialize(address)
        } else {
            initialize({
                country_id: 215
            })
        }
    }, [address, initialize]);

    const { items } = countries;
    const countriesOptions = items.map((country) => (
        <option key={country.id} value={country.id}>{country.short_name}</option>
    ));

    const fields = [
        { name: 'street', label: 'Gatuadress', fieldType: 'field' },
        [
            { name: 'zip', label: 'Postnummer', parts: '4', fieldType: 'field' },
            { name: 'city', label: 'Ort', fieldType: 'field' }
        ],
        { name: 'country_id', label: 'Land', as: 'select', children: countriesOptions, fieldType: 'field' },
        { name: 'submit', variant: 'dark', label: 'Spara', fieldType: 'submit' }
    ];

    return (
        <>
            <Form onSubmit={handleSubmit}>
                {renderForm(fields)}
            </Form>
        </>
    );
}

export default connect(
    (state, ownProps) => ({
        countries: state.countries,
        initialize,
        initialValues: ownProps.address
    })
)(
    reduxForm({
        form: 'address',
        validate,
        warn,
        enableReinitialize : true
    })(AddressForm)
);
