import update from 'immutability-helper'

export const TOGGLED_LOGIN = 'TOGGLED_LOGIN'

export const handleToggledLogin = (state, action) => update(state, {
    login: {isOpen: {$set: action.state}}
})

export function toggledLogin (
    state = undefined
) {
    return {
        type: TOGGLED_LOGIN,
        state: state
    }
}

export const openLogin = () => {
    return (dispatch, getState) => {
        dispatch(toggledLogin(true))
    }
}

export const closeLogin = () => {
    return (dispatch, getState) => {
        dispatch(toggledLogin(false))
    }
}

export const login = { isOpen: false }
