import request from 'reqwest'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'
import { loadPermissions } from './LoadPermissions'
import { loadData } from './LoadData'
import { successfulLogin } from './Login'
import { history } from '../../../configureStore'
import { pushAlert } from '../alerts'

/**
 * Initiator
 * 
 * @param {*} user 
 */
export const register = (user) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/auth/register`,
                method: 'POST',
                crossOrigin: true,
                headers: {},
                data: user,
                error (err) {
                    console.error(JSON.stringify(err))
                    const { responseText } = err
                    const { heading, messages } = JSON.parse(responseText)
                    if (Array.isArray(messages)) {
                        dispatch(pushAlert(heading, messages.join('\n')))
                    } else {
                        dispatch(pushAlert(heading, messages))
                    }
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    if (resp.data) {
                        dispatch(successfulLogin(resp.data))
                        dispatch(loadPermissions())
                        dispatch(loadData('me'))

                        history.push('/')
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
