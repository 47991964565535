import update from 'immutability-helper'
import { v4 as uuid } from 'uuid'

export const PUSHED_ALERT = 'PUSHED_ALERT'
// export const READ_ALERT = 'READ_ALERT'

export const pushAlert = (
    heading = undefined,
    alert = undefined,
    uid = uuid(),
) => ({
    type: PUSHED_ALERT,
    payload: {
        heading,
        alert,
        uid,
    },
})

export const handlePushedAlert = (state, action) =>
    update(state, {
        alerts: { $push: [action.payload] },
    })
