/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm, submit } from 'redux-form'
import { Form, Col, InputGroup } from 'react-bootstrap'

// App components

// State
// ...

// Styles
// import * as classes from './IncludeOldIssuesForm.module.scss'

// Constants
// ...

export const renderAutoSubmitSwitch = ({
  input,
  namePrefix,
  showLeftLabel,
  label,
  meta: { touched, error, warning, dispatch }
}) => {
  return (<Form.Group as={Col} controlId={`${(namePrefix ? namePrefix : '')}${input.name}`}>
    <InputGroup>
      {showLeftLabel && <Form.Label column sm={6}>{label}</Form.Label>}
      <Form.Check
        {...input} 
        onChange={value => {
          input.onChange(value)
          setTimeout(() => {dispatch(submit('includeOld'))}, 5)
        }} 
        onBlur={() => input.onBlur(input.value)}
        type="switch"
        label={label} />
    </InputGroup>
  </Form.Group>)
}

class IncludeOldIssuesForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.yourSubmitHandler = this.yourSubmitHandler.bind(this)
  }

  handleChange (val) {
    // this.props.onSubmit(val)
  }

  yourSubmitHandler () {
  }

  render () {
    const {
      handleSubmit, //dispatch, submit
    } = this.props
    try {
      return (
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <Field
              name="includeOldIssues"
              label="Visa även tidskrifter vars utgivning upphört"
              component={renderAutoSubmitSwitch}/>
          </Form.Row>
        </Form>)
    } catch (error) {
      return (<p>error</p>)
    }
  }
}

export default reduxForm({
  form: 'includeOld',
  onSubmit: submit
})(IncludeOldIssuesForm)
