import update from 'immutability-helper'

export const POPPED_TOAST = 'POPPED_TOAST'

export const popToast = (uid = undefined, pop_date = new Date()) => ({
    type: POPPED_TOAST,
    payload: {
        uid,
    },
})

export const handlePoppedToast = (state, action) => {
    const { toasts } = state
    const index = toasts.map(a => a.uid).indexOf(action.payload.uid)

    return update(state, { toasts: { $splice: [[index, 1]] } })
}
