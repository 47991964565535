/* @flow */
import update from 'immutability-helper'
import store from 'store'

// Register new user
import {
    register
} from './Register'

// Request new password for user
import {
    requestPassword
} from './RequestPassword'

// Reset password for user
import {
    resetPassword
} from './ResetPassword'

// Login
import {
    login,
    SUCCESS_LOGIN,
    FAILED_LOGIN,
    handleSuccessLogin,
    handleFailedLogin
} from './Login'

// Logout
import {
    logout,
    SUCCESS_LOGOUT,
    handleSuccessLogout
} from './Logout'

// Load user carts
import {
    loadCarts,
    LOADED_USER_CARTS,
    handleLoadedCarts
} from './LoadCarts'

// Load user carts
import {
    createCart,
    CREATED_CART,
    handleCreatedCart
} from './CreateCart'

// Update cart
import {
    updateCart,
    UPDATED_CART,
    handleUpdatedCart
} from './UpdateCart'

// Add issue to cart
import {
    addIssue,
} from './AddIssue'

// Add issue to cart
import {
    setQuantity,
} from './SetQuantity'

// Add issue to cart
import {
    removeIssue,
} from './RemoveIssue'

// Load user data
import {
    loadData,
    LOADED_USER_DATA,
    handleLoadedData
} from './LoadData'

// Update user data
import {
    updateData
} from './UpdateData'

// Load user address
import {
    loadAddress,
    LOADED_USER_ADDRESS,
    handleLoadedAddress
} from './LoadAddress'

// Update user address
import {
    updateAddress
} from './UpdateAddress'

// Update user permissions
import {
    updatePermissions
} from './UpdatePermissions'

// Load user address
import {
    loadPermissions,
    LOADED_PERMISSIONS,
    handleLoadedPermissions
} from './LoadPermissions'

// Place orders
import {
    createOrder,
    CREATED_ORDER,
    handleCreatedOrder
} from './CreateOrder'

// Load user orders
import {
    loadOrders,
    LOADED_USER_ORDERS,
    handleLoadedOrders
} from './LoadOrders'

// Load user order
import {
    loadOrder
} from './LoadOrder'

// Load user order
import {
    updateOrder,
    UPDATED_USER_ORDER,
    handleUpdatedOrder
} from './UpdateOrder'

// Add to shortlist
import {
    shortlist
} from './Shortlist'

// Load shortlist
import {
    loadShortlist,
    LOADED_SHORTLIST,
    handleLoadedShortlist
} from './LoadShortlist'

// Remove from shortlist
import {
    removeFromShortlist,
    REMOVED_FROM_SHORTLIST,
    handleRemovedFromShortlist
} from './RemoveFromShortlist'

export { register, login, logout, requestPassword, resetPassword }
export { loadData, updateData }
export { loadAddress, updateAddress }
export { loadPermissions, updatePermissions }
export { loadCarts, updateCart, addIssue, setQuantity, removeIssue, createCart }
export { createOrder, loadOrders, loadOrder, updateOrder }
export { shortlist, loadShortlist, removeFromShortlist }

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATING_USER = 'UPDATING_USER'

const INCREMENT = 1;
const DECREMENT = 2;

// ------------------------------------
// Actions
// ------------------------------------
export function pushUpdating () {
    return {
        type: UPDATING_USER,
        payload: INCREMENT
    }
}

export function popUpdating () {
    return {
        type: UPDATING_USER,
        payload: DECREMENT
    }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [UPDATING_USER]: (state, action) => {
        if (INCREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating + 1}})
        } else if (DECREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating - 1}})
        } else {
            return state
        }
    },

    [SUCCESS_LOGIN]: (state, action) => handleSuccessLogin(state, action),

    [FAILED_LOGIN]: (state, action) => handleFailedLogin(state, action),
    
    [SUCCESS_LOGOUT]: (state, action) => handleSuccessLogout(state, action),

    [LOADED_USER_DATA]: (state, action) => handleLoadedData(state, action),

    [LOADED_USER_ADDRESS]: (state, action) => handleLoadedAddress(state, action),

    [LOADED_PERMISSIONS]: (state, action) => handleLoadedPermissions(state, action),

    [LOADED_USER_CARTS]: (state, action) => handleLoadedCarts(state, action),

    [CREATED_CART]: (state, action) => handleCreatedCart(state, action),

    [UPDATED_CART]: (state, action) => handleUpdatedCart(state, action),

    [CREATED_ORDER]: (state, action) => handleCreatedOrder(state, action),

    [LOADED_USER_ORDERS]: (state, action) => handleLoadedOrders(state, action),

    [UPDATED_USER_ORDER]: (state, action) => handleUpdatedOrder(state, action),

    [LOADED_SHORTLIST]: (state, action) => handleLoadedShortlist(state, action),

    [REMOVED_FROM_SHORTLIST]: (state, action) => handleRemovedFromShortlist(state, action),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    isUpdating: 0,
    token: store.get('tbwt'),
    data: undefined,
    address: undefined,
    carts: undefined,
    orders: {
        data: [],
        links: undefined,
        meta: undefined
    },
    shortlists: {
        data: [],
        links: undefined,
        meta: undefined
    }
}

export default function userReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : state
}
