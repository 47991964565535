import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { friendColumns } from './FriendsTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateFriend } from '../../reducers/modules/friends';
import { useDebounce } from 'use-debounce';

function FriendRow({ row, handleDelete, skip = [], updateFriend }) {

    const [update, setUpdate] = useState(false);

    const [name, setName] = useState(undefined);
    const [debouncedName] = useDebounce(name, 1000);

    const [url, setUrl] = useState(undefined);
    const [debouncedUrl] = useDebounce(url, 1000);

    useEffect(() => {
        if ((debouncedName || debouncedUrl) && update) {
            setUpdate(false);
            if (debouncedName) {
                updateFriend(row.id, { name: debouncedName });
            }
            
            if (debouncedUrl) {
                updateFriend(row.id, { url: debouncedUrl });
            }
        }
    }, [debouncedName, debouncedUrl, updateFriend, row, update, setUpdate]);

    const handleNameChange = (event) => {
        setName(event.target.value);
        setUpdate(true);
    }

    const handleUrlChange = (event) => {
        setUrl(event.target.value);
        setUpdate(true);
    }

    const columns = [...friendColumns].filter((col, index) => !skip.includes(col) && !skip.includes(index));

    return (
        <tr>
            {(columns.indexOf(friendColumns[0]) !== -1) && (<td>{row.id}</td>)}
            {(columns.indexOf(friendColumns[1]) !== -1) && (<td><Form.Control defaultValue={row.name} onChange={handleNameChange} /></td>)}
            {(columns.indexOf(friendColumns[2]) !== -1) && (<td><Form.Control defaultValue={row.url} onChange={handleUrlChange} /></td>)}
            {(columns.indexOf(friendColumns[3]) !== -1) && (<td align='center'><FontAwesomeIcon icon='times' onClick={() => handleDelete(row.id)} /></td>)}
        </tr>
    );
}

export default connect((state) => ({
}), {
    updateFriend
})(FriendRow);
