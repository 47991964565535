/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Row, Col, Card } from 'react-bootstrap'

// App components
import AdminPages from '../../components/AdminPages'
import OrbitsSettingsForm from '../../components/OrbitsSettingsForm'
import OrbitUploadForm from '../../components/OrbitUploadForm'
import OrbitAdminItem from '../../components/OrbitAdminItem'
import renderLoading from '../../renderLoading'

// State
import { loadOrbits, removeOrbit, activateOrbit, deactivateOrbit, loadOrbitSettings } from '../../reducers/modules/orbits'

// Styles
// import * as classes from './AdminOrbitsView.module.scss'

// Constants
// ...

export class AdminOrbitsView extends React.Component {
    static propTypes = {
        orbits: PropTypes.object.isRequired,

        loadOrbits: PropTypes.func.isRequired,
        removeOrbit: PropTypes.func.isRequired,
        activateOrbit: PropTypes.func.isRequired,
        deactivateOrbit: PropTypes.func.isRequired,
        loadOrbitSettings: PropTypes.func.isRequired
    }

    constructor (props) {
        super(props)

        this.handleDeleteItem = this.handleDeleteItem.bind(this)
        this.handleChangeItem = this.handleChangeItem.bind(this)
        this.handleActivateItem = this.handleActivateItem.bind(this)
        this.handleDeactivateItem = this.handleDeactivateItem.bind(this)
    }

    handleDeleteItem (orbitId) {
        this.props.removeOrbit(orbitId)
    }

    handleChangeItem (orbitId) {
        console.log(`change ${orbitId}`)
    }

    handleActivateItem (orbitId) {
        this.props.activateOrbit(orbitId)
    }

    handleDeactivateItem (orbitId) {
        this.props.deactivateOrbit(orbitId)
    }

    componentDidMount () {
        this.props.loadOrbits()
        this.props.loadOrbitSettings()
    }

    render () {
        try {
            const { isUpdating } = this.props.orbits
            const { data: settings } = this.props.orbits.settings
            const setting = settings[0]

            const { data: images } = this.props.orbits.images

            return (
                <Container>
                    <Row>
                        <Col xs={2}>
                            <AdminPages />
                        </Col>
                        <Col xs={10}>
                            <Card body>
                                <h4>Bildhantering</h4>
                                <OrbitsSettingsForm setting={setting} />
                            </Card>

                            <Row>
                                <Col>
                                    <Card body>
                                        <OrbitUploadForm />
                                    </Card>
                                </Col>
                                <Col>
                                    <Card body>
                                        {isUpdating > 0 && renderLoading()}
                                        {images.length > 0 && images.map(orbit => <OrbitAdminItem
                                            key={`orbitItem.${orbit.id}`}
                                            orbit={orbit}
                                            onDelete={this.handleDeleteItem}
                                            onChange={this.handleChangeItem}
                                            onActivate={this.handleActivateItem}
                                            onDeactivate={this.handleDeactivateItem} />)}
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            )
        } catch (err) {
            console.error(err)
            return (<div />)
        }
    }
}

const mapStateToProps = (state) => ({
    orbits: state.orbits
})
export default connect((mapStateToProps), {
    loadOrbits,
    removeOrbit,
    activateOrbit,
    deactivateOrbit,
    loadOrbitSettings
})(AdminOrbitsView)
