import React from 'react'
import { unserialize } from 'php-serialize'
import { URI, PROTOCOL } from './reducers/modules/api'
import { CARD_PAYMENT, INVOICE_PAYMENT, PRE_PAYMENT } from './components/ReceiptTable'

export { unserialize }

export const urldecode = (str) => {
    return decodeURIComponent((str + '').replace(/\+/g, '%20'))
}

/**
 * Get all issues of the cart.
 * 
 * @param {*} order 
 */
export const getAddress = (order) => {
    const { address, extraaddress } = order;
    
    let shippingAddress = undefined;

    if (extraaddress) {
        shippingAddress = urldecode(extraaddress)
    } else {
        shippingAddress = urldecode(address)
    }
    const unserialized = unserialize(shippingAddress)
    return Object.keys(unserialized).map((key) => unserialized[key])
}

export const fetchAction = (orderId, xTbToken) => {
    setTimeout(() => {
        const response = {
            file: `${PROTOCOL}://${URI}/receipt/${orderId}?X-Tb-Token=${xTbToken}`,
        }
        window.open(response.file)
    }, 100);
}

export const getReceipt = ({ id }, xTbToken, showOrderId) => (
    <a href={`/receipt/${id}`} target='_blank' rel='noopener noreferrer' onClick={(e) => {
        e.preventDefault()

        fetchAction(id, xTbToken)
    }} >{showOrderId === true ? id : `Hämta kvitto (Order ${id})`}</a>
)

export const getInvoice = ({ id, processedAt }, xTbToken, showOrderId) => {
    if (processedAt === null) {
        return undefined
    }

    return (<a href={`/receipt/${id}`} target='_blank' rel='noopener noreferrer' onClick={(e) => {
        e.preventDefault()

        fetchAction(id, xTbToken)
    }} >{showOrderId === true ? id : `Hämta faktura (Order ${id})`}</a>)
}

export const getPaymentInformation = ({ id }, xTbToken, showOrderId) => (
    <a href={`/receipt/${id}`} target='_blank' rel='noopener noreferrer' onClick={(e) => {
        e.preventDefault()

        fetchAction(id, xTbToken)
    }} >{showOrderId === true ? id : `Hämta betalningsinformation (Order ${id})`}</a>
)

export const getStorePaymentInformation = ({ id }, xTbToken, showOrderId) => (
    <a href={`/receipt/${id}`} target='_blank' rel='noopener noreferrer' onClick={(e) => {
        e.preventDefault()

        fetchAction(id, xTbToken)
    }} >{showOrderId === true ? id : `Skriv ut kvitto (Order ${id})`}</a>
)

export const getOrderDocument = (order, xTbToken, showOrderId = false) => {
    const { checkoutmethod } = order

    if (typeof checkoutmethod === 'undefined') {
        return undefined
    }

    switch (parseInt(checkoutmethod)) {
        case CARD_PAYMENT:
            return getReceipt(order, xTbToken, showOrderId)
        case INVOICE_PAYMENT:
            const { invoice_number } = order || undefined
            if (!invoice_number || parseInt(order.invoice_number) <= 0 ) {
                return undefined
            } else {
                return getInvoice(order, xTbToken, showOrderId)
            }
        case PRE_PAYMENT:
            return getPaymentInformation(order, xTbToken, showOrderId)
        default:
            return undefined
    }
}
