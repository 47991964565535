import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Img from 'react-image';
import renderLoading from '../../renderLoading';
import * as classes from './CreateItem.module.scss';

const getCreateUrl = (issue, categoryId) => {
    console.log('getCreateUrl')
    console.log(issue)
    console.log(categoryId)
    if (issue && issue.magazine) {
        // Create a new issue, for the specified magazine
        const { magazine } = issue;
        const { id: magazineId } = magazine;
        return `/magazines/${magazineId}/newissue`;
    }

    // Create a new magazine
    return `/categories/${categoryId}/newmagazine`;
}

function CreateItem(props) {

    const { image, issue, categoryId } = props;

    const createUrl = getCreateUrl(issue, categoryId);

    return (
        <Link to={createUrl}>
            <Img
                src={image}
                className={classes.image}
                loader={renderLoading('info', true)}
            />
        </Link>
    );
}

export default connect((state, ownProps) => ({
}), {
})(CreateItem)
