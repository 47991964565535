/* @flow */

// Framework
import React from 'react'
import { Form, Col, InputGroup } from 'react-bootstrap'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const styles = {
  input: styles => ({ width: '100%' }),
}

export const renderFormSearch = ({
  input,
  namePrefix,
  options,
  meta: { touched, error, warning }
}) => (
  <Form.Group as={Col} controlId={`${(namePrefix ? namePrefix : '')}${input.name}`}>
    <InputGroup>
    <div style={{width: '100%'}}>
      <Select
        {...input} 
        onChange={value => input.onChange(value)} 
        onBlur={() => input.onBlur(input.value)}
        isMulti={true}
        styles={styles}
        options={options}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.title}
        components={animatedComponents}
      />
    </div>
    </InputGroup>
  </Form.Group>
)
