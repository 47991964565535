import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'
import { loadAddress } from './LoadAddress'
import { loadCarts } from './LoadCarts'
import { loadPermissions } from './LoadPermissions'
import { logout } from './Logout'

/**
 * Actions
 */
export const LOADED_USER_DATA = 'LOADED_USER_DATA'

/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export const loadedData = (
    user = {}
) => ({
    type: LOADED_USER_DATA,
    payload: user
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadedData = (state, action) => update(state, {
    data: {$set: action.payload}
})


/**
 * Initiator
 * 
 * @param {*} userId 
 */
export const loadData = (
    userId = 0
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/users/${userId}`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    dispatch(logout())
                    resolve()
                },
                success (resp) {
                    if (resp.data) {
                        dispatch(loadedData(resp.data))
                        dispatch(loadAddress(resp.data.id))
                        dispatch(loadCarts(resp.data.id))
                        dispatch(loadPermissions())
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
    