import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, initialize, change } from 'redux-form';
import {  Form } from 'react-bootstrap';
import Barcode from 'react-barcode';
import { renderForm } from '../../../components/FormHelper';
import { URI_UPLOAD } from '../../../reducers/modules/api';
import { URI, PROTOCOL } from '../../../reducers/modules/api';
import store from 'store';
// import * as classes from './IssueDetailsForm.module.scss';
import axios from 'axios';

const validate = values => {
    const errors = {};
    if (!values.editions) {
        errors.editions = 'Obligatorisk';
    }
    return errors;
}

const warn = values => {
  const warnings = {}
  return warnings
}

function IssueDetailsForm({ handleSubmit, issue, barcode, categories, submitting, pristine, initialize, handleChange, change }) {

    const [uploadedCover, setUploadedCover] = useState(undefined);
    const [cover, setCover] = useState(undefined);
    const [loadedCover, setLoadedCover] = useState(false);

    const handleOnDrop = (files) => {        
        var formData = new FormData();
        formData.append('cover', files[0]);

        try {
            axios({
                method: "post",
                url: `${PROTOCOL}://${URI}/covers`,
                data: formData,
                headers: {
                    'X-Tb-Token': store.get('tbwt'),
                    "Content-Type": "multipart/form-data"
                },
            }).then((resp) => {
                setUploadedCover(resp.data.data.filename);
            })
        } catch(error) {
            console.warn(error)
        }
    }

    useEffect(() => {
        if (!loadedCover && issue && issue.cover && issue.cover.name) {
            const filename = issue.cover.name;
            setCover({
                name: filename,
                preview: `http://${URI_UPLOAD}/covers/${filename}`
            })
            setLoadedCover(true);
        }
    }, [issue, loadedCover])

    useEffect(() => {
        if (uploadedCover) {
            setCover({
                name: uploadedCover,
                preview: `http://${URI_UPLOAD}/covers/${uploadedCover}`,
                size: 0
            })
        }
    }, [uploadedCover])

    useEffect(() => {
        if (cover) {
            change("cover", {
                name: cover.name
            })
        }
    }, [cover, change])

    const withLabel = true;

    const { data } = categories || [];
    const categoryOptions = data.map((category) => (
        <option key={category.id} value={category.id}>{category.name}</option>
    ));

    const fields = [
        { name: 'issue.cover.name', label: 'Omslag', imagefile: cover, showLabel: false, handleOnDrop: handleOnDrop, fieldType: 'dropzone', onChange: handleChange },
        { name: 'magazine.category_id', label: 'Kategori', as: 'select', children: categoryOptions, fieldType: 'field', onChange: handleChange },
        { name: 'magazine.issues_anually', label: 'Nummer per år:', showLabel: withLabel, fieldType: 'field', onChange: handleChange },
        { name: 'yearly_issue', label: 'Akt. nummer:', showLabel: withLabel, fieldType: 'field', onChange: handleChange },
        { name: 'year', label: 'Akt. år:', showLabel: withLabel, fieldType: 'field', onChange: handleChange },
        { name: 'total_issue', label: 'Löpnummer:', showLabel: withLabel, fieldType: 'field', onChange: handleChange },
        { name: 'magazine.issn', label: 'Issn:', showLabel: withLabel, fieldType: 'field', onChange: handleChange },
        { name: 'magazine.language', label: 'Språk:', showLabel: withLabel, fieldType: 'field', onChange: handleChange },
        { name: 'pages', label: 'Antal sidor:', showLabel: withLabel, fieldType: 'field', onChange: handleChange },
        { name: 'magazine.dimensions', label: 'BBxHH:', showLabel: withLabel, fieldType: 'field', onChange: handleChange },
        { name: 'weight', label: 'Vikt: *', showLabel: withLabel, fieldType: 'field', onChange: handleChange },
        { name: 'editions', label: 'Multipelnummer: *', showLabel: withLabel, fieldType: 'field', onChange: handleChange },
        '-',
        { name: 'stock', label: 'I lager: *', showLabel: withLabel, fieldType: 'field', onChange: handleChange },
        { name: 'sold', label: 'Sålda: *', showLabel: withLabel, fieldType: 'field', onChange: handleChange },
        { name: 'destroyed', label: 'Makulerade: *', showLabel: withLabel, fieldType: 'field', onChange: handleChange },
        { name: 'paid', label: 'Fakturerade: *', showLabel: withLabel, fieldType: 'field', onChange: handleChange },
        '-',
        { name: 'barcode', label: 'streckkod:', showLabel: withLabel, fieldType: 'field', onChange: handleChange },
    ];

    return (
        <>
            <Form onSubmit={handleSubmit}>
                {renderForm(fields)}
    
                <Form.Row>
                    {barcode && <Barcode value={barcode} fontSize={0} />}
                </Form.Row>
            </Form>
        </>
    );
}

const selector = formValueSelector('issueDetails');

export default connect((state, ownProps) => ({
        categories: state.categories,
        barcode: selector(state, 'barcode'),
        initialValues: ownProps.issue,
        initialize
}), {
    change
})
(
    reduxForm({
        form: 'issueDetails',
        validate,
        warn
    })(IssueDetailsForm)
);
