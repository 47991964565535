import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Badge } from 'react-bootstrap';
import CartTable from '../CartTable';
import { getCartIssues, makeCartData } from '../../cartHelper';
import { closeDivideOrder } from '../../reducers/modules/modals';
import { splitOrder } from '../../reducers/modules/orders';
// import * as classes from './DivideOrderModal.module.scss';

function DivideOrderModal(props) {

    const { modal, orders, closeDivideOrder, splitOrder } = props;

    const [loaded, setLoaded] = useState(false);
    const [issues, setIssues] = useState([]);
    const [cartData, setCartData] = useState({});

    useEffect(() => {
        setLoaded(true);
    }, [setLoaded]);

    useEffect(() => {
        const { orderId, isOpen } = modal;
        if (isOpen) {
            const { data } = orders;
            const index = data.map(o => o.id).indexOf(parseInt(orderId));
            const order = data[index];
            const { cart } = order;

            const issues = getCartIssues(cart);

            setIssues(issues);
        } else {
            setIssues([]);
        }
    }, [modal, orders]);

    useEffect(() => {
        if (loaded) {
            setCartData(makeCartData(issues));
        }
    }, [loaded, issues]);

    const handleCancel = () => {
        closeDivideOrder();
    }

    const handleSplit = () => {
        const { orderId } = modal;
        splitOrder(orderId, issues);
        closeDivideOrder();
    }

    const handleRemoveIssue = (issueId, cartId) => {
        const cartIssues = getCartIssues(cartData);
        const index = cartIssues.map(i => parseInt(i.id)).indexOf(parseInt(issueId));
        const newIssues = [...cartIssues.slice(0, index), ...cartIssues.slice(index + 1)];
        setIssues(newIssues);
    }

    const handleUpdateQuantity = (issueId, cartId, quantity) => {
        const cartIssues = getCartIssues(cartData);
        const index = cartIssues.map(i => parseInt(i.id)).indexOf(parseInt(issueId));
        const updatedIssue = {...cartIssues[index], ...{quantity: parseInt(quantity)}};
        const newIssues = [...cartIssues.slice(0, index), updatedIssue, ...cartIssues.slice(index + 1)];
        setIssues(newIssues);
    }
    
    const { isOpen, orderId } = modal;

    if (!isOpen) {
        return (<div />);
    }

    // const { data } = orders;
    // const index = data.map(o => o.id).indexOf(parseInt(orderId));
    // const order = data[index];
    // const { cart } = order;

    return (
        <Modal size='lg' show={isOpen} onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Dela upp order {orderId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Den här dialogen tillåter dig att dela upp en order i två delar. Först väljer du vilka tidskrifter du vill ha kvar i den ursprungliga ordern, resterande tidskrifter kommer flyttas till en ny order.</p>
                <h5>Välj vilka tidskrifter som skall skickas i första ordern</h5>
                <p><Badge variant="danger">OBS! Sätt aldrig ett högre antal i en utgåva än orginal ordern tillåter!</Badge></p>
                <CartTable
                    editable={true}
                    deleteable={true}
                    cartData={cartData}
                    onRemoveIssue={handleRemoveIssue}
                    onUpdateQuantity={handleUpdateQuantity} />
                <Button variant='dark' type='submit' onClick={handleSplit}>Bekräfta</Button>
            </Modal.Body>
        </Modal>
    );
}

export default connect((state) => ({
    modal: state.modals.divideOrder,
    orders: state.orders
}), {
    closeDivideOrder,
    splitOrder
})(DivideOrderModal);
