import { updatePage } from './UpdatePage'

export const lockPage = (pageId = undefined): Functon => setPageActive(pageId, false)

export const unlockPage = (pageId = undefined): Functon => setPageActive(pageId, true)

/**
 * Initiator
 * 
 * @param {*} pageId 
 * @param {*} name
 */
export const setPageActive = (
    pageId = undefined,
    locked = undefined
) => updatePage(pageId, {active: (locked ? 1 : 0)})
    