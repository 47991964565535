import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating, loadCarts } from './index'
import { CARD_PAYMENT, INVOICE_PAYMENT, PRE_PAYMENT } from '../../../components/ReceiptTable'
import { history } from '../../../configureStore'
import { clearCheckoutState } from '../checkout'

/**
 * Actions
 */
export const CREATED_ORDER = 'CREATED_ORDER'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function createdOrder (
    order = undefined
) {
    return {
        type: CREATED_ORDER,
        payload: order
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleCreatedOrder = (state, action) => {
    if (action.payload.constructor.name === "Array") {
        return update(state, {
            orders: {
                data: {$push: action.payload}
            }
        })
    } else {
        return update(state, {
            orders: {
                data: {$push: [action.payload]}
            }
        })
    }
}


/**
 * Inititator
 */
export const createOrder = (
    userId = undefined,
    cartId = undefined,
    paymentMethod = undefined,
    address = undefined,
    useAlternativeAddress = undefined,
    isStore = false
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/users/${userId}/orders`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: {
                    cartId,
                    paymentMethod,
                    address,
                    useAlternativeAddress
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {

                    // Lägg till order!!!
                    dispatch(createdOrder([resp.data.order]))

                    if (parseInt(resp.data.order.checkoutmethod) === CARD_PAYMENT) {
                        // Start the back payment window
                        new window.PaymentWindow(resp.data.merchantData).open()
                    } else if ([INVOICE_PAYMENT, PRE_PAYMENT].includes(parseInt(resp.data.order.checkoutmethod))) {
                        // Initiate a redirect for checkout (go to '/account/checkout/<order-id>')
                        if (isStore) {
                            history.push(`/account/receipt/${resp.data.order.id}`)
                        } else {
                            history.push(`/account/accept/${resp.data.order.id}`)
                        }
                    } else {
                        // This shouldn't happen... the API should have complained
                        console.error('Unknown payment method, ignoring.')
                    }

                    // When an order has been created, make sure to clear the checkout
                    dispatch(clearCheckoutState())

                    // When an order has been created, make sure to reload the cart (clear it)
                    dispatch(loadCarts(userId))

                    // Finally, pop the updating state
                    dispatch(popUpdating())

                    resolve()
                }
            })
        })
    }
}
