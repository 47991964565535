/* @flow */

// Framework
import React from 'react'
import { Form, Col } from 'react-bootstrap'

export const wrapFormCol = ({
  name,
  ...component
}) => {
  return (
    <Form.Group as={Col} controlId={name}>
      {component}
    </Form.Group>)
}
