import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'
import { createdUser } from './CreateUser'

/**
 * Actions
 */
export const UPDATED_USER = 'UPDATED_USER'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function updatedUser (
    user = {},
    index = -1
) {
    return {
        type: UPDATED_USER,
        payload: user,
        index: index
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleUpdatedUser = (state, action) => update(state, {
    data: {
        [action.index]: {$set: action.payload}
    }
})


/**
 * Initiator
 * 
 * @param {*} userId 
 * @param {*} name
 */
export const updateUser = (
    userId = undefined,
    data = undefined
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/users/${userId}`,
                method: 'PUT',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: data,
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    // dispatch(pushMessage('User', 'Failed to update user address', 'danger'))
                    resolve()
                },
                success (resp) {
                    try {
                        const { data: user } = resp
                        const { data: users } = getState().users
                        var index = users.map(u => u.id).indexOf(parseInt(user.id))
                        if (index > -1) {
                            dispatch(updatedUser(user, index))
                        } else {                        
                            dispatch(createdUser(user))
                        }
                    } catch (err) {
                        console.error(err)
                    } finally {
                        dispatch(popUpdating())
                        resolve()
                    }
                }
            })
        })
    }
}
    