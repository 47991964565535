import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import queryString from 'query-string';
import AdminPages from '../../components/AdminPages';
import FilterOrdersYear from '../../components/FilterOrdersYear';
import FilterOrdersMonth from '../../components/FilterOrdersMonth';
import FilterOrdersState from '../../components/FilterOrdersState';
import FilterOrdersPayment from '../../components/FilterOrdersPayment';
import OrdersTable from '../../components/OrdersTable';
import PaginationView from '../../components/PaginationView';
import renderLoading from '../../renderLoading';
import { clearOrders, loadOrders, cancelOrder, restoreOrder } from '../../reducers/modules/orders';
import { openPickOrder, openDivideOrder, openBuybackOrder, openSetTransactionId } from '../../reducers/modules/modals';
// import * as classes from './AdminOrdersView.module.scss';

const defaultFilter = {
    page: 1,
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    processed: 2,
    payment: 3
};

function AdminOrdersView(props) {

    const {
        location,
        history,
        orders,
        openPickOrder,
        openDivideOrder,
        openBuybackOrder,
        clearOrders,
        loadOrders,
        cancelOrder,
        restoreOrder,
        openSetTransactionId
    } = props;

    const [filter, setFilter] = useState(defaultFilter);

    useEffect(() => {
        const { search } = location;
        const args = queryString.parse(search);

        const newFilter = {...filter, ...args};

        // We're only interested in the page in this case
        if (parseInt(newFilter.page) !== parseInt(filter.page)) {
            setFilter(newFilter);
        }
    }, [location, filter]);

    useEffect(() => {
        const urlArgs = queryString.stringify(filter);
        history.push(`?${urlArgs}`);

        clearOrders();
        loadOrders(filter);
    }, [filter, clearOrders, loadOrders, history]);

    const filterYear = (form) => {
        const { year } = form;

        setFilter({
            ...filter,
            ...{year: parseInt(year), page: 1}
        });
    }

    const filterMonth = (form) => {
        const { month } = form;

        setFilter({
            ...filter,
            ...{month: parseInt(month), page: 1}
        });
    }

    const filterState = (state) => {
        setFilter({
            ...filter,
            ...{processed: parseInt(state), page: 1}
        });
    }

    const filterPayment = (payment) => {
        setFilter({
            ...filter,
            ...{payment: parseInt(payment), page: 1}
        });
    }

    const handleDelete = (orderId) => {
        cancelOrder(orderId);
    }

    const handleUndelete = (orderId) => {
        restoreOrder(orderId);
    }

    const handlePickOrder = (orderId) => {
        openPickOrder(orderId);
    }

    const handleDivideOrder = (orderId) => {
        openDivideOrder(orderId);
    }

    const handleBuyback = (orderId) => {
        openBuybackOrder(orderId);
    }

    const handleTransactionId = (orderId) => {
        openSetTransactionId(orderId);
    }

    const { data, isUpdating } = orders;

    return (
        <Container>
            <Row>
                <Col xs={2}>
                    <AdminPages />
                </Col>
                <Col xs={10}>
                    <Card body>
                        <h4>Orderhantering</h4>
                    </Card>

                    <Card body>
                        <FilterOrdersYear onSubmit={filterYear} defaultValue={filter} />
                        <FilterOrdersMonth onSubmit={filterMonth} defaultValue={filter} />
                        <FilterOrdersState handleChange={filterState} defaultValue={filter} />
                        <FilterOrdersPayment handleChange={filterPayment} defaultValue={filter} />
                    </Card>

                    <Card body>
                        {isUpdating > 0 && renderLoading()}
                        {data.length > 0 && <OrdersTable orders={data} path='admin' 
                            handleDelete={handleDelete}
                            handleUndelete={handleUndelete}
                            handlePickOrder={handlePickOrder}
                            handleDivideOrder={handleDivideOrder}
                            handleBuyback={handleBuyback}
                            handleTransactionId={handleTransactionId} />}
                        {data.length > 0 && <PaginationView {...props} data={orders} />}
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default connect((state) => ({
    orders: state.orders
}), {
    clearOrders,
    loadOrders,
    cancelOrder,
    restoreOrder,
    openPickOrder,
    openSetTransactionId,
    openDivideOrder,
    openBuybackOrder
})(AdminOrdersView);
