import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating} from './index'

/**
 * Actions
 */
export const UPDATED_PAGE = 'UPDATED_PAGE'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function updatedPage (
    page = undefined,
    index = undefined
) {
    return {
        type: UPDATED_PAGE,
        payload: page,
        index: index
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleUpdatedPage = (state, action) => update(state, {
    data: {
        [action.index]: {$set: action.payload}
    }
})


/**
 * Initiator
 * 
 * @param {*} pageId 
 * @param {*} name
 */
export const updatePage = (
    pageId = undefined,
    data = undefined
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/pages/${pageId}`,
                method: 'PUT',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: data,
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    const index = getState().pages.data.map(c => c.id).indexOf(parseInt(resp.data.id))
                    if (index > -1) {
                        dispatch(updatedPage(resp.data, index))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
    