/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// App components
// ...

// State
// ...

// Styles
import * as classes from './OrbitAdminItem.module.scss'
import { PROTOCOL, URI_UPLOAD } from '../../reducers/modules/api'

// Constants
// ...

const renderIconButton = (orbit, iconButton) => {
    const { text, icon, func } = iconButton

    return (<OverlayTrigger
        key={`${text}.${orbit.id}`}
        placement='bottom'
        overlay={<Tooltip>{text}</Tooltip>}>
        <FontAwesomeIcon icon={icon} onClick={() => {if (func) func(orbit.id)}} />
    </OverlayTrigger>)
}

export class OrbitAdminItem extends React.Component {
    static propTypes = {
        orbit: PropTypes.object.isRequired,

        onDelete: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onActivate: PropTypes.func.isRequired,
        onDeactivate: PropTypes.func.isRequired
    }

    render () {
        const { orbit, onDelete, onChange, onActivate, onDeactivate } = this.props
        
        const buttons = [
            {text: 'Ta bort', icon: ['fas', 'times-circle'], func: onDelete},
            {text: 'Ändra', icon: ['fas', 'arrow-alt-circle-right'], func: onChange},
            (parseInt(orbit.active) === 1 ? {text: 'Göm', icon: 'unlock', func: onDeactivate} : {text: 'Visa', icon: 'lock', func: onActivate})
        ]
        
        return (<div className={classes.container}>
            <Image
                src={`${PROTOCOL}://${URI_UPLOAD}/banners/${orbit.name}`}
                thumbnail />
                <div className={[classes.buttons, 'd-flex', 'flex-column'].join(' ')}>
                    {buttons.map(btn => renderIconButton(orbit, btn))}
                </div>
        </div>)
    }
}

const mapStateToProps = (state, ownProps) => ({
    orbit: ownProps.orbit
})
export default connect((mapStateToProps), {
})(OrbitAdminItem)
