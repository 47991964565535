import update from 'immutability-helper'

export const TOGGLED_EDIT_MAGAZINE = 'TOGGLED_EDIT_MAGAZINE'

export const handleToggledEditMagazine = (state, action) => update(state, {
    editMagazine: {
        isOpen: {$set: action.state},
        magazineId: {$set: action.magazineId}
    }
})

export function toggledEditMagazine (
    state = undefined,
    magazineId = undefined
) {
    return {
        type: TOGGLED_EDIT_MAGAZINE,
        state,
        magazineId
    }
}

export const openEditMagazine = (
    magazineId = undefined
) => {
    return (dispatch, getState) => {
        dispatch(toggledEditMagazine(true, magazineId))
    }
}

export const closeEditMagazine = () => {
    return (dispatch, getState) => {
        dispatch(toggledEditMagazine(false, undefined))
    }
}

export const editMagazine = { isOpen: false, magazineId: undefined }
