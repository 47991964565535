import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const CREATED_PAGE = 'CREATED_PAGE'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function createdPage (
    page = {}
) {
    return {
        type: CREATED_PAGE,
        payload: page
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleCreatedPage = (state, action) => update(state, {
    data: {$push: [action.payload]}
})


/**
 * Inititator
 */
export const createPage = (
    data
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/pages`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: data,
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(createdPage(resp.data))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
