import React from 'react';
import { Provider } from 'react-redux';
import * as classes from './Root.module.scss';

export default function Root(props) {
    const { routes, store } = props;

  // get devTools () {
  //   if (__DEBUG__) {
  //     if (__DEBUG_NEW_WINDOW__) {
  //       if (!window.devToolsExtension) {
  //         require('../redux/utils/createDevToolsWindow').default(this.props.store)
  //       } else {
  //         window.devToolsExtension.open()
  //       }
  //     } else if (!window.devToolsExtension) {
  //       const DevTools = require('containers/DevTools').default
  //       return <DevTools />
  //     }
  //   }
  // }

    return (
        <Provider store={store}>
            <div className={classes.container}>
                {routes}
                {/* {this.devTools} */}
            </div>
        </Provider>
    );
}
