import { updateCategory } from './UpdateCategory'

export const lockCategory = (categoryId = undefined): Functon => setCategoryActive(categoryId, false)

export const unlockCategory = (categoryId = undefined): Functon => setCategoryActive(categoryId, true)

/**
 * Initiator
 * 
 * @param {*} categoryId 
 * @param {*} name
 */
export const setCategoryActive = (
    categoryId = undefined,
    locked = undefined
) => updateCategory(categoryId, {active: (locked ? 1 : 0)})
    