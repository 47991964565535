import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import AdminPages from '../../components/AdminPages';
import SettingsForm from '../../components/SettingsForm';
import OrdersTable from '../../components/OrdersTable';
import renderLoading from '../../renderLoading';
import { loadSettings, updateSettings } from '../../reducers/modules/settings';
import { clearOrders, loadOrders, cancelOrder, restoreOrder } from '../../reducers/modules/orders';
import { openPickOrder, openDivideOrder, openBuybackOrder, openSetTransactionId } from '../../reducers/modules/modals';
import SocialNetworksForm from '../../components/SocialNetworksForm';
// import * as classes from './AdminFrontView.module.scss';

function AdminFrontView({
    orders,
    settings,
    openPickOrder,
    openDivideOrder,
    openBuybackOrder,
    openSetTransactionId,
    loadOrders,
    cancelOrder,
    restoreOrder,
    updateSettings,
    loadSettings
}) {

    useEffect(() => {
        loadSettings();
        loadOrders();
    }, [loadSettings, loadOrders]);

    const onSettingsValidated = (form) => {
        const { id: settingsId } = settings.data;
        const { name, orgid, orgid_int, bank, bg, pg, iban, bic, email, phone, street, zip, city, country_id, perpage, merchantnumber, currency, VAT, invoice_days, filter_months } = form;

        updateSettings(settingsId, {name, orgid, orgid_int, bank, bg, pg, iban, bic, email, phone, street, zip, city, country_id, perpage, merchantnumber, currency, VAT, invoice_days, filter_months});
    }

    const onSocialValidated = (form) => {
        const { id: settingsId } = settings.data;
        const { twitter, facebook, googleplus, linkedin } = form;

        updateSettings(settingsId, {twitter, facebook, googleplus, linkedin});
    }

    const handleDelete = (orderId) => {
        cancelOrder(orderId);
    }

    const handleUndelete = (orderId) => {
        restoreOrder(orderId);
    }

    const handlePickOrder = (orderId) => {
        openPickOrder(orderId);
    }

    const handleDivideOrder = (orderId) => {
        openDivideOrder(orderId);
    }

    const handleBuyback = (orderId) => {
        openBuybackOrder(orderId);
    }

    const handleTransactionId = (orderId) => {
        openSetTransactionId(orderId);
    }

    if (!orders || !settings || !settings.data) {
        return (
            <Container>
                <Row>
                    <Col>
                        <Card body>
                            <h4>Orderhantering (de senaste 3 ordrarna visas här)</h4>
                            {renderLoading()}
                        </Card>
                    </Col>
                </Row>
    
                <Row>
                    <Col xs={2}>
                        <AdminPages />
                    </Col>
                    <Col xs={10}>
                        <Card body>
                            <h4>inställningar</h4>
                            {renderLoading()}
                            <SettingsForm />
                        </Card>
                        <Card body>
                            <h5>Sociala nätverk</h5>
                            {renderLoading()}
                            <SocialNetworksForm />
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    const { data: ordersData, isUpdating: ordersUpdating } = orders;
    const { data: settingsData, isUpdating: settingsUpdating } = settings;
    const { name: title } = settingsData;

    return (
        <Container>
            <Row>
                <Col>
                    <Card body>
                        <h4>Orderhantering (de senaste 3 ordrarna visas här)</h4>
                        {ordersUpdating > 0 && renderLoading()}
                        {ordersData && <OrdersTable orders={ordersData.slice(0, 3)}
                                path='admin'
                                showOrderId={false}
                                giveDocument={true}
                                skip={[1, 2, 3, 4]}
                                handleDelete={handleDelete}
                                handleBuyback={handleBuyback}
                                handleUndelete={handleUndelete}
                                handlePickOrder={handlePickOrder}
                                handleDivideOrder={handleDivideOrder}
                                handleTransactionId={handleTransactionId} />}
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xs={2}>
                    <AdminPages />
                </Col>
                <Col xs={10}>
                    <Card body>
                        <h4>{title && `${title} - `}inställningar</h4>
                        {settingsUpdating > 0 && renderLoading()}
                        {settingsData && <SettingsForm settings={settingsData} onSubmit={onSettingsValidated} />}
                    </Card>
                    <Card body>
                        <h5>Sociala nätverk</h5>
                        {settingsUpdating > 0 && renderLoading()}
                        {settingsData && <SocialNetworksForm settings={settingsData} onSubmit={onSocialValidated} />}
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default connect((state) => ({
    orders: state.orders,
    settings: state.settings
}), {
    updateSettings,
    loadSettings,

    clearOrders,
    loadOrders,
    cancelOrder,
    restoreOrder,

    openPickOrder,
    openDivideOrder,
    openBuybackOrder,
    openSetTransactionId
})(AdminFrontView)
