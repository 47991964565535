import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const LOADED_ORBITS = 'LOADED_ORBITS'

/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function loadedOrbits (
    orbits = undefined
) {
    return {
        type: LOADED_ORBITS,
        payload: orbits
    }
}

/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadedOrbits = (state, action) => update(state, {
    images: {
        data: {$set: action.payload.data},
        links: {$set: action.payload.links},
        meta: {$set: action.payload.meta}
    }
})


/**
 * Initiator
 */
export const loadOrbits = (
    filter = undefined
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/orbits`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: filter,
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(loadedOrbits(resp))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}

export const loadOrbitsState = {
    data: [],
    links: undefined,
    meta: undefined
}
