import React from "react";
import PropTypes from "prop-types";

const ImagePreview = ({ imagefile }) => (
    <div key={(imagefile.name ? imagefile.name : `image-preview-${imagefile.name}`)} className="render-preview">
        <div>
            <img style={{width: '100%', height: 'auto'}} src={imagefile.preview} alt={imagefile.name} />
        </div>
    </div>
);

ImagePreview.propTypes = {
    imagefile: PropTypes.objectOf(PropTypes.any)
};

export default ImagePreview;
