/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Table } from 'react-bootstrap'

// App components
import ShortlistRow, { shortlistColumns } from './ShortlistRow'

// State
// ...

// Styles
// ...

// Constants
// ...

export class ShortlistTable extends React.Component {
    static propTypes = {
        shortlists: PropTypes.array.isRequired,
        skip: PropTypes.array,
        openSendEmail: PropTypes.func,
        handleRemove: PropTypes.func.isRequired
    }

    render = () => {
        const { shortlists, openSendEmail = () => {}, handleRemove = () => {}, skip = [] } = this.props
        const filtered = [...shortlistColumns].filter((col, index) => !skip.includes(col) && !skip.includes(index))

        return (
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        {filtered.map((col, index) => (<th key={index}>{isNaN(col) ? col : ''}</th>))}
                    </tr>
                </thead>
                <tbody>
                    {shortlists.map((row) => (
                        <ShortlistRow key={row.id} row={row} skip={skip} openSendEmail={openSendEmail} handleRemove={handleRemove} />
                    ))}
                </tbody>
            </Table>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
})
export default connect((mapStateToProps), {
})(ShortlistTable)
