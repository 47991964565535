/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'

// App components
import EditMagazineForm from '../EditMagazineForm'

// State
import { closeEditMagazine } from '../../reducers/modules/modals'

// Styles
// import * as classes from './EditMagazineModal.module.scss'

// Constants
// ...

export class EditMagazineModal extends React.Component {
    static propTypes = {
        modal: PropTypes.object.isRequired,
        magazines: PropTypes.object.isRequired,
        
        closeEditMagazine: PropTypes.func.isRequired
    }

    constructor (props) {
        super(props)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.formValidated = this.formValidated.bind(this)
    }

    handleCancel () {
        this.props.closeEditMagazine()
    }

    handleSubmit () {
        // this.refs.form.submit()
    }

    formValidated (form) {
        // this.props.login(form.email, form.password)
    }

    render () {
        const { isOpen } = this.props.modal
        const { magazineId } = this.props.modal

        if (!isOpen || !magazineId) {
            return <div />
        }

        const { data: magazines } = this.props.magazines
        const magazine = magazines.find(m => parseInt(m.id) === parseInt(magazineId))

        return (
            <Modal size='lg' show={isOpen} onHide={this.handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Hantera tidskrift "{magazine.title}"</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EditMagazineForm magazine={magazine} />
                <Button variant='dark' type='submit' onClick={() => {}}>Spara</Button>
            </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    modal: state.modals.editMagazine,
    magazines: state.magazines
})
export default connect((mapStateToProps), {
    closeEditMagazine
})(EditMagazineModal)
