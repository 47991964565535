import { changedStage } from './ChangeStage' 

/**
 * Inititator
 */
export const prevStage = () => {
    return (dispatch, getState) => {
        const nextStage =  (getState().checkout.stage <= 0 ? 0 : getState().checkout.stage - 1)
        dispatch(changedStage(nextStage))
    }
}
