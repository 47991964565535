import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

// import { routerReducer as router } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'

import accounting from './modules/accounting'
import alerts from './modules/alerts'
import carts from './modules/carts'
import categories from './modules/categories'
import checkout from './modules/checkout'
import countries from './modules/countries'
import exports from './modules/exports'
import friends from './modules/friends'
import shortlists from './modules/shortlists'
import toasts from './modules/toasts'
import issues from './modules/issues'
import magazines from './modules/magazines'
import messages from './modules/messages'
import modals from './modules/modals'
import orbits from './modules/orbits'
import orders from './modules/orders'
import pages from './modules/pages'
import postages from './modules/postages'
import search from './modules/search'
import settings from './modules/settings'
import user from './modules/user'
import users from './modules/users'

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),

  accounting,
  alerts,
  carts,
  categories,
  checkout,
  countries,
  exports,
  friends,
  shortlists,
  toasts,
  issues,
  magazines,
  messages,
  modals,
  orbits,
  orders,
  pages,
  postages,
  search,
  settings,
  user,
  users,
  
  form: formReducer
})

export default rootReducer
