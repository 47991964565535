import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL, minutesPassed } from '../api'
import { pushUpdating, popUpdating} from './index'

export const MINIMUM_UPDATE_TIME = 15 //minutes

/**
 * Actions
 */
export const LOADED_CATEGORIES = 'LOADED_CATEGORIES'
export const LOADING_CATEGORIES = 'LOADING_CATEGORIES'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function loadingCategories () {
    return {
        type: LOADING_CATEGORIES,
        payload: Date()
    }
}


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function loadedCategories (
    categories = undefined
) {
    return {
        type: LOADED_CATEGORIES,
        payload: categories
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadedCategories = (state, action) => update(state, {
    data: {$set: action.payload.data},
    links: {$set: action.payload.links},
    meta: {$set: action.payload.meta}
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadingCategories = (state, action) => update(state, {
    loaded: {$set: action.payload}
})


/**
 * Inititator
 * 
 * @param {*} userId 
 */
export const loadCategories = () => {
    return (dispatch, getState) => {
        
        const { loaded } = getState().categories || undefined
        if (!minutesPassed(MINIMUM_UPDATE_TIME, loaded)) {
            return
        }
        dispatch(loadingCategories())
        
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/categories`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(loadedCategories(resp))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
