import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const REMOVED_DOM_POSTAGE = 'REMOVED_DOM_POSTAGE'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function domesticRemoved (
    index = undefined
) {
    return {
        type: REMOVED_DOM_POSTAGE,
        index: index
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleRemovedDomestic = (state, action) => update(state, {
    domestic: {$splice: [[action.index, 1]]}
})


/**
 * Inititator
 */
export const removeDomestic = (
    postageId = 0
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/postages/${postageId}`,
                method: 'DELETE',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    const postageId = resp.data.id
                    const index = getState().postages.domestic.map(p => p.id).indexOf(parseInt(postageId))
                    if (index > -1) {
                        dispatch(domesticRemoved(index))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
