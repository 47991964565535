/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Img from 'react-image'
import update from 'immutability-helper'
import { Link } from 'react-router-dom'

// App componetns
import { formatPrice } from '../../PriceHelper'

// State
import { URI_UPLOAD } from '../../reducers/modules/api'

// Styles
import * as classes from './CartTable.module.scss'

// Constants
import MISSING from './missingimage.jpg'

class CartRow extends React.Component {
    static propTypes = {
        row: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        editable: PropTypes.bool.isRequired,
        deleteable: PropTypes.bool.isRequired,
        checkable: PropTypes.bool.isRequired,

        onUpdateQuantity: PropTypes.func.isRequired,
        onPickedChanged: PropTypes.func.isRequired,
        onRemoveIssue: PropTypes.func.isRequired,
    }

    constructor (props) {
        super(props)

        this.removeRow = this.removeRow.bind(this)
        this.setQuantity = this.setQuantity.bind(this)
        this.setPicked = this.setPicked.bind(this)

        this.quantityRef = React.createRef();

        this.state = {
            checked: false
        }
    }

    setQuantity (evt) {
        evt.preventDefault()

        const { carts } = this.props.user

        const cartId = carts[0].id
        const issueId = this.props.row.id
        const { value: quantity } = this.quantityRef.current
        
        this.props.onUpdateQuantity(issueId, cartId, quantity)
    }

    removeRow (evt) {
        evt.preventDefault()

        const { carts } = this.props.user

        const cartId = carts[0].id
        const issueId = this.props.row.id

        this.props.onRemoveIssue(issueId, cartId)
    }

    setPicked (evt) {
        evt.preventDefault()
        
        const newState = !this.state.checked
        const { onPickedChanged, row } = this.props

        this.setState(update(this.state, {
            checked: {$set: newState}
        }), () => {
            if (onPickedChanged) {
                try {
                    onPickedChanged(row, this.state.checked)
                } catch (err) {
                    console.error(err)
                }
            }
        })
    }

    render () {
        try {
            const { checked } = this.state
            const { row, editable, deleteable, checkable } = this.props
            const cover = (row.image ? `https://${URI_UPLOAD}/covers/${row.image}` : MISSING)

            return (
                <tr>
                <td><Link to={`/issue/${row.id}`}><Img src={[cover, MISSING]} className={classes.image} loader={<p>Loading...</p>} /></Link></td>
                <td><Link to={`/issue/${row.id}`}>{row.name}</Link></td>
                <td>{formatPrice(row.price)} kr.</td>
                <td style={{width: '10%'}}>{editable ? <Form.Control defaultValue={row.quantity} ref={this.quantityRef} /> : row.quantity}</td>
                {editable && <td align='center'><FontAwesomeIcon icon='redo' onClick={this.setQuantity} /></td>}
                {deleteable && <td align='center'><FontAwesomeIcon icon='times' onClick={this.removeRow} /></td>}
                {checkable && <td align='center'><FontAwesomeIcon icon={(checked ? 'check' : 'minus')} onClick={this.setPicked} /></td>}
                </tr>
            )
        } catch (err) {
            return (
                <tr>
                <td><Img src={MISSING} className={classes.image} loader={<p>Loading...</p>} /></td>
                <td>...</td>
                <td>- kr.</td>
                <td><Form.Control /></td>
                <td><FontAwesomeIcon icon='redo' /></td>
                <td><FontAwesomeIcon icon='times' /></td>
                </tr>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})
export default connect((mapStateToProps), {
})(CartRow)

