import React from 'react'
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form'
import { Form } from 'react-bootstrap'
import { renderForm } from '../../components/FormHelper'
// import * as classes from './TransactionNumberForm.module.scss'

const validate = values => {
    const errors = {}
    if (!values.txnid) {
        errors.txnid = 'Obligatorisk'
    }
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

function TransactionNumberForm({ handleSubmit, pristine, submitting }) {

    const fields = [
        { name: 'txnid', label: 'Transaktionsnummer', fieldType: 'field' },
        { name: 'submit', variant: 'dark', label: 'Bekräfta', fieldType: 'submit' },
    ];
  
    return (
        <>
            <Form onSubmit={handleSubmit}>
                {renderForm(fields)}
            </Form>
        </>
    );
}

export default connect(
    state => ({
    })
)(
    reduxForm({
        form: 'transactionsnumber',
        validate,
        warn
    })(TransactionNumberForm)
);
