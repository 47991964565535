/* @flow */
import update from 'immutability-helper'

import {
    changeCheckoutAddress,
    CHANGED_CHECKOUT_ADDRESS,
    handleChangeCheckoutAddress
} from './ChangeCheckoutAddress'

import {
    toggleAlternativeCheckoutAddress
} from './ToggleCheckoutAddress'

import {
    changeCheckoutStage,
    CHANGED_STAGE,
    handleChangeStage
} from './ChangeStage'

import {
    prevStage
} from './PrevStage'

import {
    nextStage
} from './NextStage'

import {
    dispatchRemoteSubmitHack
} from './DispatchFormsHack'

import {
    setCartId,
    DID_SET_CART_ID,
    handleDidSetCartId
} from './SetCartId'

import {
    setAddress,
    DID_SET_ADDRESS,
    handleDidSetAddress
} from './SetAddress'

import {
    setPaymentMethod,
    DID_SET_PAYMENT_METHOD,
    handleDidSetPaymentMethod
} from './SetPaymentMethod'

import {
    cancelPayment,
    CANCELLED_ORDER,
    handleCancelledOrder
} from './CancelPayment'

export { changeCheckoutAddress, toggleAlternativeCheckoutAddress }
export { changeCheckoutStage, prevStage, nextStage }
export { dispatchRemoteSubmitHack }
export { setCartId, setAddress, setPaymentMethod }
export { cancelPayment }

export const CLEAR_CHECKOUT_STATE = 'CLEAR_CHECKOUT_STATE'

export function clearCheckoutState () {
    return {
        type: CLEAR_CHECKOUT_STATE
    }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [CHANGED_STAGE]: (state, action) => handleChangeStage(state, action),

    [CHANGED_CHECKOUT_ADDRESS]: (state, action) => handleChangeCheckoutAddress(state, action),

    [DID_SET_CART_ID]: (state, action) => handleDidSetCartId(state, action),

    [DID_SET_ADDRESS]: (state, action) => handleDidSetAddress(state, action),

    [DID_SET_PAYMENT_METHOD]: (state, action) => handleDidSetPaymentMethod(state, action),

    [CANCELLED_ORDER]: (state, action) => handleCancelledOrder(state, action),
    
    [CLEAR_CHECKOUT_STATE]: (state, action) => (
        update(state, {
            stage: {$set: 0},
            useAlternativeAddress: {$set: false},

            cartId: {$set: undefined},
            address: {$set: undefined},
            paymentMethod: {$set: undefined},
        })
    )
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    stage: 0,
    useAlternativeAddress: false,
    createOrder: false,

    cartId: undefined,
    address: undefined,
    paymentMethod: undefined
}

export default function checkoutReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
