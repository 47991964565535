/**
 * Helper to find if a user is a certain type. This only checks a previously returned state
 * from server - and will not pose a security hole as all protected data requires a valid
 * token to be displayed.
 */

const ADMIN = 'admin'
const STORE = 'store'
const USERS = 'users'

/**
 * Helper to check if the user has admin privileges
 * 
 * @param {Object} userState 
 */
export function isAdmin (userState) {
    if (!userState.permissions) {
        return false;
    }
    return userState.permissions.includes(ADMIN)
}

/**
 * Helper to check if the user has store privileges
 * 
 * @param {Object} userState 
 */
export function isStore (userState) {
    if (!userState.permissions) {
        return false;
    }
    return userState.permissions.includes(STORE)
}

/**
 * Helper to check if the user has user privileges
 * 
 * @param {Object} userState 
 */
export function isUser (userState) {
    if (!userState?.permissions) {
        return false;
    }
    return userState.permissions.includes(USERS)
}
