import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { reduxForm } from 'redux-form';
import { renderForm } from '../../components/FormHelper';
import renderLoading from '../../renderLoading';
import { loadAllMagazines, clearMagazines } from '../../reducers/modules/magazines';
// import * as classes from './FilterAccountingForm.module.scss';

const validate = values => {
  const errors = {}
  return errors
}

const warn = values => {
  const warnings = {}
  return warnings
}

function ExportDataBaseForm(props) {

    const { handleSubmit, loadAllMagazines, magazines } = props;
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) {
            console.log('loading magazines...')
            setLoaded(true);
            loadAllMagazines();
        }
    }, [loaded, loadAllMagazines, setLoaded]);

    if (!magazines) {
        return (
            renderLoading()
        );
    }

    const { data } = magazines;
    const filteredMagazines = data.filter(e => e.title);

    const fields = [
        [
          { name: 'start_year', label: 'år', fieldType: 'field'}, {name: 'start_month', label: 'månad', fieldType: 'field'}, {name: 'start_day', label: 'dag', fieldType: 'field' }
        ],
        [
          { name: 'stop_year', label: 'år', fieldType: 'field'}, {name: 'stop_month', label: 'månad', fieldType: 'field'}, {name: 'stop_day', label: 'dag', fieldType: 'field' }
        ],
        { name: 'overwrite', label: 'Ersätt hela databasen på bokbörsen?', fieldType: 'switch' },
        { name: 'magazines', label: 'Tidskrifter', options: filteredMagazines, fieldType: 'search' },
        { name: 'submit', variant: 'dark', label: 'Exportera', fieldType: 'submit' },
        { name: 'export_all', label: 'Ska alla tidskrifter exporteras?', fieldType: 'switch' }
    ];

    return (
        <Form onSubmit={handleSubmit}>
            {renderForm(fields)}
        </Form>
    );
}

export default connect(
    ((state, ownProps) => ({
        magazines: state.magazines,
        initialValues: {start_year: 2019, start_month: 1, start_day: 1, stop_year: 2019, stop_month: 12, stop_day: 31}
    })),
    {
        clearMagazines,
        loadAllMagazines
    }
)(
    reduxForm({
        form: 'filterExport',
        validate,
        warn,
        enableReinitialize : true
    })(ExportDataBaseForm)
);
