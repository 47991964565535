import update from 'immutability-helper'

export const TOGGLED_NOTIFY_INTERESTED_USERS = 'TOGGLED_NOTIFY_INTERESTED_USERS'

export const handleToggledNotifyInterestedUsers = (state, action) => update(state, {
    notifyInterestedUsers: {
        isOpen: {$set: action.state},
        issueId: {$set: action.issueId}
    }
})

export function toggledNotifyInterestedUsers (
    state = undefined,
    issueId = undefined
) {
    return {
        type: TOGGLED_NOTIFY_INTERESTED_USERS,
        state,
        issueId
    }
}

export const openNotifyInterestedUsers = (
    issueId = undefined
) => {
    return (dispatch, getState) => {
        dispatch(toggledNotifyInterestedUsers(true, issueId))
    }
}

export const closeNotifyInterestedUsers = () => {
    return (dispatch, getState) => {
        dispatch(toggledNotifyInterestedUsers(false, undefined))
    }
}

export const notifyInterestedUsers = { isOpen: false, issueId: undefined }
