import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminPages from '../../components/AdminPages';
import CategoryTable from '../../components/CategoryTable';
import CreateCategoryForm from '../../components/CreateCategoryForm';
import renderLoading from '../../renderLoading';
import { createCategory, lockCategory, unlockCategory } from '../../reducers/modules/categories';
import { openDeleteCategory } from '../../reducers/modules/modals';
// import * as classes from './AdminCategoriesView.module.scss';

function AdminCategoriesView(props) {

    const { categories, createCategory, lockCategory, unlockCategory, openDeleteCategory } = props;

    const handleAddCategory = (form) => {
        const { name: categoryName } = form;
    
        createCategory(categoryName);
    }
    
    const handleLockCategory = (categoryId, shouldLock) => {
        if (shouldLock) {
            lockCategory(categoryId);
        } else {
            unlockCategory(categoryId);
        }
    }
    
    const handleDelCategory = (categoryId) => {
        openDeleteCategory(categoryId);
    }

    const { data, isUpdating } = categories;

    return (
        <Container>
            <Row>
                <Col xs={2}>
                    <AdminPages />
                </Col>
                <Col xs={10}>
                    <Card body>
                        <h4>Information</h4>
                        <p>För att ändra en redan existerande kategori så behöver du endast skriva in det nya namnet i listan, efter c:a 1 sekunds uppehåll så uppdateras listan automatiskt.</p>
                        <p>För att lägga till en ny kategori använd formuläret längst ner på sidan.</p>
                        <p>För att inaktivera en kateogri klickar du på <FontAwesomeIcon icon='unlock' /> - om en kategori är låst syns den med ikonen <FontAwesomeIcon icon='lock' /> i listan.</p>
                        <p>För att ta bort en existerande katgori klickar du på <FontAwesomeIcon icon='times' /> i listan, du får då en dialog där du måste bekräfta genom att skriva in kategorins namn.</p>
                        <Badge variant="danger">OBS, om du tar bort en kategori försvinner även de tidskrifter som är kopplade till den.</Badge>
                    </Card>

                    <Card body>
                        {isUpdating > 0 && renderLoading()}
                        {data && <CategoryTable categories={data} handleLock={handleLockCategory} handleDelete={handleDelCategory} />}
                    </Card>

                    <Card body>
                        <h4>Lägg till ny kategori här</h4>
                        <CreateCategoryForm onSubmit={handleAddCategory} />
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default connect((state) => ({
    categories: state.categories
}), {
    createCategory,
    lockCategory,
    unlockCategory,
    openDeleteCategory
})(AdminCategoriesView);
