import update from 'immutability-helper'

// Load categories
import {
    loadCategories,
    LOADING_CATEGORIES, LOADED_CATEGORIES,
    handleLoadingCategories, handleLoadedCategories
} from './LoadCategories'

// Load category
import {
    loadCategory
} from './LoadCategory'

// Create category
import {
    createCategory,
    CREATED_CATEGORY,
    handleCreatedCategory
} from './CreateCategory'

// Update category
import {
    updateCategory,
    UPDATED_CATEGORY,
    handleUpdatedCategory
} from './UpdateCategory'

import {
    removeCategory,
    REMOVED_CATEGORY,
    handleRemovedCategory
} from './RemoveCategory'

import {
    lockCategory,
    unlockCategory
} from './ActivateCategory'

export { loadCategories, loadCategory }
export { createCategory, updateCategory, removeCategory }
export { lockCategory, unlockCategory }

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATING_CATEGORIES = 'UPDATING_CATEGORIES'
export const CLEAR_ALL_CATEGORIES = 'CLEAR_ALL_CATEGORIES'

const INCREMENT = 1;
const DECREMENT = 2;

export const ACTIVE = 1
export const INACTIVE = 0

// ------------------------------------
// Actions
// ------------------------------------
export function pushUpdating () {
	return {
		type: UPDATING_CATEGORIES,
		payload: INCREMENT
	}
}

export function popUpdating () {
	return {
		type: UPDATING_CATEGORIES,
		payload: DECREMENT
	}
}

export function clearCategories () {
	return {
		type: CLEAR_ALL_CATEGORIES
	}
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[UPDATING_CATEGORIES]: (state, action) => {
		if (INCREMENT === action.payload) {
			return update(state, { isUpdating: {$set: state.isUpdating + 1}})
		} else if (DECREMENT === action.payload) {
			return update(state, { isUpdating: {$set: state.isUpdating - 1}})
		} else {
			return state
		}
	},

	[LOADING_CATEGORIES]: (state, action) => handleLoadingCategories(state, action),

	[LOADED_CATEGORIES]: (state, action) => handleLoadedCategories(state, action),

	[CREATED_CATEGORY]: (state, action) => handleCreatedCategory(state, action),

	[UPDATED_CATEGORY]: (state, action) => handleUpdatedCategory(state, action),

	[REMOVED_CATEGORY]: (state, action) => handleRemovedCategory(state, action),
	
	[CLEAR_ALL_CATEGORIES]: (state, action) => (
		update(state, {
			isUpdating: {$set: 0},
			data: {$set: []},
			links: {$set: undefined},
			meta: {$set: undefined}
		})
	)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	isUpdating: 0,
	data: [],
	links: undefined,
	meta: undefined,
	loaded: undefined
}

export default function categoriesReducer (state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}
