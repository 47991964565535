import { changeCheckoutAddress } from './ChangeCheckoutAddress'

/**
 * Inititator
 */
export const toggleAlternativeCheckoutAddress = () => {
    return (dispatch, getState) => {
        dispatch(changeCheckoutAddress(!getState().checkout.useAlternativeAddress))
    }
}
