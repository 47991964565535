import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import queryString from 'query-string';
import AdminPages from '../../components/AdminPages';
import AccountForm from '../../components/AccountForm';
import AddressForm from '../../components/AddressForm';
import renderLoading from '../../renderLoading';
import OrdersTable from '../../components/OrdersTable';
import PaginationView from '../../components/PaginationView';
import { updateData, updateAddress, loadOrders, updatePermissions } from '../../reducers/modules/user';
import { loadUser } from '../../reducers/modules/users';
import { clearOrders } from '../../reducers/modules/orders';
import PermissionsForm from '../../components/PermissionsForm';
// import * as classes from './AdminUserView.module.scss';

function AdminUserView(props) {

    const { match, location, user, users, loadUser, loadOrders, updatePermissions } = props;

    const [userId, setUserId] = useState(undefined);
    const [loadedUser, setLoadedUser] = useState(false);
    const [loadedOrders, setLoadedOrders] = useState(false);
    const [orderPage, setOrderPage] = useState({ page: 1 });

    useEffect(() => {
        const { search: page } = location;
        const args = queryString.parse(page);
        
        setOrderPage({
            page: (args.page ? args.page : 1)
        });
    }, [location]);

    useEffect(() => {
        setLoadedOrders(false);
    }, [orderPage]);

    useEffect(() => {
        const { userId } = match.params;
        if (parseInt(userId) !== userId) {
            setUserId(userId);
            setLoadedUser(false);
        }

    }, [match, userId]);

    useEffect(() => {
        if (!loadedUser && userId) {
            loadUser(userId)
            setLoadedUser(true);
        }
    }, [loadUser, userId, loadedUser, location]);

    useEffect(() => {
        if (loadedUser && !loadedOrders) {
            setLoadedOrders(true);
            loadOrders(userId, orderPage);
        }
    }, [loadedUser, loadedOrders, orderPage, setLoadedOrders, loadOrders, userId]);

    // const onPasswordValidated = (form) => {
    //     console.log(form);
    //     updatePassword(form.new_password1, form.new_password2, form.password);
    // }
  
    const onAccountValidated = (form) => {
        const { id: userId } = user.data;
        const { first_name, last_name, email } = form;
    
        updateData(userId, { first_name, last_name, email });
    }
  
    const onAddressValidated = (form) => {
        const { id: userId } = user.data;
        const { id: addressId } = user.address;
        const { street, zip, city, country_id } = form;
    
        updateAddress(userId, addressId, street, zip, city, country_id);
    }

    const onPermissionsValidated = (form) => {
        updatePermissions(userId, form.permissions)
    }

    // const onGroupsValidated = (form) => {
    //     console.log(form)
    // }

    const { isUpdating, data } = users;
    const index = data.map(u => u.id).indexOf(parseInt(userId));
    const userData = data[index];
    const address = userData ? userData.address : undefined;
    const permissions = userData ? userData.permissions : undefined;
    // const groups = userData ? userData.groups : undefined;
    const { orders: { data: ordersData } } = user || [];

    console.log(permissions)
    return (
        <Container>
            <Row>
                <Col xs={2}>
                    <AdminPages />
                </Col>
                <Col xs={10}>
                    <Row>
                        <Col xs={6}>
                            <Card body>
                                <h6>Kontouppgifter</h6>
                                {userData ? <AccountForm user={userData} onSubmit={onAccountValidated} /> : renderLoading()}
                            </Card>
                        </Col>
                        <Col xs={6}>
                            <Card body>
                                <h6>Adress</h6>
                                {address ? <AddressForm address={address} onSubmit={onAddressValidated} /> : renderLoading()}
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Card body>
                                <h6>Rättigheter</h6>
                                <p>Här kan du sätta specifika rättigheter för en enskild användare. Rättigheterna måste vara skrivna på ett specifikt sätt för att fungera.</p>
                                <p>T.ex. "admin": 1 eller "store": 1.</p>
                                {address ? <PermissionsForm permissions={permissions} onSubmit={onPermissionsValidated} /> : renderLoading()}
                            </Card>
                        </Col>
                        {/* <Col xs={6}>
                            <Card body>
                                <h6>Grupptillhörighet</h6>
                                <p>Här kan du välja vilka grupper en användare tillhör, som alternativ till enkilda rättigheter för användaren.</p>
                                {address ? <GroupForm groups={groups} onSubmit={onGroupsValidated} /> : renderLoading()}
                            </Card>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col>
                            <Card body>
                                {userData && <h4>{userData.first_name} {userData.last_name}'s ordrar</h4>}
                                {ordersData && <OrdersTable orders={ordersData} path='admin' skip={[1, 10, 11, 12]} />}
                                {ordersData && <PaginationView {...props} data={user.orders} />}
                                {isUpdating > 0 && renderLoading()}
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default connect((state, ownProps) => ({
    user: state.user,
    users: state.users
}), {
    updateAddress,
    updateData,
    clearOrders,
    loadOrders,
    loadUser,
    updatePermissions
})(AdminUserView);
