import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'

import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'
import { closeLogin } from '../modals'
import { loadPermissions } from './LoadPermissions'
import { loadData } from './LoadData'
import { history } from '../../../configureStore'
import { pushAlert } from '../alerts'

/**
 * Actions
 */
export const SUCCESS_LOGIN = 'SUCCESS_LOGIN'
export const FAILED_LOGIN = 'FAILED_LOGIN'


/**
 * Dispatcher, success
 * 
 * @param {*} token 
 */
export const successfulLogin = (
    token = ''
) => {
    store.set('tbwt', token)
    return ({
        type: SUCCESS_LOGIN,
        payload: token
    })
}


/**
 * Dispatcher, failed
 */
export const failedLogin = () => ({
    type: FAILED_LOGIN
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleSuccessLogin = (state, action) => update(state, {
    token: {$set: action.payload}
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleFailedLogin = (state, action) => update(state, {
})


/**
 * Initiator
 * 
 * @param {*} username 
 * @param {*} password 
 */
export const login = (
    username = '',
    password = ''
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
            url: `${PROTOCOL}://${URI}/auth/login`,
            method: 'POST',
            crossOrigin: true,
            headers: {
                'X-Tb-Token': store.get('tbwt')
            },
            data: {
                email: username,
                password: password
            },
            error (err) {
                console.error(JSON.stringify(err))
                const { responseText } = err
                try {
                    const { heading, messages } = JSON.parse(responseText)
                    if (Array.isArray(messages)) {
                        dispatch(pushAlert(heading, messages.join('\n')))
                    } else {
                        dispatch(pushAlert(heading, messages))
                    }
                } catch(exception) {
                    console.error(exception);
                }
                dispatch(closeLogin())
                dispatch(popUpdating())
                resolve()
            },
            success (resp) {
                if (resp.data) {
                    dispatch(successfulLogin(resp.data))
                    dispatch(closeLogin())

                    dispatch(loadPermissions())
                    dispatch(loadData('me'))

                    history.push('/')
                } else {
                    dispatch(failedLogin({}))
                }
                dispatch(popUpdating())
                resolve()
            }
            })
        })
    }
}
