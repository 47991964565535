import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Form } from 'react-bootstrap';
import { renderForm } from '../../../components/FormHelper';

const validate = values => {
  const errors = {}
  return errors
}

const warn = values => {
  const warnings = {}
  return warnings
}

function DistributorForm({ handleSubmit, countries, submitting, pristine, handleChange }) {

    const { items } = countries;

    const options = items.map((country) => (
        <option key={country.id} value={country.id}>{country.short_name}</option>
    ));

    const fields = [
        { name: 'country_id', label: 'Land', as: 'select', children: options, fieldType: 'field', onChange: handleChange },
        { name: 'street', label: 'Gatuadress', fieldType: 'field', onChange: handleChange },
        [
            { name: 'zip', label: 'Postnummer', parts: '5', fieldType: 'field', onChange: handleChange },
            { name: 'city', label: 'Ort', parts: '7', fieldType: 'field', onChange: handleChange }
        ]
    ];

    return (
        <>
            <Form onSubmit={handleSubmit}>
                {renderForm(fields)}
            </Form>
        </>
    );
}

export default connect(
    (state, ownProps) => ({
        countries: state.countries,
        initialValues: ownProps.issue
    })
)(
    reduxForm({
        form: 'issueDistributor',
        validate,
        warn
    })(DistributorForm)
);
