import update from 'immutability-helper';
import request from 'reqwest';
import store from 'store';
import { pushAlert } from '../alerts';
import { URI, PROTOCOL } from '../api';
import { pushToast } from '../toasts';
import { pushUpdating, popUpdating } from './index';
import { reset } from 'redux-form';

/**
 * Actions
 */
export const CREATED_USER = 'CREATED_USER'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function createdUser (
    user = {}
) {
    return {
        type: CREATED_USER,
        payload: user
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleCreatedUser = (state, action) => update(state, {
    data: {$push: [action.payload]}
})


/**
 * Inititator
 * 
 * @param {*} userId
 */
export const createUser = (
    data = undefined
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/users/`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: data,
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating());
                    const { response } = err;
                    const error = JSON.parse(response);
                    dispatch(pushAlert('Skapa användare', error.messages[0]));
                    resolve();
                },
                success (resp) {
                    dispatch(createdUser(resp.data));
                    dispatch(popUpdating());
                    dispatch(pushToast(undefined, `Skapade användare i systemet.`));
                    dispatch(reset('registerUser'));
                    resolve();
                }
            })
        })
    }
}
