import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
// NOTE: here we're making use of the `resolve.root` configuration
// option in webpack, which allows us to specify import paths as if
// they were from the root of the ~/src directory. This makes it
// very easy to navigate to files regardless of how deeply nested
// your current file is.
import PrivateRoute from './PrivateRoute'

import TopbarView from '../views/TopbarView'
import MainbarView from '../views/MainbarView'
import AlertsView from '../views/AlertsView'

import FrontView from '../views/FrontView'
import CategoryView from '../views/CategoryView'
import IssueView from '../views/IssueView'
import PageView from '../views/PageView'
import RegisterView from '../views/RegisterView'
import ActivateView from '../views/ActivateView'

import AccountView from '../views/AccountView'
import CartView from '../views/CartView'
import AccountCheckoutView from '../views/AccountCheckoutView'
import AccountPaymentView from '../views/AccountPaymentView'
import AccountOrdersView from '../views/AccountOrdersView'
import AccountOrderView from '../views/AccountOrderView'
import AccountShortlistView from '../views/AccountShortlistView'

import AccountAcceptUrl from '../views/AccountAcceptUrl'
import AccountCallbackUrl from '../views/AccountCallbackUrl'
import AccountCancelUrl from '../views/AccountCancelUrl'

import NewIssueView from '../views/IssueView/NewIssueView'
import NewMagazineView from '../views/IssueView/NewMagazineView'
import SearchView from '../views/SearchView'

import AdminFrontView from '../views/AdminFrontView'
import AdminUsersView from '../views/AdminUsersView'
import AdminUserView from '../views/AdminUserView'
import AdminCreateUserView from '../views/AdminCreateUserView'
import AdminCategoriesView from '../views/AdminCategoriesView'
import AdminPagesView from '../views/AdminPagesView'
import AdminPageView from '../views/AdminPageView'
import AdminOrbitsView from '../views/AdminOrbitsView'
import AdminPostagesView from '../views/AdminPostagesView'
import AdminOrdersView from '../views/AdminOrdersView'
import AdminOrderView from '../views/AdminOrderView'
import AdminFriendsView from '../views/AdminFriendsView'
import AdminShortlistView from '../views/AdminShortlistView'
import AdminMagazinesView from '../views/AdminMagazinesView'
import AdminAccountingView from '../views/AdminAccountingView'
import AdminExportView from '../views/AdminExportView'
import AdminCleaningView from '../views/AdminCleaningView'

import FooterView from '../views/FooterView'
import ModalHolder from '../components/ModalHolder'
import ToastsView from '../views/ToastsView/ToastsView'
import AccountReceiptView from '../views/AccountReceiptView'
// import MessageHandler from '../../components/MessageHandler/MessageHandler'

export default (store, history) => (
  <Router history={history}>
    <Route component={TopbarView} />
    <Route component={MainbarView} />
    <Route component={AlertsView} />
    <Route component={ToastsView} />

    <Switch>
      <Route exact path="/"><FrontView /></Route>

      <Route exact path="/categories/:categoryId" render={(props) => <CategoryView {...props} />} />

      <Route path="/issue/:issueId" render={(props) => <IssueView {...props} />} />
      
      <Route path="/pages/:pageId" render={(props) => <PageView {...props} />} />

      <PrivateRoute exact path="/account" component={() => (<AccountView />)} />
      <PrivateRoute exact path="/account/cart" component={() => (<CartView />)} />
      <PrivateRoute exact path="/account/checkout" component={(props) => (<AccountCheckoutView {...props} />)} />
      <PrivateRoute exact path="/account/payment" component={() => (<AccountPaymentView />)} />
      <PrivateRoute exact path="/account/orders" component={(props) => (<AccountOrdersView {...props} />)} />
      <PrivateRoute exact path="/account/orders/:orderId" component={(props) => (<AccountOrderView {...props} />)} />
      <PrivateRoute exact path="/account/receipt/:orderId" component={(props) => (<AccountReceiptView {...props} />)} />
      <PrivateRoute exact path="/account/shortlist" component={(props) => (<AccountShortlistView {...props} />)} />

      <PrivateRoute exact path="/account/accept/:orderId" component={(props) => (<AccountAcceptUrl {...props} />)} />
      <PrivateRoute exact path="/account/callback" component={() => (<AccountCallbackUrl />)} />
      <PrivateRoute exact path="/account/cancel" component={(props) => (<AccountCancelUrl {...props} />)} />

      <Route exact path="/register" render={(props) => <RegisterView {...props} />} />
      
      <Route path="/activate/" render={(props) => <ActivateView {...props} />} />
      <Route path="/activate/:resetToken" render={(props) => <ActivateView {...props} />} />

      <PrivateRoute exact path='/magazines/:magazineId/newissue' component={(props) => (<NewIssueView {...props} />)} />
      <PrivateRoute exact path='/categories/:categoryId/newmagazine' component={(props) => (<NewMagazineView {...props} />)} />

      <Route exact path='/search' render={(props) => (<SearchView {...props} />)} />

      <PrivateRoute exact path="/admin" component={() => (<AdminFrontView />)} />
      <PrivateRoute exact path="/admin/users" component={(props) => (<AdminUsersView {...props} />)} />
      <PrivateRoute exact path="/admin/users/:userId" component={(props) => (<AdminUserView {...props} />)} />
      <PrivateRoute exact path="/admin/createuser" component={(props) => (<AdminCreateUserView {...props} />)} />
      <PrivateRoute exact path="/admin/categories" component={() => (<AdminCategoriesView />)} />
      <PrivateRoute exact path="/admin/pages" component={(props) => (<AdminPagesView {...props} />)} />
      <PrivateRoute exact path="/admin/pages/:pageId" component={(props) => (<AdminPageView {...props} />)} />
      <PrivateRoute exact path="/admin/orbit" component={() => (<AdminOrbitsView />)} />
      <PrivateRoute exact path="/admin/postage" component={() => (<AdminPostagesView />)} />
      <PrivateRoute exact path="/admin/orders" component={(props) => (<AdminOrdersView {...props} />)} />
      <PrivateRoute exact path="/admin/orders/:orderId" component={(props) => (<AdminOrderView {...props} />)} />
      <PrivateRoute exact path="/admin/friends" component={(props) => (<AdminFriendsView {...props} />)} />
      <PrivateRoute exact path="/admin/shortlists" component={(props) => (<AdminShortlistView {...props} />)} />
      <PrivateRoute exact path="/admin/magazines" component={(props) => (<AdminMagazinesView {...props} />)} />
      <PrivateRoute exact path="/admin/accounting" component={() => (<AdminAccountingView />)} />
      <PrivateRoute exact path="/admin/export" component={() => (<AdminExportView />)} />
      <PrivateRoute exact path="/admin/gallring" component={() => (<AdminCleaningView />)} />
    </Switch>

    <Route component={FooterView} />
    <Route component={ModalHolder} />
  </Router>
)
