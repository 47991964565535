import update from 'immutability-helper'
import { createOrder } from '../user'

/**
 * Actions
 */
export const DID_SET_PAYMENT_METHOD = 'DID_SET_PAYMENT_METHOD'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function didSetPaymentMethod (
    paymentMethod = undefined
) {
    return {
        type: DID_SET_PAYMENT_METHOD,
        payload: paymentMethod
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleDidSetPaymentMethod = (state, action) => update(state, {
    paymentMethod: {$set: action.payload}
})


/**
 * Inititator
 */
export const setPaymentMethod = (
    paymentMethod = undefined,
    shouldPlaceOrder = false
) => {
    return (dispatch, getState) => {
        dispatch(didSetPaymentMethod(paymentMethod))

        // When we set the payment method we have the option of also placing an order
        if (shouldPlaceOrder) {
            const { permissions, data } = getState().user
            const { id: userId } = data
            const isStore = permissions.includes('store')

            const { cartId, paymentMethod, address, useAlternativeAddress } = getState().checkout
            dispatch(createOrder(userId, cartId, paymentMethod, address, useAlternativeAddress, isStore))
        }
    }
}
