import { updateOrbit } from './UpdateOrbit'

export const deactivateOrbit = (pageId = undefined): Functon => setOrbitActive(pageId, false)

export const activateOrbit = (pageId = undefined): Functon => setOrbitActive(pageId, true)

/**
 * Initiator
 * 
 * @param {*} pageId 
 * @param {*} name
 */
export const setOrbitActive = (
    orbitId = undefined,
    locked = undefined
) => updateOrbit(orbitId, {active: (locked ? 1 : 0)})
    