/* @flow */

// Framework
import React from 'react'
import { connect } from 'react-redux'
import { Card, Button, ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'

// App componetns
// ...

// State
// ...

// Styles
import * as classes from './AdminPages.module.scss'

// Constants
// ...

const adminPages = [
  {title: 'Administration', to: '/admin'},
  {title: 'Användare', to: '/admin/users'},
  {title: 'Kategorier', to: '/admin/categories'},
  {title: 'Sidor', to: '/admin/pages'},
  {title: 'Bildspel', to: '/admin/orbit'},
  {title: 'Portohantering', to: '/admin/postage'},
  {title: 'Orderhantering', to: '/admin/orders'},
  {title: 'Våra vänner', to: '/admin/friends'},
  {title: 'Önskade nummer', to: '/admin/shortlists'},
  {title: 'Tidskrifter', to: '/admin/magazines'},
  {title: 'Redovisning', to: '/admin/accounting'},
  {title: 'Exportera excel', to: '/admin/export'},
  {title: 'Gallringsdax', to: '/admin/gallring'}
]

const renderPage = (page) => {
  return (
    <ListGroup.Item key={page.title} as="li">
      <Button as={Link} to={page.to} variant="link">{page.title}</Button>
    </ListGroup.Item>
  )
}

const renderPages = (pages) => {
  return (
    <ListGroup as="ul" className={classes.list}>
      {pages.map((page) => renderPage(page))}
    </ListGroup>
  )
}

class AdminPages extends React.Component {
  static propTypes = {
  }
  
  render () {
    return (
      <Card body>
        <h6>Lista över adminsidor</h6>
        {renderPages(adminPages)}
      </Card>
    )
  }
}

const mapStateToProps = (state) => ({
})
export default connect((mapStateToProps), {
})(AdminPages)

