import update from 'immutability-helper'

/**
 * Actions
 */
export const CHANGED_STAGE = 'CHANGED_STAGE'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function changedStage (
    stage = 0
) {
    return {
        type: CHANGED_STAGE,
        payload: stage
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleChangeStage = (state, action) => update(state, {
    stage: {$set: action.payload}
})


/**
 * Inititator
 */
export const changeCheckoutStage = (
    stage = 0
) => {
    return (dispatch, getState) => {
        dispatch(changedStage(stage))
    }
}
