import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating} from './index'

/**
 * Actions
 */
export const CREATED_CATEGORY = 'CREATED_CATEGORY'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function createdCategory (
    category = undefined
) {
    return {
        type: CREATED_CATEGORY,
        payload: category
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleCreatedCategory = (state, action) => update(state, {
    data: {$push: [action.payload]}
})


/**
 * Inititator
 * 
 * @param {*} userId 
 */
export const createCategory = (
    name = ''
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/categories/`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: { name },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(createdCategory(resp.data))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
