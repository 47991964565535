import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const UPDATED_MAGAZINE = 'UPDATED_MAGAZINE'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function updatedMagazine (
    magazine = {},
    index = -1
) {
    return {
        type: UPDATED_MAGAZINE,
        payload: magazine,
        index: index
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleUpdatedMagazine = (state, action) => update(state, {
    data: {
        [action.index]: {$set: action.payload}
    }
})


/**
 * Initiator
 * 
 * @param {*} magazineId 
 * @param {*} name
 */
export const updateMagazine = (
    magazineId = 0,
    data = {}
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/magazines/${magazineId}`,
                method: 'PUT',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data,
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    // dispatch(pushMessage('User', 'Failed to update user address', 'danger'))
                    resolve()
                },
                success (resp) {
                    const { data: magazines } = getState().magazines
                    var index = magazines.map(m => m.id).indexOf(parseInt(resp.data.id))
                    if (index > -1) {
                        dispatch(updatedMagazine(resp.data, index))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
    