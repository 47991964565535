import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'react-bootstrap'
import OrderRow from './OrderRow'
// import * as classes from './OrdersTable.module.scss'

const renderTable = (props) => {
    const { orders, path, skip } = props
    const { handleDelete, handleUndelete, handlePickOrder, handleDivideOrder, handleBuyback, handleTransactionId, handlePrint, showOrderId, giveDocument } = props
    return (
        orders.map((row) => (
            <OrderRow
                row={row}
                skip={skip}
                path={path}
                key={row.id}
                showOrderId={showOrderId}
                giveDocument={giveDocument}
                handleDelete={handleDelete}
                handleBuyback={handleBuyback}
                handleTransactionId={handleTransactionId}
                handleUndelete={handleUndelete}
                handlePickOrder={handlePickOrder}
                handleDivideOrder={handleDivideOrder}
                handlePrint={handlePrint} />
        ))
    );
}

export const orderColumns = [
    'nr.',
    'Anv.',
    'Faktura nr',
    'Summa',
    'Frakt',
    'Totalt',
    'Betald',
    'Behandlad',
    'Innehåll',
    '9',
    '10',
    '11',
    '12',
    '13'
];

function OrdersTable({ 
    orders,
    path,
    skip = [],
    handleDelete,
    handleUndelete,
    handlePickOrder,
    handleDivideOrder,
    handleBuyback,
    handleTransactionId,
    handlePrint,
    showOrderId = true, 
    giveDocument = true 
}) {

    const filtered = [...orderColumns].filter((col, index) => !skip.includes(col) && !skip.includes(index));

    return (
        <>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        {filtered.map((col, index) => (<th width={isNaN(col) ? '' : '30'} key={index}>{isNaN(col) ? col : ''}</th>))}
                    </tr>
                </thead>
                <tbody>
                    {renderTable({orders, path, skip, handleDelete, handleUndelete, handlePickOrder, handleDivideOrder, handleBuyback, handleTransactionId, handlePrint, showOrderId, giveDocument})}
                </tbody>
            </Table>
        </>
    );
}

export default connect((state, ownProps) => ({
}), {
})(OrdersTable)
