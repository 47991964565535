import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import AdminPages from '../../components/AdminPages';
import SearchUsersForm from '../../components/SearchUsersForm';
import UsersTable from '../../components/UsersTable';
import PaginationView from '../../components/PaginationView';
import renderLoading from '../../renderLoading';
import { clearUsers, searchUsers, activateUser, deactivateUser } from '../../reducers/modules/users';
// import * as classes from './AdminUsersView.module.scss';

const MINIMUM_CHARS_SEARCH = 3;

function AdminUsersView(props) {

    const { users, clearUsers, searchUsers, location, activateUser, deactivateUser } = props;
    
    const [search, setSearch] = useState(undefined);
    const [searching, setSearching] = useState(false);
    const [usersPage, setUsersPage] = useState({ page: 1 });

    useEffect(() => {
        clearUsers();
    }, [clearUsers]);

    useEffect(() => {
        const { search: page } = location;
        const args = queryString.parse(page);
        
        setUsersPage({
            page: (args.page ? args.page : 1)
        });
    }, [location]);

    useEffect(() => {
        setSearching(false);
    }, [usersPage]);

    useEffect(() => {
        if (search && !searching && usersPage) {
            setSearching(true);
            searchUsers({...search, ...usersPage});
        }
    }, [search, searching, searchUsers, usersPage]);

    const handleSearchUsers = (form) => {
        const { search } = form;

        if (search.length >= MINIMUM_CHARS_SEARCH) {
            setUsersPage({ page: 1 });
            setSearch({
                email: search,
                first_name: search,
                last_name: search
            });
            setSearching(false);
        }
    }

    const handleActivate = (userId, shouldActivate) => {
        if (shouldActivate) {
            activateUser(userId);
        } else {
            deactivateUser(userId);
        }
    }

    const { data, isUpdating } = users;

    return (
        <Container>
            <Row>
                <Col xs={2}>
                    <AdminPages />
                </Col>
                <Col xs={10}>
                    <Card body>
                        <h4>Information</h4>
                        <p>Användarhantering.</p>
                        <p>För att söka efter en användare så skriver du in hela, eller en del av, dess e-post address.</p>
                        <p>Vill du aktivera eller av-aktivera en användare så trycker du på hänglåset. Är hänglåset öppet så betyder det att användaren är aktiverad och redo att användas, om låset är stängt betyder det att användaren måste aktiveras - det kan du göra här eller så kan användaren aktiveras genom att återställa sitt lösenord.</p>
                        <p>Om du vill ändra en användares e-post address så behöver du bara skriva in den nya addressen på rätt rad, websidan kommer automatiskt att spara addressen när du är klar.</p>
                        <p>Om du vill skapa en ny användare så kan du öppna <Link to={`/admin/createuser`}>det här formuläret</Link>.</p>
                    </Card>

                    <Card body>
                        <SearchUsersForm onSubmit={handleSearchUsers} />
                    </Card>

                    <Card body>
                        {isUpdating > 0 && renderLoading()}
                        {data.length > 0 && <UsersTable users={data} activate={handleActivate} />}
                        {data.length > 0 && <PaginationView {...props} data={users} />}
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default connect((state) => ({
    users: state.users
}), {
    clearUsers,
    searchUsers,
    activateUser,
    deactivateUser
})(AdminUsersView);
