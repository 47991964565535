import React from 'react'
import { render } from 'react-dom'
import configureStore, { history } from './configureStore'
import makeRoutes from './routes'
import Root from './containers/Root'
import * as serviceWorker from './serviceWorker'

// Import TB bootstrap theme
import './styles/bootstrap.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// Setup Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab as brands } from '@fortawesome/free-brands-svg-icons'
import { faSearch, faUser, faKey, faShoppingBasket, faRedo, faCog, faLock, faUnlock, faPlus, faPlusCircle, faMinus, faMinusCircle, faEdit, faLongArrowAltRight, faLongArrowAltLeft, faQuestion, faTasks, faDivide, faUndo, faPrint } from '@fortawesome/free-solid-svg-icons'

import { faCreditCard, faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faCheck, faTimes, faTimesCircle, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'

// Solid icons
const fas = {
    faSearch, faUser, faKey, faShoppingBasket, faRedo, faCog, faLock, faUnlock, faPlus, faPlusCircle, faMinus, faMinusCircle, faEdit, faLongArrowAltRight, faLongArrowAltLeft, faQuestion, faTasks, faDivide, faUndo, faPrint,
    faCheck, faTimes, faTimesCircle, faArrowAltCircleRight
}

// Regular icons, needs the 'far' prefix to work
const far = {
    faCreditCard, faEnvelope
}

library.add(brands, fas, far)

const store = configureStore()

// Now that we have the Redux store, we can create our routes. We provide
// the store to the route definitions so that routes have access to it for
// hooks such as `onEnter`.
const routes = makeRoutes(store, history)

const renderApp = () =>
    render(
        // <Provider store={ store }>
        //     <ConnectedRouter history={history}>
        //         <App />
        //     </ConnectedRouter>
        // </Provider>,
        <Root history={history} routes={routes} store={store} />,
        document.getElementById('root')
    )

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./App', renderApp)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

renderApp()
