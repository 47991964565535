import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { popToast } from '../../reducers/modules/toasts';

// An toast will show for maximum 10 seconds
const TIMEOUT_MESSAGE = 10000;

function ToastMessage (props) {

    const { toast, popToast } = props;

    const { heading, toast: message, uid } = toast;

    useEffect(() => {
        const timer = setTimeout(() => {
            popToast(uid);
        }, TIMEOUT_MESSAGE);

        return () => clearTimeout(timer);
    }, [popToast, uid]);

    return (
        <Alert variant="success" onClose={() => popToast(uid)} dismissible>
            {heading && <Alert.Heading>{ heading }</Alert.Heading>}
            {message && <p>{ message }</p>}
        </Alert>
    );
}

export default connect((state) => ({
}), {
    popToast
})(ToastMessage);
