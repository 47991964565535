/* @flow */

// Framework
import React from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap'

// App components
// ...

// State
// ...

// Styles
// import * as classes from './AccountCallbackUrl.module.scss'

// Constants
//...

export class AccountCallbackUrl extends React.Component {
    render () {
        return (
            <Container>
                <Row>
                    <Col>
                        <p>Callback URL</p>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
})
export default connect((mapStateToProps), {
})(AccountCallbackUrl)
