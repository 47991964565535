import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const LOADED_USERS = 'LOADED_USERS'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function loadedUsers (
    users = []
) {
    return {
        type: LOADED_USERS,
        payload: users
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadedUsers = (state, action) => update(state, {
    data: {$set: action.payload.data},
    links: {$set: action.payload.links},
    meta: {$set: action.payload.meta}
})


/**
 * Inititator
 * 
 * @param {*} userId 
 */
export const loadUsers = () => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/users`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating)
                    resolve()
                },
                success (resp) {
                    dispatch(loadedUsers(resp))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
