/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container } from 'react-bootstrap'

// App components
import AlertMessage from './AlertMessage'

// State
// ...

// Styles
// import * as classes from './AlertsView.module.scss'

export class AlertsView extends React.Component {
  static propTypes = {
    alerts: PropTypes.object.isRequired,
  }

  render () {
    try {
      const { alerts } = this.props.alerts

      return (
        <Container>
          {alerts && alerts.map((alert) => <AlertMessage alert={alert} key={alert.uid} />)}
        </Container>
      )
    } catch (error) {
      return (
        <Container>
          <p>laddar...</p>
        </Container>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  alerts: state.alerts
})
export default connect((mapStateToProps), {
})(AlertsView)
