/* @flow */
import update from 'immutability-helper'

import {
    loadIssues,
    LOADED_ISSUES,
    handleLoadedIssues
} from './LoadIssues'

import {
    createIssue,
    CREATED_ISSUE,
    handleCreatedIssue
} from './CreateIssue'

import {
    updateIssue,
    UPDATED_ISSUE,
    handleUpdatedIssue
} from './UpdateIssue'

import {
    loadIssue
} from './LoadIssue'

import {
    loadMagazineIssues
} from './LoadMagazineIssues'

import {
    loadLatestIssues
} from './LoadLatestIssues'

import {
    loadCategoryIssues
} from './LoadCategoryIssues'

import {
    createIssueMagazine,
    CREATED_ISSUE_MAGAZINE,
    handleCreatedIssueMagazine
} from './CreateIssueMagazine'

import {
    deleteIssue,
    DELETED_ISSUE,
    handleDeletedIssue
} from './DeleteIssue'

export { loadIssues, loadIssue, updateIssue, loadMagazineIssues, loadLatestIssues, loadCategoryIssues, createIssue, createIssueMagazine, deleteIssue }

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATING_ISSUES = 'UPDATING_ISSUES'
export const CLEAR_ALL_ISSUES = 'CLEAR_ALL_ISSUES'

const INCREMENT = 1;
const DECREMENT = 2;

// ------------------------------------
// Actions
// ------------------------------------
export function pushUpdating () {
    return {
        type: UPDATING_ISSUES,
        payload: INCREMENT
    }
}

export function popUpdating () {
    return {
        type: UPDATING_ISSUES,
        payload: DECREMENT
    }
}

/**
 * Public function
 * Tell the store to clear out all the issues
 */
export function clearIssues () {
    return {
        type: CLEAR_ALL_ISSUES
    }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [UPDATING_ISSUES]: (state, action) => {
        if (INCREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating + 1}})
        } else if (DECREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating - 1}})
        } else {
            return state
        }
    },
    
    [LOADED_ISSUES]: (state, action) => handleLoadedIssues(state, action),
    
    [CREATED_ISSUE]: (state, action) => handleCreatedIssue(state, action),
    
    [UPDATED_ISSUE]: (state, action) => handleUpdatedIssue(state, action),

    [CREATED_ISSUE_MAGAZINE]: (state, action) => handleCreatedIssueMagazine(state, action),

    [DELETED_ISSUE]: (state, action) => handleDeletedIssue(state, action),

    [CLEAR_ALL_ISSUES]: (state, action) => (
        update(state, {
            isUpdating: {$set: 0},
            items: {$set: []}
        })
    )
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    isUpdating: 0,
    items: []
}

export default function issuesReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
