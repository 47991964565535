import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'
import { createdCart } from './CreateCart'

/**
 * Actions
 */
export const UPDATED_CART = 'UPDATED_CART'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export const updatedCart = (
    cart = {},
    index = 0
) => ({
    type: UPDATED_CART,
    payload: cart,
    index: index
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleUpdatedCart = (state, action) => update(state, {
    carts: {
        [action.index]: {$set: action.payload}
    }
})


/**
 * Inititator
 */
export const updateCart = (
    cartId = 0,
    data = {}
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/carts/${cartId}`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: data,
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    const { carts } = getState().user
                    const index = carts.map(c => c.id).indexOf(parseInt(resp.data.id))
                    if (index > -1) {
                        dispatch(updatedCart(resp.data, index))
                    } else {
                        dispatch(createdCart(resp.data))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
