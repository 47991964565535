/* @flow */

// Framework
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { Form } from 'react-bootstrap'

// App componetns
import { renderForm } from '../../components/FormHelper'

// State
// ...

// Styles
// import * as classes from './SearchForm.module.scss'

// Constants
// ...

const validate = values => {
    const errors = {}
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

class SearchForm extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    }

    render () {
        const {
        handleSubmit
        } = this.props

        const fields = [
            {
                name: 'query',
                fieldType: 'field',
                placeholder: 'Sök i butiken',
                appendSubmit: {
                    name: 'submit', variant: 'dark', label: 'Sök', fieldType: 'submit'
                },
                specialMainbarSearchViewHack: true
            }
        ]
        
        return (
            <Form onSubmit={handleSubmit}>
                {renderForm(fields)}
            </Form>
        )
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
SearchForm = reduxForm({
    form: 'searchForm',
    validate,
    warn
})(SearchForm)

// You have to connect() to any reducers that you wish to connect to yourself
SearchForm = connect(
    state => ({
    })
)(SearchForm)

export default SearchForm
