/**
 * Helper to handle prices
 */


/**
 * Helper format price.
 * 
 * @param {Integer} unformatted 
 */
export function formatPrice (unformatted) {
    if (!unformatted) {
        return 0;
    }
    return (parseInt(unformatted) / 100).toFixed(2)
}


/**
 * Helper to calculate postage for a specific weight and address.
 * 
 * @param {Object}  postageStore    The postage store.
 * @param {Integer} totalWeight     The weight, in gram, of the entire cart.
 * @param {Boolean} foreign         If the postage is international or not.
 */
export function calculatePostage (postageStore, totalWeight, foreign) {
    const postages = (foreign ? postageStore.international : postageStore.domestic)

    const shipping = [...postages].sort((a, b) => (b.lbound > a.lbound) ? 1 : ((a.lbound > b.lbound) ? -1 : 0))
                   .find(a => (a.lbound < totalWeight))
                   .price

    if (shipping) {
        return shipping
    }

    return [...postages].sort((a, b) => (a.lbound > b.lbound) ? 1 : ((b.lbound > a.lbound) ? -1 : 0))[0]
                   .price
}