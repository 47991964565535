import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, withRouter } from "react-router-dom";

const PrivateRoute = ({ component, token, ...rest }) => {
    let ComponentToRender = component;

    return (
        <Route
            {...rest}
            render={props =>
                token
                ? console.log(`Trying to render component`) || (<ComponentToRender {...props} />)
                : console.log("Redirected") || (<Redirect to={{ pathname: "/", state: { from: props.location } }} />)
            }
        />
    );
};

const mapStateToProps = (state, ownProps) => ({ token: state.user.token });
export default withRouter(connect(mapStateToProps)(PrivateRoute));
