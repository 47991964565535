import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import CartRow from './CartRow';
import { getCartIssues } from '../../cartHelper';
// import * as classes from './CartTable.module.scss';

function CartTable(props) {

    const { cartData, editable, deleteable, onUpdateQuantity, onRemoveIssue, checkable, onPicked } = props;

    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (onPicked) {
            const nbrChecked = rows.filter(r => r.checked === true).length;
            onPicked(nbrChecked === rows.length);
        }
    }, [onPicked, rows]);

    useEffect(() => {
        const issues = getCartIssues(cartData);
        setRows(issues.map((i) => ({issue: i.id, checked: false})));
    }, [cartData])

    const handleRowPicked = (row, isChecked) => {
        const index = rows.map(r => parseInt(r.issue)).indexOf(parseInt(row.id));

        const newRow = {...rows[index], ...{checked: isChecked}};
        const newRows = [...rows.slice(0, index), ...[newRow], ...rows.slice(index + 1)];

        setRows(newRows);
    }

    const handleRemoveIssue = (issueId, cartId) => {
        if (onRemoveIssue) {
            onRemoveIssue(issueId, cartId)
        }
    }

    const handleUpdateQuantity = (issueId, cartId, quantity) => {
        if (onUpdateQuantity) {
            onUpdateQuantity(issueId, cartId, quantity)
        }
    }

    const issues = getCartIssues(cartData);

    const isCheckable = checkable ? checkable : false;

    return (
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th></th>
                    <th><b>Tidskrift</b></th>
                    <th><b>Pris</b></th>
                    <th><b>Antal</b></th>
                    {editable && <th></th>}
                    {deleteable && <th></th>}
                    {checkable && <th><b>Hämtad</b></th>}
                </tr>
            </thead>
            <tbody>
                {issues.map((row) => (
                    <CartRow key={`cart.item.${row.id}`}
                        row={row}
                        editable={editable}
                        deleteable={deleteable}
                        checkable={isCheckable}
                        onPickedChanged={handleRowPicked}
                        onRemoveIssue={handleRemoveIssue}
                        onUpdateQuantity={handleUpdateQuantity} />
                ))}
            </tbody>
        </Table>
    );
}

export default connect((state, ownProps) => ({
}), {
})(CartTable);
