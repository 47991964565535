/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// App componetns
import { renderForm } from '../../components/FormHelper'

// State
// ...

// Styles
// import * as classes from './CreateFriendForm.module.scss'

// Constants
// ...

const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = 'Obligatoriskt'
    } else if (parseInt(values.name) < 1) {
        errors.name = 'Namnet måste vara minst ett tecken långt.'
    }
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

class CreateFriendForm extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired
    }

    render () {
        const {
            handleSubmit, submitting, pristine
        } = this.props

        const submitIcon = (<FontAwesomeIcon icon='plus' />)

        const fields = [
            [
                {fieldType: 'field', name: 'name', label: 'Namn'},
                {fieldType: 'field', name: 'url', label: 'URL'},
                {fieldType: 'submit', name: 'friend-submit', variant: 'dark', label: submitIcon, submitting, pristine}
            ]
        ]
        try {
            return (
                <Form onSubmit={handleSubmit}>
                    {renderForm(fields)}
                </Form>)
        } catch (error) {
            return (<p>error</p>)
        }
    }
}

export default reduxForm({
    form: 'createFriend',
    validate,
    warn
})(CreateFriendForm)
