import update from 'immutability-helper'

export const POPPED_ALERT = 'POPPED_ALERT'

export const popAlert = (uid = undefined, pop_date = new Date()) => ({
    type: POPPED_ALERT,
    payload: {
        uid,
    },
})

export const handlePoppedAlert = (state, action) => {
    const { alerts } = state
    const index = alerts.map(a => a.uid).indexOf(action.payload.uid)

    return update(state, { alerts: { $splice: [[index, 1]] } })
}
