import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { closePrintReceipt } from '../../reducers/modules/modals';
import { PrintReceipt } from '../PrintReceipt/PrintReceipt';
import { useReactToPrint } from 'react-to-print';
import { getCartIssues } from '../../cartHelper';
// import * as classes from './PrintReceiptModal.module.scss';

function PrintReceiptModal(props) {

    const componentRef = useRef();

    const { modal, orders, closePrintReceipt, settings } = props;
    const { isOpen, order } = modal;

    const [issues, setIssues] = useState([]);

    useEffect(() => {
        if (isOpen && order) {
            const {  cart } = order;
            setIssues(getCartIssues(cart));
        } else {
            setIssues([]);
        }
    }, [modal, orders, order, isOpen]);

    const handleCancel = () => {
        closePrintReceipt();
    }

    const pageStyle = `{ size: 70mm }`;

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: pageStyle
    });

    if (!isOpen) {
        return (<div />);
    }
    
    return (
        <Modal size='lg' show={isOpen} onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Skriv ut kvitto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PrintReceipt ref={componentRef} issues={issues} total={order.amount_total} vat={settings.data.VAT} date={order.created_at} />
                
                <Button variant='dark' type='submit' onClick={handlePrint}>Skriv ut</Button>
            </Modal.Body>
        </Modal>
    );
}

export default connect((state) => ({
    modal: state.modals.printReceipt,
    user: state.user.data,
    orders: state.user.orders,
    settings: state.settings
}), {
    closePrintReceipt
})(PrintReceiptModal);
