import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import AccountPages from '../../components/AccountPages';
import OrdersTable from '../../components/OrdersTable';
import PaginationView from '../../components/PaginationView';
import renderLoading from '../../renderLoading';
import { loadOrders } from '../../reducers/modules/user';
import { openPrintReceipt } from '../../reducers/modules/modals';
// import * as classes from './AccountOrdersView.module.scss';

function AccountOrdersView(props) {

    const { user, loadOrders, openPrintReceipt } = props;
    const [loaded, setLoaded] = useState(false);

    const handlePrint = (orderId) => {
        openPrintReceipt(orderId);
    }

    useEffect(() => {
        if (!loaded && user.data) {
            const { id: userId } = user.data;
            setLoaded(true);
            loadOrders(userId);
        }
    }, [loaded, user, loadOrders]);

    const { orders } = user;
    const { data, isUpdating } = orders;
    
    return (
        <Container>
            <Row>
                <Col xs={3}>
                    <AccountPages />
                </Col>
                <Col xs={9}>
                    <Card body>
                        <h4>Mina ordrar</h4>
                        {isUpdating > 0 && renderLoading()}
                        {data && <OrdersTable orders={data} path='account' skip={[1, 9, 10, 11, 12]} showOrderId={true} giveDocument={true} handlePrint={handlePrint} />}
                        {data && <PaginationView {...props} data={orders} />}
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default connect((state, ownProps) => ({
    user: state.user
}), {
    loadOrders,
    openPrintReceipt
})(AccountOrdersView)
