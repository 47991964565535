/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// App componetns
import { pageColumns } from './PagesTable'

// State
import { ACTIVE } from '../../reducers/modules/pages'

// Styles
// ...

// Constants
// ...

class PageRow extends React.Component {
  static propTypes = {
    row: PropTypes.object.isRequired,
    skip: PropTypes.array,
    handleLock: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired
  }

  constructor (props) {
      super(props)

      this.nameRef = React.createRef()
  }

  render () {
    const { row, handleLock, handleDelete, skip = [] } = this.props
    const columns = [...pageColumns].filter((col, index) => !skip.includes(col) && !skip.includes(index))
        
    const activeIcon = parseInt(row.active) === ACTIVE ? 'unlock' : 'lock'

    return (<tr>
      {(columns.indexOf(pageColumns[0]) !== -1) && (<td>{row.id}</td>)}
      {(columns.indexOf(pageColumns[1]) !== -1) && (<td><Link to={`/admin/pages/${row.id}`}>{row.name}</Link></td>)}
      {(columns.indexOf(pageColumns[2]) !== -1) && (<td align='center'><FontAwesomeIcon icon={activeIcon} onClick={() => handleLock(row.id, parseInt(row.active) === ACTIVE)} /></td>)}
      {(columns.indexOf(pageColumns[3]) !== -1) && (<td align='center'><FontAwesomeIcon icon='times' onClick={() => handleDelete(row.id)} /></td>)}
    </tr>)
  }
}

const mapStateToProps = (state) => ({
})
export default connect((mapStateToProps), {
})(PageRow)
