import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import queryString from 'query-string';
import SearchIssue from '../../components/SearchIssue';
import SearchForm from '../../components/SearchForm';
import AdvancedSearchForm from '../../components/AdvancedSearchForm';
import chunk from '../../chunk';
import renderLoading from '../../renderLoading';
import { performSearch, clearSearch, toggleAdvanced } from '../../reducers/modules/search';
import * as classes from './SearchView.module.scss';
import { isStore } from '../../PermissionsHelper';
import { addIssue } from '../../reducers/modules/user';

const MAX_ITEMS_ROW = 6
const COL_SIZE = 12 / MAX_ITEMS_ROW

const renderShelves = (rows) => (
    rows.map((row, index) => (
        <Row key={index} className={[classes.row, 'd-flex', 'justify-content-start']}>
            {row.map((issue) => (
                <Col key={issue.id} xs={COL_SIZE} className={['d-flex', 'align-self-end', 'justify-content-center', 'align-items-start']}>
                    <SearchIssue issue={issue} />
                </Col>
            ))}
        </Row>
    ))
)

function SearchView(props) {

    const { search, performSearch, history, location, toggleAdvanced, user, addIssue } = props;
    const { data, showAdvanced, isUpdating } = search;

    const toggleShowAdvanced = (evt) => {
        toggleAdvanced(!showAdvanced);
    }

    const onSearch = (form) => {
        const urlArgs = queryString.stringify(form);

        if (urlArgs) {
            history.push(`/search?${urlArgs}`);
        }
    }

    useEffect(() => {
        const handleSearch = (query) => {
            if (query) {

                console.log(query)
                performSearch(query)
            }
        }

        const { search } = location;
        handleSearch(search);
    }, [location, performSearch]);

    useEffect(() => {
        const automagicCartEntry = (issueId) => {
            const cartId = user.carts?.[0]?.id;
            if (cartId) {
                addIssue(issueId, cartId, 1);
            }
        }

        if (isStore(user)) {
            const issue = search.data?.[0]
            
            if (issue && search) {
                const { barcode } = issue
                const { search } = location

                // Only do automagic adding to cart when search is done by store-user
                // and if the barcode of the first issue matches the search query.
                if (search.includes(barcode)) {

                    automagicCartEntry(issue.id)
                    history.push('/search')
                }
            }
        }
    }, [search, user, location, history, addIssue]);

    const rows = chunk([...data], MAX_ITEMS_ROW);
    const content = data.length > 0 ? renderShelves(rows) : '';

    return (
        <Container>
            {isUpdating > 0 && renderLoading()}
            <Row>
                <Col><h3 classnames={classes.h3}>Söker efter:</h3></Col>
            </Row>
            <Row>
                <Col>
                    <SearchForm onSubmit={onSearch} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card body>
                        <h4 className={classes.h4}>Detaljerad sökning <Button variant='link' onClick={toggleShowAdvanced}>({showAdvanced ? 'göm' : 'visa'})</Button></h4>
                        {showAdvanced && <AdvancedSearchForm onSubmit={onSearch} />}
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>Hittade {data.length} utgåvor, visar x till y</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    {content}
                </Col>
            </Row>
        </Container>
    );
}

export default connect((state, ownProps) => ({
    search: state.search,
    user: state.user
}), {
    clearSearch,
    performSearch,
    toggleAdvanced,
    addIssue
})(SearchView);
