/* @flow */
import update from 'immutability-helper'

// ------------------------------------
// Constants
// ------------------------------------
export const PUSH_MESSAGE = 'PUSH_MESSAGE'
export const POP_MESSAGE = 'POP_MESSAGE'

// ------------------------------------
// Actions
// ------------------------------------
export function pushMessage (
    title,
    message,
    style
) {
    return {
        type: PUSH_MESSAGE,
        title: title,
        message: message,
        style: style
    }
}
export function popMessage (
    index = 0
) {
    return {
        type: POP_MESSAGE,
        index: index
    }
}

export const actions = {
    pushMessage,
    popMessage
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [PUSH_MESSAGE]: (state, action) => (
        update(state, {
            items: {$push: [{title: action.title, message: action.message, style: action.style}]}
        })
    ),
    [POP_MESSAGE]: (state, action) => (
        {
            items: [
                ...state.items.slice(0, action.index),
                ...state.items.slice(action.index + 1)
            ]
        }
    )
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {items: []}

export default function messageReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
