/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// App componetns
import { renderForm } from '../../components/FormHelper'

// State
// ...

// Styles
// import * as classes from './DomesticForm.module.scss'

// Constants
// ...

const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = 'Obligatoriskt'
    } else if (parseInt(values.name) < 1) {
        errors.name = 'Namnet måste vara minst ett tecken långt.'
    }
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

class DomesticForm extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired
    }

    render () {
        const {
            handleSubmit, submitting, pristine
        } = this.props

        const prefix = 'dom.'
        const submitIcon = (<FontAwesomeIcon icon='plus' />)

        const fields = [
            [
                {fieldType: 'field', key: 'domestic-price', name: 'price', label: 'pris', namePrefix: prefix},
                {fieldType: 'field', key: 'domestic-lbound', name: 'lbound', label: 'min (gram)', namePrefix: prefix},
                {variant: 'dark', name: 'domestic-submit', label: submitIcon, fieldType: 'submit', submitting, pristine}
            ]
        ]
        
        return (
            <Form onSubmit={handleSubmit}>
                {renderForm(fields)}
            </Form>)
    }
}

export default reduxForm({
    form: `createDomesticPostage`,
    validate,
    warn
})(DomesticForm)
