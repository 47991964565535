import update from 'immutability-helper'
import { v4 as uuid } from 'uuid'

export const PUSHED_TOAST = 'PUSHED_TOAST'
// export const READ_TOAST = 'READ_TOAST'

export const pushToast = (
    heading = undefined,
    toast = undefined,
    uid = uuid(),
) => ({
    type: PUSHED_TOAST,
    payload: {
        heading,
        toast,
        uid,
    },
})

export const handlePushedToast = (state, action) =>
    update(state, {
        toasts: { $push: [action.payload] },
    })
