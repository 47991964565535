import update from 'immutability-helper'

import {
    loadUsers,
    LOADED_USERS,
    handleLoadedUsers
} from './LoadUsers'

import {
    loadUser
} from './LoadUser'

import {
    createUser,
    CREATED_USER,
    handleCreatedUser
} from './CreateUser'

import {
    updateUser,
    UPDATED_USER,
    handleUpdatedUser
} from './UpdateUser'

import {
    searchUsers
} from './SearchUsers'

import {
    activateUser,
    deactivateUser
} from './ActivateUser'

export { loadUser, loadUsers, updateUser, createUser, searchUsers }
export { activateUser, deactivateUser }

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATING_USERS = 'UPDATING_USERS'
export const CLEAR_ALL_USERS = 'CLEAR_ALL_USERS'

const INCREMENT = 1;
const DECREMENT = 2;

// ------------------------------------
// Actions
// ------------------------------------
export function pushUpdating () {
    return {
        type: UPDATING_USERS,
        payload: INCREMENT
    }
}

export function popUpdating () {
    return {
        type: UPDATING_USERS,
        payload: DECREMENT
    }
}

/**
 * Public function
 * Tell the store to clear out all the issues
 */
export function clearUsers () {
    return {
        type: CLEAR_ALL_USERS
    }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [UPDATING_USERS]: (state, action) => {
        if (INCREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating + 1}})
        } else if (DECREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating - 1}})
        } else {
            return state
        }
    },

    [LOADED_USERS]: (state, action) => handleLoadedUsers(state, action),

    [CREATED_USER]: (state, action) => handleCreatedUser(state, action),

    [UPDATED_USER]: (state, action) => handleUpdatedUser(state, action),
    
    [CLEAR_ALL_USERS]: (state, action) => (
        update(state, {$set: initialState})
    )
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isUpdating: 0,
  data: [],
  links: undefined,
  meta: undefined,
  showAdvanced: false
}

export default function usersReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
