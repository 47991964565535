/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Img from 'react-image'

// App components
// ...

// State
import { URI_UPLOAD } from '../../reducers/modules/api'

// Styles
import * as classes from './ShortlistTable.module.scss'

// Constants
import MISSING from './missingimage.jpg'

export const shortlistColumns = [
  '0',
  'Titel',
  'Utgåva',
  'Antal',
  '4',
  '5'
]

class ShortlistRow extends React.Component {
  static propTypes = {
    row: PropTypes.object.isRequired,
    skip: PropTypes.array,
    openSendEmail: PropTypes.func.isRequired,
    handleRemove: PropTypes.func.isRequired
  }

  render () {
    const { row, openSendEmail, handleRemove, skip = [] } = this.props
    const columns = [...shortlistColumns].filter((col, index) => !skip.includes(col) && !skip.includes(index))
    const cover = (row.cover ? `http://${URI_UPLOAD}/covers/${row.cover.name}` : MISSING)

    return (<tr>
      {(columns.indexOf(shortlistColumns[0]) !== -1) && (<td><Img src={[cover, MISSING]} className={classes.image} loader={<div />} /></td>)}
      {(columns.indexOf(shortlistColumns[1]) !== -1) && (<td>{row.magazine.title}</td>)}
      {(columns.indexOf(shortlistColumns[2]) !== -1) && (<td>{`${row.year}:${row.yearly_issue}`}</td>)}
      {(columns.indexOf(shortlistColumns[3]) !== -1) && (<td>{row.users_count}</td>)}
      {(columns.indexOf(shortlistColumns[4]) !== -1) && (<td align='center'><FontAwesomeIcon icon={['far', 'envelope']} onClick={() => openSendEmail(row.id)} /></td>)}
      {(columns.indexOf(shortlistColumns[5]) !== -1) && (<td align='center'><FontAwesomeIcon icon='times' onClick={() => handleRemove(row.id)} /></td>)}
    </tr>)
  }
}

const mapStateToProps = (state) => ({
})
export default connect((mapStateToProps), {
})(ShortlistRow)
