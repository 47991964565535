/* @flow */

// Framework
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import queryString from 'query-string'

// App components
// ...

// State
import { cancelPayment } from '../../reducers/modules/checkout'

// Styles
// import * as classes from './AccountCancelUrl.module.scss'

// Constants
//...

export class AccountCancelUrl extends React.Component {
    componentDidMount () {
        const { search } = this.props.location
        const args = queryString.parse(search)
        this.props.cancelPayment({
            orderid: args.orderid,
            error: args.error
        })
    }

    render () {
        return (
            <Container>
                <Row>
                    <Col>
                        <Card body>
                            <h4>Order avbruten</h4>
                            <Link as={Button} to='/' variant='primary'>Fortsätt handla</Link>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
})
export default connect((mapStateToProps), {
    cancelPayment
})(AccountCancelUrl)
