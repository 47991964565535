import request from 'reqwest'
import { URI, PROTOCOL } from '../api'

/**
 * Initiator
 * 
 * @param {*} user 
 */
export const resetPassword = (
    resettoken = undefined,
    password = undefined,
    password_confirmation = undefined
) => {
    return (dispatch, getState) => {
        // dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/auth/reset`,
                method: 'POST',
                crossOrigin: true,
                headers: {},
                data: {
                    resettoken, password, password_confirmation
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    // dispatch(pushMessage('Återställ lösenord', 'Misslyckades att återställa lösenordet.', 'danger'))
                    resolve()
                },
                success (resp) {
                    if (resp.data) {
                        // dispatch(pushMessage('Återställ lösenord', 'Lösenordet är återställt, du har fått mail.', 'success'))
                    }
                    resolve()
                }
            })
        })
    }
}