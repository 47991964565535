/* @flow */

// Framework
import React from 'react'
import { Form, Col, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { renderField } from './renderForm'

// Just a very special hack to remove bottom margin on the main-bar search view!
const getStyleObjectMargins = (removeMargins) => {
    if (removeMargins) {
        return {
            marginBottom: '0'
        };
    }

    return {};
}

export const renderFormField = (props) => {
  const {
    input,
    
    namePrefix,
    type,
    parts,
    as,
    children,
    size = 'sm',

    showLabel = false,
    label = '',
    
    prependText = '',
    prependIcon = '',

    appendLabel = '',

    appendSubmit,
    appendButton,

    specialMainbarSearchViewHack = false,
    
    placeholder,
    meta: { touched, error }
  } = props

  return (
    <Form.Group as={Col} xs={parts} controlId={`${(namePrefix ? namePrefix : '')}${input.name}`} style={getStyleObjectMargins(specialMainbarSearchViewHack)}>
        {showLabel && <Form.Label size={size}>{label}</Form.Label>}
        <InputGroup size={size}>
            {(prependText || prependIcon) && <InputGroup.Prepend>
                    <InputGroup.Text id={`${input.name}Prepend`}>
                    {prependText}{prependIcon && <FontAwesomeIcon icon={prependIcon} />}
                    </InputGroup.Text>
            </InputGroup.Prepend>}      
            <Form.Control
                {...input}
                name={`${(namePrefix ? namePrefix : '')}${input.name}`}
                placeholder={(placeholder ? placeholder : label)}
                type={type}
                value={input.value}
                autoComplete="off"
                as={as}>
                {children}
            </Form.Control>
            <InputGroup.Append >
                {appendLabel && <InputGroup.Text id="basic-addon2">@example.com</InputGroup.Text>}
                {appendSubmit && renderField({...appendSubmit, group: false})}
                {appendButton && renderField({...appendButton, group: false})}
            </InputGroup.Append>
                {(touched && error) &&
                    <Form.Control.Feedback type="invalid" className="d-block">{error}</Form.Control.Feedback>
                }
        </InputGroup>
    </Form.Group>
  )
}
