/* @flow */

// Framework
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// App components
import renderLoading from '../../renderLoading'

// State
import { formatPrice } from '../../PriceHelper'

// Styles
// import * as classes from './OrderTotalsTable.module.scss'

// Constants
// ...

const getPaymentMethod = (paymentMethod) => {
    switch (paymentMethod) {
        case 0:
            return (<><FontAwesomeIcon icon={['far', 'credit-card']} /> VISA/MasterCard</>);
        case 1:
            return (<><FontAwesomeIcon icon={['far', 'envelope']} /> Faktura</>);
        case 2:
            return (<><FontAwesomeIcon icon={['far', 'envelope']} /> Förskott</>);
        default:
            return '';
    }
}

export class OrderTotalsTable extends React.Component {
    static propTypes = {
        order: PropTypes.object.isRequired,
        settings: PropTypes.object.isRequired
    }

    render = () => {
        try {
            const { order } = this.props
            const { data: settings } = this.props.settings
            const vat = parseInt(order.amount_total) - (parseInt(order.amount_total) / (1 + parseInt(settings.VAT) / 100));

            return (
                <Table striped bordered hover size="sm">
                    <tbody>
                        <tr><td colSpan={2}><b>Kvitto</b></td></tr>
                        <tr>
                            <td>Genomfört</td>
                            <td>{order.created_at}</td>
                        </tr>
                        <tr>
                            <td>Transaktionsnummer</td>
                            <td>{order.txnid}</td>
                        </tr>
                        <tr>
                            <td>Betalningsmetod</td>
                            <td>{getPaymentMethod(parseInt(order.checkoutmethod))}</td>
                        </tr>
                        <tr>
                            <td>Ordernummer</td>
                            <td>{order.id}</td>
                        </tr>
                        <tr>
                            <td>Summa</td>
                            <td>{formatPrice(order.amount)} kr.</td>
                        </tr>
                        <tr>
                            <td>Frakt</td>
                            <td>{formatPrice(order.shipping)} kr.</td>
                        </tr>
                        <tr>
                            <td><b>Totalt belopp</b></td>
                            <td><b>{formatPrice(order.amount_total)} kr.</b></td>
                        </tr>
                        <tr>
                            <td>varav moms (6%)</td>
                            <td>{formatPrice(vat)} kr.</td>
                        </tr>
                    </tbody>
                </Table>
            )
        } catch (err) {
            console.error(err)
            return renderLoading()
        }
    }
}

const mapStateToProps = (state, ownProps) => ({
    settings: state.settings
})
export default connect((mapStateToProps), {
})(OrderTotalsTable)
