import React from "react";
import { formatPrice } from "../../PriceHelper";

export const PrintReceipt = React.forwardRef((props, ref) => {

    const { issues, total, vat, date } = props;

    const formatedVat = parseInt(total) - (parseInt(total) / (1 + parseInt(vat) / 100));

    const dateObj = new Date(date)


    return (
        <div ref={ref} style={{ width: '100%', padding: '10px' }}>
            <h3 style={{ width: '100%', textAlign: 'center' }}>Tidskriftsbutiken</h3>

            <p style={{ width: '100%', textAlign: 'center' }}>
                Tidskriftsverkstaden Skåne<br />
                S. Förstadsgatan 18, 211 43 Malmö<br />
                Tel. 040-780 80<br />
                Org.nr: 769603-2692<br />
            </p>

            <table style={{ width: '100%' }}>
                <tbody>
                    {issues.map(({ name, quantity, price }) => (
                        <tr key={`${name}.${quantity}`}><td>{name} x ({formatPrice(price)}) {quantity}</td><td style={{ textAlign: 'right' }}>{formatPrice((quantity * price))}</td></tr>
                    ))}
                    <tr><td></td></tr>
                    <tr><td>TOTALT</td><td style={{ textAlign: 'right' }}>{formatPrice(total)}</td></tr>
                    <tr><td>(varav moms, {vat}% {formatPrice(formatedVat)})</td></tr>
                    <tr><td>{dateObj.toLocaleDateString("sv-SE")} {dateObj.getHours()}:{dateObj.getMinutes()}</td></tr>
                </tbody>
            </table>

            <p style={{width: '100%', textAlign: 'center'}}>
                Tack för besöket<br />och välkommen åter!
            </p>
        </div>
    );
});
