import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import RegisterUserForm from '../../components/RegisterUserForm';
import RegisterCompanyForm from '../../components/RegisterCompanyForm';
import renderLoading from '../../renderLoading';
import { register } from '../../reducers/modules/user';
// import * as classes from './RegisterView.module.scss';

function AccountView({ user, register }) {

    const handleRegister = (form) => {
        register(form);
    }

    const { isUpdating } = user;

    return (
        <Container>
            <Row className="mb-4">
                <Col>
                    <Card body>
                        <Card.Title>Registrera ny användare</Card.Title>
                        <Card.Text>Registrera dig antingen som privatperson eller företag - företag har möjlighet att genomföra beställningar mot faktura.</Card.Text>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Card body>
                        {isUpdating > 0 && renderLoading()}
                        <Card.Title>Privatperson</Card.Title>
                        <RegisterUserForm onSubmit={handleRegister} />
                    </Card>
                </Col>
                <Col xs={6}>
                    <Card body>
                        {isUpdating > 0 && renderLoading()}
                        <Card.Title>Företag</Card.Title>
                        <RegisterCompanyForm onSubmit={handleRegister} />
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default connect(((state, ownProps) => ({
    user: state.user
})), {
    register
})(AccountView);
