import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const CREATED_FRIEND = 'CREATED_FRIEND'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function createdFriend (
    friend = {}
) {
    return {
        type: CREATED_FRIEND,
        payload: friend
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleCreatedFriend = (state, action) => update(state, {
    data: {$push: [action.payload]}
})


/**
 * Inititator
 */
export const createFriend = (
    name = 0,
    url = 0
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/friends`,
                method: 'POST',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: {
                    name, url
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(createdFriend(resp.data))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
