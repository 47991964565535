import update from 'immutability-helper'

import {
    login,
    openLogin,
    closeLogin,
    TOGGLED_LOGIN,
    handleToggledLogin
} from './Login'


import {
    pickOrder,
    openPickOrder,
    closePickOrder,
    TOGGLED_PICK_ORDER,
    handleToggledPickOrder
} from './PickOrder'


import {
    divideOrder,
    openDivideOrder,
    closeDivideOrder,
    TOGGLED_DIVIDE_ORDER,
    handleToggledDivideOrder
} from './DivideOrder'


import {
    buybackOrder,
    openBuybackOrder,
    closeBuybackOrder,
    TOGGLED_BUYBACK_ORDER,
    handleToggledBuybackOrder
} from './BuybackOrder'


import {
    editMagazine,
    openEditMagazine,
    closeEditMagazine,
    TOGGLED_EDIT_MAGAZINE,
    handleToggledEditMagazine
} from './EditMagazine'


import {
    deleteCategory,
    openDeleteCategory,
    closeDeleteCategory,
    TOGGLED_DELETE_CATEGORY,
    handleToggledDeleteCategory
} from './DeleteCategory'


import {
    deleteMagazine,
    openDeleteMagazine,
    closeDeleteMagazine,
    TOGGLED_DELETE_MAGAZINE,
    handleToggledDeleteMagazine
} from './DeleteMagazine'


import {
    notifyInterestedUsers,
    openNotifyInterestedUsers,
    closeNotifyInterestedUsers,
    TOGGLED_NOTIFY_INTERESTED_USERS,
    handleToggledNotifyInterestedUsers
} from './NotifyInterestedUsers'


import {
    setTransactionId,
    openSetTransactionId,
    closeSetTransactionId,
    TOGGLED_SET_TRANSACTION_ID,
    handleToggledSetTransactionId
} from './SetTransactionId'


import {
    confirmDeleteIssue,
    openConfirmDeleteIssue,
    closeConfirmDeleteIssue,
    TOGGLED_CONFIRM_DELETE_ISSUE,
    handleToggledConfirmDeleteIssue
} from './ConfirmDeleteIssue'


import {
    printReceipt,
    openPrintReceipt,
    closePrintReceipt,
    TOGGLED_PRINT_RECEIPT,
    handleToggledPrintReceipt
} from './PrintReceipt'


export { openLogin, closeLogin }
export { openPickOrder, closePickOrder }
export { openDivideOrder, closeDivideOrder }
export { openBuybackOrder, closeBuybackOrder }
export { openEditMagazine, closeEditMagazine }
export { openDeleteCategory, closeDeleteCategory }
export { openDeleteMagazine, closeDeleteMagazine }
export { openNotifyInterestedUsers, closeNotifyInterestedUsers }
export { openSetTransactionId, closeSetTransactionId }
export { openConfirmDeleteIssue, closeConfirmDeleteIssue }
export { openPrintReceipt, closePrintReceipt }

// ------------------------------------
// Constants
// ------------------------------------
export const CLEAR_ALL_MODALS = 'CLEAR_ALL_MODALS'

export function clearAllModals () {
    return {
        type: CLEAR_ALL_MODALS
    }
}

const ACTION_HANDLERS = {
    [TOGGLED_LOGIN]: (state, action) => handleToggledLogin(state, action),

    [TOGGLED_PICK_ORDER]: (state, action) => handleToggledPickOrder(state, action),
    
    [TOGGLED_DIVIDE_ORDER]: (state, action) => handleToggledDivideOrder(state, action),

    [TOGGLED_BUYBACK_ORDER]: (state, action) => handleToggledBuybackOrder(state, action),

    [TOGGLED_EDIT_MAGAZINE]: (state, action) => handleToggledEditMagazine(state, action),

    [TOGGLED_DELETE_CATEGORY]: (state, action) => handleToggledDeleteCategory(state, action),

    [TOGGLED_DELETE_MAGAZINE]: (state, action) => handleToggledDeleteMagazine(state, action),

    [TOGGLED_NOTIFY_INTERESTED_USERS]: (state, action) => handleToggledNotifyInterestedUsers(state, action),

    [TOGGLED_SET_TRANSACTION_ID]: (state, action) => handleToggledSetTransactionId(state, action),

    [TOGGLED_CONFIRM_DELETE_ISSUE]: (state, action) => handleToggledConfirmDeleteIssue(state, action),

    [TOGGLED_PRINT_RECEIPT]: (state, action) => handleToggledPrintReceipt(state, action),

    [CLEAR_ALL_MODALS]: (state, action) => (
        update(state, {$set: initialState})
    )
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    login,
    pickOrder,
    divideOrder,
    buybackOrder,
    editMagazine,
    deleteCategory,
    deleteMagazine,
    notifyInterestedUsers,
    setTransactionId,
    confirmDeleteIssue,
    printReceipt
}


export default function modalsReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
