import update from 'immutability-helper'

/**
 * Actions
 */
export const CHANGED_CHECKOUT_ADDRESS = 'CHANGED_CHECKOUT_ADDRESS'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function changedCheckoutAddress (
    useAlternativeAddress = false
) {
    return {
        type: CHANGED_CHECKOUT_ADDRESS,
        payload: useAlternativeAddress
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleChangeCheckoutAddress = (state, action) => update(state, {
    useAlternativeAddress: {$set: action.payload}
})


/**
 * Inititator
 */
export const changeCheckoutAddress = (
    useAlternativeAddress = false
) => {
    return (dispatch, getState) => {
        dispatch(changedCheckoutAddress(useAlternativeAddress))
    }
}
