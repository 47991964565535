import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'
import { loadedIssues } from './LoadIssues'


/**
 * Initiator
 */
export const loadMagazineIssues = (
    magainzeId = 0
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/magazines/${magainzeId}/issues`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(loadedIssues(resp.data))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
