import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { reduxForm, initialize } from 'redux-form'
import { Form } from 'react-bootstrap'
import { renderForm } from '../../components/FormHelper'
// import * as classes from './SettingsForm.module.scss'

const validate = values => {
  const errors = {}
  // if (!values.first_name) {
  //   errors.first_name = 'Obligatoriskt'
  // } else if (values.first_name.length < 1) {
  //   errors.first_name = 'Ditt förnamn måste vara ifyllt'
  // }
  // if (!values.last_name) {
  //   errors.last_name = 'Obligatoriskt'
  // } else if (values.last_name.length < 1) {
  //   errors.last_name = 'Ditt efternamn måste vara ifyllt'
  // }
  // if (!values.email) {
  //   errors.email = 'Obligatorisk'
  // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
  //   errors.email = 'Ogiltig E-Post adress'
  // }
  return errors
}

const warn = values => {
  const warnings = {}
  return warnings
}

function SettingsForm({ settings, countries, handleSubmit, initialize, submitting, pristine }) {

    useEffect(() => {
        if (settings) {
            initialize(settings);
        } else {
            initialize({});
        }
    }, [settings, initialize]);

    const { items } = countries;
    
    const countriesOptions = items.map((country) => (
        <option key={country.id} value={country.id}>{country.short_name}</option>
    ));

    const fields = [
        [
          { fieldType: 'field', name: 'name', label: 'Namn', showLabel: true },
          { fieldType: 'field', name: 'orgid', label: 'Organisationsnummer', showLabel: true },
          { fieldType: 'field', name: 'orgid_int', label: 'Org.nr., internationellt', showLabel: true }
        ],
        [
          { fieldType: 'field', name: 'bank', label: 'Bank', showLabel: true },
          { fieldType: 'field', name: 'bg', label: 'Bankgiro', showLabel: true },
          { fieldType: 'field', name: 'pg', label: 'Postgiro', showLabel: true }
        ],
        [
          { fieldType: 'field', name: 'iban', label: 'IBAN', showLabel: true },
          { fieldType: 'field', name: 'bic', label: 'BIC', showLabel: true }
        ],
        '-',
        [
          { fieldType: 'field', name: 'email', label: 'E-Post', parts: 4, showLabel: true },
          { fieldType: 'field', name: 'phone', label: 'Telefon', parts: 4, showLabel: true }
        ],
        '-',
        [
          { fieldType: 'field', name: 'street', label: 'Gata', showLabel: true },
          { fieldType: 'field', name: 'zip', label: 'Postnummer', showLabel: true },
          { fieldType: 'field', name: 'city', label: 'Ort', showLabel: true }
        ],
        { fieldType: 'field', name: 'country_id', label: 'Land', as: 'select', parts: 4, showLabel: true, children: countriesOptions },
        '-',
        [
          { fieldType: 'field', name: 'perpage', label: 'Produkter per sida', showLabel: true },
          { fieldType: 'field', name: 'merchantnumber', label: 'E-Pay Merchant Number', showLabel: true },
          { fieldType: 'field', name: 'currency', label: 'E-Pay currency code', showLabel: true }
        ],
        [
          { fieldType: 'field', name: 'VAT', label: 'Moms (hela procentenheter)', showLabel: true },
          { fieldType: 'field', name: 'invoice_days', label: 'Fakturerings period (dagar)', showLabel: true },
          { fieldType: 'field', name: 'filter_months', label: 'Filter: nedlagd tidskrift (månader)', showLabel: true }
        ],
        { name: 'settings-submit', variant: 'dark', label: 'Spara', fieldType: 'submit', submitting, pristine },
    ];

    return (
        <>
            <Form onSubmit={handleSubmit}>
                {renderForm(fields)}
            </Form>
        </>
    );
}

export default connect(
    (state, ownProps) => ({
        countries: state.countries,
        initialize,
        initialValues: ownProps.settings
    })
)(
    reduxForm({
        form: 'settings',
        validate,
        warn,
        enableReinitialize : true
    })(SettingsForm)
);
