import update from 'immutability-helper'

import {
    loadMagazines,
    LOADED_MAGAZINES,
    handleLoadedMagazines
} from './LoadMagazines'

import {
    createMagazine,
    CREATED_MAGAZINE,
    handleCreatedMagazine
} from './CreateMagazine'

import {
    updateMagazine,
    UPDATED_MAGAZINE,
    handleUpdatedMagazine
} from './UpdateMagazine'

import {
    removeMagazine,
    REMOVED_MAGAZINE,
    handleRemovedMagazine
} from './RemoveMagazine'

import {
    loadMagazine
} from './LoadMagazine'

import {
    loadCategoryMagazines
} from './LoadCategoryMagazines'

import {
    loadLatestMagazines
} from './LoadLatestMagazines'

import {
    loadAllMagazines
} from './LoadAllMagazines'

export { loadMagazines, createMagazine, updateMagazine, loadMagazine, removeMagazine }
export { loadCategoryMagazines, loadLatestMagazines, loadAllMagazines }

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATING_MAGAZINES = 'UPDATING_MAGAZINES'
export const CLEAR_ALL_MAGAZINES = 'CLEAR_ALL_MAGAZINES'

const INCREMENT = 1;
const DECREMENT = 2;

// ------------------------------------
// Actions
// ------------------------------------
export function pushUpdating () {
    return {
        type: UPDATING_MAGAZINES,
        payload: INCREMENT
    }
}

export function popUpdating () {
    return {
        type: UPDATING_MAGAZINES,
        payload: DECREMENT
    }
}

/**
 * Public function
 * Tell the store to clear out all the magazines
 */
export function clearMagazines () {
    return {
        type: CLEAR_ALL_MAGAZINES
    }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [UPDATING_MAGAZINES]: (state, action) => {
        if (INCREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating + 1}})
        } else if (DECREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating - 1}})
        } else {
            return state
        }
    },

    [LOADED_MAGAZINES]: (state, action) => handleLoadedMagazines(state, action),

    [CREATED_MAGAZINE]: (state, action) => handleCreatedMagazine(state, action),

    [UPDATED_MAGAZINE]: (state, action) => handleUpdatedMagazine(state, action),

    [REMOVED_MAGAZINE]: (state, action) => handleRemovedMagazine(state, action),

    [CLEAR_ALL_MAGAZINES]: (state, action) => (
        update(state, {$set: initialState})
    )
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    isUpdating: 0,
    data: [],
    links: {},
    meta: {}
}

export default function magazinesReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
