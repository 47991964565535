import request from 'reqwest';
import store from 'store';
import { URI, PROTOCOL } from '../api';
import { pushUpdating, popUpdating } from './index';
import { loadedData } from './LoadData';
import { successfulLogin } from './Login';

/**
 * Initiator
 * 
 * @param {*} userId 
 * @param {*} data 
 */
export const updateData = (
    userId = 0,
    data = {}
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/users/${userId}`,
                method: 'PUT',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: data,
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    if (resp.data) {
                        dispatch(loadedData(resp.data.user))
                        dispatch(successfulLogin(resp.data.token))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
