/* @flow */

// Framework
import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Form, Col } from 'react-bootstrap'
import { renderForm, renderFormRadio } from '../../../components/FormHelper'

const validate = values => {
  const errors = {}
  return errors
}

const warn = values => {
  const warnings = {}
  return warnings
}

function FinancialForm({ handleSubmit, submitting, pristine, handleChange }) {

    const fields = [
        { name: 'magazine.PG', label: 'Postgiro', fieldType: 'field', onChange: handleChange },
        { name: 'magazine.BG', label: 'Bankgiro', fieldType: 'field', onChange: handleChange },
        { name: 'magazine.return', label: 'Returnera gamla ex.', fieldType: 'check', onChange: handleChange },
        { name: 'magazine.VAT', label: 'Momsbefriad', fieldType: 'check', onChange: handleChange }
    ];

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Field
                            name='paymentmethod_id'
                            id='paymentmethod_id'
                            label='PG'
                            component={renderFormRadio}
                            onChange={handleChange} />
                        <Field
                            name='paymentmethod_id'
                            id='paymentmethod_id'
                            label='BG'
                            component={renderFormRadio}
                            onChange={handleChange} />
                        <Field
                            name='paymentmethod_id'
                            id='paymentmethod_id'
                            label='BOTH'
                            component={renderFormRadio}
                            onChange={handleChange} />
                        <Field
                            name='paymentmethod_id'
                            id='paymentmethod_id'
                            label='NONE'
                            component={renderFormRadio}
                            onChange={handleChange} />
                    </Form.Group>
                </Form.Row>
        
                {renderForm(fields)}
            </Form>
        </>
    );
}

export default connect(
    (state, ownProps) => ({
        initialValues: ownProps.issue
    })
)(
    reduxForm({
        form: 'issueFinancial',
        validate,
        warn
    })(FinancialForm)
);
