import update from 'immutability-helper'

export const TOGGLED_BUYBACK_ORDER = 'TOGGLED_BUYBACK_ORDER'

export const handleToggledBuybackOrder = (state, action) => update(state, {
    buybackOrder: {
        isOpen: {$set: action.state},
        orderId: {$set: action.orderId}
    }
})

export function toggledBuybackOrder (
    state = undefined,
    orderId = undefined
) {
    return {
        type: TOGGLED_BUYBACK_ORDER,
        state,
        orderId
    }
}

export const openBuybackOrder = (
    orderId = undefined
) => {
    return (dispatch, getState) => {
        dispatch(toggledBuybackOrder(true, orderId))
    }
}

export const closeBuybackOrder = () => {
    return (dispatch, getState) => {
        dispatch(toggledBuybackOrder(false, undefined))
    }
}

export const buybackOrder = { isOpen: false, orderId: undefined }
