import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { reduxForm, initialize } from 'redux-form'
import { Form } from 'react-bootstrap'
import { renderForm } from '../../components/FormHelper'
// import * as classes from './AccountForm.module.scss'

const validate = values => {
    const errors = {}
    if (!values.first_name) {
        errors.first_name = 'Obligatoriskt'
    } else if (values.first_name.length < 1) {
        errors.first_name = 'Ditt förnamn måste vara ifyllt'
    }
    if (!values.last_name) {
        errors.last_name = 'Obligatoriskt'
    } else if (values.last_name.length < 1) {
        errors.last_name = 'Ditt efternamn måste vara ifyllt'
    }
    if (!values.email) {
        errors.email = 'Obligatorisk'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Ogiltig E-Post adress'
    }
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

function AccountForm({ user, handleSubmit, submitting, pristine, initialize }) {

    useEffect(() => {
        if (user) {
            initialize(user)
        }
    }, [user, initialize]);

    const fields = [
        { name: 'first_name', label: 'Förnamn', icon: 'user', fieldType: 'field' },
        { name: 'last_name', label: 'Efternamn', icon: 'user', fieldType: 'field' },
        { name: 'email', label: 'E-Post', icon: 'envelope', fieldType: 'field' },
        { name: 'submit', variant: 'dark', label: 'Spara', fieldType: 'submit', submitting, pristine }
    ];

    return (
        <>
            <Form onSubmit={handleSubmit}>
                {renderForm(fields)}
            </Form>
        </>
    );
}

export default connect(
    (state, ownProps) => ({
        initialize,
        initialValues: ownProps.user
    })
)(
    reduxForm({
        form: 'account',
        validate,
        warn,
        enableReinitialize: true
    })(AccountForm)
);
