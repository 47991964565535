/* @flow */
import update from 'immutability-helper'

// Load orders
import {
    loadOrders,
    LOADED_ORDERS,
    handleLoadedOrders
} from './LoadOrders'

// Create order
import {
    createOrder,
    CREATED_ORDER,
    handleCreatedOrder
} from './CreateOrder'

// Create order
import {
    updateOrder,
    UPDATED_ORDER,
    handleUpdatedOrder
} from './UpdateOrder'

// Load order
import {
    loadOrder
} from './LoadOrder'

// Delete order
import {
    deleteOrder,
    DELETED_ORDER,
    handleDeletedOrder
} from './DeleteOrder'

// Cancel order
import {
    cancelOrder
} from './CancelOrder'

// Cancel order
import {
    restoreOrder
} from './RestoreOrder'

// Process order
import {
    processOrder
} from './ProcessOrder'

// Split order
import {
    splitOrder
} from './SplitOrder'

// Buy back order
import {
    buybackOrder
} from './BuybackOrder'

export { loadOrders, loadOrder, createOrder, updateOrder, deleteOrder, cancelOrder, restoreOrder, processOrder, splitOrder, buybackOrder }

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATING_ORDERS = 'UPDATING_ORDERS'
export const CLEAR_ALL_ORDERS = 'CLEAR_ALL_ORDERS'

const INCREMENT = 1;
const DECREMENT = 2;

// ------------------------------------
// Actions
// ------------------------------------
export function pushUpdating () {
    return {
        type: UPDATING_ORDERS,
        payload: INCREMENT
    }
}

export function popUpdating () {
    return {
        type: UPDATING_ORDERS,
        payload: DECREMENT
    }
}

/**
 * Public function
 * Tell the store to clear out all the orders
 */
export function clearOrders () {
    return {
        type: CLEAR_ALL_ORDERS
    }
}


// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [UPDATING_ORDERS]: (state, action) => {
        if (INCREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating + 1}})
        } else if (DECREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating - 1}})
        } else {
            return state
        }
    },

    [LOADED_ORDERS]: (state, action) => handleLoadedOrders(state, action),

    [CREATED_ORDER]: (state, action) => handleCreatedOrder(state, action),

    [UPDATED_ORDER]: (state, action) => handleUpdatedOrder(state, action),

    [DELETED_ORDER]: (state, action) => handleDeletedOrder(state, action),

    [CLEAR_ALL_ORDERS]: (state, action) => (
        update(state, {
            isUpdating: {$set: 0},
            data: {$set: []},
            links: {$set: undefined},
            meta: {$set: undefined}
        })
    )
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    isUpdating: 0,
    data: [],
    links: undefined,
    meta: undefined
}

export default function ordersReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
