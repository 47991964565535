import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Card, Button } from 'react-bootstrap';
import CartTable from '../CartTable';
import CustomInvoiceNbrForm from '../CustomInvoiceNbrForm';
import { getAddress } from '../../orderHelper';
import { closePickOrder } from '../../reducers/modules/modals';
import { processOrder } from '../../reducers/modules/orders';
// import * as classes from './PickOrderModal.module.scss';

// Constants
// ...

const renderAddress = (heading, address) => (
    <Card body>
        <h5>{heading}</h5>
        <p>
            {address.map((row, index) => {
                if (row === '1') {
                    // This is because of a bug... the api seems to somehow add a '1' for extraaddress payload. Maybe it's the "checkbox"?
                    return undefined
                }
                if (/^([\d]{1,})$/i.test(row)) {
                    return (<React.Fragment key={`address.row.${index}`}>{row}{'\u00A0'}</React.Fragment>)
                } else {
                    return (<React.Fragment key={`address.row.${index}`}>{row}<br/></React.Fragment>)
                }
            })}
        </p>
    </Card>
)

function PickOrderModal(props) {

    const { modal, orders, closePickOrder, processOrder } = props;

    const [picked, setPicked] = useState(false);
    const [customNumber, setCustomNumber] = useState(undefined);

    const handleCancel = () => {
        setPicked(false);
        closePickOrder();
    }

    const handlePicked = (isPicked) => {
        setPicked(isPicked);
    }

    const handleProcess = () => {
        const { orderId } = modal;
        processOrder(orderId, customNumber);
        closePickOrder();
    }

    const onChangeInvoiceNumber = (customNumber) => {
        setCustomNumber(customNumber);
    }

    const { isOpen, orderId } = modal;

    if (!isOpen) {
        return (
            <div />
        );
    }

    const { data } = orders;
    const index = data.map(o => o.id).indexOf(parseInt(orderId));
    const order = data[index];

    const { cart, checkoutmethod } = order;
    
    const orderType = checkoutmethod === 1 ? 'faktura' : 'transaktions';
    const delivery = getAddress(order);

    return (
        <Modal size='lg' show={isOpen} onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Hantera order {orderId} {customNumber && `(Nytt ${orderType}nummer: ${customNumber}` }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Kvitto/faktura öppnas automatiskt när du bekräftat att ordern är behandlad.</p>
                <CartTable editable={false} deleteable={false} cartData={cart} checkable={true} onPicked={handlePicked} />
                <CustomInvoiceNbrForm handleChange={onChangeInvoiceNumber} orderType={orderType} />
                {renderAddress('Skickas till', delivery)}
                <Button variant='dark' type='submit' onClick={handleProcess} disabled={!picked}>Bekräfta</Button>
            </Modal.Body>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    modal: state.modals.pickOrder,
    orders: state.orders
})
export default connect((mapStateToProps), {
    closePickOrder,
    processOrder
})(PickOrderModal)
