import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const LOADED_SHORTLIST = 'LOADED_SHORTLIST'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function loadedShortlist (
    shortlist = []
) {
    return {
        type: LOADED_SHORTLIST,
        payload: shortlist
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadedShortlist = (state, action) => update(state, {
    shortlists: {
        data: {$set: action.payload.data},
        links: {$set: action.payload.links},
        meta: {$set: action.payload.meta}
    }
})


/**
 * Inititator
 */
export const loadShortlist = (
    userId = undefined
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/users/${userId}/shortlists`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(loadedShortlist(resp))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
