/* @flow */
import update from 'immutability-helper'

import {
    pushToast,
    PUSHED_TOAST,
    handlePushedToast
} from './Push'

import {
    popToast,
    POPPED_TOAST,
    handlePoppedToast
} from './Pop'

export { pushToast, popToast }

// ------------------------------------
// Constants
// ------------------------------------
export const TOASTS_UPDATING = 'TOASTS_UPDATING'

// ------------------------------------
// Actions
// ------------------------------------
export const toastsIsUpdating = (
    value = true
) => ({
    type: TOASTS_UPDATING,
    payload: value
})

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [TOASTS_UPDATING]: (state, action) => (
        update(state, {
            isUpdating: {$set: action.payload}
        })
    ),

    [PUSHED_TOAST]: (state, action) => handlePushedToast(state, action),
    [POPPED_TOAST]: (state, action) => handlePoppedToast(state, action),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    isUpdating: false,
    toasts: []
}

export default function toastsReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
