import update from 'immutability-helper'

// Filter accounting
import {
    filterAccounting,
    FILTERED_ACCOUNTING,
    handleFilteredAccounting
} from './FilterAccounting'

export { filterAccounting }

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATING_ACCOUNTING = 'UPDATING_ACCOUNTING'
export const CLEAR_ACCOUNTING = 'CLEAR_ACCOUNTING'

// ------------------------------------
// Actions
// ------------------------------------
export function accountingIsUpdating (
    state = true
) {
    return {
        type: UPDATING_ACCOUNTING,
        payload: state
    }
}

export function clearAccounting () {
    return {
        type: CLEAR_ACCOUNTING
    }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [UPDATING_ACCOUNTING]: (state, action) => (
        update(state, {
            isUpdating: {$set: action.payload}
        })
    ),

    [FILTERED_ACCOUNTING]: (state, action) => handleFilteredAccounting(state, action),
    
    [CLEAR_ACCOUNTING]: (state, action) => (
        update(state, {
            isUpdating: {$set: false},
            data: {$set: {}}
        })
    )
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    isUpdating: false,
    data: {}
}

export default function accountingReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
