import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const LOADED_USER_ORDERS = 'LOADED_USER_ORDERS'

/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export const loadedOrders = (
    orders = undefined
) => ({
    type: LOADED_USER_ORDERS,
    payload: orders
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadedOrders = (state, action) => update(state, {
    orders: {
        data: {$set: action.payload.data},
        links: {$set: action.payload.links},
        meta: {$set: action.payload.meta}
    }
})


/**
 * Initiator
 */
export const loadOrders = (
    userId = undefined,
    data = undefined
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/users/${userId}/orders`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: data,
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    if (resp.data) {
                        dispatch(loadedOrders(resp))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
