import update from 'immutability-helper'

export const TOGGLED_SET_TRANSACTION_ID = 'TOGGLED_SET_TRANSACTION_ID'

export const handleToggledSetTransactionId = (state, action) => update(state, {
    setTransactionId: {
        isOpen: {$set: action.state},
        orderId: {$set: action.orderId}
    }
})

export function toggledSetTransactionId (
    state = undefined,
    orderId = undefined
) {
    return {
        type: TOGGLED_SET_TRANSACTION_ID,
        state,
        orderId
    }
}

export const openSetTransactionId = (
    orderId = undefined
) => {
    return (dispatch, getState) => {
        dispatch(toggledSetTransactionId(true, orderId))
    }
}

export const closeSetTransactionId = () => {
    return (dispatch, getState) => {
        dispatch(toggledSetTransactionId(false, undefined))
    }
}

export const setTransactionId = { isOpen: false, orderId: undefined }
