/* @flow */

// Framework
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { Form } from 'react-bootstrap'

// App componetns
import { renderForm } from '../FormHelper'

// State
// ...

// Styles
// import * as classes from './OrbitUploadForm.module.scss'

// Constants
// ...

const validate = values => {
    const errors = {}
    return errors
}

const warn = values => {
    const warnings = {}
    return warnings
}

class OrbitUploadForm extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired
    }

    handleOnDrop (files) {
        console.log(files)
    }

    render () {
        const {
            handleSubmit, //submitting, pristine
        } = this.props

        const fields = [
            {
                name: 'image',
                text: 'Släpp en bild här, eller klicka inne i det sträckade området, för att ladda upp nytt bildspel.',
                showLabel: false,
                handleOnDrop: this.handleOnDrop,
                fieldType: 'dropzone'
            }
        ]

        return (
            <Form onSubmit={handleSubmit}>
                {renderForm(fields)}
            </Form>
        )
    }
}

OrbitUploadForm = reduxForm({
    form: 'orbitUpload',
    validate,
    warn
})(OrbitUploadForm)

OrbitUploadForm = connect(
    state => ({
    })
)(OrbitUploadForm)

export default OrbitUploadForm
