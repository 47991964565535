import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating} from './index'

/**
 * Actions
 */
export const REMOVED_CATEGORY = 'REMOVED_CATEGORY'


/**
 * Dispatcher
 * 
 * @param {*} index 
 */
export function removedCategory (
    index = undefined
) {
    return {
        type: REMOVED_CATEGORY,
        index: index
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleRemovedCategory = (state, action) => update(state, {
    data: {$splice: [[action.index, 1]]}
})


/**
 * Inititator
 * 
 * @param {*} userId 
 */
export const removeCategory = (
    categoryId = undefined
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/categories/${categoryId}`,
                method: 'DELETE',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    const index = getState().categories.data.map(c => c.id).indexOf(parseInt(resp.data.id))
                    if (index > -1) {
                        dispatch(removedCategory(index))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
