import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'
import { pushAlert } from '../alerts'

/**
 * Actions
 */
export const REMOVED_FROM_SHORTLIST = 'REMOVED_FROM_SHORTLIST'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function removedFromShortlist (
    index = undefined
) {
    return {
        type: REMOVED_FROM_SHORTLIST,
        payload: index
    }
}
 
 
/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleRemovedFromShortlist = (state, action) => update(state, {
    shortlists: {
        data: {$splice: [[action.payload, 1]]}
    }
})


/**
 * Inititator
 * 
 * @param {*} userId 
 */
export const removeFromShortlist = (
    issueId = 0
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            const { id: userId } = getState().user.data
            request({
                url: `${PROTOCOL}://${URI}/users/${userId}/shortlists/${issueId}`,
                method: 'DELETE',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    let { response } = err
                    let error = JSON.parse(response)
                    dispatch(pushAlert('Önskelistan', error.messages[0]))
                    resolve()
                },
                success (resp) {
                    const { issueId } = resp.data
                    const index = getState().user.shortlists.data.map(s => s.id).indexOf(parseInt(issueId))
                    if (index > -1) {
                        dispatch(removedFromShortlist(index))
                    }
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}
