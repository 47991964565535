/* @flow */
import request from 'reqwest'
import store from 'store'
import update from 'immutability-helper'
import { URI, PROTOCOL } from './api'

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATING_SETTINGS = 'UPDATING_SETTINGS'
export const UPDATED_SETTINGS = 'UPDATED_SETTINGS'
export const CLEAR_ALL_SETTINGS = 'CLEAR_ALL_SETTINGS'

const INCREMENT = 1;
const DECREMENT = 2;

// ------------------------------------
// Actions
// ------------------------------------
export function pushUpdating () {
    return {
        type: UPDATING_SETTINGS,
        payload: INCREMENT
    }
}

export function popUpdating () {
    return {
        type: UPDATING_SETTINGS,
        payload: DECREMENT
    }
}

/**
 * Private function
 * Pass the store a specific setting
 *
 * @param setting     The loaded settings
 */
export function updatedSettings (
  setting = {}
) {
  return {
    type: UPDATED_SETTINGS,
    payload: setting
  }
}

/**
 * Public function
 * Attempt to fetch the list of all settings
 */
export const loadSettings = () => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/settings`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(updatedSettings(resp.data))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}

/**
 * Public function
 * Attempt to update the settings
 */
export const updateSettings = (
    settingId = 0,
    data = {}
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/settings/${settingId}`,
                method: 'PUT',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: data,
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(updatedSettings(resp.data))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}

/**
 * Public function
 * Attempt to fetch a specific setting
 *
 * @param setting     The setting id
 */
export const loadSetting = (
    setting = 0
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/settings/${setting}/`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    dispatch(updatedSettings(resp.data))
                    dispatch(popUpdating())
                    resolve()
                }
            })
        })
    }
}

/**
 * Public function
 * Tell the store to clear out all the settings
 */
export function clearSettings () {
    return {
        type: CLEAR_ALL_SETTINGS
    }
}

export const actions = {
    loadSettings,
    loadSetting,
    clearSettings,
    updateSettings
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [UPDATING_SETTINGS]: (state, action) => {
        if (INCREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating + 1}})
        } else if (DECREMENT === action.payload) {
            return update(state, { isUpdating: {$set: state.isUpdating - 1}})
        } else {
            return state
        }
    },
    [UPDATED_SETTINGS]: (state, action) => (
        update(state, {
            data: {$set: action.payload}
        })
    ),
    [CLEAR_ALL_SETTINGS]: (state, action) => (
        update(state, {
            data: {$set: {}}
        })
    )
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    isUpdating: 0,
    data: undefined
}

export default function settingsReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
