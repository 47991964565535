import update from 'immutability-helper'

/**
 * Actions
 */
export const DID_SET_CART_ID = 'DID_SET_CART_ID'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export function didSetCartId (
    cartId = -1
) {
    return {
        type: DID_SET_CART_ID,
        payload: cartId
    }
}


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleDidSetCartId = (state, action) => update(state, {
    cartId: {$set: action.payload}
})


/**
 * Inititator
 */
export const setCartId = (
    cartId = -1
) => {
    return (dispatch, getState) => {
        dispatch(didSetCartId(cartId))
    }
}
