import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { cartsIsUpdating } from './index'
import { cartUpdated } from './UpdateCart'
import { pushAlert } from '../alerts'
import { getCartIssues } from '../../../cartHelper'
import { pushToast } from '../toasts'
  
export const setQuantity = (issueId, cartId, quantity) => {
    return (dispatch, getState) => {
        dispatch(cartsIsUpdating(true))
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/carts/${cartId}/issues/${issueId}`,
                method: 'PUT',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                data: {
                    quantity: quantity
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(cartsIsUpdating(false))
                    let { response } = err
                    let error = JSON.parse(response)
                    dispatch(pushAlert('Varukorgen', error.messages))
                    resolve()
                },
                success (resp) {
                    const { carts } = getState().user
                    const index = carts.map(c => c.id).indexOf(parseInt(resp.data.id))

                    const issues = getCartIssues(resp.data.data)
                    const changedIssue = issues.find((issue) => (issue.id === issueId))

                    if (index > -1) {
                        dispatch(cartUpdated(resp.data, index))
                    
                        if (quantity === 0) {
                            dispatch(pushToast(undefined, `Tog bort tidskriften från varukorgen.`))
                        } else {
                            dispatch(pushToast(undefined, `Det finns nu ${quantity} st. ${changedIssue.name} i varukorgen.`))
                        }
                    } else {
                        dispatch(cartsIsUpdating(false))
                    }
                    resolve()
                }
            })
        })
    }
}
