import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import AdminPages from '../../components/AdminPages';
import renderLoading from '../../renderLoading';
import RegisterUserForm from '../../components/RegisterUserForm';
import { createUser } from '../../reducers/modules/users';
// import * as classes from './AdminCreateUserView.module.scss';

function AdminCreateUserView({ users, createUser }) {

    const handleRegisterUser = (form) => {
        createUser(form);
    }

    const { isUpdating } = users;

    return (
        <Container>
            <Row>
                <Col xs={2}>
                    <AdminPages />
                </Col>
                <Col xs={10}>
                    <Card body>
                        <h4>Information</h4>
                        <p>Här kan du skapa en ny användare i webbutiken, användaren kommer att motta ett mail precis som om användaren själv hade registrerat sig.</p>
                    </Card>
                    <Card body>
                        {isUpdating > 0 && renderLoading()}
                        <RegisterUserForm onSubmit={handleRegisterUser} />
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default connect((state, ownProps) => ({
    users: state.users
}), {
    createUser
})(AdminCreateUserView);
