/* @flow */

// Framework
import React from 'react'
import { Form, Col, InputGroup } from 'react-bootstrap'

export const renderFormSwitch = ({
  input,
  namePrefix,
  showLeftLabel,
  size = 'sm',

  showLabel = false,
  label = '',

  meta: { touched, error, warning }
}) => (
  <Form.Group as={Col} controlId={`${(namePrefix ? namePrefix : '')}${input.name}`}>
    {showLabel && <Form.Label size={size}>{label}</Form.Label>}
    <InputGroup>
      {showLeftLabel && <Form.Label column sm={6}>{label}</Form.Label>}
      <Form.Check
        {...input}
        onChange={value => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        type='switch'
        label={label} />
    </InputGroup>
  </Form.Group>
)
