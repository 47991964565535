import { unserialize, serialize } from 'php-serialize'
import { calculatePostage } from './PriceHelper'

export { unserialize }

export const urldecode = (str) => {
    return decodeURIComponent((str + '').replace(/\+/g, '%20'))
}

export const urlencode = (str) => {
    return encodeURIComponent((str + '').replace(/\+/g, '%20'))
}

export const makeCartData = (issues) => {
    const serialized = serialize(issues)
    return urlencode(serialized)
}

/**
 * Get all issues of the cart.
 * 
 * @param {*} cartData 
 */
export const getCartIssues = (cartData) => {
    try {
        const decoded = urldecode(cartData);
        const unserialized = unserialize(decoded);
        return Object.keys(unserialized).map((key) => unserialized[key]);
    } catch (err) {
        return [];
    }
}

/**
 * Get the total number of items in the cart.
 * 
 * @param {*} issues 
 */
export const getCartItems = (issues) => {
    return issues.reduce((t, {quantity}) => {
        return parseInt(t) + parseInt(quantity)
    }, 0)
}

/**
 * Get the total weight for this cart.
 * 
 * @param {*} issues    The props.
 */
export const getTotalWeight = (issues) => {
    return issues.reduce((t, {quantity, weight}) => {
        return parseInt(t) + (parseInt(quantity) * parseInt(weight))
    }, 0)
}

/**
 * Calculate the postage for this cart.
 * 
 * @param {*} postageState  The props.
 * @param {*} issues        The issues, decoded.
 */
export const getCartPostage = (postageState, storeCountryId, userCountryId, issues, isStore) => {
    if (isStore) {
        return 0
    }
    const internationalPostage = storeCountryId !== userCountryId
    return calculatePostage(postageState, getTotalWeight(issues), internationalPostage)
}

/**
 * Calculate the postage for this cart.
 * 
 * @param {*} postageState  The props.
 * @param {*} totalWeight   The total weight, use getTotalWeight func.
 */
export const getPostage = (postageState, totalWeight) => {
    const internationalPostage = this.props.settings.data.country_id !== this.props.user.address.country_id
    return calculatePostage(postageState, totalWeight, internationalPostage)
}


/**
 * Calculate the price for this cart.
 * 
 * @param {*} issues    The props.
 */
export const getCartPrice = (issues) => {
    return issues.reduce((t, {quantity, price}) => {
        return parseInt(t) + (parseInt(quantity) * parseInt(price))
    }, 0)
}