import update from 'immutability-helper'
import request from 'reqwest'
import store from 'store'
import { URI, PROTOCOL } from '../api'
import { pushUpdating, popUpdating } from './index'

/**
 * Actions
 */
export const LOADED_USER_ADDRESS = 'LOADED_USER_ADDRESS'


/**
 * Dispatcher
 * 
 * @param {*} token 
 */
export const loadedAddress = (
    address = {}
) => ({
    type: LOADED_USER_ADDRESS,
    payload: address
})


/**
 * Handler
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const handleLoadedAddress = (state, action) => update(state, {
    address: {$set: action.payload}
})


/**
 * Inititator
 * 
 * @param {*} userId 
 */
export const loadAddress = (
    userId = 0
) => {
    return (dispatch, getState) => {
        dispatch(pushUpdating())
        return new Promise((resolve) => {
            request({
                url: `${PROTOCOL}://${URI}/users/${userId}/addresses`,
                method: 'GET',
                crossOrigin: true,
                headers: {
                    'X-Tb-Token': store.get('tbwt')
                },
                error (err) {
                    console.error(JSON.stringify(err))
                    dispatch(popUpdating())
                    resolve()
                },
                success (resp) {
                    if (resp.data) {
                        dispatch(loadedAddress(resp.data))
                        dispatch(popUpdating())
                    }
                    resolve()
                }
            })
        })
    }
}
    