import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import LoginForm from '../LoginForm/LoginForm';
import { closeLogin } from '../../reducers/modules/modals';
import { login, requestPassword } from '../../reducers/modules/user';
import renderLoading from '../../renderLoading';
// import * as classes from './LoginModal.module.scss';

function LoginModal({ user, modal, login, closeLogin, requestPassword, history }) {

    const handleCancel = () => {
        closeLogin()
    }

    const handleRegister = () => {
        closeLogin()
        history.push('/register')
    }

    const handleResetPassword = (email) => {
        closeLogin()
        requestPassword(email)
    }

    const formValidated = (form) => {
        login(form.email, form.password)
    }

    const { isOpen } = modal;
    const { isUpdating } = user;

    return (
        <Modal show={isOpen} onHide={handleCancel}>
            {isUpdating > 0 && renderLoading()}
            <Modal.Header closeButton>
                <Modal.Title>Logga in</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoginForm onSubmit={formValidated} handleRegister={handleRegister} handleResetPassword={handleResetPassword} />
            </Modal.Body>
        </Modal>
    );
}

export default connect((state) => ({
    modal: state.modals.login,
    user: state.user
}), {
    closeLogin,
    login,
    requestPassword
})(LoginModal);
