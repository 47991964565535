import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Badge } from 'react-bootstrap';
import CartTable from '../CartTable';
import { getCartIssues, makeCartData } from '../../cartHelper';
import { closeBuybackOrder } from '../../reducers/modules/modals';
import { buybackOrder } from '../../reducers/modules/orders';
// import * as classes from './BuybackOrderModal.module.scss';

function BuybackOrderModal(props) {

    const { modal, orders, closeBuybackOrder, buybackOrder } = props;

    const [loaded, setLoaded] = useState(false);
    const [issues, setIssues] = useState([]);
    const [cartData, setCardData] = useState({});

    useEffect(() => {
        setLoaded(true)
    }, [setLoaded]);

    useEffect(() => {
        const { orderId, isOpen } = modal;
        if (isOpen) {
            const { data } = orders;
            const index = data.map(o => o.id).indexOf(parseInt(orderId));
            const order = data[index];
            const { cart } = order;

            const issues = getCartIssues(cart);

            setIssues(issues);
        } else {
            setIssues([]);
        }
    }, [modal, orders]);

    useEffect(() => {
        if (loaded) {
            setCardData(makeCartData(issues));
        }
    }, [issues, loaded]);

    const handleCancel = () => {
        closeBuybackOrder()
    }

    const handleBuyback = () => {
        const { orderId } = modal;
        buybackOrder(orderId, issues);
        closeBuybackOrder();
    }

    const handleUpdateQuantity = (issueId, cartId, quantity) => {
        const issues = getCartIssues(cartData);
        const index = issues.map(i => parseInt(i.id)).indexOf(parseInt(issueId));
        
        const issue = {...issues[index], ...{quantity: parseInt(quantity)}};
        const newIssues = [...issues.slice(0, index), ...[issue], ...issues.slice(index + 1)];

        setIssues(newIssues);
    }

    const { isOpen, orderId } = modal;

    if (!isOpen) {
        return (<div />);
    }

    const { data } = orders;
    const index = data.map(o => o.id).indexOf(parseInt(orderId));
    const order = data[index];
    const { cart } = order;

    return (
        <Modal size='lg' show={isOpen} onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Återköp {orderId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Den här dialogen tillåter dig att återköpa enskilda tidskrifter i en order. Du väljer hur många av varje tidskrift du vill skall vara kvar i ordern.</p>
                <p><Badge variant="danger">OBS, öka inte antalet tidskrifter.</Badge></p>
                <h5>Välj vilka tidskrifter som skall vara kvar i ordern.</h5>
                <CartTable
                    editable={true}
                    deleteable={false}
                    cartData={cart}
                    onUpdateQuantity={handleUpdateQuantity} />
                <Button variant='dark' type='submit' onClick={handleBuyback}>Bekräfta</Button>
            </Modal.Body>
        </Modal>
    );
}

export default connect((state) => ({
    modal: state.modals.buybackOrder,
    orders: state.orders
}), {
    closeBuybackOrder,
    buybackOrder
})(BuybackOrderModal);
